import React, {PropsWithChildren} from "react";

export interface DataGridCellProps extends PropsWithChildren {
    details?:boolean
    left?:boolean
    right?:boolean
    center?:boolean
}

export const DataGridCell:React.FC<DataGridCellProps> = ({children, details, left, right, center}) => {
    const classes = `${details ?'details':''} ${left ? 'left': right ? 'right': center ? 'center':''}`
    return (
        <td className={classes}>
            {children}
        </td>
    )
}

