import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import "react-tooltip/dist/react-tooltip.css";
import "./Tooltip.scss";

export enum TooltipPosition {
    top = "top",
    bottom = "bottom"
}

export interface TagProps  {
    position: TooltipPosition;
    id: string;
    label: string;
    center?: boolean;
    classNames?: string;
    children: React.ReactElement;
}

export const Tooltip: React.FC<TagProps> = ({ position, id, label, center = false, classNames, children }) => {
    return (
        <>
            <div data-tooltip-id={id} data-tooltip-content={label} className={`tooltip-element ${classNames ? classNames: ''}`}>{children}</div>
            <ReactTooltip id={id} place={position} className={`tooltip-gt ml-1 ${center ? 'tooltip-text-center': ''}`} />
        </>
    )
}