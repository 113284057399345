import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { printDateFromIso } from '../../../i18n';
import { App, AppStatus, useApps, useCreateApp, useUpdateApp } from '../../../model/app';
import { AppCreateEdit } from './AppCreateEdit';
import {
    Button,
    ButtonType,
    Container,
    DataGridCell,
    DataGridContent,
    DataGridFooter,
    DataGridHeader,
    DataGridRow,
    Dot, Loader, LoaderSize, PlainColor,
} from '../../amethyst';

const statusToColor = (status: AppStatus) => {
    switch (status) {
        case AppStatus.Online: return PlainColor.green;
        case AppStatus.Maintenance: return PlainColor.orange;
        case AppStatus.Offline: return PlainColor.red;
        default: return PlainColor.softgray;
    }
}

export const AppCrud:React.FC<any> = () => {
    const { apps, loading } = useApps();
    const { i18n, t } = useTranslation();
    const i18nEnums = useTranslation('enums');
    const [ createApp ] = useCreateApp();
    const [ updateApp ] = useUpdateApp();
    const [showEditForm, setShowEditForm ] = useState<boolean>(false);
    const [appToEdit, setAppToEdit] = useState<App|undefined>(undefined);

    const onOpenEditForm = (app: App|undefined = undefined) => {
        setAppToEdit(!!app ? {...app} : undefined);
        setShowEditForm(true);
    }

    return (
        <>
            <AppCreateEdit
                onCreate={createApp}
                onUpdate={updateApp}
                appToEdit={appToEdit}
                show={showEditForm}
                close={() => setShowEditForm(false)}
            />
            <Container fluid>
                <DataGridHeader title={t("AppManagement.Title")}>
                    <Button kind={ButtonType.primary} label={t("AppManagement.AddApp")} onClick={() => onOpenEditForm()} />
                </DataGridHeader>
                { loading ? <Loader label={t("AppManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("AppManagement.Name"),
                            t("AppManagement.Status"),
                            t("AppManagement.NextMaintenance"),
                            t("AppManagement.Target"),
                            t("AppManagement.ProductPage")
                        ]}>
                            {
                                apps.map((app, index) =>
                                    <DataGridRow left key={index} >
                                        <DataGridCell>{app.name}</DataGridCell>
                                        <DataGridCell><Dot color={statusToColor(app.status)} />{i18nEnums.t(`AppStatus.${app.status}`)}</DataGridCell>
                                        <DataGridCell>{(app.status === AppStatus.Maintenance && !!app.maintenanceWindow) ? `${printDateFromIso(app.maintenanceWindow.from, i18n.language)} - ${printDateFromIso(app.maintenanceWindow.to, i18n.language)}` : t("AppManagement.None")}</DataGridCell>
                                        <DataGridCell>{app.url}</DataGridCell>
                                        <DataGridCell>{app.fallbackUrl}</DataGridCell>
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} title={t("AppManagement.EditApp")} icon="chevron-small-right" aria-label={t("AppManagement.EditApp")} onClick={() => onOpenEditForm(app)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                        <DataGridFooter />
                    </>
                }
            </Container>
        </>
    )
}
