import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow, Message, MessageSize } from '../amethyst';

export const ContentNotFound = () => {
    const { t } = useTranslation();
    return <Grid app fluid >
        <GridRow center>
            <GridColumn>
                <Message size={MessageSize.large} icon="glasses" label={t("MyGT.ErrorContentNotFound")} />
            </GridColumn>
        </GridRow>
    </Grid>
}