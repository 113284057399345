import { gql } from '@apollo/client';

export const QueryGetApps = gql`
    query GetApps {
      apps {
        _id,
        name,
        description,
        url,
        fallbackUrl,
        logo {
            _id,
            filename,
            mimeType,
            downloadUrl
        },
        status,
        maintenanceWindow { from, to },
        subscriptions { customer { _id, name, disabled } }
      }
    }    
`;

export const QueryGetAppById = gql`
    query GeyAppsById($_id: ObjectId!) {
        app(_id: $_id) {
            _id,
            name,
            description,
            url,
            fallbackUrl,
            logo {
                _id,
                filename,
                mimeType,
                downloadUrl
            },
            status,
            maintenanceWindow { from, to },
            subscriptions { customer { _id, name, disabled } }
        }
    }
`;

export const MutationCreateApp = gql`
    mutation CreateApp($app: AppInput!) {
        createApp(app: $app) {
            _id,
            name,
            description,
            url,
            fallbackUrl,
            logo {
                _id,
                filename,
                mimeType,
                downloadUrl
            },
            status,
            maintenanceWindow { from, to },
            subscriptions { customer { _id, name, disabled } }
        }
    }
`;

export const MutationUpdateApp = gql`
    mutation UpdateApp($_id: ObjectId!, $app: AppInput!) {
        updateApp(_id: $_id, app: $app) {
            _id,
            name,
            description,
            url,
            fallbackUrl,
            logo {
                _id,
                filename,
                mimeType,
                downloadUrl
            },
            status,
            maintenanceWindow { from, to },
            subscriptions { customer { _id, name, disabled } }
        }
    }
`;

export const MutationUpdateAppSubscription = gql`
    mutation UpdateAppSubscription($subscription: SubscriptionInput!) {
        updateAppSubscription(subscription: $subscription) {
            _id,
            name,
            description,
            url,
            fallbackUrl,
            logo {
                _id,
                filename,
                mimeType,
                downloadUrl
            },
            status,
            maintenanceWindow { from, to },
            subscriptions { customer { _id, name, disabled } }
        }
    }
`;

export const MutationUpdateAppSubscriptions = gql`
    mutation UpdateAppSubscriptions($subscriptions: SubscriptionsInput!) {
        updateAppSubscriptions(subscriptions: $subscriptions) {
            _id,
            name,
            description,
            url,
            fallbackUrl,
            logo {
                _id,
                filename,
                mimeType,
                downloadUrl
            },
            status,
            maintenanceWindow { from, to },
            subscriptions { customer { _id, name, disabled } }
        }
    }
`;

export const QueryGetAppsUsersSubscriptionByCustomerId = gql`
    query QueryGetAppsUsersSubscriptionByCustomerId($customerId: ObjectId!) {
        appsUsersSubscriptions(customerId: $customerId) {
            _id,
            name,
            customerId,
            customerUserIds
        }
    }
`

