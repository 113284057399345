import React, { PropsWithChildren } from "react";
import "./Container.scss";

interface ContainerProps<T extends React.ElementType> extends PropsWithChildren {
    container?:boolean
    fluid?:boolean
    app?:boolean
    className?: string
    as?: T
}

export const Container =
    <T extends React.ElementType = "div">({
        fluid = false,
        container = false,
        app = false,
        as,
        className,
        children,
        ...props}: ContainerProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ContainerProps<T>>
    ) => {
    const Component = as || "div";
    return (
        <Component {...props} className={`${container ?'container':''} ${fluid? 'container-fluid':''} ${app?'container-app':''} ${className? className:'' }`}>
            {children}
        </Component>
    )
}

export interface HomeContainerProps extends PropsWithChildren {}

export const HomeContainer:React.FC<HomeContainerProps> = ({children}) => {
    return (
        <Container id="content" as="main" app className="container-app-logged-in">
            {children}
        </Container>
    )
}
