import React, {PropsWithChildren} from 'react'
import "./Form.scss"

export interface FormProps extends PropsWithChildren {
    id:string
    inline?:boolean
    title?:string
}

export interface FormGroupProps extends PropsWithChildren {
    legend?:string
    disabled?:boolean
}

export const Form:React.FC<FormProps> = ({id, inline, title, children}) => {
    return (
        <>
            {title ? <h4 className="form-title">{title}</h4> : null}
            <form className={`form ${inline ? 'inline':''}`} id={id}>
                {children}
            </form>
        </>

    )
}

export const FormGroup:React.FC<FormGroupProps> = ({legend, disabled, children}) => {
    return (
        <fieldset className="fields" disabled={disabled}>
            {legend? <legend>{legend}</legend> : null }
            {children}
        </fieldset>
    )
}