import React, { createContext, useContext, useState, PropsWithChildren, useLayoutEffect } from 'react';
import { persistedCustomerAccount } from './persistedCustomerAccount';
import { Me } from '../../../model/user';

/**
 * React context to provide current customer account id
 */
interface ICurrentCustomerContext {
    currentCustomerId:string|null,
    setCustomerId:(customerId: string) => void
}

const CurrentCustomerContext = createContext<ICurrentCustomerContext>({
    currentCustomerId:null,
    setCustomerId:() => null
})

export interface CurrentCustomerProviderProps extends PropsWithChildren {
    me:Me|null
}

/**
 * CurrentCustomerContext provider component
 * @param me
 * @param children
 * @constructor
 */
export const CurrentCustomerProvider:React.FC<CurrentCustomerProviderProps> = ({me, children}) => {
    const [currentCustomerId, setCurrentCustomerId ] = useState<string|null>(null);

    /**
     * Refresh currentCustomerId based on "me" and persisted value
     */
    useLayoutEffect(() => {
        if(me && me.customerProperties && me.numberOfCustomerRelationships > 0) {
            if(persistedCustomerAccount.customerAccount) {
                // check for valid persisted customerId against customer user relationship
                const validCustomerId = me.customerProperties.relationships.find(r => r.customer._id === persistedCustomerAccount.customerAccount?.customerId);
                if(!!validCustomerId) {
                    setCurrentCustomerId(validCustomerId.customer._id);
                } else {
                    persistedCustomerAccount.remove(); // If invalid customerId is stored let's remove it
                    setCurrentCustomerId(me.customerProperties.relationships[0].customer._id)
                }
            } else {
                setCurrentCustomerId(me.customerProperties.relationships[0].customer._id);
            }
        } else {
            setCurrentCustomerId(null);
        }
    }, [me]);

    /**
     * Update persisted customer id in local storage
     * @param newCustomerId
     */
    const onChangeCustomer = (newCustomerId:string) => {
        persistedCustomerAccount.save({customerId:newCustomerId});
        setCurrentCustomerId(newCustomerId);
    }

    return (
        <CurrentCustomerContext.Provider value={{currentCustomerId:currentCustomerId, setCustomerId:onChangeCustomer}}>
            {children}
        </CurrentCustomerContext.Provider>
    )
}

/**
 * Custom hook to access CurrentCustomerContext
 */
export const useCurrentCustomer = ():ICurrentCustomerContext => {
    return useContext(CurrentCustomerContext);
}
