interface appConfig {
    azB2C_clientId:string,
    azB2C_tenantId:string,
    mygt_api_graphql:string,
    mygt_api_blob:string
    website_name:string
}

export const config:appConfig = {
    azB2C_clientId:(process.env.REACT_APP_AZ_B2C_CLIENT_ID || ""),
    azB2C_tenantId:(process.env.REACT_APP_AZ_B2C_TENANT_ID || ""),
    mygt_api_graphql:`${(process.env.REACT_APP_MYGT_API_URL || "")}/graphql`,
    mygt_api_blob:`${(process.env.REACT_APP_MYGT_API_URL || "")}/blob`,
    website_name:(process.env.REACT_APP_WEBSITE_NAME || "MyGTexperience")
}
