import { useQueryParams, NumberParam, withDefault } from 'use-query-params';
import { PaginationFilter, DEFAULT_PAGE_SIZE } from '../../amethyst'

type UseCustomerQuery = [updateCustomerQuery:(pagination?: PaginationFilter) => void, pagination: PaginationFilter]

export const useCustomersQuery = (pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}):UseCustomerQuery => {
    const [query, setQuery] = useQueryParams({
        p: withDefault(NumberParam, pagination.page),
        s: withDefault(NumberParam, pagination.pageSize)
    });

    return [
        (pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}) => {
            setQuery({
                p:pagination.page,
                s:pagination.pageSize
            })
        },
        { page:query.p, pageSize:query.s }
    ]
}

