import {StylesConfig} from "react-select";
import {ISelectItem} from "./ISelectItem";

export const customStyles:StylesConfig<ISelectItem> = {
    control: (provided, { isDisabled, menuIsOpen, selectProps: { className } }) => {
        const isInvalid = className === "is-invalid";
        return {
            ...provided,
            ":hover": {
                border: isDisabled ? "1px solid #ebe7e4" : "1px solid #c8beaf" //to avoid default blue
            },
            ":after": {
                position: "absolute",
                right: "0",
                left:"0",
                bottom:"0",
                display:"block",
                height:"1px",
                content:'""',
                background: isInvalid ? "#de002e" : "#4f2d7f"
            },
            boxShadow: menuIsOpen ? "0 0 0 0.125rem #ebe7e4, 0 0 0 0.150rem #9681b2" : "",
            border: isDisabled ? "1px solid #ebe7e4": isInvalid ? "1px solid #de002e" : "1px solid #c8beaf",
            borderRadius:"0%",
            paddingLeft:"0.25em",
            paddingRight:"0.25em",
            transition:"border-color 0.2s ease",
            minWidth:"15em"
        }
    },
    singleValue:(provided, { isDisabled, selectProps: { className}}) => {
        const isInvalid = className === "is-invalid";
        return {
            ...provided,
            color: isDisabled ? "grey" : isInvalid ? "#de002e" : "#4f2d7f",
            transition: "color 0.2s ease",
            ":hover": {
                color: isDisabled ? "inherit" : isInvalid ? "#de002e" : "#9681b2"
            },
            fontFamily:"GTWalsheimPro, Arial, sans-serif",
            fontWeight:"400",
            lineHeight:"2.125",
        }
    },
    multiValue:(provided) => ({
        ...provided,
        marginTop:"0",
        marginBottom:"0",
        borderRadius: "0.9375rem",
        border: "1px solid #c8beaf",
        ":hover": {
            borderColor:"#4f2d7f"
        },
        backgroundColor: "none"
    }),
    multiValueLabel:(provided, { isDisabled, selectProps:{className} }) => {
        const isInvalid = className === "is-invalid";
        return {
            ...provided,
            color: isDisabled ? "grey" : isInvalid ? "#de002e" : "#4f2d7f",
            transition: "color 0.2s ease",
            ":hover": {
                color: isDisabled ? "inherit" : isInvalid ? "#de002e" : "#9681b2"
            },
            fontFamily:"GTWalsheimPro, Arial, sans-serif",
            fontWeight:"400",
            lineHeight:"2.055",
            padding:"2px"
        }
    },
    multiValueRemove:(provided) => ({
        ...provided,
        ":hover": {
            color:"#DE350B",
            backgroundColor:"none"
        }
    }),
    valueContainer:(provided) => ({
        ...provided,
        paddingTop:"0.5rem",
        paddingBottom:"0.5rem"
    }),
    dropdownIndicator:(provided, {selectProps: { menuIsOpen}}) => ({
        ...provided,
        transition:"all 0.25s ease",
        transform:menuIsOpen ? "rotate(180deg)" : ""
    }),
    placeholder:(provided, { isDisabled, selectProps: { className}}) => ({
        ...provided,
        color: isDisabled ? "grey" : "inherit",
        fontFamily:"GTWalsheimPro, Arial, sans-serif",
        fontWeight:"400",
        lineHeight:"2.125"
    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop:"0"
    }),
    option: (provided, state) => ({
        ...provided,
        color:state.isSelected || state.isFocused ? "#9681b2" : "#333333",
        background: state.isSelected ? "#F5F3F2" : state.isFocused ? "#ebe7e4" : "white",
        transition: "color 0.2s ease, background-color 0.2s ease",
        cursor:'#115610',
        ":hover": {
            color: "#9681b2",
            background: "#ebe7e4",
            textDecoration: "none"
        }
    }),
}