import React, { useState } from 'react';
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { printDateFromIso } from "../../../i18n"
import { App, AppStatus } from '../../../model/app';
import { AppTile } from './AppTile';
import { Divider, Modal, ModalContent, Link, Icon } from '../../amethyst';

interface AppModalProps {
    app: App,
    disabledApps: App[],
    onClose:() => void
}

const AppModal:React.FC<AppModalProps> = ({app, disabledApps, onClose}) => {
    const { i18n, t } = useTranslation();

    return (
        <Modal open={true} onClose={onClose} title={`${t("Apps.InformationAbout")} ${app.name}`} >
            <ModalContent>
                <Divider />
                {app.status === AppStatus.Online || disabledApps.includes(app) ?
                    <>
                        <p>{app.description}</p>
                        <Link external href={app.fallbackUrl} target="_blank" rel="noreferrer" >{t("Apps.MoreInformation")}</Link>
                    </>
                    : null
                }
                {app.status === AppStatus.Maintenance && !!app.maintenanceWindow && !disabledApps.includes(app) ?
                    <>
                        <p>{t("Apps.MaintenanceEndDateInfo", { date:  printDateFromIso(app.maintenanceWindow.to, i18n.language) })}</p>
                    </>
                    : null
                }
            </ModalContent>
        </Modal>
    )
}

export interface AppTilesProps {
    apps: App[],
    disabledApps: App[]
    maxApps?:number
}

export const AppTiles: React.FC<AppTilesProps> = ({ apps, disabledApps , maxApps}) => {
    const [appToDetails, setAppToDetails ] = useState<App|null>(null)
    const { t } = useTranslation();

    const openAppDescription = (app: App) => setAppToDetails(app);
    const closeAppDescription = () => setAppToDetails(null);

    return (
        <>
            <div className="app-tiles mb-2">
                <>
                    {
                        apps.map((app, idx) =>
                            <AppTile key={idx} app={app} showThisAppDetails={() => openAppDescription(app)} active maintenance={app.status === AppStatus.Maintenance} />
                        )
                    }
                    {
                        disabledApps.map((app, idx) =>
                            <AppTile key={idx} app={app} showThisAppDetails={() => openAppDescription(app)} />
                        )
                    }
                </>
            </div>
            {!!maxApps ?
                <div className="all-apps text-sm-center mb-4">
                    <RouterLink className="link" to="/apps" >{t("Apps.ViewAllApps")}&nbsp;<Icon icon="chevron-right" /></RouterLink>
                </div> :
                null
            }
            {appToDetails ?
                <AppModal app={appToDetails} onClose={closeAppDescription} disabledApps={disabledApps} />
                : null
            }
        </>
    )
}
