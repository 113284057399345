import React, { PropsWithChildren } from 'react';
import { Container } from '../container/Container';

export const NavBarTopBar:React.FC<PropsWithChildren> = ({children}) => {
    return (
        <div className="navbar-top-bar navbar-top-bar-logged-in" >
            <Container fluid>
                <div className="navbar-top-bar-container">
                    {children}
                </div>
            </Container>
        </div>
    )
}