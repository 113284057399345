import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { ContactCrud } from './ContactCrud';

export const ContactManagement:React.FC = () => {
    return (
        <CrudLayout>
            <ContactCrud />
        </CrudLayout>
    )
}
