import React, { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { App, AppStatus, MutationCreateAppArgs, MutationUpdateAppArgs } from '../../../model/app';
import {
    Container,
    FieldDateRangePicker,
    FieldFormSimpleSelect,
    FieldImageUpload,
    FieldTextAreaInput,
    FieldTextInput,
    Form,
    FormAction,
    FormGroup,
    IImageSelector,
    ISelectItem,
    Modal,
    ModalContent,
} from '../../amethyst';

import { AxiosProgressEvent } from 'axios';
import { buildBlobValue } from '../../utils';
import { useMe } from '../../../model/user';
import { useTranslation } from 'react-i18next';
import { ImagePreviewRatio } from '../../amethyst/form';

interface AppFormType {
    name:string,
    description: string
    url: string,
    fallbackUrl:string
    logo:IImageSelector
    status:ISelectItem

    maintenanceWindow?: {
        from:Date|null,
        to:Date|null
    } | null
}

export interface AppCreateEditProps {
    appToEdit:App|undefined
    onCreate: (params: MutationCreateAppArgs) => Promise<any>
    onUpdate: (params: MutationUpdateAppArgs) => Promise<any>
    show:boolean,
    close:() => void
}

const defaultValues:AppFormType = {
    name: '',
    description: '',
    url: '',
    fallbackUrl: '',
    logo:{
        currentImageUrl:null,
        newImageFile:null,
        noImage:false
    },
    status: { id:AppStatus.Online, value:AppStatus.Online, label:AppStatus.Online },
    maintenanceWindow: {
        from:null,
        to:null
    }
}

export const AppCreateEdit:React.FC<AppCreateEditProps> = ({appToEdit, onCreate, onUpdate, show, close}) => {
    const { me } = useMe();
    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');
    const locale = me ? me.language : 'en';

    const appStatusItems: ISelectItem[] = Object.values(AppStatus).map(name => {
        return {
            id: name,
            label: i18nEnums.t(`AppStatus.${name}`),
            value: name
        }
    });

    const initialValues = useMemo<AppFormType>(() => {
        if(!!appToEdit) {
            return {
                name:appToEdit.name,
                description:appToEdit.description,
                url:appToEdit.url,
                fallbackUrl:appToEdit.fallbackUrl,
                logo: {
                    noImage: appToEdit.logo === null,
                    currentImageUrl:appToEdit.logo ? appToEdit.logo.downloadUrl : null,
                    newImageFile:null
                },
                status: appStatusItems.find(status => status.value === appToEdit.status) || { id:AppStatus.Online, value:AppStatus.Online, label:AppStatus.Online },
                maintenanceWindow:appToEdit.maintenanceWindow ? {from: parseISO(appToEdit.maintenanceWindow.from), to:parseISO(appToEdit.maintenanceWindow.to)} : null
            }
        }
        return defaultValues;
    }, [appToEdit, appStatusItems]);

    const progressCallback = (progressEvent: AxiosProgressEvent) => { console.log(progressEvent); }

    // Form validation rules
    const formValidationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(50, t("FormValidation.TooLong", { length:50 }))
            .required(t("FormValidation.Required")),
        description: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(400, t("FormValidation.TooLong", { length:400 }))
            .required(t("FormValidation.Required")),
        url: Yup.string()
            .url(t("FormValidation.InvalidUrl"))
            .required(t("FormValidation.Required")),
        fallbackUrl: Yup.string()
            .url(t("FormValidation.InvalidUrl"))
            .required(t("FormValidation.Required")),
        status: Yup.object(),
        maintenanceWindow: Yup.object().nullable().when('status', {
            is:(status:ISelectItem) => status.value === AppStatus.Maintenance,
            then:Yup.object({
                from:Yup.date().typeError(t("FormValidation.InvalidDateRange")).label("Maintenance start date").required("FormValidation.Required"),
                to:Yup.date().typeError(t("FormValidation.InvalidDateRange")).label("Maintenance end date").required("FormValidation.Required")
            })
        })

    });

    const onSubmit = async (values:AppFormType, { resetForm }:FormikHelpers<AppFormType>):Promise<void> => {
        let status = values.status.value as AppStatus

        if (appToEdit) {
            await onUpdate({
                _id: appToEdit._id,
                app:{
                    name:values.name,
                    description:values.description,
                    url:values.url,
                    fallbackUrl:values.fallbackUrl,
                    logo:await buildBlobValue(values.logo, appToEdit.logo, progressCallback),
                    status,
                    maintenanceWindow:status === AppStatus.Maintenance ? values.maintenanceWindow : null
                }
            });
        } else {
            await onCreate({
                app:{
                    name:values.name,
                    description:values.description,
                    url:values.url,
                    fallbackUrl:values.fallbackUrl,
                    logo:await buildBlobValue(values.logo, null, progressCallback),
                    status,
                    maintenanceWindow:status === AppStatus.Maintenance ? values.maintenanceWindow : null
                }
            });
        }
        resetForm({ values: defaultValues });
        close();
    }

    if(!show) return null;

    const editMode = !!appToEdit
    return (
        <Modal open={show} title={!editMode ? t("AppManagement.AddApp") : `${t("Actions.Update")} ${appToEdit?.name}`} onClose={() => close()} closeOnEscape>
            <ModalContent>
                <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()}
                        enableReinitialize>
                    {({values}) =>
                        <Container fluid>
                            <Form id="appForm" inline>
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("AppManagement.Name")}
                                        form="appForm"
                                        id="name"
                                        name="name"
                                        placeholder={t("AppManagement.NamePlaceholder")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("AppManagement.AppLocation")}
                                        form="appForm"
                                        id="url"
                                        name="url"
                                        placeholder={t("AppManagement.AppLocationPlaceholder")}
                                    />
                                    <FieldTextInput
                                        label={t("AppManagement.AppInformation")}
                                        form="appForm"
                                        id="fallbackUrl"
                                        name="fallbackUrl"
                                        placeholder={t("AppManagement.AppInformationPlaceholder")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldImageUpload
                                        id="logo"
                                        name="logo"
                                        label={t("AppManagement.Logo")}
                                        hint={t("AppManagement.LogoHint")}
                                        resolutionType="ratio"
                                        maxFileSize={204800} // 200kb
                                        fileFormat={['jpg', 'jpeg', 'png']}
                                        resolutionErrorLabel={t("FormValidation.ImageResolutionDoesntMatch")}
                                        acceptTypeErrorLabel={t("FormValidation.UnsupportedFileFormat")}
                                        maxFileSizeErrorLabel={t("FormValidation.FileTooLarge")}
                                        ratio={ImagePreviewRatio.oneAndHalf}
                                        allowCropping={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldTextAreaInput
                                        id="description"
                                        name="description"
                                        label={t("AppManagement.Description")}
                                        fluid
                                        form="appForm"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldFormSimpleSelect
                                        id="status"
                                        name="status"
                                        label={t("AppManagement.Status")}
                                        placeholder={t("AppManagement.StatusPlaceholder")}
                                        items={appStatusItems}
                                        noOptionsMessage=""
                                        form="appForm"
                                    />
                                    <FieldDateRangePicker
                                        disabled={values.status.value !== AppStatus.Maintenance}
                                        id="maintenanceWindow"
                                        name="maintenanceWindow"
                                        startDateField="maintenanceWindow.from"
                                        endDateField="maintenanceWindow.to"
                                        label={t("AppManagement.MaintenanceDates")}
                                        placeholder={t("AppManagement.MaintenanceDatesPlaceholder")}
                                        form="appForm"
                                        locale={locale}
                                    />
                                </FormGroup>
                                <FormAction
                                    submittingLabel={t("Actions.Saving")}
                                    submitLabel={t("Actions.Save")}
                                    resetLabel={t("Actions.Cancel")}
                                />
                            </Form>
                        </Container>
                    }
                </Formik>
            </ModalContent>
        </Modal>
    )
}