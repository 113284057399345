import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useArticleToView } from '../model/article';
import { NewsContent } from '../components/article/view/NewsContent';
import { Grid, GridColumn, GridRow, Loader, LoaderSize } from '../components/amethyst';
import { ContentNotFound } from "../components/error/ContentNotFound"
import { useTranslation } from 'react-i18next';

interface NewsLoaderProps {
    slug:string;
}

const NewsLoader:React.FC<NewsLoaderProps> = ({slug}) => {
    const {loading, article} = useArticleToView(undefined, slug);
    const { t } = useTranslation();
    
    if(loading && !article) {
        return (
            <article>
                <header className="article-header"></header>
                <div className="content-app content-app-text-content">
                    <Grid>
                        <GridRow>
                            <GridColumn defaultSize={12}>
                                <Loader size={LoaderSize.medium} label={t("News.Loading")}/>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </div>
            </article>
        )
    }

    if(!!article) {
        return <NewsContent article={article} />
    }

    return <ContentNotFound />
}

export const OneNews:React.FC<any> = () => {
    const navigate = useNavigate();
    const params = useParams();
    if(!!params.slug) {
        return <NewsLoader slug={params.slug} />
    } else {
        navigate('/');
        return null;
    }
}