import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { CustomerUsersCrud } from './CustomerUsersCrud';

export const CustomerUsersManagement:React.FC = () => {
    return (
        <CrudLayout>
            <CustomerUsersCrud />
        </CrudLayout>
    )
}
