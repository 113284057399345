import React, { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import * as Yup from 'yup';
import { FieldHelperProps, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    Customer,
    IndustriesItems,
    MutationCreateCustomerArgs,
    MutationUpdateCustomerArgs,
    ServicesItems,
    useIsGtCustomerIdAvailable,
} from '../../../model/customer';

import { ManagerRole, useManagerUsers } from '../../../model/user';

import {
    Container,
    FieldCheckbox,
    FieldFormMultipleSelect,
    FieldTextAreaInput,
    FieldTextInput,
    Form,
    FormAction,
    FormGroup,
    ISelectItem,
    Modal,
    ModalContent,
} from '../../amethyst';
import { cacheTest } from '../../utils';
import { useContextManagerRole } from '../../security/ProtectedRoute';

interface CustomerFormType {
    name: string,
    gtCustomerId:string,
    properties:{
        comments:string,
        contactInfo:string
        industries:ISelectItem[],
        services:ISelectItem[],
        gtManagers:ISelectItem[]
    },
    disabled: boolean,
}

const defaultValues:CustomerFormType = {
    name: '',
    gtCustomerId:'',
    properties:{
        comments:'',
        contactInfo:'',
        industries:[],
        services:[],
        gtManagers:[]
    },
    disabled: false,
}

export interface CustomerEditProps {
    customerToEdit:Customer|undefined
    onCreate: (input: MutationCreateCustomerArgs) => Promise<any>
    onUpdate: (input: MutationUpdateCustomerArgs) => Promise<any>
    show:boolean,
    close:() => void
}

export const CustomerCreateEdit:React.FC<CustomerEditProps> = ({customerToEdit, onCreate, onUpdate, show, close}) => {
    const [validating, setValidating] = useState<boolean>(false);
    const [isGtCustomerIdAvailable] = useIsGtCustomerIdAvailable();
    const { pageOfManagerUsers, loading: loadingManagers } = useManagerUsers({ page:1, pageSize:100 }, { managerRoles:[ManagerRole.CustomerManager] });
    const { t } = useTranslation();
    const { managerRoles } = useContextManagerRole();

    const managerItems = useMemo<ISelectItem[]>(() => {
        return pageOfManagerUsers.items.map(m => ({id: m._id, label:m._id, value:m._id}));
    }, [pageOfManagerUsers]);


    const readOnly = useMemo(() => !managerRoles.includes(ManagerRole.Admin), [managerRoles]);

    const initialValues = useMemo<CustomerFormType>(() => {
        if(!!customerToEdit) {
            return {
                name: customerToEdit.name,
                gtCustomerId:customerToEdit.gtCustomerId,
                disabled: customerToEdit.disabled,
                properties: {
                    comments:customerToEdit.properties.comments,
                    contactInfo:customerToEdit.properties.contactInfo,
                    industries:customerToEdit.properties.industries.reduce<ISelectItem[]>((indItems, id) => {
                        let item = IndustriesItems.find(item => item.id === id);
                        if(item) indItems.push(item);
                        return indItems;
                    }, []),
                    services:customerToEdit.properties.services.reduce<ISelectItem[]>((svcItems, id) => {
                        let item = ServicesItems.find(item => item.id === id);
                        if(item) svcItems.push(item);
                        return svcItems;
                    }, []),
                    gtManagers:customerToEdit.properties.gtManagers.reduce<ISelectItem[]>((cItems, id) => {
                        let item = pageOfManagerUsers.items.find(item => item._id === id);
                        if(item) cItems.push({ id:item._id, value:item._id, label:item._id });
                        return cItems;
                    }, [])
                }
            }
        }
        return defaultValues;
    }, [customerToEdit, pageOfManagerUsers]);

    const testUniqueGtCustomerId = useRef(cacheTest(isGtCustomerIdAvailable, setValidating));
    const formValidationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string()
                .min(2, t("FormValidation.TooShort", { length:2 }))
                .max(50, t("FormValidation.TooLong", { length:50 }))
                .required(t("FormValidation.Required")),
            gtCustomerId: Yup.string()
                .min(2, t("FormValidation.TooShort", { length:2 }))
                .max(50, t("FormValidation.TooLong", { length:50 }))
                .label('Customer unique Id')
                .test('customer-id-unique',t("CustomerManagement.CustomerIDError"), (arg:string|undefined) => {
                    if(!!customerToEdit) return true;
                    return !!arg && testUniqueGtCustomerId.current(arg);
                })
                .required(t("FormValidation.Required")),
            properties: Yup.object({
                comments:Yup.string().max(500, t("FormValidation.TooLong", { length:500 })),
                contactInfo:Yup.string().max(500, t("FormValidation.TooLong", { length:500 })),
                industries:Yup.array().min(1).label(t('CustomerManagement.Industries')).required(t("FormValidation.AtLeastOne")),
                services:Yup.array().min(1).label(t('CustomerManagement.Services')).required(t("FormValidation.AtLeastOne")),
            }),
            disabled: Yup.boolean()
        });
    }, [testUniqueGtCustomerId, customerToEdit, t]);

    const onSubmit = async (values: CustomerFormType, { resetForm }: FormikHelpers<CustomerFormType>): Promise<void> => {

        // Yup validation enforce these keys to exists
        //TODO refactor... smell duplicate of code
        if(customerToEdit) {
            const customerToUpdate:MutationUpdateCustomerArgs = {
                _id: customerToEdit._id,
                customer: {
                    name: values.name,
                    disabled: values.disabled,
                    properties: {
                        comments: values.properties.comments,
                        contactInfo: values.properties.contactInfo,
                        industries:values.properties.industries.map(i => i.id),
                        services:values.properties.services.map(i => i.id),
                        gtManagers:values.properties.gtManagers.map(i => i.id),
                    }
                }
            }
            await onUpdate(customerToUpdate);
        } else {
            const newCustomer:MutationCreateCustomerArgs = {
                customer: {
                    name: values.name,
                    gtCustomerId:values.gtCustomerId,
                    properties: {
                        comments: values.properties.comments,
                        contactInfo: values.properties.contactInfo,
                        industries:values.properties.industries.map(i => i.id),
                        services:values.properties.services.map(i => i.id),
                        gtManagers:values.properties.gtManagers.map(i => i.id),
                    }
                }
            }
            await onCreate(newCustomer);
        }

        resetForm({ values: defaultValues });
        close();
    }

    const debounceSearch = useMemo(() => debounce((value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, true);
    }, 500, { trailing:true, leading:false}), [])

    const debouncedCustomerIdValidation = useCallback(debounceSearch, [debounceSearch]);

    const validateGtCustomerId = (value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, false);
        debouncedCustomerIdValidation(value, helpers);
    }

    if(!show) return null

    const editMode = !!customerToEdit
    return (
        <Modal open={show} title={!editMode ? t("CustomerManagement.AddCustomer") : `${t("Actions.Update")} ${customerToEdit?.name}`} onClose={() => close()} closeOnEscape>
            <ModalContent>
                <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                    <Container fluid>
                        <Form id="CustomerForm" inline>
                            <FormGroup disabled={readOnly}>
                                <FieldTextInput
                                    label={t("CustomerManagement.Name")}
                                    form="CustomerForm"
                                    id="name"
                                    name="name"
                                    placeholder={t("CustomerManagement.NamePlaceholder")}
                                />
                            </FormGroup>
                            <FormGroup disabled={readOnly}>
                                <FieldFormMultipleSelect
                                    id="properties.industries"
                                    items={IndustriesItems}
                                    placeholder={t("CustomerManagement.IndustriesPlaceholder")}
                                    name="properties.industries"
                                    form="CustomerForm"
                                    label={t("CustomerManagement.Industries")}
                                    noOptionsMessage=""
                                    displayLabelAsValue
                                    disabled={readOnly}
                                />
                                <FieldTextInput
                                    disabled={!!customerToEdit}
                                    label={t("CustomerManagement.CustomerID")}
                                    form="CustomerForm"
                                    id="gtCustomerId"
                                    name="gtCustomerId"
                                    placeholder={t("CustomerManagement.CustomerIDPlaceholder")}
                                    customValidation={(value:string, helpers: FieldHelperProps<string>) => !customerToEdit && validateGtCustomerId(value, helpers)}
                                    loading={validating}
                                />
                            </FormGroup>
                            <FormGroup disabled={readOnly}>
                                <FieldFormMultipleSelect
                                    id="properties.services"
                                    items={ServicesItems}
                                    placeholder={t("CustomerManagement.ServicesPlaceholder")}
                                    name="properties.services"
                                    form="CustomerForm"
                                    label={t("CustomerManagement.Services")}
                                    noOptionsMessage=""
                                    disabled={readOnly}
                                />
                                <FieldFormMultipleSelect
                                    id="properties.gtManagers"
                                    items={managerItems}
                                    placeholder={t("CustomerManagement.GTManagersPlaceholder")}
                                    name="properties.gtManagers"
                                    form="CustomerForm"
                                    label={t("CustomerManagement.GTManagers")}
                                    noOptionsMessage=""
                                    disabled={loadingManagers || readOnly}
                                />
                            </FormGroup>
                            {editMode && <FormGroup disabled={readOnly}>
                                <FieldCheckbox id="disabled" name="disabled" label={t("CustomerManagement.IsInactive")} form="CustomerForm" />
                            </FormGroup>}
                            <FormGroup disabled={readOnly}>
                                <FieldTextAreaInput fluid id="properties.comments" name="properties.comments" label={t("CustomerManagement.Comments")} form="CustomerForm"  />
                            </FormGroup>
                            <FormGroup disabled={readOnly}>
                                <FieldTextAreaInput fluid id="properties.contactInfo" name="properties.contactInfo" label={t("CustomerManagement.ContactInfo")} form="CustomerForm" />
                            </FormGroup>
                            <FormAction
                                disabled={readOnly}
                                submittingLabel={t("Actions.Saving")}
                                submitLabel={t("Actions.Save")}
                                resetLabel={t("Actions.Cancel")}
                            />
                        </Form>
                    </Container>
                </Formik>
            </ModalContent>
        </Modal>
    )
}
