import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdTokenClaims } from '../../security/types';
import { Form, FormGroup, Modal, ModalContent, ToggleInput } from '../amethyst';

export interface PersonalInfoProps {
    showPersonalInfo:boolean
    onClosePersonalInfo:() => void
    claims:IdTokenClaims
    email:string
}
export const PersonalInfo:React.FC<PersonalInfoProps> = ({showPersonalInfo, onClosePersonalInfo, claims, email}) => {
    const { t } = useTranslation();
    const { family_name, given_name } = claims;
    return (
        <Modal title={t("PersonalInfo.Title")} open={showPersonalInfo} onClose={onClosePersonalInfo} closeOnEscape closeOnDimmerClick >
            <ModalContent>
                <Form id="personalInfo">
                    <FormGroup>
                        <ToggleInput
                            id="firstname"
                            name="firstname"
                            label={t("PersonalInfo.FirstName")}
                            form="personalInfo"
                            value={given_name as string}
                        />
                        <ToggleInput
                            id="lastname"
                            name="lastname"
                            label={t("PersonalInfo.Lastname")}
                            form="personalInfo"
                            value={family_name as string}
                        />
                        <ToggleInput
                            id="email"
                            name="email"
                            label={t("PersonalInfo.Email")}
                            form="personalInfo"
                            value={email}
                        />
                    </FormGroup>
                </Form>
            </ModalContent>
        </Modal>
    )
}