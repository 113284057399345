import { Me } from '../../model/user';
import { IdTokenClaims } from '../../security/types';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, NavBarContentVisibility, NavBarDropDown, NavBarMenuSubItems } from '../amethyst';
import { capitalize } from '../utils';
import { useCurrentCustomer } from '../customer/view/customerContext';

export interface UserNavBarDropDownProps {
    me:Me
    claims:IdTokenClaims,
    onShowPersonalInfo?:(e: MouseEvent<HTMLAnchorElement>) => void
    onChangePassword?:(e: MouseEvent<HTMLAnchorElement>) => void
    onChangeCustomerAccount?:(e: MouseEvent<HTMLAnchorElement>) => void
    onLogout:(e:React.MouseEvent<HTMLAnchorElement>) => void
}

export const UserNavBarDropDown:React.FC<UserNavBarDropDownProps> = ({me, onShowPersonalInfo, onLogout, onChangePassword, onChangeCustomerAccount, claims}) => {
    const { t } = useTranslation();
    const { currentCustomerId } = useCurrentCustomer();

    const menuItems = [
        (!!me && me.isCustomer ?
                <>
                    <a href="/#" onClick={onShowPersonalInfo}>
                        <Icon icon="document" />&nbsp;{t("UserMenu.Profile")}
                    </a>
                    <a href="/#" onClick={onChangePassword}>
                        <Icon icon="key" />&nbsp;{t("UserMenu.ChangePassword")}
                    </a>
                    {me.numberOfCustomerRelationships > 1 ?
                        <a href="/#" onClick={onChangeCustomerAccount}>
                            <Icon icon="building-commercial" />&nbsp;{t('UserMenu.CustomerAccount')}
                        </a>
                    : null}
                </> :
                <></>
        ),
        <a href="/#" onClick={(e) => onLogout(e)}>
            <Icon icon="arrow-draw-right" />&nbsp;{t("UserMenu.Logout")}
        </a>
    ]

    const { family_name, given_name } = claims;

    return (
        <>
            <NavBarDropDown right name="profile" dropDrownMenuItems={menuItems} visibility={NavBarContentVisibility.Large}>
                <span className="navbar-top-bar-user-dropdown-content">
                    <span className="navbar-top-bar-user-dropdown-name">{capitalize(given_name as string)} {capitalize(family_name as string)}</span>
                    { me && me.isCustomer ?
                        <span className="navbar-top-bar-user-dropdown-position">{me.companyName(currentCustomerId)}</span>
                        : <></>
                    }
                </span>
            </NavBarDropDown>
        </>
    );
}

export interface UserNavBarMenuProps extends UserNavBarDropDownProps {
    name:string,
    onToggle:(menuName: string) => void
    isOpen:boolean
}

export const UserNavBarMenu:React.FC<UserNavBarMenuProps> = ({me, onShowPersonalInfo, onLogout, onChangePassword, onChangeCustomerAccount, claims, onToggle, isOpen, name}) => {
    const { t } = useTranslation();
    const { currentCustomerId } = useCurrentCustomer();
    const { family_name, given_name } = claims;

    let menuLabel = `${capitalize(given_name as string)} ${capitalize(family_name as string)}`;
    if(me && me.isCustomer) menuLabel = `${menuLabel} / ${me.companyName(currentCustomerId)}`;

    return (
        <NavBarMenuSubItems name={name} onToggle={onToggle} isOpen={isOpen} label={menuLabel} icon="person" hideOnXl>
            {(!!me && me.isCustomer ?
                <>
                    <li className="no-dotted">
                        <a href='/#' onClick={onShowPersonalInfo}>
                            <span className="navbar-nav-icon"><Icon block icon="document" /></span>
                            &nbsp;{t("UserMenu.Profile")}
                        </a>
                    </li>
                    <li className="no-dotted">
                        <a href='/#' onClick={onChangePassword}>
                            <span className="navbar-nav-icon"><Icon block icon="key" /></span>
                            &nbsp;{t("UserMenu.ChangePassword")}
                        </a>
                    </li>
                    {me.numberOfCustomerRelationships > 1 ?
                        <li className="no-dotted">
                            <a href='/#' onClick={onChangeCustomerAccount}>
                                <span className="navbar-nav-icon"><Icon block icon="building-commercial" /></span>
                                &nbsp;{t('UserMenu.CustomerAccount')}
                            </a>
                        </li>
                    : null}
                </>
                : null)}
            <li className="no-dotted">
                <a href='/#' onClick={onLogout}>
                    <span className="navbar-nav-icon"><Icon block icon="arrow-draw-right" /></span>
                    &nbsp;{t("UserMenu.Logout")}
                </a>
            </li>
        </NavBarMenuSubItems>
    )
}
