import { InteractionRequiredAuthError, SilentRequest } from "@azure/msal-browser";
import { myGTAPIScope } from "./authConfig";
import msalInstance from "../msalInstance";

const getAccessToken = async (request:SilentRequest):Promise<string> => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const accessTokenRequest = { ...request, account};

    return msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => accessTokenResponse.accessToken) // Acquire token silent success
        .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
            throw(error);
        });
}

export const getMyGTUserToken = async ():Promise<string> => {
    return getAccessToken(myGTAPIScope);
}

