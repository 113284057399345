import { ISelectItem } from '../../components/amethyst';

export const ServicesItems:ISelectItem[] = [
    {"id":"Accounting","value":"Accounting","label":"Accounting"},
    {"id":"Accounting-BSS","value":"Accounting-BSS","label":"Accounting-BSS"},
    {"id":"Advisory","value":"Advisory","label":"Advisory"},
    {"id":"Bitbourg Office Center (BOC)","value":"Bitbourg Office Center (BOC)","label":"Bitbourg Office Center (BOC)"},
    {"id":"Blockchain","value":"Blockchain","label":"Blockchain"},
    {"id":"Business Transformation","value":"Business Transformation","label":"Business Transformation"},
    {"id":"Compliance (Financial Services)","value":"Compliance (Financial Services)","label":"Compliance (Financial Services)"},
    {"id":"Compliance & Governance (Advisory)","value":"Compliance & Governance (Advisory)","label":"Compliance & Governance (Advisory)"},
    {"id":"Corporate & Legal","value":"Corporate & Legal","label":"Corporate & Legal"},
    {"id":"Direct Tax Advisory","value":"Direct Tax Advisory","label":"Direct Tax Advisory"},
    {"id":"Direct Tax Compliance","value":"Direct Tax Compliance","label":"Direct Tax Compliance"},
    {"id":"Domiciliation","value":"Domiciliation","label":"Domiciliation"},
    {"id":"ESG & Sustainability","value":"ESG & Sustainability","label":"ESG & Sustainability"},
    {"id":"EU desk","value":"EU desk","label":"EU desk"},
    {"id":"External Audit","value":"External Audit","label":"External Audit"},
    {"id":"Financial Services","value":"Financial Services","label":"Financial Services"},
    {"id":"GDPR","value":"GDPR","label":"GDPR"},
    {"id":"Internal Audit","value":"Internal Audit","label":"Internal Audit"},
    {"id":"IT/Technology","value":"IT/Technology","label":"IT/Technology"},
    {"id":"IT Audit","value":"IT Audit","label":"IT Audit"},
    {"id":"Liquidation","value":"Liquidation","label":"Liquidation"},
    {"id":"Payroll","value":"Payroll","label":"Payroll"},
    {"id":"Personal Tax","value":"Personal Tax","label":"Personal Tax"},
    {"id":"Recovery & Reorganisation","value":"Recovery & Reorganisation","label":"Recovery & Reorganisation"},
    {"id":"Risk Management & Reporting","value":"Risk Management & Reporting","label":"Risk Management & Reporting"},
    {"id":"Security","value":"Security","label":"Security"},
    {"id":"Tax","value":"Tax","label":"Tax"},
    {"id":"Transfer Pricing","value":"Transfer Pricing","label":"Transfer Pricing"},
    {"id":"Valuation","value":"Valuation","label":"Valuation"},
    {"id":"VAT","value":"VAT","label":"VAT"},
];
