import {
    CustomerUserProperties, ManagerRole,
    ManagerUserProperties,
    User,
    UserLanguage,
    UserProperties, UserType,
    B2CAccount, B2CPasswordPolicy, B2CmfaMethod, AuditTrail
} from "../../service/api/generated-types";
const isCustomerProperties = (properties:UserProperties):properties is CustomerUserProperties => {
    return "userType" in properties && properties.userType === UserType.Customer
}
const isManagerProperties = (properties:UserProperties):properties is ManagerUserProperties => {
    return "userType" in properties && properties.userType === UserType.Manager
}

const isCustomer = (user: User) => isCustomerProperties(user.properties);
const isManager = (user: User) => isManagerProperties(user.properties);

const isAllowed = (roles:ManagerRole[], expectedRoles:ManagerRole[]):boolean => {
    return expectedRoles.reduce((lastCheck, expectedRole) => {
        let currentCheck = roles.includes(expectedRole);
        return lastCheck || currentCheck;
    }, false);
}

export class BaseUser implements User {
    constructor(
        readonly _id:string,
        readonly properties:UserProperties,
        readonly auditTrail:AuditTrail
    ) {}

    get userType():UserType {
        return this.properties.userType;
    }

    get language():UserLanguage {
        return this.properties.language
    }


}

export class Me extends BaseUser {

    get customerProperties():CustomerUserProperties|null {
        if(isCustomerProperties(this.properties)) {
            return this.properties as CustomerUserProperties
        }
        return null;
    }

    get numberOfCustomerRelationships():number {
        if(isCustomerProperties(this.properties)) {
            return (this.properties as CustomerUserProperties).relationships.length;
        }
        return 0
    }

    get managerProperties():ManagerUserProperties|null {
        if(isManagerProperties(this.properties)) {
            return this.properties as ManagerUserProperties
        }
        return null;
    }

    companyName(customerId:string|null = null):string {
        if(isCustomerProperties(this.properties)) {
            if(this.properties.relationships.length > 0) {
                if(!!customerId) {
                    let r = this.properties.relationships.find(r => r.customer._id === customerId);
                    return r ? r.customer.name : ""
                }
                return this.properties.relationships[0].customer.name;
            }
        }
        return "";
    }

    get isCustomer():boolean {
        return isCustomer(this);
    }

    get isManager():boolean {
        return isManager(this);
    }

    isManagerWithRoles(roles: ManagerRole[]):boolean {
        if(isManager(this)) {
            const properties = this.properties as ManagerUserProperties;
            return isAllowed(properties.managerRoles, roles);
        }
        return false;
    }

    static fromApi(payload: User):Me {
        return new Me(
            payload._id,
            payload.properties,
            payload.auditTrail
        );
    }
}

export class ManagerUser extends BaseUser {
    constructor(
        readonly _id:string,
        readonly properties:ManagerUserProperties,
        readonly auditTrail:AuditTrail
    ) {
        super(_id, properties, auditTrail);
    }

    static fromApi(payload: User):ManagerUser {
        return new ManagerUser(
            payload._id,
            payload.properties as ManagerUserProperties,
            payload.auditTrail,
        );
    }
}

export class CustomerUser extends BaseUser {
    constructor(
        readonly _id:string,
        readonly properties:CustomerUserProperties,
        readonly auditTrail:AuditTrail,
    ) {
        super(_id, properties, auditTrail);
    }

    static fromApi(payload: User):CustomerUser {
        return new CustomerUser(
            payload._id,
            payload.properties as CustomerUserProperties,
            payload.auditTrail,
        );
    }
}

export class CustomerUserAccount implements B2CAccount {
    constructor (
        readonly id:string,
        readonly accountEnabled:boolean,
        readonly displayName:string,
        readonly givenName:string,
        readonly surname:string,
        readonly email:string,
        readonly lastSignInDateTime:string,
        readonly passwordPolicies:B2CPasswordPolicy[],
        readonly mfaMethod:B2CmfaMethod,
        readonly mustResetPassword:boolean,
    ) {}

    static fromApi(payload:any):CustomerUserAccount {
        if(payload.__typename === "B2CAccount") {
            return new CustomerUserAccount(
                payload.id,
                payload.accountEnabled,
                payload.displayName,
                payload.givenName,
                payload.surname,
                payload.email,
                payload.lastSignInDateTime,
                payload.passwordPolicies,
                payload.mfaMethod,
                payload.ResetPassword
            );
        }
        return new CustomerUserAccount(
            "",
            false,
            "",
            "",
            "",
            "",
            "",
            [],
            B2CmfaMethod.Email,
            false
        );
    }
}