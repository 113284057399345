import React from "react"
import "./divider.scss";

export interface DividerProps {
    content?:string
    horizontal?:boolean
    vertical?:boolean
    dark?:boolean
    narrow?:boolean
}

export const Divider:React.FC<DividerProps> = ({content, horizontal, vertical, dark, narrow}) => {
    return <div className={`divider ${dark ? 'dark':''} ${horizontal ? 'horizontal' : vertical ? 'vertical' : ''} ${narrow ? 'narrow':''}`} >{content}</div>
}
