import React from "react"
import { Button, ButtonGroup } from "../../button";

export interface FormButtonGroupProps {
    id:string
    name:string
    label?:string
    children:React.ReactElement<typeof Button>[]|React.ReactElement<typeof Button>;
}

export const FormButtonGroup:React.FC<FormButtonGroupProps> = ({id, name, label, children}) => {
    return (
        <div id={id} className="form-button-group">
            {label ? <label htmlFor={id} className="form-label">{label}</label> : <label htmlFor={id} className="form-label">&nbsp;</label>}
            <ButtonGroup>
                {children}
            </ButtonGroup>
        </div>
    )
}