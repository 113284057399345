import {Resource} from "i18next";
import { amethystTranslation } from "../components/amethyst"
import { AgendaEntryType } from '../model/agendaEntry';
import { ManagerRole, B2CmfaMethod, UserLanguage } from '../model/user';
import { AppStatus } from '../model/app'
import { ArticleStatus } from '../model/article'

export const translations:Resource = {
    en: {
        translation: {
            MyGT:{
                AnonymousWelcome:'Welcome to {{website_name}}, starting the application...',
                AnonymousWelcomeFallback: "If you have not been redirect, you can click on the sign-in button",
                ErrorLoadingProfile: "We are unable to load your profile. Please refresh your web browser",
                ErrorContentNotFound:"We are sorry, but this page does not exists",
                NoAccess:"Unfortunately we cannot give you access to this service, as your user account is not linked to any active customer account. Please contact our support team",
                GlobalError:{
                    AuthenticationError:"Your session has expired, we redirect you to the authentication page.",
                    NetworkError:"We are unable to join the {{website_name}} services. Please check your internet connection and refresh your web browser",
                    ApiForbiddenError:"Your user profil is not allowed to perform this action",
                    ApiValidationError:"This action is not possible at the moment. Please reach out support if the problem remains",
                    UnexpectedError:"Something wrong happened, please refresh your web browser. Please reach out support if the problem remains",
                }
            },
            PageLayout:{
                MeLoading:"Preparing the experience",
                HomeLoading:"Loading your content"
            },
            PersonalInfo:{
                Title: "Your personal information",
                FirstName: "First name",
                Lastname: "Last name",
                Email: "Email"
            },
            UserMenu:{
                Home:'Home',
                Apps:"Apps",
                Profile:"Personal info",
                ChangePassword:"Change password",
                CustomerAccount:"Customer account",
                CustomerAccountModal:{
                    Title:"Switch customer account",
                    ListLabel:"Available customer accounts"
                },
                Logout:"Log out",
                News:"News",
                Agenda:"Agenda",
                Admin:"My GT Admin",
                Language:"Languages",
                Languages:{
                    en:'English',
                    fr:'French'
                },
                AboutGT: "About Grant Thornton Luxembourg",
                PrivacyPolicy: "Privacy policy",
                PrivacyPolicyURL: "https://www.grantthornton.lu/privacy-notice-my-gt-experience/"
            },
            ManagementTab:{
                Apps:"Apps",
                Customers:"Customers",
                Subscriptions:"App subscriptions",
                CustomerUsers: "Customer Users",
                Managers:"GT Users",
                News: "News",
                Agenda: "Events & deadlines",
                Contact:"Contacts",
                Notifications:"Notifications"
            },
            SignInButton: { Label: "Sign in" },
            AppManagement: {
                Loading: "Loading apps...",
                Title: "Applications",
                AddApp: "Add an application",
                Name: "Name",
                NamePlaceholder: "Name",
                Status: "Status",
                StatusPlaceholder: "Status",
                NextMaintenance: "Next Maintenance",
                Target: "Target",
                ProductPage: "Product page",
                EditApp: "Edit application",
                None: "None",
                AppLocation: "App location",
                AppLocationPlaceholder: "URL",
                AppInformation: "App Information for non-subscribers",
                AppInformationPlaceholder: "URL",
                Logo: "Logo (Optional, Only jpeg or png files, 200Kb max, best is 400px height, with 1,5x h/w ratio)",
                LogoHint: "Only jpeg or png files, 400px height, 200Kb max",
                Description: "Description",
                MaintenanceDates: "Maintenance dates (from - to)",
                MaintenanceDatesPlaceholder: "Select a date range"
            },
            CustomerManagement: {
                Loading: "Loading customers...",
                Title: "Customers",
                AddCustomer: "Add a customer",
                Name: "Name",
                NamePlaceholder: "Name",
                Status: "Status",
                GTEntity: "GT Entity",
                GTService: "GT Service",
                ViewCustomerUsers: "View customers users",
                ViewApplicationSubscriptions: "View applications subscriptions",
                EditCustomer: "Edit customer",
                Industries:"Industries",
                IndustriesPlaceholder: "Industries",
                Services: "Services",
                ServicesPlaceholder: "Services",
                GTManagers:"Managers in charge",
                GTManagersPlaceholder:"Managers",
                CustomerID: "CustomerID",
                CustomerIDPlaceholder: "Internal customer id",
                CustomerIDError: "The customer id must be at least 2 characters, and must not be already exists in customer list",
                Industry: "Industry",
                Sector: "Sector",
                SectorPlaceholder: "Sector",
                ServingEntity: "Serving entity",
                ServingEntityPlaceholder: "Serving entity",
                Country: "Country",
                CountryPlaceholder: "Country",
                Size: "Size",
                SizePlaceholder: "Size",
                TurnOver: "Turn over",
                TurnOverPlaceholder: "Turn over",
                IsInactive: "Is inactive?",
                IsRegulated: "Is regulated?",
                IsPublicSector: "Is public sector?",
                hasAProvider: "Has a provider?",
                Comments: "Comments",
                ContactInfo: "Contact info"
            },
            CustomerUserManagement: {
                Loading: "Loading customers users...",
                Title: "Customer users",
                EmailError: "This email is invalid or already used by another user",
                RetrievingUserDetails: "Retrieving user details",
                DeletingUser: "Deleting user",
                SearchUsers: "Search",
                AddUser: "Add a user",
                FirstName: "First name",
                FirstNamePlaceholder: "User first name",
                LastName: "Last name",
                LastNamePlaceholder: "User last name",
                Email: "Email",
                Customers:"Customers",
                EmailPlaceholder: "User email",
                LastSignIn: "Last sign in",
                MFA: "MFA",
                MFAPlaceholder: "Method",
                AccountEnabled: "Account enabled",
                DeleteUser: "Delete user",
                ClickToConfirmDeletion: "Click again to confirm deletion of user {{userId}}",
                ConfirmDeletion: "Do you confirm you want to delete this user ?",
                SearchingUser: "Searching for user",
                NoUsersFound: "No users found",
                EditUser: "Edit customer user",
                CustomerFilter: "Filter by customer",
                PleaseSelectCustomer: "Please select a customer",
                SearchUserEmails: "Filter by email pattern",
                RelatedCustomers:"Retated to customers",
                RelatedCustomersPlaceholder: "Select one or more customers",
                Language: "Language",
                LanguagePlaceholder: "Language"
            },
            ManagerManagement: {
                Loading: "Loading GT users...",
                Title: "GT users",
                ConfirmDeletion: "Do you confirm you want to delete this manager?",
                Email: "Email",
                EmailPlaceholder: "someone@lu.gt.com",
                Roles: "Roles",
                RolesPlaceholder: "Roles",
                EmailError: "This email is invalid or already used by another user",
                AddManagerUser: "Add a GT user",
                UpdateManagerUser: "Update GT user: {{name}}",
                EditManagerUser: "Edit GT user",
                DeleteManagerUser: "Delete GT user"
            },
            Apps: {
                Loading: "Retrieving your apps ...",
                InformationAbout: "Information about",
                GetAccessToApp: "Get access to your app here",
                MaintenanceEndDateInfo: "This service is under maintenance until the {{date}}.",
                MaintenanceRangeDatesInfo: "Due to maintenance, this service will be unavailable from {{startDate}} to {{endDate}}.",
                MoreInformation: "More information here",
                ViewAllApps: "View all apps",
            },
            SubscriptionManagement: {
                LoadingAppsAndCustomers: "Loading apps and customers...",
                LoadingUsers: "Loading users...",
                LoadingApps: "Loading apps...",
                Title: "App subscriptions",
                SearchingSubscriptions: "Searching for subscriptions",
                SavingSubscriptions: "Saving subscriptions",
                ManageSubscriptions: "Manage subscriptions",
                NoSubcriptionsFound: "No subscriptions found",
                NoUsersFound: "No user is matching your query",
                NoAppsFound: "No apps found",
                Email: "Email / Apps",
                AddAllUsers: "Add all customer users",
                AddSelectedUsers: "Add selected users",
                FoundUsersLength: "Found {{length}} users",
                AddAllUsersConfirm: "Do you confirm you want to add them all?",
                AddAllUsersMessage: "Any existing users/subscriptions will not be replaced, but merged.",
                SelectCustomer: "Select a customer",
                PleaseSelectCustomer: "Please select a customer",
                SelectApp: "Select an app",
                SelectAppPlaceholder: "Select an app",
                SearchUsers: "Type here to search user by email",
                AddRowMessage:"Can you confirm you want to add all apps to {{userId}} ?",
                RemoveRowMessage:"Can you confirm you want to remove all apps from {{userId}} ?",
                AddColumnMessage:"Can you confirm you want to add this app to all users ?",
                RemoveColumnMessage:"Can you confirm you want to remove this app from all users ?"
            },
            ContactManagement: {
                Loading: "Loading contacts...",
                Title: "Contacts",
                ConfirmDeletion: "Do you confirm you want to delete this contact?",
                AddContact: "Add a contact",
                Picture: "Picture",
                FirstName: "First name",
                FirstNamePlaceholder: "Contact first name",
                LastName: "Last name",
                LastNamePlaceholder: "Contact last name",
                Email: "Email",
                EmailPlaceholder: "Contact email",
                DeleteContact: "Delete contact",
                EditContact: "Edit contact",
                ContactTitle: "Title",
                ContactTitlePlaceholder: "Contact title",
                Phone: "Phone",
                PhonePlaceholder: "Contact phone number",
                Specialisations: "Specialisations",
                SpecialisationsPlaceholder: "Contact specialisations",
                PictureInput: "Required: Contact picture (Only jpeg or png files, best is 240x240px, 100Kb max)",
                PictureHint: "Only jpeg or png files, 240px height, 240px width"
            },
            News: {
                Title: "News",
                ViewAllNews: "View all news",
                Loading: "Loading your news...",
                ContactAuthor: "Contact with author",
                Specializations: "Specializations"
            },
            Agenda: {
                Title: "Agenda",
                ViewAllAgengaEntries: "View all events & deadlines",
                Loading: "Loading your events & deadlines...",
                LoadingAgendaEntry: "Loading your agenda entry...",
                ContactAuthor: "Contact with author",
                Specializations: "Specializations"
            },
            ArticleManagement: {
                Loading: "Loading news...",
                Title: "News",
                FilterStatusPlaceholder: "Filter by status",
                AddNews: "Add a news",
                ArticleTitle: "Title",
                ArticleTitlePlaceholder: "Article title",
                ArticleTitleHint: "Between 3 and 100 characters",
                Content: "Content",
                ContentPlaceholder: "'Let`s write an awesome story!'",
                CreatedAt:"Created at",
                Status: "Status",
                PubDate: "Pub. date",
                ViewArticleDetails: "View article details",
                BackToNewsList: "Back to news list",
                CurrentStatus: "Current status",
                NewStatus: "New status",
                NewStatusPlaceholder: "New status",
                NoNewStatus: "If no other status are available, please check with an editor or admin",
                PublicationDate: "Publication date",
                Picture: "Main picture (Optional)",
                PictureHint: "Only jpeg or png files, best is 400px height, with 1.5x ration, 500Kb max",
                PictureUploaderCaption: "Click here to select an image. Max size: 500kb",
                Tags: "Tags",
                TagsPlaceholder: "Select or create tags",
                RelatedContactPerson: "Related contact person",
                RelatedContactPersonPlaceholder: "Select a contact person",
                ErrorEditNoPermission: "As per your role and the current status of this article, you cannot edit it"
            },
            AgendaManagement: {
                Loading: "Loading your events & deadlines...",
                LoadingEvent: "Loading your event...",
                LoadingDeadline: "Loading your deadline...",
                Title: "Events & deadlines",
                FilterTypePlaceholder: "Filter by type",
                AddEvent: "Add an event",
                AddDeadline: "Add a deadline",
                EditEvent: "Edit event",
                EditDeadline: "Edit deadline",
                DeleteEvent: "Delete event",
                DeleteDeadline: "Delete deadline",
                Date: "Date",
                TitleAgendaEntry: "Title",
                TitleAgendaEntryPlaceholder: "Title",
                CTA: "Call to action",
                Type: "Type",
                ConfirmDeletion: "Do you confirm you want to delete this agenda entry?",
                Tags: "Tags",
                TagsPlaceholder: "Select or create tags",
                Content: "Description",
                ContentPlaceholder: "'Let`s describe an awesome event!'",
                PictureUploaderCaption: "Click here to select an image. Max size: 500kb",
                CtaLabel: "Label",
                CtaTitle: "Link title",
                CtaUrl: "Link url",
                RelatedContactPerson: "Related contact person",
                RelatedContactPersonPlaceholder: "Select a contact person",
                DuplicateEvent: "Duplicate event",
                DuplicateDeadline: "Duplicate deadline",
                CreateEventFrom: "Create an event from",
                CreateDeadlineFrom: "Create a deadline from",
                Targeting: "Targeting",
                TargetIndustries: "Industries",
                TargetServices: "Services",
                TargetCustomers: "Customers",
                TargetCustomersPlaceHolder: "Customers"
            },
            NotificationManagement:{
                Title:"Notifications",
                AddNotification:"Add maintenance notification",
                Loading:"Loading Notifications",
                Description:"Description",
                ExpirationDate:"Expiration date",
                DescriptionPlaceholder:"Type here a notification description",
                ExpirationDatePlaceHolder:"Select here an expiration date",
                ConfirmDeletion:"Can you confirm to you to remove this notification",
                Create:"New notification",
                Delete:"Remove this notification",
                Edit:"Edit this notification"

            },
            FormValidation: {
                TooShort: "Must contain at least {{length}} characters",
                TooLong: "Must contain maximum {{length}} characters",
                AtLeastOne: "At least one is required",
                Required: "Required field",
                InvalidUrl: "Must be a valid URL",
                InvalidDateRange: "Must be a valid date range",
                InvalidDate: "Must be a valid date",
                FileTooLarge: "File too large",
                UnsupportedFileFormat: "Unsupported file format",
                ImageResolutionDoesntMatch: "The image resolution does not match requirements",
                InvalidEmail: "This email is invalid",
                TooFewItems: "Must contain at least {{length}} element(s)",
                TooManyItems: "Must contain maximum {{length}} elements",
            },
            Actions: {
                Submit: "Submit",
                Save: "Save",
                Cancel: "Cancel",
                Saving: "Saving",
                Update: "Update",
                Delete: "Delete",
                Edit: "Edit",
                WorkingOn: "Working on...",
                Confirm: "Confirm",
                Create: "Create",
                Yes: "Yes",
                No: "No",
                Back: "Back"
            },
            AuditTrail: {
                Creation:"Created at {{date}} by {{userId}}",
                Update:"Updated at {{date}} by {{userId}}",
            },
            Status: {
                Active: "Active",
                Inactive: "Inactive"
            }
        },
        enums:{
           /* CustomerTurnOver:{
                [CustomerTurnOver.Small]: '<=100k€',
                [CustomerTurnOver.Medium]: '100k€<X<1m€',
                [CustomerTurnOver.Large]: '>=1m€',
            },
            CustomerSize:{
                [CustomerSize.Small]:'1-15',
                [CustomerSize.Medium]:'16-50',
                [CustomerSize.Large]:'51-150',
                [CustomerSize.XLarge]:'150+',
            },
            CustomerIndustry:{
                [CustomerIndustry.Industry1]:'Industry 1',
                [CustomerIndustry.Industry2]:'Industry 2',
                [CustomerIndustry.Industry3]:'Industry 3',
            },
            CustomerSector:{
                [CustomerSector.Sector1]:'Sector 1',
                [CustomerSector.Sector2]:'Sector 2',
                [CustomerSector.Sector3]:'Sector 3',
            },
            GtServingEntity:{
                [GtServingEntity.Entity1]:'Entity 1',
                [GtServingEntity.Entity2]:'Entity 2',
                [GtServingEntity.Entity3]:'Entity 3',
            },
            GtService:{
                [GtService.Service1]:'Service 1',
                [GtService.Service2]:'Service 2',
                [GtService.Service3]:'Service 3'
            }, */
            ArticleStatus:{
                [ArticleStatus.Draft]:'Draft',
                [ArticleStatus.Deleted]:'Archived',
                [ArticleStatus.Published]:'Published',
                [ArticleStatus.SubmittedForApproval]:'Submitted for approval',
            },
            AppStatus: {
                [AppStatus.Online]: 'Online',
                [AppStatus.Offline]: 'Offline',
                [AppStatus.Maintenance]:'Maintenance',
            },
            B2CmfaMethod: {
                [B2CmfaMethod.Email]: 'Email',
                [B2CmfaMethod.Totp]: 'Totp',
            },
            ManagerRole: {
                [ManagerRole.Admin]: 'Admin',
                [ManagerRole.Contributor]: 'Contributor',
                [ManagerRole.CustomerManager]: 'Customer manager',
                [ManagerRole.Editor]: 'Editor',
            },
            AgendaEntryType: {
                [AgendaEntryType.Deadline]: 'Deadline',
                [AgendaEntryType.Event]: 'Event',
            },
            UserLanguage: {
                [UserLanguage.En]: 'English',
                [UserLanguage.Fr]: 'French',
            }
        },
        ...amethystTranslation.en
    },
    fr: {
        translation: {
            MyGT:{
                AnonymousWelcome:'Bienvenue sur {{website_name}}, démarrage de l\'application...',
                AnonymousWelcomeFallback: "Si vous n'avez pas été redirigé, vous pouvez cliquer sur le bouton de connexion.",
                ErrorLoadingProfile: "Nous n'arrivons pas à obtenir votre profil utilisateur. Veuillez s'il vous plait rafraichir votre navigateur web",
                ErrorContentNotFound:"Nous sommes désolé, mais cette page n'existe pas",
                NoAccess:"Malheureusement nous ne pouvons pas donner suite à votre demande. En effet votre compte utilisateur n'est lié à aucun compte client actif. Merci de contacter notre équipe de support.",
                GlobalError:{
                    AuthenticationError:"Votre session a expiré. Vous allez être redirigé vers la page d'authentification",
                    NetworkError:'Nous n\'arrivons pas à joindre les servcies de {{website_name}}. Pouvez-vous s\'il vous plait vérifier votre connexion internet et rafraichir votre navigateur',
                    ApiForbiddenError:"Vous profil utilisateur n'est pas autorisé à réaliser cette opération",
                    ApiValidationError:"Cette action n'est pas réalisable pour le moment. Si le problème persiste, merci de contacter le support",
                    UnexpectedError:"Une erreur est survenue. Rafraichissez, s'il vous plait, votre votre navigateur. Si le problème persiste, merci de contacter le support",
                }
            },
            PageLayout:{
                MeLoading:"Nous préparons votre application",
                HomeLoading:"Chargement de votre contenu..."
            },
            PersonalInfo:{
                Title: "Vos informations personelles",
                FirstName: "Prénom",
                Lastname: "Nom",
                Email: "E-mail"
            },
            UserMenu:{
                Home:'Accueil',
                Apps:"Apps",
                Profile:"Informations personnelles",
                ChangePassword:"Changer mon mot de passe",
                CustomerAccount:"Compte client",
                CustomerAccountModal:{
                    Title:"Changer de compte client",
                    ListLabel:"List des comptes clients disponibles"
                },
                Logout:"Déconnexion",
                News:"News",
                Agenda:"Agenda",
                Admin:"My GT Admin",
                Language:"Langues",
                Languages:{
                    en:'Anglais',
                    fr:'Français'
                },
                AboutGT: "À propos de Grant Thornton Luxembourg",
                PrivacyPolicy: "Déclaration de confidentialité",
                PrivacyPolicyURL: "https://www.grantthornton.lu/privacy-notice-my-gt-experience-fr/"
            },
            ManagementTab:{
                Apps:"Apps",
                Customers:"Clients",
                Subscriptions:"Abonnement aux Apps",
                CustomerUsers: "Utilisateurs clients",
                Managers:"Utilisateurs GT",
                News: "News",
                Agenda: "Événements & échéances",
                Contact:"Contacts",
                Notifications:"Notifications"
            },
            SignInButton: { Label: "S'authentifier" },
            AppManagement: {
                Loading: "Chargement des apps...",
                Title: "Applications",
                AddApp: "Ajouter une application",
                Name: "Nom",
                NamePlaceholder: "Nom",
                Status: "Statut",
                StatusPlaceholder: "Statut",
                NextMaintenance: "Prochaine maintenance",
                Target: "URL",
                ProductPage: "Site d'information",
                EditApp: "Modifier l'application",
                None: "Aucune",
                AppLocation: "URL de l'application",
                AppLocationPlaceholder: "URL",
                AppInformation: "Site d'information pour les non-abonnés",
                AppInformationPlaceholder: "URL",
                Logo: "Logo (Optionnel, seulement un fichier jpeg ou png, 200Kb max, de préférence de 400px de hauteur, avec un rapport h/l de 1,5x, )",
                LogoHint: "Seulement fichier jpeg ou png, 200Kb max",
                Description: "Description",
                MaintenanceDates: "Dates de maintenance (début - fin)",
                MaintenanceDatesPlaceholder: "Sélectionner un plage de dates"
            },
            CustomerManagement: {
                Loading: "Chargement des comptes clients...",
                Title: "Clients",
                AddCustomer: "Ajouter un client",
                Name: "Nom",
                NamePlaceholder: "Nom",
                Status: "Statut",
                GTEntity: "Entité GT",
                GTService: "Service GT",
                ViewCustomerUsers: "Voir les utilisateurs clients",
                ViewApplicationSubscriptions: "Voir les abonnements aux apps",
                EditCustomer: "Modifier le client",
                Industries:"Industries",
                IndustriesPlaceholder: "Industries",
                Services: "Services",
                ServicesPlaceholder: "Services",
                GTManagers:"Managers responsable de",
                GTManagersPlaceholder:"Managers",
                CustomerID: "Identifiant client",
                CustomerIDPlaceholder: "Identifiant client interne",
                CustomerIDError: "L'identifiant du client doit comporte au moins 2 caractères et ne doit pas exister dans la base de données.",
                Industry: "Industrie",
                Sector: "Secteur",
                SectorPlaceholder: "Secteur",
                ServingEntity: "Entité de service",
                ServingEntityPlaceholder: "Entité de service",
                Country: "Pays",
                CountryPlaceholder: "Pays",
                Size: "Taille",
                SizePlaceholder: "Taille",
                TurnOver: "Chiffre d'affaire",
                TurnOverPlaceholder: "Chiffre d'affaire",
                IsInactive: "Inactif",
                IsRegulated: "Réglementé",
                IsPublicSector: "Secteur public",
                hasAProvider: "A un prestataire",
                Comments: "Commentaires",
                ContactInfo: "Informations de contact"
            },
            CustomerUserManagement: {
                Loading: "Chargement des comptes utilisateurs clients...",
                Title: "Utilisateurs clients",
                EmailError: "Cet e-mail n'est pas valide ou est déjà utilisé par un autre utilisateur",
                RetrievingUserDetails: "Chargement des détails de l'utilisateur",
                DeletingUser: "Suppression de l'utilisateur",
                SearchUsers: "Rechercher",
                AddUser: "Ajouter un utilisateur",
                FirstName: "Prénom",
                FirstNamePlaceholder: "Prénom",
                LastName: "Nom",
                LastNamePlaceholder: "Nom",
                Email: "E-mail",
                Customers:"Clients",
                EmailPlaceholder: "E-mail",
                LastSignIn: "Dernière connexion",
                MFA: "MFA",
                MFAPlaceholder: "Méthode",
                AccountEnabled: "Compte activé",
                DeleteUser: "Supprimer l'utilisateur",
                ClickToConfirmDeletion: "Confirmer la suppression de l'utilisateur {{userId}}",
                ConfirmDeletion: "Confirmez vous la suppression de cet utilisateur ?",
                SearchingUser: "Recherche des utilisateurs...",
                NoUsersFound: "Aucun utilisateur trouvé",
                EditUser: "Modifier l'utilisateur",
                CustomerFilter: "Filtrer par client",
                PleaseSelectCustomer: "Veuillez sélectionner un client",
                SearchUserEmails: "Filtrer par email approchant",
                RelatedCustomers:"Associé aux clients",
                RelatedCustomersPlaceholder: "Choisissez un ou des clients",
                Language: "Langue",
                LanguagePlaceholder: "Langue"
            },
            ManagerManagement: {
                Loading: "Chargement des comptes GT",
                Title: "Utilisateurs GT",
                ConfirmDeletion: "Confirmez-vous la suppression de cet utilisateur GT ?",
                Email: "E-mail",
                EmailPlaceholder: "someone@lu.gt.com",
                Roles: "Rôles",
                RolesPlaceholder: "Rôles",
                EmailError: "Cet e-mail n'est pas valide ou est déjà utilisé par un autre utilisateur.",
                AddManagerUser: "Ajouter un utilisateur GT",
                UpdateManagerUser: "Modifier l'utilisateur GT : {{name}}",
                EditManagerUser: "Modifier l'utilisateur GT",
                DeleteManagerUser: "Supprimer l'utilisateur GT"
            },
            Apps: {
                Loading: "Chargement de vos applications ...",
                InformationAbout: "Informations sur",
                GetAccessToApp: "Accédez à votre application ici.",
                MaintenanceEndDateInfo: "Ce service est en cours de maintenance jusqu'au {{date}}.",
                MaintenanceRangeDatesInfo: "Pour des raisons de maintenance, ce service sera indisponible du {{startDate}} au {{endDate}}.",
                MoreInformation: "Plus d'informations ici.",
                ViewAllApps: "Voir toutes les apps",
            },
            SubscriptionManagement: {
                LoadingAppsAndCustomers: "Chargement des apps et des comptes clients...",
                LoadingUsers: "Chargement des comptes utilisateurs...",
                LoadingApps: "Chargement des apps...",
                Title: "Abonnement aux apps",
                SearchingSubscriptions: "Recherche des abonnements...",
                SavingSubscriptions: "Sauvegarde de l'abonnement...",
                ManageSubscriptions: "Gérer les abonnements",
                NoSubcriptionsFound: "Aucun abonnement trouvé",
                NoUsersFound: "Aucun utilisateur trouvé",
                NoAppsFound: "Aucune application trouvée",
                Email: "E-mail / Apps",
                AddAllUsers: "Ajouter tous les utilisateurs du client",
                AddSelectedUsers: "Ajouter les utilisateurs sélectionnés",
                FoundUsersLength: "{{length}} utilisateurs trouvés",
                AddAllUsersConfirm: "Voulez-vous vraiment ajouter tous les utilisateurs ?",
                AddAllUsersMessage: "Les utilisateurs/abonnements existants ne seront pas remplacés, mais fusionnés.",
                SelectCustomer: "Sélectionner un client",
                PleaseSelectCustomer: "Veuillez sélectionner un client",
                SelectApp: "Sélectionner une application",
                SelectAppPlaceholder: "Sélectionner une application",
                SearchUsers: "Recherche des utilisateurs...",
                AddRowMessage:"Êtes vous sûr de vouloir ajouter toutes les applications à l'utilisateur {{userId}} ?",
                RemoveRowMessage:"Êtes vous sûr de vouloir retirer toutes les applications à l'utilisateur {{userId}} ?",
                AddColumnMessage:"Êtes vous sûr de vouloir ajouter cette application à tous les utilisateurs ?",
                RemoveColumnMessage:"Êtes vous sûr de vouloir supprimer cette application à tous les utilisateurs ?"
            },
            ContactManagement: {
                Loading: "Chargement des personnes de contacts...",
                Title: "Contacts",
                ConfirmDeletion: "Voulez-vous vraiment supprimer ce contact ?",
                AddContact: "Ajouter un contact",
                Picture: "Image",
                FirstName: "Prénom",
                FirstNamePlaceholder: "Prénom",
                LastName: "Nom",
                LastNamePlaceholder: "Nom",
                Email: "E-mail",
                EmailPlaceholder: "E-mail",
                DeleteContact: "Supprimer le contact",
                EditContact: "Modifier le contact",
                ContactTitle: "Titre",
                ContactTitlePlaceholder: "Titre",
                Phone: "Numéro de téléphone",
                PhonePlaceholder: "Numéro de téléphone",
                Specialisations: "Spécialisations",
                SpecialisationsPlaceholder: "Spécialisations",
                PictureInput: "Obligatoire: Avatar du contact (Seulement fichier jpeg ou png, de préférence 240x240px, 100Kb max)",
                PictureHint: "Seulement fichier jpeg or png, taille de 240px sur 240px"
            },
            News: {
                Title: "News",
                ViewAllNews: "Voir toutes les news",
                Loading: "Chargement de vos news...",
                ContactAuthor: "Informations sur l'auteur",
                Specializations: "Spécialisations"
            },
            Agenda: {
                Title: "Agenda",
                ViewAllAgengaEntries: "Voir tous les événements et échéances",
                Loading: "Chargement de vos événements et échéances...",
                LoadingAgendaEntry: "Chargement de votre élément d'agenda...",
                ContactAuthor: "Informations sur l'auteur",
                Specializations: "Spécialisations"
            },
            ArticleManagement: {
                Loading: "Chargement des news...",
                Title: "News",
                FilterStatusPlaceholder: "Filtrer par statut",
                AddNews: "Ajouter une news",
                ArticleTitle: "Titre de l'article",
                ArticleTitlePlaceholder: "Titre",
                ArticleTitleHint: "Entre 3 et 100 caractères",
                Content: "Contenu",
                ContentPlaceholder: "'Écrivons une histoire géniale !'",
                CreatedAt:"Créé le",
                Status: "Statut",
                PubDate: "Date de publication",
                ViewArticleDetails: "Voir les détails de l'article",
                BackToNewsList: "Retour aux news",
                CurrentStatus: "Statut actuel",
                NewStatus: "Nouveau statut",
                NewStatusPlaceholder: "Nouveau statut",
                NoNewStatus: "Si aucun autre statut n'est disponible, veuillez consulter un éditeur ou un administrateur",
                Picture: "Photo",
                PictureHint: "Seulement fichier jpeg ou png, 400px de hauteur, avec un ratio de 1,5x, 500Kb max",
                PictureUploaderCaption: "Cliquer ici pour sélectionner une image. Taille max. : 500kb",
                Tags: "Mots-clés",
                TagsPlaceholder: "Sélectionner ou créer des mots-clés",
                RelatedContactPerson: "Personne de contact associée",
                RelatedContactPersonPlaceholder: "Sélectionner une personne de contact",
                ErrorEditNoPermission: "Selon votre rôle et le statut actuel de cet article, vous ne pouvez pas le modifier"
            },
            AgendaManagement: {
                Loading: "Chargement des événements et échéances...",
                LoadingEvent: "Chargement de votre événement...",
                LoadingDeadline: "Chargement de votre échéance...",
                Title: "Événements & échéances",
                FilterTypePlaceholder: "Filtrer par type",
                AddEvent: "Ajouter un événement",
                AddDeadline: "Ajouter une échéance",
                EditEvent: "Modifier l'événement",
                EditDeadline: "Modifier l'échéance",
                DeleteEvent: "Supprimer l'événement",
                DeleteDeadline: "Supprimer l'échéance",
                Date: "Date",
                TitleAgendaEntry: "Titre",
                TitleAgendaEntryPlaceholder: "Titre",
                CTA: "Appel à action",
                Type: "Type",
                ConfirmDeletion: "Voulez-vous vraiment supprimer cette entrée de l'agenda ?",
                Tags: "Mots-clés",
                TagsPlaceholder: "Sélectionner ou créer des mots-clés",
                Content: "Description",
                ContentPlaceholder: "'Écrivons une description géniale !'",
                PictureUploaderCaption: "Cliquer ici pour sélectionner une image. Taille max. : 500kb",
                CtaLabel: "Libellé",
                CtaTitle: "Titre du lien",
                CtaUrl: "URL",
                RelatedContactPerson: "Personne de contact associée",
                RelatedContactPersonPlaceholder: "Sélectionner une personne de contact",
                DuplicateEvent: "Dupliquer l'événement",
                DuplicateDeadline: "Dupliquer l'échéance",
                CreateEventFrom: "Créér un événement à partir de",
                CreateDeadlineFrom: "Créér l'échéance à partir de",
                Targeting: "Ciblage",
                TargetIndustries: "Industries",
                TargetServices: "Services",
                TargetCustomers: "Clients",
                TargetCustomersPlaceHolder: "Clients"
            },
            NotificationManagement:{
                Title:"Notifications",
                AddNotification:"Ajouter une notification pour maintenance",
                Loading:"Chargement des notifications",
                Description:"Description",
                DescriptionPlaceholder:"Saisir la description de la notification",
                ExpirationDate:"Date d'expiration",
                ExpirationDatePlaceHolder:"Saisir la date d'expiration de la notification",
                ConfirmDeletion:"Pouvez-vous confirme la suppression de cette notification ?",
                Create:"Nouvelle notification",
                Delete:"Supprimer la notification",
                Edit:"Editer la notification"
            },
            FormValidation: {
                TooShort: "Doit contenir au moins {{length}} caractères",
                TooLong: "Doit contenir maximum {{length}} caractères",
                AtLeastOne: "Au minimum une selection nécessaire",
                Required: "Champ obligatoire",
                InvalidUrl: "Doit être une URL valide",
                InvalidDateRange: "Doit être une plage de dates valide",
                InvalidDate: "Doit être une date valide",
                FileTooLarge: "Fichier trop volumineux",
                UnsupportedFileFormat: "Format de fichier non pris en charge",
                ImageResolutionDoesntMatch: "La résolution de l'image ne correspond pas aux exigences",
                InvalidEmail: "Doit être une adresse e-email valide",
                TooFewItems: "Doit contenir au moins {{length}} élément(s)",
                TooManyItems: "Doit contenir maximum {{length}} éléments",
            },
            Actions: {
                Submit: "Envoyer",
                Save: "Sauver",
                Cancel: "Annuler",
                Saving: "Sauvegarde...",
                Update: "Modifier",
                Delete: "Supprimer",
                Edit: "Modifier",
                WorkingOn: "En cours...",
                Confirm: "Confirmer",
                Create: "Créer",
                Yes: "Oui",
                No: "Non",
                Back: "Retour"
            },
            AuditTrail: {
                Creation:"Créé le {{date}} par {{userId}}",
                Update:"Mis à jour le {{date}} par {{userId}}",
            },
            Status: {
                Active: "Actif",
                Inactive: "Inactif"
            }
        },
        enums:{
           /* CustomerTurnOver:{
                [CustomerTurnOver.Small.toString()]: '<=100k€',
                [CustomerTurnOver.Medium.toString()]: '100k€<X<1m€',
                [CustomerTurnOver.Large.toString()]: '>=1m€',
            },
            CustomerSize:{
                [CustomerSize.Small]:'1-15',
                [CustomerSize.Medium]:'16-50',
                [CustomerSize.Large]:'51-150',
                [CustomerSize.XLarge]:'150+',
            },
            CustomerIndustry:{
                [CustomerIndustry.Industry1]:'Industrie 1',
                [CustomerIndustry.Industry2]:'Industrie 2',
                [CustomerIndustry.Industry3]:'Industrie 3',
            },
            CustomerSector:{
                [CustomerSector.Sector1]:'Secteur 1',
                [CustomerSector.Sector2]:'Secteur 2',
                [CustomerSector.Sector3]:'Secteur 3',
            },
            GtServingEntity:{
                [GtServingEntity.Entity1]:'Entité 1',
                [GtServingEntity.Entity2]:'Entité 2',
                [GtServingEntity.Entity3]:'Entité 3',
            },
            GtService:{
                [GtService.Service1]:'Service 1',
                [GtService.Service2]:'Service 2',
                [GtService.Service3]:'Service 3'
            },*/
            ArticleStatus:{
                [ArticleStatus.Draft]:'Brouillon',
                [ArticleStatus.Deleted]:'Archivé',
                [ArticleStatus.Published]:'Publié',
                [ArticleStatus.SubmittedForApproval]:'Soumis pour approbation',
            },
            AppStatus: {
                [AppStatus.Online]: 'En ligne',
                [AppStatus.Offline]: 'Hors ligne',
                [AppStatus.Maintenance]:'En maintenance',
            },
            B2CmfaMethod: {
                [B2CmfaMethod.Email]: 'E-mail',
                [B2CmfaMethod.Totp]: 'Totp',
            },
            ManagerRole: {
                [ManagerRole.Admin]: 'Administrateur',
                [ManagerRole.Contributor]: 'Contributeur',
                [ManagerRole.CustomerManager]: 'Gestionnaire client',
                [ManagerRole.Editor]: 'Éditeur',
            },
            AgendaEntryType: {
                [AgendaEntryType.Deadline]: 'Échéance',
                [AgendaEntryType.Event]: 'Événement',
            },
            UserLanguage: {
                [UserLanguage.En]: 'Anglais',
                [UserLanguage.Fr]: 'Français',
            }
        },
        ...amethystTranslation.fr
    }
};