import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Image from "@editorjs/image";
import Embed from '@editorjs/embed';
import List from "@editorjs/list";
import Undo from "editorjs-undo";

export const initUndo = (editor, initialData) => {
    const undo = new Undo({ editor });
    undo.initialize(initialData);
}

export const BuildEditorTools = (imageUploader, imageUploaderCaption) => {
    return {
        // Block plugin to transform shared linked into embed from different social platform
        // (youtube, vimeo, instagram, twitter, facebook)
        embed: {
            class: Embed,
            inlineToolbar: true,
            config: {
                services: {
                    youtube: true,  // ie. https://youtu.be/MLsch7z4juY
                    vimeo:true,   // ie. https://vimeo.com/783455041
                    instagram:false,
                    twitter:false,
                    facebook:false,
                }
            }
        },
        // Block plugin for header
        header: {
            class: Header,
            inlineToolbar: true,
            config:{
                placeholder: 'Enter a header',
                levels: [2, 3, 4],
                defaultLevel: 2
            }
        },
        // Block plugin for regular paragraph (p)
        paragraph: {
            class: Paragraph,
            inlineToolbar: true
        },
        // Block plugin to support inline image, with customer image uploader
        image: {
            class: Image,
            inlineToolbar: true,
            config: {
                buttonContent:imageUploaderCaption,
                types:"image/*",
                uploader: {
                    uploadByFile(file){
                        return imageUploader(file);
                    },
                    uploadByUrl(file){
                        return null;//imageUploader(file);
                    }
                }
            }
        },
        // Block plugin to support list
        list: {
            class: List,
            inlineToolbar: true
        }
    }
}


