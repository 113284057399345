import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomerUser, ManagerRole, useGetCustomerUsers, useDeleteCustomerUser, useMe, CustomerUsersFilter } from '../../../model/user';
import { useCustomers } from '../../../model/customer';
import {
    Button,
    ButtonType,
    Container,
    DataGridCell,
    DataGridContent,
    DataGridFooter,
    DataGridHeader,
    DataGridPagination,
    DataGridRow,
    ISelectItem,
    Loader,
    LoaderSize,
    Message,
    MessageSize,
    SimpleSelect,
    TextInput,
} from '../../amethyst';
import { CustomerUserCreateEdit } from './CustomerUserCreateEdit';
import './customerUsers.scss';
import { useTranslation } from 'react-i18next';
import { useCustomerUserQuery } from './useCustomerUserQuery';
import { debounce } from 'lodash';
import { ConfirmModal } from '../../common/ConfirmModal';

interface CustomerUserTableProps {
    customerUsers:CustomerUser[]
    fetching:boolean
    onEdit:(user:CustomerUser) => void
    page:number
    totalPages:number
    onChangePage:(page: number) => void
    filter:CustomerUsersFilter
}

const CustomerUserTable: React.FC<CustomerUserTableProps> = ({ customerUsers, fetching, onEdit, page, totalPages, onChangePage, filter }) => {
    const { me } = useMe();
    const { t } = useTranslation();
    const [ deleteCustomerUser ] = useDeleteCustomerUser(filter);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState<boolean>(false);
    const [customerUserToDelete, setCustomerUserToDelete] = useState<CustomerUser | undefined>(undefined);

    const onOpenDeleteConfirmModal = (customerUser:CustomerUser) => {
        setCustomerUserToDelete(customerUser);
        setShowDeleteConfirmModal(true);
    }

    const onDelete = () => {
        if(customerUserToDelete) {
            return deleteCustomerUser({_id:customerUserToDelete._id})
        }
        return Promise.resolve();
    }

    return (
        <div className="customer-user-result">
            {fetching ? <Loader label={t("CustomerUserManagement.SearchingUser")} size={LoaderSize.medium} />:
                <>
                    <ConfirmModal
                        show={showDeleteConfirmModal}
                        close={() => setShowDeleteConfirmModal(false)}
                        title={t("CustomerUserManagement.ConfirmDeletion")}
                        message={customerUserToDelete ? customerUserToDelete._id : ''}
                        onConfirmAction={onDelete}
                    />
                    {(customerUsers.length === 0) ? <Message label={t("CustomerUserManagement.NoUsersFound")} icon="information-mark" size={MessageSize.medium} /> : null}
                    {(customerUsers.length > 0) ?
                        <>
                            <DataGridContent left hoverable columnNames={[t("CustomerUserManagement.Email"), t('CustomerUserManagement.Customers') , '']} >
                                {customerUsers.map((c, idx) =>
                                    <DataGridRow left key={idx}>
                                        <DataGridCell>{c._id}</DataGridCell>
                                        <DataGridCell>{c.properties.relationships.map(r => r.customer.name).join(', ')}</DataGridCell>
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} disabled={!!me && !me.isManagerWithRoles([ManagerRole.Admin])} title={t("CustomerUserManagement.DeleteUser")} icon="trash" aria-label={t("ManagerManagement.DeleteManagerUser")} onClick={() => onOpenDeleteConfirmModal(c)} />
                                            <Button kind={ButtonType.tertiary} title={t("CustomerUserManagement.EditUser")} aria-label={t("CustomerUserManagement.EditUser")} icon="chevron-small-right" onClick={() => onEdit(c)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )}
                            </DataGridContent>
                            <DataGridFooter>
                                <DataGridPagination disabled={fetching || totalPages < 2} currentPage={page} totalPages={totalPages} onPageClick={onChangePage} />
                            </DataGridFooter>
                        </>
                        : null
                    }
                </>
            }
        </div>
    )
}

type EditAction = {
    userToEdit:CustomerUser|undefined
    showEditForm:boolean
}

export const CustomerUsersCrud:React.FC<any> = () => {
    const { t } = useTranslation();
    const searchInputRef = useRef<HTMLInputElement>(null);

    // Customer entities
    const { pageOfCustomers, loading:loadingCustomer } = useCustomers({page:1, pageSize:100});

    // use query params to store pagination and email/customerId filtering
    const [setCustomerUserQuery, { customerId, emailPattern }, pagination ] = useCustomerUserQuery();
    const { pageOfCustomerUsers, loading:loadingCustomerUsers } = useGetCustomerUsers({ pagination, emailPattern, customerId });

    const loading = loadingCustomer || loadingCustomerUsers;

    const [ , setSearchInput] = useState<string>(emailPattern);

    const selectedCustomerItem = useMemo<ISelectItem|null>(() => {
        if(customerId) {
            let c = pageOfCustomers.items.find(c => c._id === customerId);
            if(!!c) return {id:c._id, label:c.name, value:c._id};
        }
        return null;
    }, [customerId, pageOfCustomers.items]);

    // user edit logic
    const [editAction, setEditAction] = useState<EditAction>({userToEdit:undefined, showEditForm:false})

    const customerItems = useMemo<ISelectItem[]>(() => {
        return pageOfCustomers.items.map(c => ({id:c._id, label:c.name, value:c._id}));
    }, [ pageOfCustomers.items ]);

    const onEditUser = (user: CustomerUser) => {
        setEditAction({userToEdit:user, showEditForm:true});
    }

    const onCloseEdit = () => {
        setEditAction({userToEdit:undefined, showEditForm:false});
    }

    const onChangePage = (page: number) => {
        setCustomerUserQuery({ emailPattern, customerId }, { page, pageSize:pagination.pageSize });
    }

    const onChangeCustomer = (item: ISelectItem|null) => {
        if(item) {
            setCustomerUserQuery({ emailPattern, customerId:item.id });
        } else {
            setCustomerUserQuery({ emailPattern, customerId:undefined });
        }
    }

    const delaySearch = useMemo(() => debounce((input:string, customerId:string|undefined) => {
            setCustomerUserQuery({ emailPattern:input, customerId:customerId });
        },
        800,
        // eslint-disable-next-line
        { trailing:true, leading: false}), []);

    // use callback as delaySearch depends on customerId and searchInputRef
    const onEmailPatternChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value.trim());
        delaySearch(e.target.value.trim(), customerId);
    }, [customerId, delaySearch]);

    useEffect(() => {
        if(!loading && searchInputRef.current) {
            searchInputRef.current.focus()
        }
    }, [loading]);

    return (
        <Container fluid className="customer-user">
            <CustomerUserCreateEdit user={editAction.userToEdit} show={editAction.showEditForm} onClose={onCloseEdit} customers={customerItems} filter={{customerId, emailPattern, pagination}} />
            <DataGridHeader title={t("CustomerUserManagement.Title")}>
                <div style={{display:"flex", gap:"10px"}}>
                    <SimpleSelect id="customer" items={customerItems} isClearable disabled={loading} searchable selectedItemId={selectedCustomerItem?.id} placeholder={t("CustomerUserManagement.CustomerFilter")} name="customer" noOptionsMessage="" onChange={onChangeCustomer} />
                    <TextInput ref={searchInputRef} defaultValue={emailPattern} id="user-search" form="user-filter" autoComplete="off" name="user-search" placeholder={t("CustomerUserManagement.SearchUserEmails")} disabled={loading} onChange={onEmailPatternChange} />
                    <Button kind={ButtonType.primary} disabled={loading} label={t("CustomerUserManagement.AddUser")} onClick={() => setEditAction({userToEdit:undefined, showEditForm:true})} />
                </div>
            </DataGridHeader>
            <CustomerUserTable customerUsers={pageOfCustomerUsers.items} fetching={loading} onEdit={(user) => onEditUser(user)} onChangePage={onChangePage} page={pagination.page} totalPages={pageOfCustomerUsers.totalPages} filter={{customerId, emailPattern, pagination}} />
        </Container>
    )
}
