import { QueryGetAgendaEntries } from '../../service/api/api-queries/agenda';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
    AgendaEntry,
    AgendaEntryType,
    MutationCreateAgendaDeadlineArgs,
    MutationCreateAgendaEventArgs,
    MutationRemoveAgendaEntryByIdArgs,
    MutationUpdateAgendaDeadlineArgs,
    MutationUpdateAgendaEventArgs,
    PaginatedAgendaEntries, PaginatedCustomers, Sorting,
    useCreateAgendaDeadlineMutation,
    useCreateAgendaEventMutation,
    useGetAgendaEntriesQuery,
    useGetCrudAgendaEntriesQuery,
    useQueryGetAgendaEntriesTagsQuery,
    useQueryGetAgendaEntryToEditQuery,
    useQueryGetAgendaEntryToViewQuery,
    useRemoveAgendaEntryByIdMutation,
    useUpdateAgendaDeadlineMutation,
    useUpdateAgendaEventMutation,
} from '../../service/api/generated-types';

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 10

const refetchOnCreateDelete = (filter:AgendaEntriesFilter) => (
    {
        query:QueryGetAgendaEntries,
        variables: {
            pagination: { page:filter.page, pageSize:filter.pageSize},
            filter: {
                type:filter.type.length > 0 ? filter.type : undefined,
                dateRange: { from:filter.from, to:filter.to, order:filter.order }
            }
        }
    }
)
const refetchLatestAgendaEntries = (
    {
        query:QueryGetAgendaEntries,
        variables: { pagination: { page:DEFAULT_PAGE, pageSize: DEFAULT_PAGE_SIZE} }
    }
)

type FetchAgendaEntriesRes = { pageOfAgendaEntries:PaginatedAgendaEntries, error: ApolloError|undefined, loading: boolean }
type FetchCrudAgendaEntriesRes = { pageOfAgendaEntries:PaginatedAgendaEntries, pageOfCustomers:PaginatedCustomers, error: ApolloError|undefined, loading: boolean }

export const useLatestAgendaEntries = (page: number = DEFAULT_PAGE, pageSize: number = DEFAULT_PAGE_SIZE, currentCustomerId: string|null = null):FetchAgendaEntriesRes => {

    const filter = currentCustomerId ? { filter: { customerId: currentCustomerId }} : null
    const {data, error, loading } = useGetAgendaEntriesQuery({
        variables: {
            pagination: { page, pageSize },
            ...filter
        }
    });
    if(data && data.agendaEntries && !loading) {
        return { pageOfAgendaEntries: data.agendaEntries as PaginatedAgendaEntries, error, loading}
    } else {
        return { pageOfAgendaEntries: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

type AgendaEntriesFilter = {
    page: number,
    pageSize: number,
    type: AgendaEntryType[],
    from: Date,
    to: Date
    order: Sorting
}

export const useAgendaEntries = (filter: AgendaEntriesFilter):FetchAgendaEntriesRes => {
    const { page, pageSize, type, to, from, order } = filter;
    const { data, error, loading } = useGetAgendaEntriesQuery({
        variables: {
            pagination: { page, pageSize},
            filter: { type:type.length > 0 ? type : undefined, dateRange: { from, to, order } }
        }
    });
    if(data && data.agendaEntries && !loading) {
        return { pageOfAgendaEntries: data.agendaEntries as PaginatedAgendaEntries, error, loading}
    } else {
        return { pageOfAgendaEntries: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

export const useCrudAgendaEntries = (filter: AgendaEntriesFilter):FetchCrudAgendaEntriesRes => {
    const { page, pageSize, type, to, from, order } = filter;
    const { data, error, loading } = useGetCrudAgendaEntriesQuery({
        variables: {
            pagination: { page, pageSize},
            filter: { type:type.length > 0 ? type : undefined, dateRange: { from, to, order } },
            customersFilter: {
                assigned:true
            },
            customersPagination: {
                page:1,
                pageSize:1000
            }
        }
    });
    if(data && data.agendaEntries && !loading) {
        return { pageOfAgendaEntries: data.agendaEntries as PaginatedAgendaEntries, pageOfCustomers:data.customers as PaginatedCustomers, error, loading}
    } else {
        return { pageOfAgendaEntries: { items:[], currentPage:0, totalPages:0 }, pageOfCustomers: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

type FetchAgendaEntry = { agendaEntry:AgendaEntry|undefined, error: ApolloError|undefined, loading: boolean }

export const useAgendaEntryToEdit = (_id:string|undefined = undefined):FetchAgendaEntry => {
    const {data, error, loading } = useQueryGetAgendaEntryToEditQuery({variables: {_id}});
    if(data && data.agendaEntry && !loading) {
        return { agendaEntry: data.agendaEntry as AgendaEntry, error, loading}
    } else {
        return { agendaEntry: undefined, error, loading}
    }
}

export const useAgendaEntryToView = (_id:string|undefined = undefined):FetchAgendaEntry => {
    const {data, error, loading } = useQueryGetAgendaEntryToViewQuery({variables: {_id}});
    if(data && data.agendaEntry && !loading) {
        return { agendaEntry: data.agendaEntry as AgendaEntry, error, loading}
    } else {
        return { agendaEntry: undefined, error, loading}
    }
}

type FetchAgendaEntriesTags = { loading: boolean, tags: string[], error: ApolloError | undefined }

export const useAgendaEntriesTags = ():FetchAgendaEntriesTags => {
    const { data, loading, error } = useQueryGetAgendaEntriesTagsQuery({fetchPolicy: "cache-and-network"});
    if(loading) return { loading, tags:[], error }
    return { loading, tags: (data ? data.agendaEntriesTags : []), error}
}

type CreateAgendaEntryRes = {
    agendaEntry:AgendaEntry|null,
    errors:readonly GraphQLError[]|undefined
}


type CreateAgendaEvent = [fn:(args: MutationCreateAgendaEventArgs) => Promise<CreateAgendaEntryRes>, status: { loading: boolean}]

export const useCreateAgendaEvent = (filter: AgendaEntriesFilter):CreateAgendaEvent => {
    const [createAgendaEvent, { loading }] = useCreateAgendaEventMutation();

    const fn = (args: MutationCreateAgendaEventArgs):Promise<CreateAgendaEntryRes> => {
        return createAgendaEvent({ variables: args, refetchQueries:[refetchOnCreateDelete(filter), refetchLatestAgendaEntries] })
            .then(({data, errors}) => {
                if(data) return { agendaEntry:data.createAgendaEvent as AgendaEntry, errors}
                return { agendaEntry:null, errors }
            });
    }
    return [fn, { loading }]
}

type CreateAgendaDeadline = [fn:(args: MutationCreateAgendaDeadlineArgs) => Promise<CreateAgendaEntryRes>, status: { loading: boolean}]

export const useCreateAgendaDeadline= (filter: AgendaEntriesFilter):CreateAgendaDeadline => {
    const [createAgendaDeadline, { loading }] = useCreateAgendaDeadlineMutation();
    const fn = (args: MutationCreateAgendaDeadlineArgs):Promise<CreateAgendaEntryRes> => {
        return createAgendaDeadline({variables: args, refetchQueries:[refetchOnCreateDelete(filter), refetchLatestAgendaEntries]})
            .then(({data, errors}) => {
                if(data) return { agendaEntry:data.createAgendaDeadline as AgendaEntry, errors}
                return { agendaEntry:null, errors }
            });
    }
    return [fn, { loading }]
}

type UpdateAgendaEntryRes = {
    agendaEntry:AgendaEntry|null
    errors:readonly GraphQLError[]|undefined
}

type UpdateAgendaEvent= [fn:(args: MutationUpdateAgendaEventArgs) => Promise<UpdateAgendaEntryRes>, status: { loading: boolean}]

export const useUpdateAgendaEvent = ():UpdateAgendaEvent => {
    const [updateAgendaEvent, { loading }] = useUpdateAgendaEventMutation();
    const fn = (args: MutationUpdateAgendaEventArgs):Promise<UpdateAgendaEntryRes> => {
        return updateAgendaEvent({variables:args}).then(({data, errors}) => {
            if(data) return { agendaEntry:data.updateAgendaEvent as AgendaEntry, errors}
            return { agendaEntry:null, errors }
        })
    }
    return [fn, { loading }]
}

type UpdateAgendaDeadline= [fn:(args: MutationUpdateAgendaDeadlineArgs) => Promise<UpdateAgendaEntryRes>, status: { loading: boolean}]

export const useUpdateAgendaDeadline = ():UpdateAgendaDeadline => {
    const [updateAgendaDeadline, { loading }] = useUpdateAgendaDeadlineMutation();
    const fn = (args: MutationUpdateAgendaDeadlineArgs):Promise<UpdateAgendaEntryRes> => {
        return updateAgendaDeadline({variables:args}).then(({data, errors}) => {
            if(data) return { agendaEntry:data.updateAgendaDeadline as AgendaEntry, errors}
            return { agendaEntry:null, errors }
        })
    }
    return [fn, { loading }]
}

type RemoveAgendaEntryRes = {
    result:boolean,
    errors:readonly GraphQLError[]|undefined
}

type RemoveAgendaEntry = [fn:(args:MutationRemoveAgendaEntryByIdArgs) => Promise<RemoveAgendaEntryRes>, status: { loading: boolean}]

export const useRemoveAgendaEntry = (filter: AgendaEntriesFilter):RemoveAgendaEntry => {
    const [removeAgendaEntry, { loading}] = useRemoveAgendaEntryByIdMutation()
    const fn = (args: MutationRemoveAgendaEntryByIdArgs):Promise<RemoveAgendaEntryRes> => {
        return removeAgendaEntry({variables:args, refetchQueries:[refetchOnCreateDelete(filter), refetchLatestAgendaEntries]})
            .then(({data, errors}) => {
                if(data) {
                    return { result:!!data.removeAgendaEntryById, errors}
                }
                return { result: false, errors }
            });
    }
    return [fn, { loading }]
}