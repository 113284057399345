import { useQueryParams, NumberParam, ArrayParam, withDefault } from 'use-query-params';
import { AgendaEntryType } from '../../../model/agendaEntry';
import { PaginationFilter, DEFAULT_PAGE_SIZE } from '../../amethyst'

const DEFAULT_TYPE = [AgendaEntryType.Deadline, AgendaEntryType.Event];

type AgendaEntryQuery = {
  type: AgendaEntryType[]
};

const AgendaEntriesKeys = Object.keys(AgendaEntryType);

type UseAgendaQuery = [updateAgendaEntryQuery:(query:AgendaEntryQuery, pagination?: PaginationFilter) => void, query:AgendaEntryQuery, pagination:PaginationFilter]

export const useAgendaEntriesQuery = (agendaEntryQuery: AgendaEntryQuery = {type:DEFAULT_TYPE}, pagination:PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}):UseAgendaQuery => {
    const [query, setQuery] = useQueryParams({
        p: withDefault(NumberParam, pagination.page),
        s: withDefault(NumberParam, pagination.pageSize),
        as: withDefault(ArrayParam, agendaEntryQuery.type as AgendaEntryType[]),
    });

    const type = (query.as as AgendaEntryType[]).reduce<AgendaEntryType[]>((selected, currentValue) => {
        if(AgendaEntriesKeys.includes(currentValue)) selected.push(currentValue);
        return selected;
    }, []);

    return [
        (agendaEntryQuery: AgendaEntryQuery, pagination:PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}) => {
            setQuery({
                p:pagination.page,
                s:pagination.pageSize,
                as:agendaEntryQuery.type
            })
        },
        {type},
        {page:query.p, pageSize:query.s}
    ]
}

