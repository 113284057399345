import React from 'react';
import { Tooltip, TooltipPosition } from '../tooltip/Tooltip';
import badgeBell from '../assets/img/bell-white.svg';
import './badge.scss';

export enum BadgeType {
    Success,
    Error,
    Notification
}

export interface BadgeProps {
    type: BadgeType
}

export interface SuccessBadgeProps extends BadgeProps {
    count:number
}

export interface ErrorBadgeProps extends BadgeProps {}

export interface NotificationBadgeProps extends BadgeProps {
    enabled?:boolean
    notificationMessage:string
}

const NotificationBadge:React.FC<NotificationBadgeProps> = ({notificationMessage, enabled}) => {
    if(enabled) {
        return (
            <span className="badge badge-notification">
                <span className="sr-only">new</span>
                <Tooltip position={TooltipPosition.bottom} id="notification-badge" label={notificationMessage}>
                    <img src={badgeBell} alt="notification bell" />
                </Tooltip>
            </span>
        )
    }
    return null;
}

const SuccessBadge:React.FC<SuccessBadgeProps> = ({count}) => {
    return (
        <span className="badge badge-success">
                <span className="sr-only">new</span>
                <span>{count}</span>
            </span>
    )
}

const ErrorBadge:React.FC<ErrorBadgeProps> = () => {
    return (
        <span className="badge badge-error">
                <span className="sr-only">warning</span>
                <span>!</span>
            </span>
    )
}

export const Badge:React.FC<NotificationBadgeProps|ErrorBadgeProps|SuccessBadgeProps> = props => {
    switch (props.type) {
        case BadgeType.Notification:
            const { ...notificationProps } = props as NotificationBadgeProps;
            return <NotificationBadge {...notificationProps} />
        case BadgeType.Success:
            const { ...successProps } = props as SuccessBadgeProps;
            return <SuccessBadge {...successProps} />
        case BadgeType.Error:
            const { ...errorProps } = props as ErrorBadgeProps
            return <ErrorBadge {...errorProps} />
        default:
            return null;
    }
}