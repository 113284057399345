import { gql } from '@apollo/client';

export const QueryGetCustomers = gql`
    query GetCustomers($pagination: CustomerPaginationArgs, $filter: CustomerFilterInput) {
      customers(pagination: $pagination, filter: $filter) {
        items {
            _id,
            name,
            gtCustomerId,
            disabled,
            properties {
                comments,
                contactInfo,
                industries,
                services,
                gtManagers
            }
        }
        currentPage
        totalPages
      }
    }    
`;

export const QueryGetCustomerById = gql`
    query GetCustomerById($_id: ObjectId!) {
        customer(_id: $_id) {
            _id,
            name,
            gtCustomerId,
            disabled,
            properties {
                comments,
                contactInfo
                industries,
                services,
                gtManagers
            }
        }
    }
`;

export const QueryIsGtCustomerIdAvailable = gql`
    query IsGtCustomerIdAvailable($gtCustomerId: String!) {
        isCustomerIdAvailable(gtCustomerId: $gtCustomerId)
    }
`

export const MutationCreateCustomer = gql`
    mutation CreateCustomer($customer: CustomerCreationInput!) {
        createCustomer(customer: $customer) {
            _id,
            name,
            gtCustomerId,
            disabled,
            properties {
                comments,
                contactInfo,
                industries
                services,
                gtManagers
            }
        }
    }
`;

export const MutationUpdateCustomer = gql`
    mutation UpdateCustomer($_id: ObjectId!, $customer: CustomerUpdateInput!) {
        updateCustomer(_id: $_id, customer: $customer) {
            _id,
            name,
            gtCustomerId,
            disabled,
            properties {
                comments,
                contactInfo,
                industries
                services,
                gtManagers
            }
        }
    }
`;

