import React from "react";
import { Icon } from "../icon";

export enum LoaderSize {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge"
}

export interface LoaderProps {
    label?:string|null
    size?:LoaderSize
}

export const Loader:React.FC<LoaderProps> = ({label, size}) => {
    return (
        <div className="information">
            <div className="loader">
                <div className={`loader-container ${size? size:'' }`}>
                    <Icon block icon="brand-mobius" label={label}/>
                </div>
                {label ? <div className={`loader-label ${size? size:'' }`}>{label}</div> : null}
            </div>
        </div>
    )
}

export const LoaderWithOverlay:React.FC<LoaderProps> = ({label, size}) => {
    return (
        <>
            <div className="show modal-backdrop"  />
            <div className="loader-overlay">
                <div className="loader">
                    <div className={`loader-container ${size? size:'' }`}>
                        <Icon block icon="brand-mobius" label={label}/>
                    </div>
                    {label ? <div className={`loader-label ${size? size:'' }`}>{label}</div> : null}
                </div>
            </div>
        </>
    )
}
