import React from 'react';
import { AgendaPage } from "../components/content/AgendaPage";

export const AllAgendaEntries:React.FC = () => {

    return (
        <div className="content-app mt-4 mb-8 ">
            <AgendaPage />
        </div>
    )
}