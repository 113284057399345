import React, { useMemo, Ref } from 'react';
import { OutputData } from '@editorjs/editorjs';
import Editor, { EditorApiRef, EditorImageUploader } from '../../editor/Editor';
import { InputStatus } from '../../common';
import { useField } from 'formik';

export interface FormEditorProps {
    id:string
    name:string
    placeholder?:string
    label?:string
    value?:string
    status?:InputStatus
    hint?:string
    disabled?:boolean
    onChange?:(value: string) => void
    imageUploader:EditorImageUploader
    imageUploaderCaption:string
    editorApiRef: Ref<EditorApiRef>
}

export const FormEditor:React.FC<FormEditorProps> = (props) => {
    const {id, label, hint, status, name, value, onChange, disabled, placeholder, imageUploader, imageUploaderCaption, editorApiRef} = props;

    const content = useMemo<OutputData>(() => {
        if(value) {
            try {
                return JSON.parse(value);
            }
            catch (e) {
                console.log(e)
                return {blocks:[]}
            }
        }
        return {blocks:[]}
    }, [value]);

    const internalOnChange = (data: OutputData) => {
        !!onChange && onChange(JSON.stringify(data));
    }

    return (
        <div className="form-wysiwyg">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
            {(!!hint || !!status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <></>
            }
            <Editor ref={editorApiRef} readonly={disabled} data={content} onChange={internalOnChange} holder="editorjs-container" imageUploader={imageUploader} imageUploaderCaption={imageUploaderCaption} placeholder={placeholder||''} />
        </div>
    )
}

export interface FieldFormEditorProps {
    id:string
    name:string
    placeholder?:string
    label?:string
    disabled?:boolean
    imageUploader:EditorImageUploader
    imageUploaderCaption:string
    editorApiRef: Ref<EditorApiRef>
}

export const FieldFormEditor:React.FC<FieldFormEditorProps> = (props) => {
    const [field, meta, helper ] = useField<string|undefined>(props.name);
    const {id, name, imageUploader, imageUploaderCaption, placeholder, label, disabled, editorApiRef } = props;
    return <FormEditor
        id={id}
        name={name}
        imageUploader={imageUploader}
        imageUploaderCaption={imageUploaderCaption}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        editorApiRef={editorApiRef}
        value={field.value}
        onChange={(value: string) => helper.setValue(value)}
        status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
        hint={meta.error}
    />
}