import React from "react";
import {Icon} from "../icon";

export enum MessageSize {
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge"
}

export interface MessageProps {
    label?:string|null
    size?:MessageSize
    icon?:string
}

export const Message:React.FC<MessageProps> = ({size, label, icon}) => {
    return (
        <div className="information">
            <div className="message">
                <div className={`message-container ${size? size:'' }`}>
                    {icon ? <Icon block icon={icon} label={label}/> : null}
                </div>
                {label ? <div className={`message-label ${size? size:'' }`}>{label}</div> : null}
            </div>
        </div>
    )
}