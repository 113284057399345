import React, {useMemo, useState, useRef, PropsWithChildren, useLayoutEffect} from "react";

export interface DropDownProps extends PropsWithChildren {
    name: string
    disabled?:boolean
    right?:boolean
    dropDrownMenuItems: React.ReactNode[]
}

interface GenericDropDownProps extends DropDownProps{
    icon:React.ReactNode
    className:string
}

export const DropDown:React.FunctionComponent<GenericDropDownProps> = ({ name, disabled, right, icon, className, dropDrownMenuItems, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dpRef = useRef<HTMLDivElement>(null);

    const classes = useMemo(() => {
        return `dropdown ${right ? "dropright" : ""} ${isOpen ? "open" : ""} ${disabled ? "disabled" : ""}`;
    }, [isOpen, right, disabled])

    /**
     * register event listener on document body to close select on outside click
     */
    useLayoutEffect(() => {
        const closeOnOutsideClick = (e: Event) => {
            const el = dpRef?.current
            // Do nothing if clicking ref's element or descendent elements
            if (!el || el.contains(e.target as Node)) {
                return;
            }
            setIsOpen(false);
        }
        if(isOpen) {
            document.body.addEventListener('click', closeOnOutsideClick);
        }

        return () => document.body.removeEventListener('click', closeOnOutsideClick);
    }, [isOpen]);

    const toggleMenu = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        disabled || setIsOpen(!isOpen);
    }

    return (
        <div ref={dpRef} className={classes}   >
            <a href="/#" onClick={(e) =>  toggleMenu(e)} id={name} title="toggle menu" aria-label="toggle menu" aria-expanded={isOpen} className={className}>
                {children}
                {icon}
            </a>
            <ul className="nav dropdown-menu" aria-labelledby={name} aria-hidden={!isOpen} style={{display: isOpen ? 'block' : 'none'}}>
                {dropDrownMenuItems.map((item, i) =>
                    <li key={i}>{item}</li>
                )}
            </ul>
        </div>
    )
}

const LinkDropDownIcon = <i className="icon icon-chevron-small-down" aria-hidden="true"></i>

export const LinkDropDown:React.FunctionComponent<DropDownProps> = ({ name, disabled= false, right= false, dropDrownMenuItems, children}) => {
    return (
        <DropDown
            name={name}
            disabled={disabled}
            right={right}
            icon={LinkDropDownIcon}
            className="dropdown-toggle link link-icon"
            dropDrownMenuItems={dropDrownMenuItems}>
                {children}
        </DropDown>
    )
}
