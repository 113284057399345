import React from 'react';
import { NewsPage } from "../components/content/NewsPage";

export const AllNews:React.FC = () => {

    return (
        <div className="content-app mt-4 mb-8 ">
            <NewsPage />
        </div>
    )
}