import { useEffect } from "react";
import { Dispatcher } from "./dispatcher";
import { MyGTApiErrors } from './api/graphqlErrors';
import { NetworkError } from '@apollo/client/errors';

export enum GlobalErrorType {
    network = 'network',
    api = 'api'
}

export enum NetworkErrorsType {
    InteractionRequiredAuthError= "InteractionRequiredAuthError",
    Unknown = "Unknown"
}

const NetworkErrorsArray = Object.values(NetworkErrorsType);

export const parseNetworkError = (error: NetworkError):NetworkErrorsType => {
    if(error && NetworkErrorsArray.includes((error.name) as NetworkErrorsType)) {
        return error.name as NetworkErrorsType;
    }
    return NetworkErrorsType.Unknown;
}


type GlobalError = {
    errorType:GlobalErrorType
    message:string
    apiError?:MyGTApiErrors
    networkError?:NetworkErrorsType
}

export const globalErrorDispatcher = new Dispatcher<GlobalError>()

export const useGlobalError = (onError: (error: GlobalError) => void) => {
    useEffect(() => {
        const unsubscribe = globalErrorDispatcher.subscribe(onError);
        return () => {
            unsubscribe?.();
        }
    }, [onError]);
}
