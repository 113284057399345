import { gql } from '@apollo/client';

export const QueryGetCrudAgendaEntries = gql`
    query GetCrudAgendaEntries($filter: AgendaEntryFilterInput, $pagination: PaginationArgs, $customersFilter: CustomerFilterInput, $customersPagination: CustomerPaginationArgs) {
        agendaEntries(filter: $filter, pagination: $pagination) {
            items {
                _id
                title
                date
                tags
                type
                properties {
                    ... on Event {
                        cta {
                            link { label, title, url }
                        }
                        teaser
                    }
                    ... on Deadline {
                        cta {
                            contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                        }
                        teaser
                    }
                }
                target {
                    customers {
                        _id
                        name
                    }
                    services
                    industries
                }
            }
            currentPage
            totalPages
        },
        customers(filter: $customersFilter, pagination: $customersPagination) {
            items {
                _id
                name
            }
        }
    }
`;


export const QueryGetAgendaEntries = gql`
    query GetAgendaEntries($filter: AgendaEntryFilterInput, $pagination: PaginationArgs) {
        agendaEntries(filter: $filter, pagination: $pagination) {
            items {
                _id
                title
                date
                tags
                type
                properties {
                    ... on Event {
                        cta {
                            link { label, title, url }
                        }
                        teaser
                    }
                    ... on Deadline {
                        cta {
                            contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                        }
                        teaser
                    }
                }
                target {
                    customers {
                        _id
                        name
                    }
                    services
                    industries
                }
            }
            currentPage
            totalPages
        }
    }
`;

export const QueryGetAgendaEntriesTags = gql`
    query QueryGetAgendaEntriesTags {
        agendaEntriesTags
    }
`

export const QueryGetAgendaEntryToView = gql`
    query QueryGetAgendaEntryToView($_id: ObjectId!) {
        agendaEntry(_id: $_id) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Event {
                    cta {
                        link { label, title, url }
                    }
                    richContent
                }
                ... on Deadline {
                    cta {
                        contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                    }
                    description
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const QueryGetAgendaEntryToEdit = gql`
    query QueryGetAgendaEntryToEdit($_id: ObjectId!) {
        agendaEntry(_id: $_id) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Event {
                    cta {
                        link { label, title, url }
                    }
                    rawContent
                }
                ... on Deadline {
                    cta {
                        contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                    }
                    description
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const MutationCreateAgendaEvent = gql`
    mutation CreateAgendaEvent($event: AgendaEventInput!) {
        createAgendaEvent(event: $event) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Event {
                    cta {
                        link { label, title, url }
                    }
                    rawContent
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const MutationCreateAgendaDeadline = gql`
    mutation CreateAgendaDeadline($deadline: AgendaDeadlineInput!) {
        createAgendaDeadline(deadline: $deadline) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Deadline {
                    cta {
                        contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                    }
                    description
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const MutationUpdateAgendaEvent = gql`
    mutation UpdateAgendaEvent($event: AgendaEventInput!, $_id: ObjectId!) {
        updateAgendaEvent(event: $event, _id: $_id) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Event {
                    cta {
                        link { label, title, url }
                    }
                    rawContent
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const MutationUpdateAgendaDeadline = gql`
    mutation UpdateAgendaDeadline($deadline: AgendaDeadlineInput!, $_id: ObjectId!) {
        updateAgendaDeadline(deadline: $deadline, _id: $_id) {
            _id
            title
            date
            tags
            type
            properties {
                ... on Deadline {
                    cta {
                        contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl } }
                    }
                    description
                }
            }
            target {
                customers {
                    _id
                    name
                }
                services
                industries
            }
        }
    }
`;

export const MutationRemoveAgendaEntryById = gql`
    mutation RemoveAgendaEntryById($_id: ObjectId!) {
        removeAgendaEntryById(_id: $_id)
    }
`