import React from "react";
import { PlainColor } from '../common';

export interface IconProps {
    icon:string
    title?:string
    label?:string|null
    block?:boolean
    color?:PlainColor
}

export const Icon:React.FC<IconProps> = ({icon, title, label, block = false, color}) => {
    const style = color ? { color } : undefined;
    return (
        <>
            <i style={style} className={`icon ${block? 'icon-d-block' : ''} icon-${icon}`} title={title} aria-hidden="true" />
            {label ? <span className="sr-only">{label}</span> : null }
        </>
    )
}
