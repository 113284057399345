import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgendaEntryContent } from '../components/agenda/view/AgendaEntryContent';
import { Grid, GridColumn, GridRow, Loader, LoaderSize } from '../components/amethyst';
import { useTranslation } from 'react-i18next';
import { useAgendaEntryToView } from '../model/agendaEntry';
import { ContentNotFound } from '../components/error/ContentNotFound';

interface AgendaLoaderProps {
    id:string;
}

const AgendaLoader:React.FC<AgendaLoaderProps> = ({id}) => {
    const {loading, agendaEntry} = useAgendaEntryToView(id);
    const { t } = useTranslation();
    
    if(loading && !agendaEntry) {
        return (
            <article>
                <header className="article-header"></header>
                <div className="content-app content-app-text-content">
                    <Grid>
                        <GridRow>
                            <GridColumn defaultSize={12}>
                                <Loader size={LoaderSize.medium} label={t("Agenda.LoadingAgendaEntry")}/>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </div>
            </article>
        )
    }

    if(!!agendaEntry) {
        return <AgendaEntryContent agendaEntry={agendaEntry} />
    }

    return <ContentNotFound />
}

export const OneAgendaEntry:React.FC<any> = () => {
    const navigate = useNavigate();
    const params = useParams();
    if(!!params.id) {
        return <AgendaLoader id={params.id} />
    } else {
        navigate('/');
        return null;
    }
}