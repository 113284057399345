import { LogLevel } from "@azure/msal-browser";
import { config } from "../config";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const clientId = config.azB2C_clientId
const tenantId = config.azB2C_tenantId
const policySignIn = "B2C_1A_MYGT_SIGNIN";
const policyChangePassword = "B2C_1A_MYGT_CHANGEPASSWORD";

const tenant = `${tenantId}.onmicrosoft.com`;
const azureADB2CHostname = `${tenantId}.b2clogin.com`
const readScope = `https://${tenant}/${tenantId}/read`;
const authorityBase = `https://${azureADB2CHostname}/tfp/${tenant}`;

export const b2cPolicies = {
    names: {
        signIn: policySignIn,
        changePassword: policyChangePassword
    },
    authorities: {
        signIn: {
            authority: `${authorityBase}/${policySignIn}`
        },
        changePassword: {
            authority: `${authorityBase}/${policyChangePassword}`
        }
    },
    authorityDomain: azureADB2CHostname
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: [readScope]
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const myGTAPIScope = {
    scopes: [`https://${tenant}/mygt-api/api`]
}

