import React, { useCallback, useEffect, useState, KeyboardEvent, useMemo } from 'react';
import Select, { components, GroupBase } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { debounce } from 'lodash';
import {ISelectItem} from "./index";
import {InputStatus} from "../common";
import { customStyles } from "./selectCustomStyle";
import { MultiValueGenericProps } from 'react-select/dist/declarations/src/components/MultiValue';


export interface MultipleSelectProps {
    id:string,
    items:ISelectItem[],
    disabled?:boolean,
    status?:InputStatus
    hint?:string
    selectedItemIds?:string[]
    placeholder:string
    isClearable?:boolean
    isSearchable?:boolean
    name:string
    form?:string
    noOptionsMessage:string
    onChange?:(items: readonly ISelectItem[]) => void
    onBlur?:(e: React.FocusEvent<HTMLInputElement>) => void
    displayLabelAsValue?:boolean
}

const DisplayValueAsLabel:React.FC<MultiValueGenericProps<ISelectItem, true, GroupBase<ISelectItem>>> = props => {
    return (
        <components.MultiValueLabel {...props}>
            <span title={props.data.label}>{props.data.value}</span>
        </components.MultiValueLabel>
    );
}

export const MultipleSelect:React.FC<MultipleSelectProps> = (props) => {
    const {id, name, form, hint, placeholder, disabled, isClearable, status, items, selectedItemIds, onChange, onBlur, noOptionsMessage, isSearchable, displayLabelAsValue} = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const subComponents = useMemo(() => {
        if(displayLabelAsValue) {
            return {MultiValueLabel:DisplayValueAsLabel}
        }
        return {}
    }, [displayLabelAsValue])

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape" && isOpen) {
            e.stopPropagation();
        }
    }

    const [selectedItems, setSelectedItems] = useState<ISelectItem[]>(items.filter(i => selectedItemIds?.includes(i.id)));
    useEffect(() => {
        setSelectedItems(items.filter(i => selectedItemIds?.includes(i.id)))
    }, [selectedItemIds, items]);



    return (
        <>
            <Select<ISelectItem, true>
                id={id}
                name={name}
                form={form}
                className={status ? status : ''}
                placeholder={placeholder}
                value={selectedItems}
                isDisabled={disabled}
                isLoading={false}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={items}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                onKeyDown={handleKeyDown}
                onMenuClose={() => setIsOpen(false)}
                onMenuOpen={() => setIsOpen(true)}
                noOptionsMessage={() => noOptionsMessage}
                onChange={(newValue) => newValue && onChange && onChange(newValue)}
                onBlur={(e) => onBlur && onBlur(e)}
                components={subComponents}
            />
            {(hint || status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <span className="" id={`${id}-hint`}>&nbsp;</span>
            }
        </>
    )
}

export interface CreatableMultiSelectProps {
    id:string
    name:string
    disabled?:boolean
    status?:InputStatus
    hint?:string
    placeholder:string
    isClearable?:boolean
    form?:string
    noOptionsMessage:string
    selectedItems:ISelectItem[]
    onChange?:(items: readonly ISelectItem[]) => void
    onBlur?:(e: React.FocusEvent<HTMLInputElement>) => void
    items: ISelectItem[]
}

export const CreatableMultiSelect:React.FC<CreatableMultiSelectProps> = (
    {
        id, isClearable,
        placeholder, form, noOptionsMessage, selectedItems,
        onChange, onBlur, name, items, disabled,
        hint, status}) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape" && isOpen) {
            e.stopPropagation();
        }
    }

    return (
        <>
            <CreatableSelect<ISelectItem, true>
                id={id}
                name={name}
                form={form}
                className={status ? status : ''}
                noOptionsMessage={() => noOptionsMessage}
                styles={customStyles}
                closeMenuOnSelect={false}
                isMulti
                options={items}
                isClearable={isClearable}
                placeholder={placeholder}
                value={selectedItems}
                onKeyDown={handleKeyDown}
                onMenuClose={() => setIsOpen(false)}
                onMenuOpen={() => setIsOpen(true)}
                onChange={(newValue) => newValue && onChange && onChange(newValue)}
                onBlur={(e) => onBlur && onBlur(e)}
                isDisabled={disabled}
            />
            {(hint || status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <span className="" id={`${id}-hint`}>&nbsp;</span>
            }
        </>
    )
}


export interface AsyncMultiSelectProps {
    id:string
    name:string
    disabled?:boolean
    status?:InputStatus
    hint?:string
    placeholder:string
    isClearable?:boolean
    form?:string
    noOptionsMessage:string
    selectedItems:ISelectItem[]
    onChange?:(items: readonly ISelectItem[]) => void
    onBlur?:(e: React.FocusEvent<HTMLInputElement>) => void
    promisedItems: (input:string) => Promise<ISelectItem[]>
}

export const AsyncMultiSelect:React.FC<AsyncMultiSelectProps> = ({id, name, placeholder, form, noOptionsMessage, onBlur, onChange, selectedItems, isClearable, disabled, promisedItems}) => {
    const [previousResults, setPreviousResults] = useState<ISelectItem[]>([])

    const debounceSearch = useMemo(() =>
        debounce(
            (input, callback) => {
                return promisedItems(input).then(result => {
                    setPreviousResults(result);
                    return callback(result)
                });
            },
            500,
            { trailing:true, leading: false}
        ), [promisedItems]);

    const triggerPromiseOptions = useCallback(debounceSearch, [debounceSearch]);

    useEffect(() => {
        // To reset any search result used in defaultOptions :
        // only when local previousResults is not empty AND selectedItems is empty
        if(previousResults.length > 0 && selectedItems.length === 0) setPreviousResults([]);
    }, [selectedItems, previousResults]);

    return (
        <AsyncSelect<ISelectItem, true>
            id={id}
            name={name}
            defaultOptions={previousResults}
            form={form}
            noOptionsMessage={() => noOptionsMessage}
            isMulti
            placeholder={placeholder}
            value={selectedItems}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={disabled}
            isClearable={isClearable}
            loadOptions={triggerPromiseOptions}
            styles={customStyles}
            closeMenuOnSelect={false}
        />
    )
}

