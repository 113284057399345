import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
    Modal, ModalContent,
    Container, Form, FormGroup, FieldTextInput, FormAction,
    FieldFormCreatableMultiSelect, ISelectItem, FieldDatePicker, FieldTextAreaInput, FieldFormSimpleSelect,
    LoaderSize, LoaderWithOverlay, FieldFormMultipleSelect,
} from '../../amethyst';
import { useTranslation } from 'react-i18next';
import { AgendaEntry, MutationCreateAgendaDeadlineArgs, MutationUpdateAgendaDeadlineArgs, Deadline } from '../../../model/agendaEntry';
import { Contact } from '../../../model/contact';
import { parseISO } from 'date-fns';
import { useContextManagerRole } from '../../security/ProtectedRoute';
import { Customer, ServicesItems, IndustriesItems } from '../../../model/customer';
import { AgendaDeadlineInput, AgendaEntryTargetInput } from '../../../service/api/generated-types';

interface AgendaDeadlineFormType {
    title:string,
    date:Date,
    tags: ISelectItem[],
    content: string,
    contact:ISelectItem|undefined
    targetIndustries:ISelectItem[],
    targetServices:ISelectItem[],
    targetCustomers:ISelectItem[]
}

export interface AgendaDeadlineEditFormProps {
    agendaEntryToEdit?:AgendaEntry
    customers:Customer[]
    onCreate: (params: MutationCreateAgendaDeadlineArgs) => Promise<any>
    onUpdate: (params: MutationUpdateAgendaDeadlineArgs) => Promise<any>
    mustBeDuplicated: boolean
    show: boolean,
    tags:string[]
    contacts:Contact[],
    close: () => void
    loading:boolean
}

const newAgendaDeadlineDefaultValues:AgendaDeadlineFormType = {
    title: '',
    date: new Date(),
    tags: [],
    content: '',
    contact:undefined,
    targetIndustries:[],
    targetServices:[],
    targetCustomers:[]
}

const targetingValue = (industries: ISelectItem[], services: ISelectItem[], customerIds: ISelectItem[]):AgendaEntryTargetInput|undefined => {
    if(industries.length === 0 && services.length === 0 && customerIds.length === 0) return undefined;
    const target:AgendaEntryTargetInput = {
        industries:industries.map(i => i.id),
        services:services.map(i => i.id),
        customerIds:customerIds.map(i => i.id)
    }
    return target;
}

export const AgendaDeadlineEditForm: React.FC<AgendaDeadlineEditFormProps> = ({ agendaEntryToEdit, customers, onCreate, onUpdate, mustBeDuplicated, show, close, tags, contacts, loading }) => {
    const { t } = useTranslation();
    const { locale } = useContextManagerRole();

    const formValidationSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, t("FormValidation.TooShort", { length: 3 }))
            .max(60, t("FormValidation.TooLong", { length: 60 }))
            .required(t("FormValidation.Required")),
        date: Yup.date()
            .typeError(t("FormValidation.InvalidDate"))
            .label("Date")
            .required(t("FormValidation.Required"))
    });

    const tagsItems = useMemo<ISelectItem[]>(() => tags.map(t => ({ id: t, label: t, value: t })), [tags]);
    const contactItems = useMemo<ISelectItem[]>(() => contacts.map(c => ({ id: c._id, value: c.email, label: c.email })), [contacts]);
    const customersItems = useMemo<ISelectItem[]>(() => customers.map(c => ({id:c._id, value: c.name, label: c.name})), [customers]);
    
    const initialValues = useMemo<AgendaDeadlineFormType>(() => {
        if (!!agendaEntryToEdit) {
            const agendaDeadlineProperties = agendaEntryToEdit.properties as Deadline;
            return {
                title: agendaEntryToEdit.title,
                date: parseISO(agendaEntryToEdit.date),
                tags: agendaEntryToEdit.tags.map(t => ({ id: t, value: t, label: t })),
                content: agendaDeadlineProperties.description,
                contact: agendaDeadlineProperties.cta.contactPerson ? ({ id: agendaDeadlineProperties.cta.contactPerson._id, value: agendaDeadlineProperties.cta.contactPerson.email, label: agendaDeadlineProperties.cta.contactPerson.email }) : undefined,
                targetIndustries:agendaEntryToEdit.target?.industries ? agendaEntryToEdit.target.industries.reduce<ISelectItem[]>((indItems, id) => {
                    let item = IndustriesItems.find(item => item.id === id);
                    if(item) indItems.push(item);
                    return indItems;
                }, []) : [],
                targetServices:agendaEntryToEdit.target?.services ? agendaEntryToEdit.target.services.reduce<ISelectItem[]>((svcItems, id) => {
                    let item = ServicesItems.find(item => item.id === id);
                    if(item) svcItems.push(item);
                    return svcItems;
                }, []) : [],
                targetCustomers:agendaEntryToEdit.target?.customers ? agendaEntryToEdit.target.customers.reduce<ISelectItem[]>((cItems, c) => {
                    let item = customers.find(item => item._id === c._id);
                    if(item) cItems.push({ id: item._id, label: item.name, value: item.name});
                    return cItems;
                }, []): []
            }
        }
        return newAgendaDeadlineDefaultValues;
    }, [agendaEntryToEdit, customers]);

    /**
     * Article submit (creation or update)
     * @param values
     */

    const onSubmit = async (values: AgendaDeadlineFormType, { resetForm }: FormikHelpers<AgendaDeadlineFormType>): Promise<void> => {
        const deadline: AgendaDeadlineInput = {
            title: values.title,
            description: values.content,
            date: values.date,
            tags: values.tags.map(t => t.value),
            cta: { contactPersonId: values.contact },
            target:targetingValue(values.targetIndustries, values.targetServices, values.targetCustomers)
        };

        if (!!agendaEntryToEdit && !mustBeDuplicated) {
            await onUpdate({ _id: agendaEntryToEdit._id, deadline });
        } else {
            await onCreate({ deadline });
        }
        resetForm({ values: initialValues });
        close();
    }

    if (!show) return null;
    const editMode = !!agendaEntryToEdit;
    const duplicateMode = mustBeDuplicated;

    return (
        <>
            {loading ?
                <LoaderWithOverlay size={LoaderSize.medium} label={t("AgendaManagement.LoadingDeadline")} /> :
                <Modal
                    open={show}
                    title={
                        !editMode ? t("AgendaManagement.AddDeadline")
                            : duplicateMode ? `${t("AgendaManagement.CreateDeadlineFrom")} ${agendaEntryToEdit.title}`
                            : `${t("Actions.Update")} ${agendaEntryToEdit.title}`
                    } onClose={() => close()}
                    closeOnEscape
                >
                    <ModalContent>
                        <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                            <Container fluid>
                                <Form id="agendaDeadlineForm" inline>
                                    <FormGroup>
                                        <FieldTextInput
                                            label={t("AgendaManagement.TitleAgendaEntry")}
                                            form="agendaDeadlineForm"
                                            id="title"
                                            name="title"
                                            placeholder={t("AgendaManagement.TitleAgendaEntryPlaceholder")}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldDatePicker
                                            id="date"
                                            form="agendaDeadlineForm"
                                            name="date"
                                            label={t("AgendaManagement.Date")}
                                            locale={locale}
                                        />
                                        <FieldFormCreatableMultiSelect
                                            id="tags"
                                            name="tags"
                                            form="agendaDeadlineForm"
                                            items={tagsItems}
                                            label={t("AgendaManagement.Tags")}
                                            placeholder={t("AgendaManagement.TagsPlaceholder")}
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldTextAreaInput
                                            fluid
                                            id="content"
                                            name="content"
                                            label={t("AgendaManagement.Content")}
                                            form="agendaDeadlineForm"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormSimpleSelect
                                            id="contact"
                                            name="contact"
                                            items={contactItems}
                                            label={t("AgendaManagement.RelatedContactPerson")}
                                            placeholder={t("AgendaManagement.RelatedContactPersonPlaceholder")}
                                            isClearable
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormGroup legend={t("AgendaManagement.Targeting")}>
                                        <FieldFormMultipleSelect
                                            id="targetIndustries"
                                            items={IndustriesItems}
                                            placeholder={t("AgendaManagement.TargetIndustries")}
                                            name="targetIndustries"
                                            label={t("AgendaManagement.TargetIndustries")}
                                            noOptionsMessage=""
                                            displayLabelAsValue
                                        />
                                        <FieldFormMultipleSelect
                                            id="targetServices"
                                            items={ServicesItems}
                                            placeholder={t("AgendaManagement.TargetServices")}
                                            name="targetServices"
                                            label={t("AgendaManagement.TargetServices")}
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormMultipleSelect
                                            id="targetCustomers"
                                            items={customersItems}
                                            label={t("AgendaManagement.TargetCustomers")}
                                            placeholder={t("AgendaManagement.TargetCustomers")}
                                            name="targetCustomers"
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormAction
                                        submittingLabel={t("Actions.Saving")}
                                        submitLabel={t("Actions.Save")}
                                        resetLabel={t("Actions.Cancel")}
                                    />
                                </Form>
                            </Container>
                        </Formik>
                    </ModalContent>
                </Modal>
            }
        </>
    )
}