import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosProgressEvent } from 'axios';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { OutputData } from '@editorjs/editorjs';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useContextManagerRole } from '../../security/ProtectedRoute';
import {
    allowedNextStatus,
    Article,
    ArticleStatus,
    MutationCreateArticleArgs,
    MutationUpdateArticleArgs,
    useCreateArticle,
    useUpdateArticle,
} from '../../../model/article';
import { Contact } from '../../../model/contact';
import {
    Button,
    ButtonType,
    FieldDatePicker,
    FieldFormCreatableMultiSelect,
    FieldFormEditor,
    FieldFormSimpleSelect,
    FieldImageUpload,
    FieldTextInput,
    Form,
    FormAction,
    FormGroup,
    Grid,
    GridColumn,
    GridRow,
    IEditorImageUploadResult,
    IImageSelector,
    ISelectItem,
    Message,
    MessageSize,
    ToggleInput,
} from '../../amethyst';
import { buildBlobValue, richContentLenght, uploadContentImage } from '../../utils';
import { EditorApiRef } from '../../amethyst/editor/Editor';
import { AuditTrail, ManagerRole } from '../../../service/api/generated-types';
import { ImagePreviewRatio } from '../../amethyst/form';
import { printDateFromIso } from '../../../i18n';

type ArticleFormType = {
    title: string
    content: string
    newStatus: ISelectItem|undefined // selected new status, based on possible list of article lifecycle
    tags:ISelectItem[]
    publicationDate:Date
    mainPicture:IImageSelector
    contactPerson:ISelectItem|undefined
}

// Max filesize for pictures attached to articles (main or inline)
const IMAGE_MAX_SIZE = 500*1024;

export interface ArticleEditFormProps {
    article?:Article
    tags:string[]
    contacts:Contact[]
}

const updatingFormValues = (article:Article):ArticleFormType => {
    return {
        title: article.title,
        content: article.rawContent,
        newStatus:undefined,
        tags: article.properties.tags.map(t => ({id:t, value:t, label:t})),
        publicationDate: parseISO(article.publicationDate),
        contactPerson: article.properties.contactPerson ? ({id:article.properties.contactPerson._id, value:article.properties.contactPerson.email, label:article.properties.contactPerson.email }) : undefined,
        mainPicture: {
            noImage: article.properties.mainPicture === null,
            currentImageUrl:article.properties.mainPicture ? article.properties.mainPicture.downloadUrl : null,
            newImageFile:null
        }
    }
}

const createFormValues = (newStatus:ISelectItem):ArticleFormType => {
    return {
        title:'',
        content:'',
        newStatus: newStatus,
        tags:[],
        publicationDate: new Date(),
        contactPerson:undefined,
        mainPicture:{ noImage:true, newImageFile:null, currentImageUrl:null}
    }
}

interface HistoryFormProps {
    auditTrail: AuditTrail;
}
const HistoryForm:React.FC<HistoryFormProps> = ({auditTrail}) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="form-input">
            <label className="form-label">{t("AuditTrail.Creation", { date: printDateFromIso(auditTrail.createdAt, i18n.language), userId: auditTrail.createdBy})}</label>
            <label className="form-label">{t("AuditTrail.Update", { date: printDateFromIso(auditTrail.updatedAt, i18n.language), userId: auditTrail.updatedBy})}</label>
        </div>
    )
}

export const ArticleEditForm:React.FC<ArticleEditFormProps> = ({article, tags, contacts}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');

    const editorApiRef = useRef<EditorApiRef>(null); // Ref to EditorJs exposed API
    const [currentStatus, setCurrentStatus] = useState<ArticleStatus>(article ? article.status : ArticleStatus.Draft);
    const [ createArticle ] = useCreateArticle()
    const [ updateArticle ] = useUpdateArticle()

    const { managerRoles, locale } = useContextManagerRole();

    const formRef = useRef<FormikProps<ArticleFormType>>(null);

    /**
     * Form is disabled :
     * - if for contributor the article is in submit for approval, published OR deleted
     * - if for editor the article is deleted
     */
    const isFormDisabled = useMemo<boolean>(() => {
        if(managerRoles.includes(ManagerRole.Admin)) return false;
        if(currentStatus === ArticleStatus.Deleted) return true;
        return !!(managerRoles.includes(ManagerRole.Contributor) && currentStatus !== ArticleStatus.Draft);
    }, [managerRoles, currentStatus]);

    const formValidationSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(100, t("FormValidation.TooLong", { length:100 }))
            .required(t("FormValidation.Required")),
        content:Yup.string().test('content-check', t("FormValidation.Required"), (arg:string|undefined) => {
            return richContentLenght(arg) > 0;
        }),
        publicationDate: Yup.date().typeError(t("FormValidation.InvalidDate")).label("Publication date").required(t("FormValidation.Required")),
        newStatus: Yup.object().label("Next status").required(t("FormValidation.Required"))
    });

    // Define the list of possible new status for the current article, based on its current status
    const possibleNextStatusItems = useMemo<ISelectItem[]>(() => {
        return allowedNextStatus(managerRoles, currentStatus).map(s =>
            ({id:s, value:s, label:i18nEnums.t(`ArticleStatus.${s}`)})
        )
    }, [i18nEnums, managerRoles, currentStatus])

    // Localized current status
    const currentStatusValue = useMemo<string>(() => {
        return i18nEnums.t(`ArticleStatus.${currentStatus}`)
    }, [currentStatus, i18nEnums]);

    const tagsItems = useMemo<ISelectItem[]>(() => tags.map(t => ({id:t, value:t, label: t})), [tags]);
    const contactItems = useMemo<ISelectItem[]>(() => contacts.map(c => ({id: c._id, value: c.email, label:c.email})), [contacts]);

    // Define the forms values depending on current article OR new article
    const articleToEdit = useMemo<ArticleFormType>(() => {
        return !!article ? updatingFormValues(article) : createFormValues({id:ArticleStatus.Draft, value:ArticleStatus.Draft, label:i18nEnums.t(`ArticleStatus.${ArticleStatus.Draft}`)})
    }, [article, i18nEnums]);

    const progressCallback = (progressEvent: AxiosProgressEvent) => { console.log(progressEvent); }

    /**
     * Manage image upload for inline image in EditorJs
     * @param file
     */
    const imageUploader = (file:File):Promise<IEditorImageUploadResult> => {
        // EditorJs image plugin does not support max file size, so we inspect it just before upload
        if(file.size > IMAGE_MAX_SIZE) {
            return Promise.reject(t("FormValidation.FileTooLarge"));
        }

        return uploadContentImage(file, progressCallback).then(r => {
            if(!!r) {
                return {
                    success: true,
                    file: {
                        url:r.url,
                        blobId:r.blobId
                    }
                }
            }
            return { file:undefined, success:false }
        })
    }

    /**
     * Article submit (creation or update)
     * @param values
     */
    const onSubmit = async (values:ArticleFormType, {resetForm}:FormikHelpers<ArticleFormType>):Promise<void> => {
        // IS update ops ?
        if(!!article) {
            const articleToUpdate:MutationUpdateArticleArgs = {
                _id:article._id,
                article:{
                    status:values.newStatus?.value as ArticleStatus,
                    title:values.title,
                    rawContent:values.content,
                    publicationDate:values.publicationDate,
                    properties:{
                        contactPersonId:values.contactPerson ? values.contactPerson.id : undefined,
                        tags:values.tags.map(t => t.value),
                        mainPicture: await buildBlobValue(values.mainPicture, article.properties.mainPicture, progressCallback)
                    }
                }
            }
            // IMPORTANT: after article update, we update the current article status
            const updatedArticle = await updateArticle(articleToUpdate);
            if(updatedArticle.article) {
                setCurrentStatus(updatedArticle.article.status);
                const nextState = { values: updatingFormValues(updatedArticle.article) };
                resetForm(nextState);
            }
            // OR create ops ?
        } else {
            const articleToCreate:MutationCreateArticleArgs = {
                article:{
                    title:values.title,
                    rawContent:values.content,
                    publicationDate:values.publicationDate,
                    properties:{
                        contactPersonId:values.contactPerson ? values.contactPerson.id : undefined,
                        tags:values.tags.map(t => t.value),
                        mainPicture: await buildBlobValue(values.mainPicture, null, progressCallback)
                    }
                }
            }
            // IMPORTANT: after article creation, we should trigger the component reload in EDIT MODE
            // With "replace:true", we also remove from browser history the intermediate page for new article
            const createdArticle = await createArticle(articleToCreate);
            if(createdArticle.article) {
                navigate(`/management/news/edit/${createdArticle.article._id}`, { replace:true });
            }
        }
    }

    // Cancel button: we reset the form and the editorJs content
    const onCancel = () => {
        if(!!editorApiRef.current) {
            const rollbackData:OutputData|undefined = article ? JSON.parse(article.rawContent) : undefined;
            editorApiRef.current.render(rollbackData);
        }
        if(!!formRef.current) {
            formRef.current.resetForm();
        }
    }

    return (
        <>
            <div className="mt-4">
                <Button style={{fontSize:"1em"}} icon="chevron-left" label={t("ArticleManagement.BackToNewsList")} kind={ButtonType.tertiary} onClick={() => navigate(-1)} />
                { isFormDisabled ? <Message size={MessageSize.medium} icon="information-mark" label={t("ArticleManagement.ErrorEditNoPermission")} />: null}
            </div>
            <Formik<ArticleFormType> innerRef={formRef} initialValues={articleToEdit} validationSchema={formValidationSchema} onSubmit={onSubmit} enableReinitialize={true} >
                {({ isSubmitting, status, dirty }) =>
                    <Form id="articleForm">
                        <Grid>
                            <GridRow>
                                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 8 }}>
                                    <FormGroup>
                                        <FieldTextInput
                                            id="title"
                                            name="title"
                                            placeholder={t("ArticleManagement.ArticleTitlePlaceholder")}
                                            label={t("ArticleManagement.ArticleTitle")}
                                            form="articleForm"
                                            disabled={isSubmitting || isFormDisabled}
                                            hint={t("ArticleManagement.ArticleTitleHint")}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldImageUpload
                                            id="mainPicture"
                                            name="mainPicture"
                                            label={t("ArticleManagement.Picture")}
                                            hint={t("ArticleManagement.PictureHint")}
                                            resolutionType="ratio"
                                            maxFileSize={IMAGE_MAX_SIZE}
                                            fileFormat={['jpg', 'jpeg', 'png']}
                                            disabled={isFormDisabled}
                                            resolutionErrorLabel={t("FormValidation.ImageResolutionDoesntMatch")}
                                            acceptTypeErrorLabel={t("FormValidation.UnsupportedFileFormat")}
                                            maxFileSizeErrorLabel={t("FormValidation.FileTooLarge")}
                                            ratio={ImagePreviewRatio.panoramic}
                                            allowCropping={true}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormEditor
                                            id="content"
                                            name="content"
                                            placeholder={t("ArticleManagement.ContentPlaceholder")}
                                            label={t("ArticleManagement.Content")}
                                            imageUploader={imageUploader}
                                            imageUploaderCaption={t("ArticleManagement.PictureUploaderCaption")}
                                            editorApiRef={editorApiRef}
                                            disabled={isFormDisabled}
                                        />
                                    </FormGroup>
                                </GridColumn>
                                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 4 }}>
                                    <FormAction
                                        noBorder
                                        submittingLabel={t("Actions.Saving")}
                                        submitLabel={t("Actions.Save")}
                                        resetLabel={article ? t("Actions.Cancel") : undefined}
                                        onCustomCancel={onCancel}
                                    />
                                    <FormGroup>
                                        <ToggleInput
                                            id="currentStatus"
                                            name="currentStatus"
                                            form="articleForm"
                                            label={t("ArticleManagement.CurrentStatus")}
                                            value={currentStatusValue}
                                            disabled={isFormDisabled}
                                        />
                                        <FieldFormSimpleSelect
                                            id="newStatus"
                                            form="articleForm"
                                            name="newStatus"
                                            label={t("ArticleManagement.NewStatus")}
                                            placeholder={t("ArticleManagement.NewStatusPlaceholder")}
                                            noOptionsMessage={t("ArticleManagement.NoNewStatus")}
                                            items={possibleNextStatusItems}
                                            disabled={isSubmitting || !article || isFormDisabled}
                                        />
                                        <FieldDatePicker
                                            id="publicationDate"
                                            form="articleForm"
                                            name="publicationDate"
                                            label={t("ArticleManagement.PubDate")}
                                            locale={locale}
                                            disabled={isSubmitting || !article || isFormDisabled}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormCreatableMultiSelect
                                            id="tags"
                                            name="tags"
                                            form="articleForm"
                                            items={tagsItems}
                                            label={t("ArticleManagement.Tags")}
                                            placeholder={t("ArticleManagement.TagsPlaceholder")}
                                            noOptionsMessage=""
                                            disabled={isSubmitting || isFormDisabled}
                                        />
                                        <FieldFormSimpleSelect
                                            id="contactPerson"
                                            name="contactPerson"
                                            items={contactItems}
                                            label={t("ArticleManagement.RelatedContactPerson")}
                                            placeholder={t("ArticleManagement.RelatedContactPersonPlaceholder")}
                                            isClearable
                                            noOptionsMessage=""
                                            disabled={isSubmitting || isFormDisabled}
                                        />
                                    </FormGroup>
                                    {article ?
                                        <FormGroup>
                                            <HistoryForm auditTrail={article.auditTrail} />
                                        </FormGroup>
                                        : null
                                    }
                                </GridColumn>
                            </GridRow>
                        </Grid>
                    </Form>
                }
            </Formik>
        </>
    )
}
