import React, { useState } from "react";
import { subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { printDateFromIso } from '../../../i18n';
import {
    Notification,
    useCreateNotification,
    useNotifications,
    useRemoveNotification,
    useUpdateNotification,
} from '../../../model/notification';

import {
    Button, ButtonType, Container,
    DataGridCell, DataGridContent,
    DataGridHeader, DataGridRow,
    Loader, LoaderSize
} from '../../amethyst';

import { ConfirmModal } from "../../common/ConfirmModal";
import { NotificationCreateEdit } from "./NotificationCreateEdit";

const lastYear = subMonths(new Date(),12);

export const NotificationCrud:React.FC<any> = () => {
    const { t, i18n } = useTranslation();
    const { data:notifications, loading} = useNotifications({notBefore: lastYear});
    const [ showEditForm, setShowEditForm ] = useState<boolean>(false);
    const [ showDeleteForm, setShowDeleteForm ] = useState<boolean>(false);
    const [ notificationToEdit, setNotificationToEdit ] = useState<Notification|undefined>(undefined);

    const [ createNotification ] = useCreateNotification({notBefore: lastYear});
    const [ updateNotification ] = useUpdateNotification();
    const [ removeNotification ] = useRemoveNotification({notBefore: lastYear});

    const onOpenEditForm = (notification:Notification|undefined = undefined) => {
        setNotificationToEdit(notification);
        setShowEditForm(true);
    }

    const onCloseEditForm = () => {
        setShowEditForm(false);
        setNotificationToEdit(undefined);
    }

    const onOpenDeleteConfirmModal = (notification:Notification) => {
        setNotificationToEdit(notification);
        setShowDeleteForm(true);
    }

    const onDelete = () => {
        if(notificationToEdit) {
            return removeNotification({_id: notificationToEdit._id}, )
        }
        return Promise.resolve();
    }

    return (
        <>
            <NotificationCreateEdit
                close={onCloseEditForm}
                show={showEditForm}
                onCreate={createNotification}
                onUpdate={updateNotification}
                notificationToEdit={notificationToEdit}
            />
            <ConfirmModal
               show={showDeleteForm}
               close={() => setShowDeleteForm(false)}
               title={t("NotificationManagement.ConfirmDeletion")}
               message={notificationToEdit ? `"${notificationToEdit.description}"` : ''}
               onConfirmAction={onDelete}
            />
            <Container fluid>
                <DataGridHeader title={t("NotificationManagement.Title")}>
                    <Button
                        kind={ButtonType.primary}
                        label={t("NotificationManagement.AddNotification")}
                        onClick={() => onOpenEditForm()}
                        disabled={loading || notifications.length > 0}
                    />
                </DataGridHeader>
                { loading ? <Loader label={t("NotificationManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("NotificationManagement.Description"),
                            t("NotificationManagement.ExpirationDate")
                        ]}>
                            {
                                notifications.map((notification, index) =>
                                    <DataGridRow left key={index} >
                                        <DataGridCell>{notification.description}</DataGridCell>
                                        <DataGridCell>{printDateFromIso(notification.expirationDate, i18n.language)}</DataGridCell>
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} title={t("NotificationManagement.Delete")} icon="trash" aria-label={t("NotificationManagement.Delete")} onClick={() => onOpenDeleteConfirmModal(notification)} />
                                            <Button kind={ButtonType.tertiary} title={t("NotificationManagement.Edit")} icon="chevron-small-right" aria-label={t("NotificationManagement.Edit")} onClick={() => onOpenEditForm(notification)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                    </>
                }
            </Container>
        </>
    )
}