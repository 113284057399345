import React, { PropsWithChildren } from "react";
import { Grid, GridColumn, GridRow } from "../amethyst";

export const CrudLayout:React.FC<PropsWithChildren> = ({children}) => {
    return (
        <Grid fluid className="paper">
            <GridRow>
                <GridColumn>
                    {children}
                </GridColumn>
            </GridRow>
        </Grid>
    )
}