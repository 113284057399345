import React, { useEffect, useState, useMemo, useCallback } from 'react';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { isValid, parseISO } from 'date-fns';
import { printDate } from '../../../i18n';
import {Formik} from "formik";
import {
    B2CmfaMethod, useGetCustomerUserAccountById, useUpdateCustomerUserMfaMethod, useUpdateCustomerUserRelationships,
    useUpdateCustomerUserLock, useDeleteCustomerUser, CustomerUsersFilter, CustomerUser,
} from '../../../model/user';
import {
    Button, ButtonType,
    FieldCheckbox, FieldFormMultipleSelect,
    ISelectItem, FieldFormSimpleSelect, FieldToggleTextInput,
    Form, FormButtonGroup, FormGroup,
    Loader, LoaderSize,
    Modal, ModalContent
} from "../../amethyst";

export interface EditCustomerUserFormProps {
    user:CustomerUser,
    customers: ISelectItem[]
    show:boolean,
    close:() => void
    filter:CustomerUsersFilter
}

interface CUNameFormType { givenName:string, surname:string, lastSignInDateTime:string }
const cuNameDefaultValues:CUNameFormType = { givenName:"", surname:"", lastSignInDateTime:""}

interface CUMFAFormType { mfaMethod:ISelectItem|undefined }
const cuMFADefaultValues:CUMFAFormType = { mfaMethod:undefined }

interface CULockFormType { accountEnabled:boolean }
const cuLockDefaultValues:CULockFormType = { accountEnabled:false }

interface CUCustomersType { selectedCustomers:ISelectItem[] }

export const EditCustomerUserForm:React.FC<EditCustomerUserFormProps> = ({user, customers, show, close, filter}) => {
    const { i18n, t } = useTranslation();
    const { t:tEnum } = useTranslation('enums');
    const [ fetching, setFetching] = useState<boolean>(true);
    const [ loaderMessage, setLoaderMessage ] = useState<string>(t("CustomerUserManagement.RetrievingUserDetails"));
    const [ cuNames, setCuNames ] = useState<CUNameFormType>(cuNameDefaultValues);
    const [ cuMFA, setCuMFA ] = useState<CUMFAFormType>(cuMFADefaultValues);
    const [ cuLock, setCuLock ] = useState<CULockFormType>(cuLockDefaultValues);
    const [ cuSelectedCustomers, setCuSelectedCustomers ] = useState<CUCustomersType>(() =>
        ({selectedCustomers: user.properties.relationships.map(r => ({id:r.customer._id, value:r.customer.name, label: r.customer.name}) ) })
    )

    const { account, loading } = useGetCustomerUserAccountById(user._id);
    const [ updateCustomerUserMfaMethod ] = useUpdateCustomerUserMfaMethod();
    const [ updateCustomerUserLock ] = useUpdateCustomerUserLock();
    const [ deleteCustomerUser ] = useDeleteCustomerUser(filter);
    const [ updateCustomerUserRelationships ] = useUpdateCustomerUserRelationships();

    const selectedCustomersValidationSchema = useMemo(() => {
        return Yup.object().shape({
            selectedCustomers:Yup.array().min(1, t("FormValidation.AtLeastOne"))
        });
    }, [t]);

    const buildMFAMethodItems = useCallback(():ISelectItem[] => Object.entries(B2CmfaMethod).map(([k,v ]) => ({
        id:v,
        label: tEnum(`B2CmfaMethod.${v}`),
        value:v
    })), [tEnum]);

    const printLastSignInDateTime = useCallback((date: Date) => isValid(date) ? printDate(date, i18n.language, "yyyy/MM/dd HH:mm zz") : "N/A", [i18n]);

    const MFAMethodItems = useMemo<ISelectItem[]>(buildMFAMethodItems, [buildMFAMethodItems])

    useEffect(() => {
        if(account) {
            setCuNames({
                givenName:account.givenName,
                surname:account.surname,
                lastSignInDateTime:printLastSignInDateTime(parseISO(account.lastSignInDateTime)),
            });
            setCuMFA({ mfaMethod: buildMFAMethodItems().find(i => i.id === account.mfaMethod) });
            setCuLock({ accountEnabled: account.accountEnabled });
            setFetching(false);
        }
    }, [account, loading, buildMFAMethodItems, printLastSignInDateTime])

    const changeCustomer = (newCustomers:ISelectItem[]) => {
        if(newCustomers.length > 0) {
            setFetching(true);
            updateCustomerUserRelationships({_id:user._id, relationships:newCustomers.map(c => c.id)}).then(result => {
                if(result) {
                    setCuSelectedCustomers({ selectedCustomers: newCustomers })
                }
                setFetching(false);
            })
        }
    }

    const changeMfa = (newMfaMethod:ISelectItem|undefined) => {
        if(newMfaMethod) {
            setFetching(true);
            updateCustomerUserMfaMethod({ _id:user._id, method: newMfaMethod.id as B2CmfaMethod}).then(result => {
                if(result) {
                    setCuMFA({ mfaMethod: MFAMethodItems.find(i => i.id === newMfaMethod.id)})
                }
                setFetching(false);
            })
        }
    }

    const changeAccountEnabled = (accountEnabled: boolean) => {
        setFetching(true);
        updateCustomerUserLock({_id:user._id, locked:!accountEnabled }).then((result) => {
            if(result) {
                setCuLock({ accountEnabled });
            }
            setFetching(false);
        })
    }

    const onDeleteCustomerUser = () => {
        if(window.confirm(t("CustomerUserManagement.ClickToConfirmDeletion", {userId: user._id}))) {
            setLoaderMessage(t("CustomerUserManagement.DeletingUser"));
            setFetching(true);
            deleteCustomerUser({_id:user._id}).then((result) => {
                if(result) {
                    close();
                }
                setFetching(false);
            });
        }
    }

    if(!show) return null;

    return (
        <Modal open={show} onClose={close} title={user._id} closeOnEscape >
            <ModalContent>
                { fetching ?
                    <Loader label={loaderMessage} size={LoaderSize.medium} /> :
                    <>
                        <Formik initialValues={cuNames} onSubmit={() => {}} >
                            <Form id="CUNameForm" inline>
                                <FormGroup>
                                    <FieldToggleTextInput
                                        label={t("CustomerUserManagement.FirstName")}
                                        form="EditCustomerUserForm"
                                        id="givenName"
                                        name="givenName"
                                    />
                                    <FieldToggleTextInput
                                        label={t("CustomerUserManagement.LastName")}
                                        form="EditCustomerUserForm"
                                        id="surname"
                                        name="surname"
                                        disabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldToggleTextInput
                                        label={t("CustomerUserManagement.LastSignIn")}
                                        form="EditCustomerUserForm"
                                        id="lastSignInDateTime"
                                        name="lastSignInDateTime"
                                        disabled
                                    />
                                </FormGroup>
                            </Form>
                        </Formik>
                        <Formik validationSchema={selectedCustomersValidationSchema} initialValues={cuSelectedCustomers} onSubmit={() => {}}>
                            { ({dirty, errors, resetForm, values}) =>
                                <Form id="CUCustomersForm" inline>
                                    <FormGroup>
                                        <FieldFormMultipleSelect
                                            id="selectedCustomers"
                                            items={customers}
                                            placeholder={t("CustomerUserManagement.RelatedCustomersPlaceholder")}
                                            name="selectedCustomers"
                                            noOptionsMessage=""
                                            label={t("CustomerUserManagement.RelatedCustomers")}
                                        />
                                        <FormButtonGroup id="related-customers-action" name="related-customers-action">
                                            <Button disabled={!dirty || !!errors.selectedCustomers} kind={ButtonType.primary} label={t("Actions.Save")} onClick={() => changeCustomer(values.selectedCustomers)} />
                                            <Button disabled={!dirty} kind={ButtonType.secondary} onClick={() => resetForm()} label={t("Actions.Cancel")} />
                                        </FormButtonGroup>
                                    </FormGroup>
                                </Form>
                            }
                        </Formik>
                        <Formik<CUMFAFormType> initialValues={cuMFA} onSubmit={() => {}}>
                            { ({dirty, resetForm, values}) =>
                                <Form id="CUMFAForm" inline >
                                    <FormGroup>
                                        <FieldFormSimpleSelect
                                            label={t("CustomerUserManagement.MFA")}
                                            form="CUMFAForm"
                                            id="mfaMethod"
                                            name="mfaMethod"
                                            items={MFAMethodItems}
                                            placeholder={t("CustomerUserManagement.MFAPlaceholder")}
                                            noOptionsMessage=""
                                        />
                                        <FormButtonGroup id="mfaMethod-action" name="mfaMethod-action">
                                            <Button disabled={!dirty} kind={ButtonType.primary} label={t("Actions.Save")} onClick={() => changeMfa(values.mfaMethod)} />
                                            <Button disabled={!dirty} kind={ButtonType.secondary} onClick={() => resetForm()} label={t("Actions.Cancel")} />
                                        </FormButtonGroup>
                                    </FormGroup>
                                </Form>
                            }
                        </Formik>
                        <Formik<CULockFormType> initialValues={cuLock} onSubmit={() => {}}>
                            { ({dirty, resetForm, values}) =>
                                <Form id="CULockForm" inline >
                                    <FormGroup>
                                        <label>&nbsp;</label>
                                        <FieldCheckbox
                                            title={t("CustomerUserManagement.AccountEnabled")}
                                            label={values.accountEnabled ? t("Actions.Yes") : t("Actions.No")}
                                            form="CULockForm"
                                            id="accountEnabled"
                                            name="accountEnabled"
                                        />
                                        <FormButtonGroup id="accountlocking-action" name="accountlocking-action">
                                            <Button disabled={!dirty} kind={ButtonType.primary} label={t("Actions.Save")} onClick={() => changeAccountEnabled(values.accountEnabled)} />
                                            <Button disabled={!dirty} kind={ButtonType.secondary} onClick={() => resetForm()} label={t("Actions.Cancel")} />
                                        </FormButtonGroup>
                                    </FormGroup>
                                </Form>
                            }
                        </Formik>
                        <Form id="CUDeleteForm">
                            <FormButtonGroup id="accountlocking-action" name="accountlocking-action">
                                <Button kind={ButtonType.secondary} label={t("CustomerUserManagement.DeleteUser")} onClick={() => onDeleteCustomerUser()} />
                            </FormButtonGroup>
                        </Form>
                    </>
                }
            </ModalContent>
        </Modal>
    )
}
