import React, { PropsWithChildren } from 'react';
import { useApps } from '../../model/app';
import { Grid, GridColumn, GridRow, Loader, LoaderSize } from '../amethyst';
import { useTranslation } from 'react-i18next';

const LoadingApps = () => {
    const { t } = useTranslation();

    return (
        <Grid app fluid >
            <GridRow center>
                <GridColumn>
                    <Loader size={LoaderSize.xlarge} label={t("PageLayout.HomeLoading")} />
                </GridColumn>
            </GridRow>
        </Grid>
    )
}

export const PageLoader:React.FC<PropsWithChildren> = ({children}) => {
    const apps = useApps();
    return <>{  apps.loading ? <LoadingApps /> : children }</>
}
