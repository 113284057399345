import React, {PropsWithChildren} from "react";

export interface DataGridHeaderProps extends PropsWithChildren{
    title:string
}

export const DataGridHeader:React.FC<DataGridHeaderProps> = ({title, children}) => {
    return (
        <div className="datagrid-header" style={{paddingTop:"1em"}}>
            <h4 className="datagrid-header-title">{title}</h4>
            {children}
        </div>
    )
}

