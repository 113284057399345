import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Customer, useCreateCustomer, useCustomers, useUpdateCustomer } from '../../../model/customer';
import { CustomerCreateEdit } from './CustomerCreateEdit';
import {
    Button, ButtonType,
    Container,
    DataGridCell, DataGridContent, DataGridFooter, DataGridHeader, DataGridRow,
    Dot,
    PlainColor,
    Loader, LoaderSize, DataGridPagination
} from '../../amethyst';
import { useTranslation } from 'react-i18next';
import { useCustomersQuery } from './useCustomerQuery';
import { useContextManagerRole } from '../../security/ProtectedRoute';
import { ManagerRole } from '../../../service/api/generated-types';


export const CustomerCrud: React.FC<any> = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { managerRoles } = useContextManagerRole();
    //const i18nEnums = useTranslation('enums');
    const [setCustomerQuery, pagination ] = useCustomersQuery()
    const { pageOfCustomers, loading } = useCustomers({page:pagination.page, pageSize:pagination.pageSize});
    const [ createCustomer ] = useCreateCustomer({page:pagination.page, pageSize:pagination.pageSize});
    const [ updateCustomer ] = useUpdateCustomer();
    const [showEditForm, setShowEditForm ] = useState<boolean>(false);
    const [customerToEdit, setCustomerToEdit] = useState<Customer|undefined>(undefined);

    const onOpenEditForm = (customer: Customer|undefined = undefined) => {
        setCustomerToEdit(!!customer ? {...customer} : undefined);
        setShowEditForm(true);
    }

    const onOpenSubscriptionsForm = (customer: Customer|undefined) => {
        if(customer) {
            navigate("/management/subscriptions", { state:{customerId:customer._id}})
        }
    }

    const onNavigateToUsers = (customer: Customer|undefined = undefined) => {
        if(customer) {
            navigate(`/management/users?c=${customer._id}`);
        }
    }

    const onChangePage = (page: number) => {
        setCustomerQuery({page:page, pageSize:pagination.pageSize});
    }

    return (
        <>
            <CustomerCreateEdit
                customerToEdit={customerToEdit}
                close={() => setShowEditForm(false)}
                show={showEditForm}
                onCreate={(input) => createCustomer(input)}
                onUpdate={(input) => updateCustomer(input)}
            />
            <Container fluid>
                <DataGridHeader title={t("CustomerManagement.Title")}>
                    <Button kind={ButtonType.primary} label={t("CustomerManagement.AddCustomer")} onClick={() => onOpenEditForm()} disabled={!managerRoles.includes(ManagerRole.Admin)} />
                </DataGridHeader>
                { loading ? <Loader label={t("CustomerManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("CustomerManagement.Name"),
                            t("CustomerManagement.Status"),
                            // t("CustomerManagement.GTEntity"),
                            // t("CustomerManagement.GTService")
                        ]}>
                            {
                                pageOfCustomers.items.map((customer, index) =>
                                    <DataGridRow left key={index} >
                                        <DataGridCell>{customer.name}</DataGridCell>
                                        <DataGridCell><Dot color={customer.disabled ? PlainColor.red : PlainColor.green} />{customer.disabled ? t("Status.Inactive") : t("Status.Active")}</DataGridCell>
                                        {/* <DataGridCell>{customer.properties.servingEntities.map(e => i18nEnums.t(`GtServingEntity.${e}`)).join(', ')}</DataGridCell>
                                <DataGridCell>{customer.properties.services.map(s => i18nEnums.t(`GtService.${s}`)).join(', ')}</DataGridCell> */}
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} title={t("CustomerManagement.ViewCustomerUsers")} icon="people-team" aria-label={t("CustomerManagement.ViewCustomerUsers")} disabled={!managerRoles.includes(ManagerRole.Admin)} onClick={() => onNavigateToUsers(customer)}/>
                                            <Button kind={ButtonType.tertiary} title={t("CustomerManagement.ViewApplicationSubscriptions")} icon="check-list" aria-label={t("CustomerManagement.ViewApplicationSubscriptions")} onClick={() => onOpenSubscriptionsForm(customer)}/>
                                            <Button kind={ButtonType.tertiary} title={t("CustomerManagement.EditCustomer")} icon="chevron-small-right" aria-label={t("Actions.EditCustomer")} onClick={() => onOpenEditForm(customer)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                        <DataGridFooter>
                            <DataGridPagination disabled={loading || pageOfCustomers.totalPages < 2} currentPage={pagination.page} totalPages={pageOfCustomers.totalPages} onPageClick={onChangePage} />
                        </DataGridFooter>
                    </>
                }
            </Container>
        </>
    )
}