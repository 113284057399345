import { ManagerRole } from './service/api/generated-types';

type RolesByService = {
    [role:string]:ManagerRole[]
}

export const rolesByService: RolesByService = {
    "apps":[ManagerRole.Admin],
    "customers":[ManagerRole.Admin, ManagerRole.CustomerManager],
    "subscriptions":[ManagerRole.Admin, ManagerRole.CustomerManager],
    "users":[ManagerRole.Admin],
    "managers":[ManagerRole.Admin],
    "news":[ManagerRole.Admin, ManagerRole.Editor, ManagerRole.Contributor],
    "agenda":[ManagerRole.Admin, ManagerRole.Editor, ManagerRole.Contributor],
    "contacts":[ManagerRole.Admin, ManagerRole.Editor],
    "notifications":[ManagerRole.Admin]
}