import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
    App,
    MutationCreateAppArgs,
    MutationUpdateAppArgs,
    MutationUpdateAppSubscriptionArgs,
    MutationUpdateAppSubscriptionsArgs,
    useGetAppsQuery,
    useCreateAppMutation,
    useUpdateAppMutation,
    useUpdateAppSubscriptionMutation,
    useUpdateAppSubscriptionsMutation,
    useQueryGetAppsUsersSubscriptionByCustomerIdLazyQuery, AppUserSubscriptions,
} from '../../service/api/generated-types';
import { QueryGetApps } from '../../service/api/api-queries/app';

type  FetchApps = { loading:boolean, apps:App[], error:ApolloError|undefined }

export const useApps = ():FetchApps => {
    const { loading, data, error } = useGetAppsQuery();
    if(loading) {
        return { loading, apps:[], error}
    } else {
        return { loading, apps: (data ? data.apps as App[] : []), error}
    }
}

type FetchAppSubscriptionsRes = {
    appUserSubscriptions:AppUserSubscriptions[]
    error:ApolloError|undefined
}

type FetchAppSubscriptions = [fn:(customerId: string) => Promise<FetchAppSubscriptionsRes>, status: { loading: boolean}]

export const useGetAppUsersSubscriptions  = ():FetchAppSubscriptions => {
    const [getAppUsersSubscriptions, { loading }] = useQueryGetAppsUsersSubscriptionByCustomerIdLazyQuery({ fetchPolicy: "network-only"});
    const fn = (customerId: string) => {
        return getAppUsersSubscriptions({ variables: { customerId }}).then(({ data, error}) => {
            if(data) return { appUserSubscriptions:data.appsUsersSubscriptions, error }
            return { appUserSubscriptions:[], error } ;
        })
    }
    return [fn, { loading }];
}

type CreateAppRes = {
    app:App|null,
    errors:readonly GraphQLError[]|undefined
}
type CreateApp = [fn:(args: MutationCreateAppArgs) => Promise<CreateAppRes>, status: { loading: boolean}]
export const useCreateApp = ():CreateApp => {
    const [createApp, { loading }] = useCreateAppMutation({refetchQueries:[{query:QueryGetApps}]});
    const fn = (args: MutationCreateAppArgs) => {
        return createApp({variables:args}).then(({data, errors}) => {
            if(data) return { app:data.createApp as App, errors}
            return { app:null, errors }
        });
    }
    return [fn, { loading}];
}

type UpdateAppRes = {
    app:App|null,
    errors:readonly GraphQLError[]|undefined
}
type UpdateApp = [fn:(args: MutationUpdateAppArgs) => Promise<UpdateAppRes>, status: { loading: boolean}]
export const useUpdateApp = ():UpdateApp => {
    const [updateApp, { loading }] = useUpdateAppMutation();
    const fn = (args: MutationUpdateAppArgs) => {
        return updateApp({variables:args}).then(({data, errors}) => {
            if(data) return { app:data.updateApp as App, errors}
            return { app:null, errors }
        });
    }
    return [fn, { loading}];
}

type UpdateAppSubscription = [fn:(args: MutationUpdateAppSubscriptionArgs) => Promise<UpdateAppRes>, status: { loading: boolean}]

export const useUpdateAppSubscription = ():UpdateAppSubscription => {
    const [updateAppSubscription, { loading }] = useUpdateAppSubscriptionMutation();

    const fn = (args: MutationUpdateAppSubscriptionArgs):Promise<UpdateAppRes> => {
        return updateAppSubscription({ variables: args}).then(({data, errors}) => {
            if(data) return { app:data.updateAppSubscription as App, errors}
            return { app:null, errors }
        });
    }
    return [fn, { loading }];
}

type UpdateAppsRes = {
    apps:App[],
    errors: readonly GraphQLError[]| undefined
}

type UpdateAppSubscriptions = [fn:(args: MutationUpdateAppSubscriptionsArgs) => Promise<UpdateAppsRes>, status: { loading: boolean}]

export const useUpdateAppSubscriptions = ():UpdateAppSubscriptions => {
    const [updateAppSubscriptions, { loading }] = useUpdateAppSubscriptionsMutation();

    const fn = (args: MutationUpdateAppSubscriptionsArgs):Promise<UpdateAppsRes> => {
        return updateAppSubscriptions({ variables: args}).then(({data, errors}) => {
            if(data) return { apps:data.updateAppSubscriptions as App[], errors}
            return { apps:[], errors }
        });
    }
    return [fn, { loading }];
}