import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    ButtonType,
    Container,
    DataGridCell,
    DataGridContent,
    DataGridFooter,
    DataGridHeader,
    DataGridPagination,
    DataGridRow,
    ISelectItem,
    Loader,
    LoaderSize,
    MultipleSelect,
} from '../../amethyst';
import { useArticlesQuery } from './useArticlesQuery';
import { ArticleStatus, useArticles } from '../../../model/article';
import { printDateFromIso } from '../../../i18n';

//TODO: should we enhance this component to allow date selection ?
const from = parse('01.01.1970', 'd.MM.yyyy', new Date());
const to = parse('01.01.2100', 'd.MM.yyyy', new Date());

export const ArticleCrud:React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');
    const { language } = i18nEnums.i18n;

    // use query params to store pagination and status filtering
    const [setArticleQuery, {status}, pagination] = useArticlesQuery()

    // News query and results
    const { pageOfArticles, loading } = useArticles({page:pagination.page, pageSize: pagination.pageSize, status, from, to});

    const statusItems = useMemo<ISelectItem[]>(() => {
        return Object.keys(ArticleStatus).map(s => ({id:s, value:s, label:i18nEnums.t(`ArticleStatus.${s}`)}));
    }, [i18nEnums]);

    const onUpdateStatusFilter = (items: readonly ISelectItem[]) => {
        setArticleQuery({status:items.map(s => s.id as ArticleStatus)})
    }

    const onChangePage = (page: number) => {
        setArticleQuery({ status}, {page:page, pageSize:pagination.pageSize});
    }

    return (
        <>
            <Container fluid>
                <DataGridHeader title={t("ArticleManagement.Title")}>
                    <div style={{display:"flex", gap:"10px"}}>
                        <MultipleSelect
                            id="status"
                            selectedItemIds={status}
                            items={statusItems}
                            onChange={onUpdateStatusFilter}
                            placeholder={t("ArticleManagement.FilterStatusPlaceholder")}
                            name="status"
                            noOptionsMessage=""
                            disabled={loading}
                        />
                        <Button kind={ButtonType.primary} label={t("ArticleManagement.AddNews")} onClick={() => navigate("edit")} />
                    </div>
                </DataGridHeader>
                {loading || !pageOfArticles ? <Loader label={t("ArticleManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("ArticleManagement.ArticleTitle"),
                            t("ArticleManagement.CreatedAt"),
                            t("ArticleManagement.Status"),
                            t("ArticleManagement.PubDate")
                        ]}>
                        {pageOfArticles.items.map((article, idx) =>
                            <DataGridRow left key={idx}>
                                <DataGridCell>{article.title}</DataGridCell>
                                <DataGridCell>{printDateFromIso(article.auditTrail.createdAt, language)}</DataGridCell>
                                <DataGridCell>{i18nEnums.t(`ArticleStatus.${article.status}`)}</DataGridCell>
                                <DataGridCell>{printDateFromIso(article.publicationDate, language)}</DataGridCell>
                                <DataGridCell details>
                                    <Button kind={ButtonType.tertiary} title={t("ArticleManagement.ViewArticleDetails")} aria-label={t("ArticleManagement.ViewArticleDetails")} icon="chevron-small-right" onClick={() =>  navigate(`edit/${article._id}`) } />
                                </DataGridCell>
                            </DataGridRow>
                        )}
                    </DataGridContent>
                    <DataGridFooter>
                        <DataGridPagination disabled={loading || pageOfArticles.totalPages < 2} currentPage={pagination.page} totalPages={pageOfArticles.totalPages} onPageClick={onChangePage} />
                    </DataGridFooter>
                    </>
                }
            </Container>
        </>
    )
}
