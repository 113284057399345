import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { NotificationCrud } from "./NotificationCrud";

export const NotificationManagement:React.FC<any> = () => {
    return (
        <CrudLayout>
            <NotificationCrud />
        </CrudLayout>
    )
}