import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { AgendaCrud } from './AgendaCrud';

export const AgendaManagement:React.FC = () => {
    return (
        <CrudLayout>
            <AgendaCrud />
        </CrudLayout>
    )
}