import { ArticleStatus } from './index';
import { ManagerRole } from '../user'

type ArticleLifeCycleType = {
    [currentStatus in ArticleStatus]: {
        [nextStatus:string ]:ManagerRole[]
    };
};

const ArticleLifeCycle:ArticleLifeCycleType = {
    [ ArticleStatus.Draft ]: {
        [ ArticleStatus.Draft ]:[ ManagerRole.Contributor, ManagerRole.Editor, ManagerRole.Admin ],
        [ ArticleStatus.SubmittedForApproval ]:[ ManagerRole.Contributor, ManagerRole.Editor, ManagerRole.Admin ],
        [ ArticleStatus.Deleted ]:[ ManagerRole.Contributor, ManagerRole.Editor, ManagerRole.Admin ]
    },
    [ ArticleStatus.SubmittedForApproval ]: {
        [ ArticleStatus.Draft ]:[ ManagerRole.Editor, ManagerRole.Admin ],
        [ ArticleStatus.Published ]:[ ManagerRole.Editor, ManagerRole.Admin ],
        [ ArticleStatus.Deleted ]:[ ManagerRole.Editor, ManagerRole.Admin ]
    },
    [ ArticleStatus.Published ]: {
        [ ArticleStatus.Published ]:[ ManagerRole.Editor, ManagerRole.Admin ],
        [ ArticleStatus.Deleted ]:[ ManagerRole.Editor, ManagerRole.Admin ]
    },
    [ ArticleStatus.Deleted ]: {
        [ ArticleStatus.Draft ]:[ ManagerRole.Admin ],
        [ ArticleStatus.Published ]:[ ManagerRole.Admin ],
    }
}

/**
 * Return allowed status depending on the current manager roles and lifecycle
 * @param roles
 * @param currentStatus
 */
export const allowedNextStatus = (roles: ManagerRole[], currentStatus: ArticleStatus):ArticleStatus[] => {
    const currentBranch = ArticleLifeCycle[currentStatus];
    if(!!currentBranch) {
        const allowed = Object.keys(currentBranch).reduce<ArticleStatus[]>((allowed, possible) => {
            const nextBranch = currentBranch[possible];
            if(!! nextBranch && nextBranch.filter(allowedRole => roles.includes(allowedRole)).length > 0) {
                allowed.push(possible as ArticleStatus);
            }
            return allowed;
        }, []);
        return allowed;
    } else return [currentStatus];
}