import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { AppCrud } from './AppCrud';

export const AppManagement:React.FC = () => {
    return (
        <CrudLayout>
            <AppCrud />
        </CrudLayout>
    )
}
