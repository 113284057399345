import React from "react";
import { useTranslation } from 'react-i18next';
import { parseISO } from "date-fns"
import { Link } from "react-router-dom"
import { useLatestArticles } from '../../model/article';
import { Container, Divider, Grid, GridRow, GridColumn, Icon } from '../amethyst';
import { printDate } from '../../i18n';
import "./newsFeed.scss"

const NewsList:React.FC<any> = () => {
    const { pageOfArticles } = useLatestArticles();
    const { i18n } = useTranslation();
    return (
        <Grid className="news-list">
            <>
                {pageOfArticles.items.slice(0, 3).map((article, idx) =>
                    <div className="mb-5" key={idx}>
                        <GridRow>
                            <div className="mb-2">
                                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                    <Link to={`/news/${article.slug}`} className="link news-link" >
                                        <span className="news-title">{article.title}</span>
                                    </Link>
                                </GridColumn>
                            </div>
                        </GridRow>
                        <GridRow>
                            {article.properties.mainPicture ?
                                <>
                                    <GridColumn defaultSize={4} columnSize={{ 'col-sm': 4, 'col-md': 12, 'col-lg': 4 }}>
                                        <Link to={`/news/${article.slug}`}>
                                            <img style={{width:"100%"}} alt="main" src={article.properties.mainPicture.downloadUrl} />
                                        </Link>
                                    </GridColumn>
                                    <GridColumn defaultSize={8} columnSize={{ 'col-sm': 8, 'col-md': 12, 'col-lg': 8 }}>
                                        <p className="font-normal news-abstract mb-0" dangerouslySetInnerHTML={{__html: article.teaser}} />
                                    </GridColumn>
                                </>
                                :
                                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                    <p className="font-normal news-abstract mb-0" dangerouslySetInnerHTML={{__html: article.teaser}} />
                                </GridColumn>
                            }
                        </GridRow>
                        <GridRow>
                            <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                <p className="news-publication-date mb-0 mt-2">
                                    {article.properties.tags.length > 0 ?
                                        <>
                                            { article.properties.tags.join(', ') }
                                            &nbsp; • &nbsp;
                                        </>
                                        : null}
                                    {printDate(parseISO(article.publicationDate), i18n.language, "dd MMM. yyyy")}
                                </p>
                            </GridColumn>
                        </GridRow>
                    </div>
                )}
            </>
        </Grid>
    )
}


export const NewsFeed: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <Container fluid className="news-feed mb-6">
            <h3 className="mb-0 mt-3"><Icon icon="newspaper" /> {t("News.Title")}</h3>
            <Divider dark narrow />
            <NewsList />
            <div className="all-news text-sm-center mb-3">
                <Link className="link" to="/news" >{t("News.ViewAllNews")}&nbsp;<Icon icon="chevron-right" /></Link>
            </div>
        </Container>
    )
}