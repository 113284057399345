import React from "react";
import './DataGrid.scss'
import { DataGridRow } from "./DataGridRow";
import { AutoCheckbox } from "../checkbox";

export interface DataGridContentProps {
    columnNames:string[]
    hoverable?:boolean
    fixed?:boolean
    checkColumn?:(idx:number, state:boolean) => void
    checkColumnConfirmMessage?:{
        add:string,
        remove:string
    }
    left?:boolean
    right?:boolean
    rotateHeaders?:boolean
    children:React.ReactElement<typeof DataGridRow>|React.ReactElement<typeof DataGridRow>[];
}

export const DataGridContent:React.FC<DataGridContentProps> = ({columnNames, children, hoverable, fixed, checkColumn, left, right, rotateHeaders, checkColumnConfirmMessage}) => {
    return (
        <div className={`datagrid-content ${fixed ? 'fixed':''} ${rotateHeaders ? 'rotate-headers':''}`}>
            <table className={`${hoverable ? 'row-hoverable':''}`}>
                <thead>
                    <tr>
                        {columnNames.map((cn, idx) =>
                            <th key={idx} className={`${left ? 'left':right ? 'right': ''}`}>
                                <div className="column-name-wrapper"><span className="column-name">{cn}</span></div>
                                { !!checkColumn && idx > 0 ? <div className="column-selector"><AutoCheckbox confirmMessage={checkColumnConfirmMessage} id={`${idx}`} name={`${idx}`} onChange={(e) => checkColumn(idx, e.target.checked)} /></div> : null}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    )
}
