import {useMsal} from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import {loginRequest} from "../../security/authConfig";
import {Button, ButtonType} from "../amethyst";

export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    const handleLogin = () => instance.loginRedirect(loginRequest);
    return <Button kind={ButtonType.primary} onClick={() => handleLogin()}  label={t("SignInButton.Label")} />
};