import React from "react";
import { AppDirectory } from "../components/app/view/AppDirectory";
import { ContentFlow } from "../components/content/ContentFlow";
import { useMe } from "../model/user";

export const Home: React.FC<any> = () => {
    const { me } = useMe();

    if(me) {
        return (
            <div className="content-app mt-4 mb-8 ">
                <AppDirectory me={me} maxApps={4} />
                <ContentFlow />
            </div>
        )
    }
    return null;
}