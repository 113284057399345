import type { MutationHook, MutationRes, FetchRes } from "../hookGeneric";

import {
    Notification,
    MutationCreateNotificationArgs,
    useGetNotificationsQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useRemoveNotificationMutation, MutationUpdateNotificationArgs, MutationRemoveNotificationArgs,
} from '../../service/api/generated-types';
import { QueryGetNotifications } from '../../service/api/api-queries/notification';

type FilterNotifications = {
    notBefore:Date
}

const refetchNotificationsNotBefore = (filter:FilterNotifications) => (
    {
        query:QueryGetNotifications,
        variables:{
            filter:{
                notBefore: filter.notBefore
            }
        }
    }
)

const refetchNotifications = { query:QueryGetNotifications }

export const useValidNotification = ():FetchRes<Notification[]> => {
    const { loading, error, data } = useGetNotificationsQuery({
        fetchPolicy:'cache-and-network' // Important to keep in sync the article list in CRUD page
    });

    if(data && data.notifications) {
        return { loading, error, data:data.notifications as Notification[]}
    }
    return { loading, error, data:[]}
}

export const useNotifications = (filter: FilterNotifications):FetchRes<Notification[]> => {
    const { loading, error, data } = useGetNotificationsQuery({
        variables: { filter:{ notBefore:filter.notBefore } },
        fetchPolicy:'cache-and-network' // Important to keep in sync the article list in CRUD page
    });

    if(data && data.notifications) {
        return { loading, error, data:data.notifications as Notification[]}
    }
    return { loading, error, data:[]}
}

export const useCreateNotification = (filter: FilterNotifications):MutationHook<MutationRes<Notification>, MutationCreateNotificationArgs> => {
    const [createNotification, { loading }] = useCreateNotificationMutation();
    const fn = (args: MutationCreateNotificationArgs) => {
        return createNotification(
            {
                variables:args,
                refetchQueries:[refetchNotificationsNotBefore(filter), refetchNotifications]
            }
        ).then(({data, errors}) => {
            if(data) return { data:data.createNotification as Notification, errors}
            return { data:null, errors }
        });
    }
    return [fn, { loading }];
}

export const useUpdateNotification = ():MutationHook<MutationRes<Notification>, MutationUpdateNotificationArgs> => {
    const [updateNotification, { loading }] = useUpdateNotificationMutation();
    const fn = (args: MutationUpdateNotificationArgs) => {
        return updateNotification({variables:args}).then(({data, errors}) => {
            if(data) return { data:data.updateNotification as Notification, errors}
            return { data:null, errors }
        });
    }
    return [fn, { loading }];
}

export const useRemoveNotification = (filter: FilterNotifications):MutationHook<MutationRes<boolean>, MutationRemoveNotificationArgs> => {
    const [removeNotification, { loading }] = useRemoveNotificationMutation();
    const fn = (args: MutationRemoveNotificationArgs) => {
        return removeNotification(
            {
                variables:args,
                refetchQueries:[refetchNotificationsNotBefore(filter), refetchNotifications]
            }
        ).then(({data, errors}) => {
            if(data) return { data:data.removeNotification as boolean, errors}
            return { data:null, errors }
        });
    }
    return [fn, { loading }];
}
