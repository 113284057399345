import React from 'react';
import { useAgendaEntryToEdit, useAgendaEntriesTags, MutationUpdateAgendaDeadlineArgs, MutationCreateAgendaDeadlineArgs } from '../../../model/agendaEntry';
import { Contact, useContacts } from '../../../model/contact';
import { Customer } from '../../../model/customer';
import { AgendaDeadlineEditForm } from './AgendaDeadlineEditForm';

interface LoadAgendaDeadlineAndEditProps {
    agendaEntryIdToEdit: string | undefined,
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaDeadlineArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaDeadlineArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    tags:string[]
    contacts:Contact[]
    loading:boolean
    close:() => void
}
interface AgendaDeadlineCreationProps {
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaDeadlineArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaDeadlineArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    tags:string[]
    contacts:Contact[]
    loading:boolean
    close:() => void
}

const LoadAgendaDeadlineAndEditOrDuplicate: React.FC<LoadAgendaDeadlineAndEditProps> = (
    {
        agendaEntryIdToEdit,
        show,
        customers,
        contacts,
        loading,
        tags,
        onCreate, onUpdate, mustBeDuplicated, close }
) => {
    const agendaEntryHook = useAgendaEntryToEdit(agendaEntryIdToEdit);
    return <AgendaDeadlineEditForm agendaEntryToEdit={agendaEntryHook.agendaEntry} customers={customers} loading={loading || agendaEntryHook.loading} show={show} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} close={close} tags={tags} contacts={contacts} />
}

const AgendaDeadlineCreation: React.FC<AgendaDeadlineCreationProps> = (
    {show, onCreate, onUpdate, mustBeDuplicated, customers, close, tags, contacts, loading}) => {
    return <AgendaDeadlineEditForm show={show} customers={customers} close={close} loading={loading} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} tags={tags} contacts={contacts} />
}

interface AgendaDeadlineEditProps {
    agendaEntryIdToEdit:string|undefined,
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaDeadlineArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaDeadlineArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    close:() => void
}

const AgendaDeadlineEditModal:React.FC<AgendaDeadlineEditProps> = ({agendaEntryIdToEdit, show, customers, onCreate, onUpdate, mustBeDuplicated, close}) => {
    const contactHook = useContacts();
    const tagsHook = useAgendaEntriesTags();
    const loading = tagsHook.loading || contactHook.loading
    const contacts = loading ? [] : contactHook.contacts;
    const tags = loading ? [] : tagsHook.tags;

    return (
        <>
            {!!agendaEntryIdToEdit ?
                <LoadAgendaDeadlineAndEditOrDuplicate show={show} close={close} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} agendaEntryIdToEdit={agendaEntryIdToEdit} customers={customers} loading={loading} contacts={contacts} tags={tags} /> :
                <AgendaDeadlineCreation show={show} customers={customers} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} close={close} loading={loading} contacts={contacts} tags={tags} />
            }
        </>
    )
}

export const AgendaDeadlineEdit:React.FC<AgendaDeadlineEditProps> = (props) => {
    const { show } = props;
    if(show) {
        return <AgendaDeadlineEditModal {...props }/>
    } return null;
}