import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { printDate } from '../../../i18n';
import { Article } from '../../../model/article';
import { ContactView } from '../../contact/view/ContactView';
import { Button, ButtonType, Grid, GridColumn, GridRow, SimpleTag } from '../../amethyst';
import articleHeaderLogo from '../../../assets/img/article-sign.svg';
import { parseISO } from 'date-fns';

import './newsContent.scss';

export interface NewsContentProps {
    article:Article
}

export const NewsContent:React.FC<NewsContentProps> = ({article}) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const pubDate = parseISO(article.publicationDate);

    return (
        <article>
            <header className="article-header">
                <div className="content-app content-app-text-content">
                    <div className="article-header-sign" >
                        <img className="article-header-sign-img" src={articleHeaderLogo} alt="article logo"/>
                    </div>
                    <Grid>
                        <GridRow>
                            <GridColumn columnSize={{ 'col-sm': 7}}>
                                <div className="article-header-content">
                                    <div className="article-header-attributes">
                                        <div className="article-header-attributes-item article-header-attributes-item-title">
                                            <Button icon="chevron-left" label={t("Actions.Back")} kind={ButtonType.tertiary} onClick={() => navigate(-1)} />
                                        </div>
                                    </div>
                                    <h1 className="article-header-title">{article.title}</h1>
                                </div>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </div>
            </header>
            <div className="content-app content-app-text-content">
                <Grid>
                    <GridRow>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 8}}>
                            <div className="article-introduction">
                                <div className="article-header-attributes">
                                    <div className="article-header-attributes-item article-header-attributes-item-date">
                                        <time dateTime={printDate(pubDate, 'yyyy-MM-dd')}>{printDate(pubDate, i18n.language, "dd MMMM yyyy")}</time>
                                    </div>
                                    {article.properties.tags.map((t, idx) =>
                                        <div key={idx} className="article-header-attributes-item">
                                            <SimpleTag label={t} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 8}}>
                            {article.properties.mainPicture ?
                                <div className="article-content pt-3">
                                    <img style={{width:"100%", height:"100%"}} alt="illustration" src={article.properties.mainPicture.downloadUrl} />
                                </div>
                                : null
                            }
                            <div className="article-content pt-3" dangerouslySetInnerHTML={{__html: article.richContent.join('')}} />
                        </GridColumn>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 4}}>
                            <div className="contact-content pt-3">
                                {article.properties.contactPerson ? <ContactView contact={article.properties.contactPerson} /> : null}
                            </div>
                        </GridColumn>
                    </GridRow>
                </Grid>
            </div>
        </article>
    )
}
