import React from 'react';
import { useAgendaEntryToEdit, useAgendaEntriesTags, MutationUpdateAgendaEventArgs, MutationCreateAgendaEventArgs } from '../../../model/agendaEntry';
import { AgendaEventEditForm } from './AgendaEventEditForm';
import { Customer } from '../../../model/customer';

interface LoadAgendaEventAndEditProps {
    agendaEntryIdToEdit: string | undefined,
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaEventArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaEventArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    tags:string[],
    loading:boolean,
    close:() => void
}
interface AgendaEventCreationProps {
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaEventArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaEventArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    tags:string[],
    loading:boolean,
    close:() => void
}

interface AgendaEventEditProps {
    agendaEntryIdToEdit:string|undefined,
    show: boolean,
    customers:Customer[],
    onCreate: (params: MutationCreateAgendaEventArgs) => Promise<any>,
    onUpdate: (params: MutationUpdateAgendaEventArgs) => Promise<any>,
    mustBeDuplicated: boolean,
    close:() => void
}

const LoadAgendaEventAndEditOrDuplicate: React.FC<LoadAgendaEventAndEditProps> = ({ agendaEntryIdToEdit, show, customers, onCreate, onUpdate, mustBeDuplicated, close, loading, tags }) => {
    const { loading: entryLoading, agendaEntry } = useAgendaEntryToEdit(agendaEntryIdToEdit);
    return <AgendaEventEditForm agendaEntryToEdit={agendaEntry} customers={customers} loading={loading || entryLoading} show={show} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} close={close} tags={tags} />
}

const AgendaEventCreation: React.FC<AgendaEventCreationProps> = ({show, customers, onCreate, onUpdate, mustBeDuplicated, close, loading, tags}) => {
    return <AgendaEventEditForm show={show} customers={customers} close={close} loading={loading} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} tags={tags} />
}

const AgendaEventEditComponent:React.FC<AgendaEventEditProps> = ({agendaEntryIdToEdit, show, customers, onCreate, onUpdate, mustBeDuplicated, close}) => {
    const tagsHook = useAgendaEntriesTags();
    return (
        <>
            {!!agendaEntryIdToEdit ?
                <LoadAgendaEventAndEditOrDuplicate show={show} close={close} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} agendaEntryIdToEdit={agendaEntryIdToEdit} tags={tagsHook.tags} customers={customers} loading={tagsHook.loading} /> :
                <AgendaEventCreation show={show} onCreate={onCreate} onUpdate={onUpdate} mustBeDuplicated={mustBeDuplicated} close={close} customers={customers} tags={tagsHook.tags} loading={tagsHook.loading}/>
            }
        </>
    )
}

export const AgendaEventEdit:React.FC<AgendaEventEditProps> = (props) => {
    const { show } = props;
    if(show) {
        return <AgendaEventEditComponent {...props} />
    } else {
        return null;
    }
}