import React, {PropsWithChildren} from "react";

export interface ModalContentProps extends PropsWithChildren {}

export const ModalContent:React.FC<ModalContentProps> = ({children}) => {
    return (
        <div className="modal-body" style={{zIndex:1000}}>
            {children}
        </div>
    )
}