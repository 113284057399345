import React, { ReactElement, useLayoutEffect } from 'react';
import {Navigate, Route, Routes, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import {Logout} from "./pages/Logout";
import {Home} from "./pages/Home";
import {AllApps} from './pages/AllApps';
import {AllNews} from './pages/AllNews';
import {OneNews} from './pages/OneNews';
import {AllAgendaEntries} from './pages/AllAgendaEntries';
import {OneAgendaEntry} from './pages/OneAgendaEntry';
import {ProtectedRoute} from "./components/security/ProtectedRoute";
import { UserType } from "./model/user";
import {Management} from "./pages/Management";
import {AppManagement} from "./components/app/edit/AppManagement";
import {CustomerManagement} from "./components/customer/edit/CustomerManagement";
import {SubscriptionManagement} from "./components/subscription/edit/SubscriptionManagement";
import {CustomerUsersManagement} from "./components/customerUser/edit/CustomerUsersManagement";
import {ManagerManagement} from "./components/manager/edit/ManagerManagement";
import {ArticleManagement} from "./components/article/edit/ArticleManagement";
import {ArticleEdit} from "./components/article/edit/ArticleEdit";
import {ContactManagement} from "./components/contact/edit/ContactManagement";
import {AgendaManagement } from './components/agenda/edit/AgendaManagement';
import {NotificationManagement} from './components/notification/edit/NotificationManagement';
import { rolesByService } from "./roles";


interface BackToTopWrapperProps {
    children:ReactElement
}

const BackToTopWrapper:React.FC<BackToTopWrapperProps> = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

export const AppRoutes:React.FC<any> = () => {
    return (
        <BackToTopWrapper>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Routes>

                    <Route path="/" element={<Home />} />
                    <Route path="/apps" element={<AllApps />} />
                    <Route path="/news" element={<AllNews />} />
                    <Route path="/news/:slug" element={<OneNews />} />
                    <Route path="/agenda" element={<AllAgendaEntries />} />
                    <Route path="/agenda/:id" element={<OneAgendaEntry />} />
                    <Route path="/logout" element={<Logout />}/>

                    <Route element={<ProtectedRoute allowedType={[UserType.Manager]} />}>
                        <Route path="/management" element={<Management />} >
                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["apps"]} />}>
                                <Route path="apps" element={<AppManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["customers"]} />}>
                                <Route path="customers" element={<CustomerManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["subscriptions"]} />}>
                                <Route path="subscriptions" element={<SubscriptionManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["users"]} />}>
                                <Route path="users" element={<CustomerUsersManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["managers"]} />}>
                                <Route path="managers" element={<ManagerManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["news"]} />}>
                                <Route path="news">
                                    <Route index element={<ArticleManagement />} />
                                    <Route path="edit">
                                        <Route index element={<ArticleEdit />} />
                                        <Route path=":articleId" element={<ArticleEdit />} />
                                    </Route>
                                </Route>
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["agenda"]} />}>
                                <Route path="agenda">
                                    <Route index element={<AgendaManagement />} />
                                </Route>
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["contacts"]} />}>
                                <Route path="contacts" element={<ContactManagement />} />
                            </Route>

                            <Route element={<ProtectedRoute allowedType={[UserType.Manager]} allowedManagerRoles={rolesByService["notifications"]} />}>
                                <Route path="notifications" element={<NotificationManagement />} />
                            </Route>

                        </Route>
                    </Route>

                    <Route path="*" element={<Navigate replace to="/" />} />

                </Routes>
            </QueryParamProvider>
        </BackToTopWrapper>
    )
}


