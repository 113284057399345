import React from "react";
import { ToggleInput, ToggleInputProps } from "../../input/ToggleInput"
import { InputStatus } from "../../common";
import { useField } from "formik";

export const FieldToggleTextInput:React.FC<ToggleInputProps> = (props) => {
    const [field, meta] = useField<string>(props.name)
    const {id, editMode, name, label, placeholder, form, ...rest} = props;

    return (
        <ToggleInput
            id={id}
            name={name}
            label={label}
            placeholder={placeholder}
            form={form}
            status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
            hint={meta.error}
            editMode={editMode}
            onBlur={(e:React.FocusEvent<HTMLInputElement>) => editMode && field.onBlur(e)}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => editMode && field.onChange(e)}
            value={field.value}
            {...rest}
        />
    )
}