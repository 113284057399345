import React, { PropsWithChildren } from "react";

export enum ColumnBreakpoint {
    Small = 'col-sm',
    Medium = 'col-md',
    Large = 'col-lg',
    XLarge = 'col-xl'
}

type ColumnSize = { [key in ColumnBreakpoint]?:number }

export interface GridColumnProps extends PropsWithChildren {
    columnSize?:ColumnSize,
    defaultSize?: number,
    marginBottom?: number
    marginTop?: number
}

export const GridColumn: React.FunctionComponent<GridColumnProps> = ({ columnSize = {}, children, defaultSize, marginBottom , marginTop}) => {
    return (
        <div className={`${marginTop ? `mt-${marginTop}`:''} ${marginBottom ? `mb-${marginBottom}`:''} col ${defaultSize ? `col-${defaultSize}` : ''} ${Object.entries(columnSize).map(([k, v]) => `${k}-${v} `).join(' ')}`}>
            {children}
        </div>
    )
}