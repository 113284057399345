import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import msalInstance from "./msalInstance";
import { ToastContainer } from 'react-toastify';
import App from './App';

// GT design system
import "./components/amethyst/amethyst-v1.01.css";

// Toastify CSS
import 'react-toastify/dist/ReactToastify.css';
import './mygt.scss';

// React bootstrap
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <App pca={msalInstance} />
        </Router>
        <ToastContainer
            className="mygt-toast"
            autoClose={10000}
            hideProgressBar={true}
            draggable={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnHover
            theme="colored"
        />
    </React.StrictMode>
);
