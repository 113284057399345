import React, { useCallback, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import {FieldHelperProps, Formik, FormikHelpers } from "formik";
import { debounce } from "lodash";
import { useIsEmailAvailable, UserLanguage } from '../../../model/user';
import {cacheTest, validateEmail} from "../../utils";
import {
    FieldFormMultipleSelect,
    FieldFormSimpleSelect,
    FieldTextInput,
    Form,
    FormAction,
    FormGroup,
    ISelectItem,
    Modal,
    ModalContent,
} from '../../amethyst';
import { useTranslation } from "react-i18next";

export interface NewCustomerUserFormType {
    firstName: string,
    lastName: string,
    email: string,
    customers: ISelectItem[],
    language: ISelectItem|undefined
}

const newCustomerUserDefaultValues:NewCustomerUserFormType = {
    firstName: '',
    lastName: '',
    email: '',
    customers:[],
    language:undefined
}

interface NewUserFormProps {
    show:boolean,
    onCreate:(values: NewCustomerUserFormType) => Promise<any>
    customers: ISelectItem[]
    close:() => void
}

export const NewCustomerUserForm: React.FC<NewUserFormProps> = ({ customers, onCreate, show, close }) => {
    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');
    const [validating, setValidating] = useState<boolean>(false);
    const [isEmailAvailable ] = useIsEmailAvailable();

    const userLanguageItems: ISelectItem[] = Object.values(UserLanguage).map(name => {
        return {
            id: name,
            label: i18nEnums.t(`UserLanguage.${name}`),
            value: name
        }
    });

    // Yup and Formik issues:
    // - each field is validated, when any field change
    // - a field validation does not stop at first test failure
    // So to avoid calling API to check email availability, we need to:
    // - cache previous email field value to detect email field change
    // - to trigger email availability check only when email field has changed and email syntax is valid
    const testUniqueEmail = useRef(cacheTest(isEmailAvailable, setValidating));
    const newCustomerUserValidationSchema = useMemo(() => {
        return Yup.object().shape({
            firstName: Yup.string()
                .label("First Name")
                .min(2, t("FormValidation.TooShort", { length:2 }))
                .max(100, t("FormValidation.TooLong", { length:100 }))
                .required(t("FormValidation.Required")),
            lastName: Yup.string()
                .label("Last name")
                .min(2, t("FormValidation.TooShort", { length:2 }))
                .max(100, t("FormValidation.TooLong", { length:100 }))
                .required(t("FormValidation.Required")),
            email: Yup.string()
                .label('Email')
                .test('email-unique',t("CustomerUserManagement.EmailError"), (arg:string|undefined) => !!arg && !!validateEmail(arg) && testUniqueEmail.current(arg).then(r => !r))
                .required(t("FormValidation.Required")),
            customers:Yup.array().min(1, t("FormValidation.AtLeastOne"))
        });
    }, [testUniqueEmail, t]);

    const onSubmit = async (values: NewCustomerUserFormType, helpers: FormikHelpers<NewCustomerUserFormType>) => {
        await onCreate(values);
        helpers.resetForm({ values: newCustomerUserDefaultValues });
        close();
    }

    const debouncedSearch = useMemo(() => debounce((value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, true);
    }, 500, { trailing:true, leading:false}), []);

    const debouncedEmailValidation = useCallback(debouncedSearch, [debouncedSearch]);

    // To avoid calling API at each key input, we debounced each validation call:
    // - we update field value instantly but without validation
    // - we use debounce method to trigger validation
    const onChangeEmail = (value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, false);
        debouncedEmailValidation(value, helpers);
    }

    if(!show) return null;

    return (
        <Formik<NewCustomerUserFormType> initialValues={{...newCustomerUserDefaultValues}} validationSchema={newCustomerUserValidationSchema} onSubmit={onSubmit} enableReinitialize>
            <Modal closeOnEscape title={t("CustomerUserManagement.AddUser")} open={show} onClose={close} >
                <ModalContent>
                    <Form id="NewCustomerUserForm">
                        <FormGroup>
                            <FieldTextInput
                                label={t("CustomerUserManagement.FirstName")}
                                form="NewCustomerUserForm"
                                id="firstName"
                                name="firstName"
                                placeholder={t("CustomerUserManagement.FirstNamePlaceholder")}
                            />
                            <FieldTextInput
                                label={t("CustomerUserManagement.LastName")}
                                form="NewCustomerUserForm"
                                id="lastName"
                                name="lastName"
                                placeholder={t("CustomerUserManagement.LastNamePlaceholder")}
                            />
                            <FieldTextInput
                                label={t("CustomerUserManagement.Email")}
                                form="NewCustomerUserForm"
                                id="email"
                                name="email"
                                placeholder={t("CustomerUserManagement.EmailPlaceholder")}
                                customValidation={onChangeEmail}
                                loading={validating}
                            />
                            <FieldFormMultipleSelect
                                label={t("CustomerUserManagement.RelatedCustomers")}
                                id="customers"
                                items={customers}
                                placeholder={t("CustomerUserManagement.RelatedCustomersPlaceholder")}
                                name="customers"
                                noOptionsMessage={""}
                            />
                            <FieldFormSimpleSelect
                                id="language"
                                name="language"
                                label={t("CustomerUserManagement.Language")}
                                placeholder={t("CustomerUserManagement.LanguagePlaceholder")}
                                items={userLanguageItems}
                                form="NewCustomerUserForm"
                                noOptionsMessage=""
                            />
                        </FormGroup>
                        <FormAction
                            submittingLabel={t("Actions.Saving")}
                            submitLabel={t("Actions.Save")}
                            resetLabel={t("Actions.Cancel")}
                            onCustomCancel={close}
                        />
                    </Form>
                </ModalContent>
            </Modal>
        </Formik>
    );
}
