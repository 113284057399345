import React from "react";
import { useTranslation } from "react-i18next";

const range = (start:number, stop:number) => {
    let result = [];
    if(start > stop) {
        start = stop;
    }
    for(let i = start; i <= stop ; i++) {
        result.push(i)
    }
    return result;
}

type PaginationParams = {
    isFirstPage:boolean,
    isLastPage:boolean,
    showStartEllipsis:boolean,
    showEndEllipsis:boolean,
    start:number,
    end:number
}

const getPaginationParams = (currentPage:number, totalPages: number, maxSibling: number = 5):PaginationParams => {
    // We need an odd number for sibling, and sibling can not be greater than half of the total number of pages
    const sibling = (totalPages/2) > maxSibling && maxSibling >= 3 ? maxSibling : (totalPages/2);
    const oddSibling = sibling % 2 === 0 ? sibling+1 : sibling;

    // We calculate the width of pages to display when both ellipsis are shown
    const centerDelta = Math.floor(oddSibling/2) - 1;

    // Either we show or not start/end ellipsis
    let showStartEllipsis = currentPage >= oddSibling;
    let showEndEllipsis = totalPages - currentPage >= oddSibling;

    // Then we compute the number of page number to show depending on ellipsis to be shown
    let start = 0, end = 0;
    if(!showStartEllipsis && showEndEllipsis) {
        start = Math.min(currentPage + 1, 2);
        end = oddSibling;
    }

    if(showStartEllipsis && showEndEllipsis) {
        start = currentPage - centerDelta;
        end = currentPage + centerDelta;
    }

    if(showStartEllipsis && !showEndEllipsis) {
        start = totalPages - oddSibling + 1;
        end = Math.max(currentPage - 1, totalPages - 1);
    }

    let isLastPage = currentPage === totalPages;
    let isFirstPage = currentPage === 1;

    return {
        isFirstPage,
        isLastPage,
        showEndEllipsis,
        showStartEllipsis,
        start,
        end
    }
}


const Ellipsis = () => <li><span className="disabled">...</span></li>

interface NavPageProps {
    disabled:boolean
    onClick:() => void
}

const PreviousPage: React.FC<NavPageProps> = ({ disabled, onClick }) => {
    const { t } = useTranslation('amethyst');
    return (
        <li>
            <button type="button" className={`pagination-link-icon ${disabled ? 'disabled' : ''}`} tabIndex={-1} aria-disabled={disabled} onClick={(e) => { e.preventDefault(); onClick() }}>
                <span className="sr-only">{t("Pagination.Previous")}</span>
                <i className="icon icon-chevron-small-left icon-d-block" aria-hidden="true"></i>
            </button>
        </li>
    )
}
    
    

const NextPage: React.FC<NavPageProps> = ({ disabled, onClick }) => {
    const { t } = useTranslation('amethyst');
    return (
        <li>
            <button type="button" className={`pagination-link-icon ${disabled ? 'disabled' : ''}`} aria-disabled={disabled} onClick={(e) => { e.preventDefault(); onClick() }} >
                <span className="sr-only">{t("Pagination.Next")}</span>
                <i className="icon icon-chevron-small-right icon-d-block" aria-hidden="true"></i>
            </button>
        </li>
    )
}
    
interface PageNumProps {
    num:number,
    active:boolean,
    disabled?:boolean
    onClick:(num: number) => void
}

const PageNum:React.FC<PageNumProps> = ({num, active, disabled, onClick}) => <li><button type="button" className={`${active ? 'active':''} ${disabled ? 'disabled':''}`}   onClick={() => onClick(num)}>{num}</button></li>

export interface DataGridPaginationProps {
    disabled?:boolean
    currentPage:number,
    totalPages:number
    maxSibling?:number;
    onPageClick: (pageNumber: number) => void
}

const Pages:React.FC<DataGridPaginationProps> = ({disabled, maxSibling, currentPage, totalPages, onPageClick}) => {
    if(totalPages < 10) {
        return (
            <>
                {range(1, totalPages).map((i, idx) =>
                    <PageNum key={idx} num={i} disabled={disabled} active={currentPage === i} onClick={() => onPageClick(i)} />
                )}
            </>
        )
    } else {

        const { isFirstPage, isLastPage, showEndEllipsis, showStartEllipsis, start, end } = getPaginationParams(currentPage, totalPages, maxSibling);

        return (
            <>
                <PreviousPage disabled={isFirstPage || !!disabled} onClick={() => onPageClick(currentPage - 1)} />
                <PageNum num={1} disabled={disabled} active={currentPage === 1} onClick={onPageClick} />
                {showStartEllipsis ? <Ellipsis /> : null }
                {range(start, end).map((pNum, idx) =>
                    <PageNum key={idx} num={pNum} disabled={disabled} active={pNum === currentPage} onClick={() => onPageClick(pNum)} />
                )}
                {showEndEllipsis ? <Ellipsis /> : null }
                <PageNum num={totalPages} disabled={disabled} active={currentPage === totalPages} onClick={onPageClick} />
                <NextPage disabled={isLastPage || !!disabled} onClick={() => onPageClick(currentPage + 1)} />
            </>
        )
    }
}

export const DataGridPagination:React.FC<DataGridPaginationProps> = ({disabled, currentPage, totalPages, maxSibling, onPageClick}) => {
    return (
        <nav aria-label="Pagination">
            <ul className="nav pagination">
                <Pages maxSibling={maxSibling} disabled={disabled} currentPage={currentPage} totalPages={totalPages} onPageClick={onPageClick} />
            </ul>
        </nav>
    )
}