import React from "react";
import { InputStatus } from "../common";
import { InputProps } from "./Input";

export interface ToggleInputProps extends InputProps {
    editMode?:boolean
}

export const ToggleInput:React.FC<ToggleInputProps> = (
    {
        id,
        name,
        label,
        value,
        form,
        placeholder,
        disabled,
        onChange,
        onBlur,
        password,
        hint,
        status,
        editMode,
        autoComplete
    }
) => {
    return (
        <div className="form-input">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
                <div className="input">
                    {editMode ?
                        <input
                            autoComplete={autoComplete}
                            type={password ? 'password' : 'text'}
                            className={`form-field ${status === InputStatus.Invalid ? 'is-invalid':''}`}
                            placeholder={placeholder}
                            id={id}
                            value={value}
                            form={form?form:''}
                            onChange={(e) => onChange && onChange(e) }
                            onBlur={(e) => onBlur && onBlur(e) }
                            disabled={disabled}
                            aria-describedby={hint ? `${id}-hint` : ''}
                        />
                        : <span className="form-field form-field-toggle-read">{value}</span>
                    }
                </div>
            {(editMode && (hint || status)) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                null
            }
        </div>
    )
}

