import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { printDate } from '../../../i18n';
import { AgendaEntry } from '../../../model/agendaEntry';
import { ContactView } from '../../contact/view/ContactView';
import { Button, ButtonType, Grid, GridColumn, GridRow, SimpleTag } from '../../amethyst';
import articleHeaderLogo from '../../../assets/img/article-sign.svg';
import { parseISO } from 'date-fns';

import './agendaEntryContent.scss';
import { Deadline, Event } from '../../../service/api/generated-types';

export interface NewsContentProps {
    agendaEntry:AgendaEntry
}

export const AgendaEntryContent:React.FC<NewsContentProps> = ({agendaEntry}) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const pubDate = parseISO(agendaEntry.date);
    const deadlineProperties = agendaEntry.properties as Deadline;
    const eventProprerties = agendaEntry.properties as Event;

    const onOpenUrl = (url: string|undefined) => {
        if (!!url) {
            window.open(url, '_blank', 'noreferrer');
        }
    }

    return (
        <article>
            <header className="article-header">
                <div className="content-app content-app-text-content">
                    <div className="article-header-sign" >
                        <img className="article-header-sign-img" src={articleHeaderLogo} alt="article logo"/>
                    </div>
                    <Grid>
                        <GridRow>
                            <GridColumn columnSize={{ 'col-sm': 7}}>
                                <div className="article-header-content">
                                    <div className="article-header-attributes">
                                        <div className="article-header-attributes-item article-header-attributes-item-title">
                                            <Button icon="chevron-left" label={t("Actions.Back")} kind={ButtonType.tertiary} onClick={() => navigate(-1)} />
                                        </div>
                                    </div>
                                    <h1 className="article-header-title">{agendaEntry.title}</h1>
                                </div>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </div>
            </header>
            <div className="content-app content-app-text-content">
                <Grid>
                    <GridRow>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 8}}>
                            <div className="article-introduction">
                                <div className="article-header-attributes">
                                    <div className="article-header-attributes-item article-header-attributes-item-date">
                                        <time dateTime={printDate(pubDate, 'yyyy-MM-dd')}>{printDate(pubDate, i18n.language, "dd MMMM yyyy")}</time>
                                    </div>
                                    {agendaEntry.tags.map((t, idx) =>
                                        <div key={idx} className="article-header-attributes-item">
                                            <SimpleTag label={t} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </GridColumn>
                    </GridRow>
                    <GridRow>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 8}}>
                            {deadlineProperties.description &&
                                <p className="article-content pt-3">{deadlineProperties.description}</p>
                            }
                            {eventProprerties.richContent &&
                                <div className="article-content pt-3" dangerouslySetInnerHTML={{__html: eventProprerties.richContent.join('')}} />
                            }
                        </GridColumn>
                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 4}}>
                            {deadlineProperties.cta.contactPerson && <div className="contact-content pt-3"><ContactView contact={deadlineProperties.cta.contactPerson} /></div>}
                            {(eventProprerties.cta.link && eventProprerties.cta.link.url.length > 0) &&
                                <div className="contact-content pt-3">
                                    <Button kind={ButtonType.primary} label={eventProprerties.cta.link.label} onClick={() => onOpenUrl(eventProprerties.cta.link?.url)} />
                                </div>
                            }
                        </GridColumn>
                    </GridRow>
                </Grid>
            </div>
        </article>
    )
}
