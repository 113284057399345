import { useSearchParams } from 'react-router-dom';

export type PaginationFilter = {
    page:number,
    pageSize:number
}

export const DEFAULT_PAGE_SIZE = 10;

type PaginationHook = [pagination:PaginationFilter, updatePagination:(pagination:PaginationFilter) => void]

export const usePagination = (pageSize: number = DEFAULT_PAGE_SIZE):PaginationHook => {
    const [searchParam, setSearchParam] = useSearchParams();
    return [
        {
            page:parseInt(searchParam.get('p') || '1') || 1,
            pageSize:parseInt(searchParam.get('s') || pageSize.toString()) || pageSize
        },
        (pagination:PaginationFilter) => {
            searchParam.set('p', pagination.page.toString());
            searchParam.set('s', pagination.pageSize.toString());
            setSearchParam(searchParam, { replace: true});
        }
    ]
}