import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
    Contact,
    MutationCreateContactArgs,
    MutationUpdateContactArgs,
    useCreateContactMutation,
    useGetContactsQuery,
    useUpdateContactMutation,
    MutationRemoveContactByIdArgs,
    useRemoveContactByIdMutation,
    useQueryGetContactsSpecialisationsQuery
} from '../../service/api/generated-types';
import { QueryGetContacts, QueryGetContactsSpecialisations } from '../../service/api/api-queries/contact';

type FetchContacts = { loading:boolean, contacts: Contact[], error: ApolloError|undefined }

export const useContacts = ():FetchContacts => {
    const { data, loading, error } = useGetContactsQuery();
    if(loading) return { loading, contacts:[], error }
    return { loading, contacts: (data ? data.contacts as Contact[] : []), error}
}

type CreateContactRes = {
    contact:Contact|null,
    errors:readonly GraphQLError[]|undefined
}
type CreateContact = [fn:(args: MutationCreateContactArgs) => Promise<CreateContactRes>, status: { loading: boolean}]
export const useCreateContact = ():CreateContact => {
    const [createContact, { loading }] = useCreateContactMutation({refetchQueries:[{query:QueryGetContacts}, {query:QueryGetContactsSpecialisations}]});
    const fn = (args: MutationCreateContactArgs) => {
        return createContact({variables:args}).then(({data, errors}) => {
            if(data) return { contact:data.createContact as Contact, errors}
            return { contact:null, errors }
        });
    }
    return [fn, { loading}];
}

type UpdateContactRes = {
    contact:Contact|null,
    errors:readonly GraphQLError[]|undefined
}
type UpdateContact = [fn:(args: MutationUpdateContactArgs) => Promise<UpdateContactRes>, status: { loading: boolean}]
export const useUpdateContact = ():UpdateContact => {
    const [updateContact, { loading }] = useUpdateContactMutation({refetchQueries:[{query:QueryGetContactsSpecialisations}]});
    const fn = (args: MutationUpdateContactArgs):Promise<UpdateContactRes> => {
        return updateContact({variables:args}).then(({data, errors}) => {
            if(data) return { contact:data.updateContact as Contact, errors}
            return { contact:null, errors }
        });
    }
    return [fn, { loading}];
}

type RemoveContactRes = {
    result:boolean,
    errors:readonly GraphQLError[]|undefined
}

type RemoveContact = [fn:(args:MutationRemoveContactByIdArgs) => Promise<RemoveContactRes>, status: { loading: boolean}]

export const useRemoveContact = ():RemoveContact => {
    const [removeContact, { loading}] = useRemoveContactByIdMutation({refetchQueries:[{query:QueryGetContacts}, {query:QueryGetContactsSpecialisations}]})
    const fn = (args: MutationRemoveContactByIdArgs):Promise<RemoveContactRes> => {
        return removeContact({variables:args}).then(({data, errors}) => {
            if(data) {
                return { result:!!data.removeContactById, errors}
            }
            return { result: false, errors }
        });
    }
    return [fn, { loading }]
}

type FetchSpecialisationTags = { loading:boolean, tags: string[], error: ApolloError|undefined }
export const useSpecialisationTags = ():FetchSpecialisationTags => {
    const { data, loading, error } = useQueryGetContactsSpecialisationsQuery({fetchPolicy: "cache-and-network"});
    if(loading) return { loading, tags:[], error }
    return { loading, tags: (data ? data.contactsSpecialisations : []), error}
}
