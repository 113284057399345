import React, { InputHTMLAttributes, forwardRef, ForwardedRef } from 'react';
import { InputStatus } from "../common";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement|HTMLTextAreaElement> {
    id:string,
    name:string
    label?:string
    password?:boolean
    hint?:string
    status?:InputStatus
    loading?:boolean
    ref?:ForwardedRef<HTMLInputElement>
}

export interface TextAreaProps extends InputHTMLAttributes<HTMLInputElement|HTMLTextAreaElement> {
    id:string,
    name:string
    label?:string
    password?:boolean
    hint?:string
    status?:InputStatus
    loading?:boolean
    fluid?:boolean
    rows?:number
    cols?:number
    ref?:ForwardedRef<HTMLTextAreaElement>
}

export const TextInput:React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>((
    {
        id,
        name,
        label,
        value,
        form,
        placeholder,
        disabled,
        onChange,
        onBlur,
        password,
        hint,
        status,
        autoComplete,
        autoFocus,
        loading,
        defaultValue
    }, ref) => {
    return (
        <div className="form-input">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
            <div className={`input ${loading ? 'loader':''}`}>
                <input
                    ref={ref}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    type={password ? 'password' : 'text'}
                    className={`form-field ${status === InputStatus.Invalid ? 'is-invalid':''}`}
                    placeholder={placeholder}
                    id={id}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    form={form?form:''}
                    onChange={(e) => onChange && onChange(e) }
                    onBlur={(e) => onBlur && onBlur(e) }
                    disabled={disabled}
                    aria-describedby={hint ? `${id}-hint` : ''}
                />
                {loading ? <i className="form-input-loader icon icon-gear" aria-hidden="true" /> : null}
            </div>
            {(hint || status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                null
            }
        </div>
    )
})

export const TextAreaInput:React.FC<TextAreaProps> = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
    id,
    name,
    label,
    value,
    form,
    placeholder,
    disabled,
    onChange,
    onBlur,
    password,
    hint,
    status,
    fluid,
    rows,
    cols,
    autoComplete,
    autoFocus,
    loading
}, ref) => {
    return (
        <div className="form-input">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
            <textarea
                ref={ref}
                className={`form-field ${status === InputStatus.Invalid ? 'is-invalid':''} ${fluid ? 'fluid' : ''}`}
                placeholder={placeholder}
                id={id}
                name={name}
                value={value}
                form={form?form:''}
                rows={rows}
                cols={cols}
                onChange={(e) => onChange && onChange(e) }
                onBlur={(e) => onBlur && onBlur(e) }
                disabled={disabled}
                aria-describedby={hint ? `${id}-hint` : ''}
            />
            {(hint || status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                null
            }
        </div>
    )
})
