import React from "react";
import { useTranslation } from "react-i18next";
import { Contact } from "../../../model/contact";
import { Icon, SimpleTag } from '../../amethyst';
import "./contact.scss";

export interface ContactViewProps {
    contact:Contact
}

export const ContactView: React.FC<ContactViewProps> = ({ contact }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="tile contact-tile">
                <span className="h4 tile-article-author-label">{t("News.ContactAuthor")}</span>
                <div className="tile-article-author-block">
                    <div className="user-card-small">
                        <div className="user-card-small-img">
                            <img className="img-circle img-fluid" alt={`${contact.firstName} ${contact.lastName}`} src={contact.picture.downloadUrl} />
                        </div>
                        <div className="user-card-small-text">
                            <div className="user-card-small-name">{`${contact.firstName} ${contact.lastName}`}</div>
                            <div className="user-card-small-position text-muted-light">{contact.title}</div>
                        </div>
                    </div>
                </div>
                <div className="tile-article-author-block">
                    <a href={`mailto:${contact.email}`} className="icon-component">
                                    <span className="icon-component-icon">
                                        <Icon icon="envelope" block />
                                    </span>
                        <span className="icon-component-text">
                                        {contact.email}
                                    </span>
                    </a>
                    <a href={`tel:${contact.phone}`} className="icon-component">
                                    <span className="icon-component-icon">
                                        <Icon icon="phone" block />
                                    </span>
                        <span className="icon-component-text">
                                        {contact.phone}
                                    </span>
                    </a>
                </div>
                <div className="tile-article-author-specializations">
                    <span className="tile-article-author-specializations-label">{t("News.Specializations")}</span>
                    {contact.specialisation.map((s, idx) =>
                        <SimpleTag key={idx} label={s} />
                    )}
                </div>
            </div>
        </>
    )
}
