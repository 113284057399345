import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useArticleToEdit, useArticlesTags } from '../../../model/article';
import { useContacts } from '../../../model/contact';
import { Grid, GridColumn, GridRow, Loader, LoaderSize } from '../../amethyst';
import { ArticleEditForm } from './ArticleEditForm';

interface LoadArticleProps {
    articleId:string
}

const LoadArticleAndEdit: React.FC<LoadArticleProps> = ({ articleId }) => {
    const { t } = useTranslation();
    const articleHook = useArticleToEdit(articleId);
    const contactHook = useContacts();
    const tagsHook = useArticlesTags();
    if(
        articleHook.loading || !articleHook.article ||
        tagsHook.loading ||
        contactHook.loading
    ) return <Loader size={LoaderSize.medium} label={t("ArticleManagement.Loading")} />
    return <ArticleEditForm article={articleHook.article} tags={tagsHook.tags} contacts={contactHook.contacts} />
}

const ArticleCreation: React.FC = () => {
    const { t } = useTranslation();
    const tagsHook = useArticlesTags();
    const contactHook = useContacts();
    if(tagsHook.loading || contactHook.loading) return <Loader size={LoaderSize.medium} label={t("ArticleManagement.Loading")} />
    return <ArticleEditForm tags={tagsHook.tags} contacts={contactHook.contacts} />
}

export const ArticleEdit:React.FC<any> = () => {
    const { articleId } = useParams();
    return (
        <Grid fluid className="paper">
            <GridRow>
                <GridColumn>
                    {!!articleId ?
                        <LoadArticleAndEdit articleId={articleId} /> :
                        <ArticleCreation />
                    }
                </GridColumn>
            </GridRow>
        </Grid>
    )
}
