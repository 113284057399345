import React from 'react';
import { TFunction } from 'i18next';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Container, IRouterTabPane, RouterTab } from '../components/amethyst';
import { Me, useMe } from '../model/user';
import { useTranslation } from 'react-i18next';
import { rolesByService } from "../roles";

export interface ManagementProps { }

const managementPanes = (me:Me, t: TFunction):IRouterTabPane[] => [
    {
        disabled:!me.isManagerWithRoles(rolesByService["apps"]), label:`${t('ManagementTab.Apps')}`, path:"apps"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["customers"]), label:`${t('ManagementTab.Customers')}`, path:"customers"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["subscriptions"]), label:`${t('ManagementTab.Subscriptions')}`, path:"subscriptions"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["users"]), label:`${t('ManagementTab.CustomerUsers')}`, path:"users"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["managers"]), label:`${t('ManagementTab.Managers')}`, path:"managers"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["news"]), label:`${t('ManagementTab.News')}`, path:"news"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["agenda"]), label:`${t('ManagementTab.Agenda')}`, path:"agenda"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["contacts"]), label:`${t('ManagementTab.Contact')}`, path:"contacts"
    },
    {
        disabled:!me.isManagerWithRoles(rolesByService["notifications"]), label:`${t('ManagementTab.Notifications')}`, path:"notifications"
    }
]

export const Management:React.FC<ManagementProps> = () => {
    const { me } = useMe();
    const { t } = useTranslation();

    const panes = me ? managementPanes(me, t) : [];

    return (
        <AuthenticatedTemplate>
            <Container className="mt-4 mb-8 ml-2">
                <RouterTab prefix="/management" panes={panes} />
            </Container>
        </AuthenticatedTemplate>
    )
}

