import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { ArticleCrud } from './ArticleCrud';

export const ArticleManagement:React.FC = () => {
    return (
        <CrudLayout>
            <ArticleCrud />
        </CrudLayout>
    )
}