import { gql } from '@apollo/client';

/***
 * IMPORTANT : these queries are used to generate graphql stubs (generated-types.tsx)
 * So add/update them, and running the "graphql-stub" script will alter stubs according to the content of these queries
 */

export const QueryGetArticles = gql`
    query GetArticles($filter: ArticleFilterInput, $pagination: PaginationArgs) {
        articles(filter: $filter, pagination: $pagination) {
            items {
                _id
                slug
                title
                teaser
                status
                publicationDate
                properties {
                    tags
                    mainPicture { _id, downloadUrl, filename, mimeType }
                }
            }
            currentPage
            totalPages
        }
    }
`;

export const QueryGetArticlesWithAuditTrail = gql`
    query GetArticlesWithAuditTrail($filter: ArticleFilterInput, $pagination: PaginationArgs) {
        articles(filter: $filter, pagination: $pagination) {
            items {
                _id
                slug
                title
                teaser
                status
                publicationDate
                properties {
                    tags
                    mainPicture { _id, downloadUrl, filename, mimeType }
                }
                auditTrail {
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                }
            }
            currentPage
            totalPages
        }
    }
`;


export const QueryGetArticleToEdit = gql`
    query GetArticleToEdit($selector: ArticleSelectorInput!) {
        article(selector: $selector) {
            _id
            slug
            title
            rawContent
            status
            publicationDate
            properties {
                tags
                mainPicture { _id, downloadUrl, filename, mimeType }
                contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl }  }
            }
            auditTrail {
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
        }
    }
`;

export const QueryGetArticleToView = gql`
    query QueryGetArticleToView($selector: ArticleSelectorInput!) {
        article(selector: $selector) {
            _id
            slug
            title
            richContent
            status
            publicationDate
            properties {
                tags
                mainPicture { _id, downloadUrl, filename, mimeType }
                contactPerson { _id, firstName, lastName, email, title, phone, specialisation, picture { downloadUrl }  }
            }
        }
    }
`;


export const QueryGetArticlesTags = gql`
    query QueryGetArticlesTags {
        articlesTags
    }
`

export const MutationCreateArticle = gql`
    mutation CreateArticle($article: ArticleCreateInput!) {
        createArticle(article: $article) {
            _id
            slug
            title
            rawContent
            status
            publicationDate
            properties {
                tags
                mainPicture { _id, downloadUrl, filename, mimeType }
                contactPerson { _id, firstName, lastName, email, title, specialisation, picture { downloadUrl }  }
            }
        }
    }
`

export const MutationUpdateArticle = gql`
    mutation UpdateArticle($_id: String!, $article: ArticleUpdateInput!) {
        updateArticle(_id: $_id, article: $article) {
            _id
            slug
            title
            rawContent
            status
            publicationDate
            properties {
                tags
                mainPicture { _id, downloadUrl, filename, mimeType }
                contactPerson { _id, firstName, lastName, email, title, specialisation, picture { downloadUrl }  }
            }
        }
    }
`