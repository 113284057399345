import Select from 'react-select';
import {ISelectItem} from "./index";
import {InputStatus} from "../common";
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { customStyles } from "./selectCustomStyle";

export interface SimpleSelectProps {
    id:string,
    items:ISelectItem[],
    disabled?:boolean,
    status?:InputStatus
    hint?:string
    selectedItemId?:string
    placeholder:string
    isClearable?:boolean
    searchable?:boolean
    name:string
    form?:string
    noOptionsMessage:string
    onChange?:(item:ISelectItem|null) => void
    onBlur?:(e: React.FocusEvent<HTMLInputElement>) => void
}

export const SimpleSelect:React.FC<SimpleSelectProps> = (props) => {
    const {id, name, form, hint, placeholder, disabled, isClearable, searchable, status, items, selectedItemId, onChange, onBlur, noOptionsMessage} = props;
    const [selectedItemIdx, setSelectedItemIdx] = useState<number>(():number => items.findIndex(i => i.id === selectedItemId));

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape" && isOpen) {
            e.stopPropagation();
        }
    }

    useEffect(() => {
        setSelectedItemIdx(items.findIndex(i => i.id === selectedItemId));
    }, [selectedItemId, items]);
    return (
        <>
            <Select<ISelectItem>
                id={id}
                name={name}
                form={form}
                className={status ? status : ''}
                value={selectedItemIdx > -1 ? items[selectedItemIdx] : null}
                placeholder={placeholder}
                isDisabled={disabled}
                isLoading={false}
                isClearable={isClearable}
                isSearchable={searchable}
                options={items}
                styles={customStyles}
                onKeyDown={handleKeyDown}
                onMenuClose={() => setIsOpen(false)}
                onMenuOpen={() => setIsOpen(true)}
                noOptionsMessage={() => noOptionsMessage}
                onChange={(newValue) => onChange && onChange(newValue)}
                onBlur={(e) => onBlur && onBlur(e)}
            />
            {(!!hint || !!status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <></>
            }
        </>
    )
}


