import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ManagerCreateEdit } from './ManagerCreateEdit';
import {
    ManagerUser,
    useMe,
    useManagerUsers,
    useUpdateManagerUserRole,
    useDeleteManager,
    useCreateManagerUser, MutationCreateManagerUserArgs,
} from '../../../model/user';
import {
    Button, ButtonType, Container,
    DataGridCell, DataGridContent, DataGridFooter,
    DataGridHeader, DataGridPagination, DataGridRow, usePagination,
    Loader, LoaderSize
} from '../../amethyst';
import { ConfirmModal } from "../../common/ConfirmModal";

export const ManagerCrud: React.FC<any> = () => {
    const { me } = useMe();
    const { t } = useTranslation();
    const [pagination, setPagination] = usePagination();
    const i18nEnums = useTranslation('enums');
    const { pageOfManagerUsers, loading } = useManagerUsers(pagination);
    const [ createManagerUser ] = useCreateManagerUser()
    const [ updateManagerUserRole ] = useUpdateManagerUserRole();
    const [ deleteManagerUser ] = useDeleteManager();

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState<boolean>(false);
    const [managerToEdit, setManagerToEdit] = useState<ManagerUser|undefined>(undefined);
    const [managerToDelete, setManagerToDelete] = useState<ManagerUser | undefined>(undefined);

    const onChangePage = (page: number) => {
        setPagination({page, pageSize:pagination.pageSize});
    }

    const onOpenEditForm = (managerUser: ManagerUser | undefined = undefined) => {
        setManagerToEdit(managerUser ? managerUser : undefined);
        setShowEditForm(true);
    }

    const onOpenDeleteConfirmModal = (managerUser: ManagerUser) => {
        setManagerToDelete(managerUser);
        setShowDeleteConfirmModal(true);
    }

    const onCreate = (input:MutationCreateManagerUserArgs):Promise<any> => createManagerUser(input, pagination);

    const onDelete = () => {
        if(managerToDelete) {
            return deleteManagerUser({_id: managerToDelete._id}, pagination)
        }
        return Promise.resolve();
    }

    return (
        <>
            <ManagerCreateEdit
                managerUserToEdit={managerToEdit}
                close={() => setShowEditForm(false)}
                show={showEditForm}
                onCreate={onCreate}
                onUpdate={updateManagerUserRole}
            />
            <ConfirmModal
               show={showDeleteConfirmModal}
               close={() => setShowDeleteConfirmModal(false)}
               title={t("ManagerManagement.ConfirmDeletion")}
               message={managerToDelete ? managerToDelete._id : ''}
               onConfirmAction={onDelete}
            />
            <Container fluid>
                <DataGridHeader title={t("ManagerManagement.Title")}>
                    <Button
                        kind={ButtonType.primary}
                        label={t("ManagerManagement.AddManagerUser")}
                        onClick={() => onOpenEditForm()}
                        disabled={loading}
                    />
                </DataGridHeader>
                { loading ? <Loader label={t("ManagerManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("ManagerManagement.Email"),
                            t("ManagerManagement.Roles")
                        ]}>
                            {
                                pageOfManagerUsers.items.map((managerUser, index) =>
                                    <DataGridRow left key={index} >
                                        <DataGridCell>{managerUser._id}</DataGridCell>
                                        <DataGridCell>
                                            {managerUser.properties.managerRoles.map(role => i18nEnums.t(`ManagerRole.${role}`)).join(', ')}
                                        </DataGridCell>
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} disabled={!!me && me._id === managerUser._id} title={t("ManagerManagement.DeleteManagerUser")} icon="trash" aria-label={t("ManagerManagement.DeleteManagerUser")} onClick={() => onOpenDeleteConfirmModal(managerUser)} />
                                            <Button kind={ButtonType.tertiary} disabled={!!me && me._id === managerUser._id} title={t("ManagerManagement.EditManagerUser")} icon="chevron-small-right" aria-label={t("ManagerManagement.EditManagerUser")} onClick={() => onOpenEditForm(managerUser)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                        <DataGridFooter>
                            <DataGridPagination disabled={loading || pageOfManagerUsers.totalPages < 2} currentPage={pagination.page} totalPages={pageOfManagerUsers.totalPages} onPageClick={onChangePage} />
                        </DataGridFooter>
                    </>
                }
            </Container>
        </>
    )
}