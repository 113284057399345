import React from "react"
import { Outlet, Link, useLocation } from "react-router-dom";

export interface IRouterTabPane {
    disabled:boolean
    label:string
    path:string
}

export interface RouterTabProps {
    prefix:string
    panes:IRouterTabPane[]
}

export const RouterTab:React.FC<RouterTabProps> = ({prefix, panes}) => {
    const { pathname } = useLocation();
    return (
        <div>
            <nav>
                <ul className="nav nav-tabs">
                    {panes.map((p, idx) =>
                        <li key={idx}>
                            <Link className={`${p.disabled ? 'disabled' : ''} ${ pathname.startsWith(`${prefix}/${p.path}`) ? 'active': ''}`} to={p.path}>{p.label}</Link>
                        </li>
                    )}
                </ul>
            </nav>
            <Outlet />
        </div>
    )
}
