import React from "react";
import { Icon } from '../icon';

export interface NavBarFooterItemProps { label:string }
export interface NavBarFooterItemLinkProps extends NavBarFooterItemProps { href:string, external:boolean }
export interface NavBarFooterItemLinkIconsProps {
    icons:{icon:string, label?:string, href:string, external?:boolean}[]
}

export const NavBarFooterItem:React.FC<NavBarFooterItemProps> = ({label}) => {
    return <li className="pb-1" style={{lineHeight:"16px"}}>
        <span className="navbar-footer-nav-label">{label}</span>
    </li>
}

export const NavBarFooterItemLink:React.FC<NavBarFooterItemLinkProps> = ({label, href, external}) => {
    if(external) {
        return <li className="pb-2">
            <a target="_blank" rel="noreferrer" href={href}>{label}</a>
        </li>
    }

    return <li className="pb-2">
        <a href={href}>{label}</a>
    </li>
}

export const NavBarFooterItemIcons:React.FC<NavBarFooterItemLinkIconsProps> = ({icons}) => {
    return (
        <li className="row pb-3">
            {icons.map(({icon, label, href, external}, idx) =>
                <a key={idx} href={href} target={external ? '_blank' : '_self'} rel="noreferrer">
                    <span className="navbar-nav-icon">
                        <Icon block icon={icon} label={label} />
                    </span>
                </a>
            )}
        </li>
    )
}


export interface NavBarFooterProps {
    children:
        React.ReactElement<typeof NavBarFooterItem| typeof NavBarFooterItemLink |typeof NavBarFooterItemIcons> |
        React.ReactElement<typeof NavBarFooterItem| typeof NavBarFooterItemLink |typeof NavBarFooterItemIcons>[]
}

export const NavBarFooter:React.FC<NavBarFooterProps> = ({children}) => {
    return (
        <nav className="navbar-footer">
            <ul className="navbar-footer-nav">
                {children}
            </ul>
        </nav>
    )
}
