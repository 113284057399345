import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button, ButtonType,
    Modal, ModalAction, ModalContent
} from "../amethyst";

export interface ConfirmModalProps {
    show:boolean,
    close:() => void
    title:string
    message:string
    onConfirmAction:() => Promise<any>
}

export const ConfirmModal:React.FC<ConfirmModalProps> = ({show, onConfirmAction, close, title, message}) => {
    const [ongoing, setOngoing] = useState<boolean>(false);
    const { t } = useTranslation();

    const onConfirm = async () => {
        setOngoing(true);
        await onConfirmAction();
        setOngoing(false);
        close();
    }

    if(!show) {
        return null
    } else {
        return (
            <Modal open={show} title={title} onClose={() => close()} closeOnEscape>
                <ModalContent>
                    <p>{ message }</p>
                </ModalContent>
                <ModalAction>
                    <Button
                        disabled={ongoing}
                        onClick={() => onConfirm()}
                        label={ongoing ? t("Actions.WorkingOn") : t("Actions.Confirm")}
                        kind={ButtonType.primary}
                    />
                    <Button
                        disabled={ongoing}
                        onClick={() => { close()}}
                        label={t("Actions.Cancel")}
                        kind={ButtonType.tertiary}
                    />
                </ModalAction>
            </Modal>
        )
    }
}