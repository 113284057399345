import { BlobImage, BlobImageInput } from '../model/blob';
import { secureFileUpload } from '../service/api/api';
import { IImageSelector } from './amethyst';
import { AxiosProgressEvent } from 'axios';

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const capitalize = (word:string):string => word[0].toUpperCase() + word.substring(1).toLowerCase();

export const validateEmail = (email:string) => {
    return String(email)
        .toLowerCase()
        .match(emailRegex);
};

export const richContentLenght = (editorContent: string|undefined):number => {
    if(editorContent) {
        try {
            const richContent = JSON.parse(editorContent);
            return richContent.blocks ? richContent.blocks.length : 0;
        } catch (e) {
            console.log(e);
            return 0;
        }
    }
    return 0;
}

// Yup and Formik issues :
// - each field is validated, when any field change
// - a field validation does not stop at first test failure
// So to avoid calling validation API for each non-related field, we need to:
// - cache previous field value to detect any field change
// - to trigger validation check only when this field has changed
// /!\ MUST BE USED WITHIN A React REF !! /!\
// -------------------------------------------
export const cacheTest = (asyncValidate:(value: any) => Promise<boolean>, setFetching: ((state:boolean) => void)|undefined = undefined):(val:any) => Promise<boolean> => {
    let _valid = false;
    let _value = '';

    return async (value:any) => {
        if (value !== _value) {
            if(!!setFetching) setFetching(true);
            const response = await asyncValidate(value);
            if(!!setFetching) setFetching(false);
            _value = value;
            _valid = response;
            return response;
        }
        return _valid;
    };
};

/**
 * Build a BlobImageInput object based on a IImageSelector and a current BlobImage
 * Can report image upload status to a callback
 * @param selectedImage
 * @param currentBlob
 * @param progressCallback
 */
export const buildBlobValue = async (selectedImage:IImageSelector, currentBlob?:BlobImage|null, progressCallback?: (progressEvent: AxiosProgressEvent) => void):Promise<BlobImageInput|null> => {
    if(selectedImage.noImage)  { // no blob
        return null;
    } else {
        if(selectedImage.newImageFile !== null) { // new blob
            // api call to upload logo file
            const callBack = progressCallback ? progressCallback : () => {}
            const savedFiles = await secureFileUpload([selectedImage.newImageFile], callBack);
            if(savedFiles.length > 0) {
                return {
                    _id:savedFiles[0].id,
                    filename:savedFiles[0].filename,
                    mimeType:savedFiles[0].mimeType
                }
            }
            return null; //TODO: manage errors
        } else if(!!currentBlob) { // we keep the same blob
            // important to avoid sending downloadUrl attribute that is not expected by the API
            return {
                _id:currentBlob._id,
                filename:currentBlob.filename,
                mimeType:currentBlob.mimeType
            }
        } else return null;
    }
}

export type UploadImageType = {
    blobId:string,
    url:string,
    filename:string
    mimeType:string
}

export const uploadContentImage = async (file: File, progressCallback?: (progressEvent: AxiosProgressEvent) => void):Promise<UploadImageType|null> => {
    const callBack = progressCallback ? progressCallback : () => {}
    const savedFiles = await secureFileUpload([file], callBack);
    if(savedFiles.length > 0) {
        return {
            blobId:savedFiles[0].id,
            filename:savedFiles[0].filename,
            mimeType:savedFiles[0].mimeType,
            url:savedFiles[0].url
        }
    } else return null;
}