import React from "react";

export interface SimpleTagProps {
    label:string
}

export const SimpleTag:React.FC<SimpleTagProps> = ({label}) => {
    return (
        <div className="tag-sm">
            <span className="tag-sm-container">
                <span className="tag-sm-content">{label}
                    <span className="tag-sm-icon"></span>
                </span>
            </span>
        </div>
    )
}