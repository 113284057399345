import React, { useMemo } from 'react';
import * as Yup from "yup";
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from "react-i18next";
import {
    Container,
    FieldTextInput,
    FieldDatePicker,
    Form, FormAction,
    FormGroup,
    Modal, ModalContent,
} from '../../amethyst';
import {
    MutationCreateNotificationArgs,
    MutationUpdateNotificationArgs,
    Notification,
} from '../../../model/notification';
import { parseISO } from 'date-fns';

export interface NotificationCreateEditProps {
    notificationToEdit:Notification|undefined,
    onCreate:(newNotification:MutationCreateNotificationArgs) => Promise<any>,
    onUpdate:(notificationToUpdate:MutationUpdateNotificationArgs) => Promise<any>
    show:boolean,
    close:() => void
}

interface NotificationFormType {
    description:string
    expirationDate:Date
}

const defaultValues:NotificationFormType = {
    description:"",
    expirationDate:new Date()
}


export const NotificationCreateEdit:React.FC<NotificationCreateEditProps> = ({notificationToEdit, onCreate, onUpdate, close, show}) => {
    const { t } = useTranslation();

    const formValidationSchema =  Yup.object().shape({
        description: Yup.string().min(5).max(150).required(),
        expirationDate: Yup.date().typeError(t("FormValidation.InvalidDate")).label(t("NotificationManagement.ExpirationDate")).required(t("FormValidation.Required")),
    });

    const initialValues:NotificationFormType = useMemo(() => {
        if(notificationToEdit) {
            return {
                description:notificationToEdit.description,
                expirationDate:parseISO(notificationToEdit.expirationDate)
            }
        }
        return defaultValues;
    }, [notificationToEdit]);

    const onSubmit = async (values: NotificationFormType, { resetForm }: FormikHelpers<NotificationFormType>) => {
        if(notificationToEdit) {
            await onUpdate({
                _id:notificationToEdit._id,
                notification: { description: values.description, expirationDate: values.expirationDate }
            });
        } else {
            await onCreate({
                notification: { description: values.description, expirationDate: values.expirationDate }
            });
        }

        resetForm({ values: defaultValues });
        close();
    }

    if(!show) return null;

    return (
        <Modal open={show} title={!notificationToEdit ? t("NotificationManagement.Create"): t("NotificationManagement.Edit")} onClose={() => close()} closeOnEscape>
            <ModalContent>
                <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                    <Container fluid>
                        <Form id="NotificationForm">
                            <FormGroup>
                                <FieldTextInput
                                    label={t("NotificationManagement.Description")}
                                    form="NotificationForm"
                                    id="description"
                                    name="description"
                                    placeholder={t("NotificationManagement.DescriptionPlaceholder")}
                                />
                                <FieldDatePicker
                                    id="expirationDate"
                                    name="expirationDate"
                                    label={t("NotificationManagement.ExpirationDate")}
                                    placeholder={t("NotificationManagement.ExpirationDatePlaceholder")}
                                    minDate={new Date()}
                                />
                            </FormGroup>
                            <FormAction
                                submittingLabel={t("Actions.Saving")}
                                submitLabel={t("Actions.Save")}
                                resetLabel={t("Actions.Cancel")}
                            />
                        </Form>
                    </Container>
                </Formik>
            </ModalContent>
        </Modal>
    )
}
