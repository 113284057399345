import React, {useEffect, useMemo, useRef} from 'react'
import { ModalAction } from "./ModalAction";
import { ModalContent } from "./ModalContent";
import { Icon } from "../icon";

import "./modal.scss"
import { useTranslation } from 'react-i18next';

export interface ModalProps {
    open:boolean
    onClose:() => void
    title?:string|null
    closeOnEscape?:boolean
    closeOnDimmerClick?:boolean
    children:
        React.ReactElement<typeof ModalContent>|
        [React.ReactElement<typeof ModalContent>, React.ReactElement<typeof ModalAction>]
}

export const Modal:React.FC<ModalProps> = ({open, title, onClose, closeOnEscape = false, closeOnDimmerClick = false, children}) => {
    const { t } = useTranslation('amethyst');
    const backDropRef = useRef<HTMLDivElement>(null);
    const inlineStyle = useMemo(() => {
        return { display:`${open ? 'block' : 'none'}`}
    }, [open]);

    /**
     * Callback function to close modal on dimmer click
     * @param e
     */
    const onCloseOnDimmerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        //TODO : How to manage outside click, when happening on element present in the modal but larger
        // than the modal (ie. select list this is longer than the modal height
        if(closeOnDimmerClick) {
            const el = backDropRef?.current
            if (!el || el === e.target) {
                onClose();
            }
        }
    }

    /**
     * Register/unregister listener with callback function to close modal on Esc key pressed
     */
    useEffect(() => {
        const keyDownHandler = (event:KeyboardEvent) => {
            if (closeOnEscape && open && event.key === 'Escape') {
                event.preventDefault();
                onClose();
            }
        };
        if(open) {  // register only is modal is open
            document.body.addEventListener('keydown', keyDownHandler);
        }
        return () => {
            document.body.removeEventListener('keydown', keyDownHandler)
        }
    }, [open, onClose, closeOnEscape]);


    if(!open) return null;

    return (
        <>
            <div className={'modal-backdrop show'} style={inlineStyle}  />
            <div ref={backDropRef} className={'modal fade show'} style={inlineStyle} onClick={(e) => onCloseOnDimmerClick(e)}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button className="link modal-header-close" onClick={() => onClose()}>
                                <Icon label={t("Modal.Close")} icon="close"/>
                            </button>
                            {title ? <h2 className="modal-header-title">{title}</h2> : null}
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}