import React from 'react';
import { useOutsideClick } from "../useOutsideClick";
import { NavBarTopBar } from "./NavBarTopBar";
import { NavBarMenu } from "./NavBarMenu";
import "./navbar.scss"

export interface NavBarProps {
    closeMenu:() => void
    children: React.ReactElement<typeof NavBarTopBar | typeof NavBarMenu> |
        React.ReactElement<typeof NavBarTopBar | typeof NavBarMenu>[]
}

export const NavBar:React.FC<NavBarProps> = ({children, closeMenu}) => {
    const menuRef = useOutsideClick<HTMLElement>(() => {
        closeMenu();
    });

    return (
        <header ref={menuRef} className="navbar">
            {children}
        </header>
    )
}
