import React from "react";
import { Icon } from "../icon"

export interface NavBarBrandProps {
    logo:string
    logoAlt:string
    title:string
    url:string
}

export const NavBarBrand:React.FC<NavBarBrandProps> = ({logo, logoAlt, title, url }) => {
    return (
        <div className="navbar-top-bar-brand">
            <a href={url} title={title}>
                <img alt={logoAlt} className="navbar-top-bar-brand-img" src={logo} />
            </a>
        </div>
    )
}

export interface NavBarBrandWithLabelProps {
    title:string,
    icon:string
}

export const NavBarBrandWithLabel:React.FC<NavBarBrandWithLabelProps> = ({title, icon}) => {
    return (
        <div className="navbar-top-bar-brand">
            <div className="navbar-top-bar-brand-label">
                <span className="navbar-nav-icon">
                    <Icon icon={icon} />
                </span>
                <span>&nbsp;{title}</span>
            </div>
        </div>
    )
}
