import React from "react";
import {InputStatus} from "../../common";
import {useField} from "formik";
import {Checkbox} from "../../checkbox";

export interface FieldCheckboxProps {
    id:string
    name:string
    title?:string
    label:string
    form:string
    disabled?:boolean
}

export const FieldCheckbox:React.FC<FieldCheckboxProps> = ({ id, title, name, label, form , disabled}) => {
    const [field, meta, helpers] = useField(name);

    return (
        <Checkbox
            id={id}
            title={title}
            name={name}
            label={label}
            form={form}
            status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
            onBlur={field.onBlur}
            onChange={(e) => helpers.setValue(e.target.checked, true)}
            checked={field.value}
            disabled={disabled}
        />
    )
}

