import React, {useCallback, useMemo, useRef, useState} from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {FieldHelperProps, Formik, FormikHelpers} from "formik";
import { debounce } from "lodash";
import { ManagerRole, ManagerUser, MutationCreateManagerUserArgs,
    MutationUpdateManagerUserRoleArgs, useIsEmailAvailable } from "../../../model/user";
import { cacheTest, validateEmail } from "../../utils";
import {
    Container, FieldFormMultipleSelect,
    FieldTextInput,
    Form, FormAction,
    FormGroup,
    ISelectItem, Modal, ModalContent,
} from '../../amethyst';

interface ManagerUserFormType {
    email: string,
    roles: ISelectItem[]
}

const defaultValues:ManagerUserFormType = {
    email: '',
    roles: []
}

const editFormValidationSchema = Yup.object().shape({
    role: Yup.object()
});

export interface ManagerUserEditProps {
    managerUserToEdit:ManagerUser|undefined
    onCreate: (input:MutationCreateManagerUserArgs) => Promise<any>
    onUpdate: (input:MutationUpdateManagerUserRoleArgs) => Promise<any>
    show:boolean,
    close:() => void
}

export const ManagerCreateEdit: React.FC<ManagerUserEditProps> = ({ managerUserToEdit, onCreate, onUpdate, show, close }) => {
    const [validating, setValidating] = useState<boolean>(false);
    const [isEmailAvailable] = useIsEmailAvailable();
    
    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');

    const testUniqueEmail = useRef(cacheTest(isEmailAvailable, setValidating));
    const testUniqueEmailFn = (arg:string|undefined) => !!arg && !!validateEmail(arg) && testUniqueEmail.current(arg).then(r => !r);
    const creationFormValidationSchema = useMemo(() => {
        return Yup.object().shape({
            email: Yup.string()
                .label('Email')
                .test('email-unique', t("ManagerManagement.EmailError"), testUniqueEmailFn)
                .required(t("FormValidation.Required")),
            role: Yup.object()
        });
    }, [t]);

    const managerRolesItems: ISelectItem[] = Object.values(ManagerRole).map((name, index) => {
        return {
            id: name,
            label: i18nEnums.t(`ManagerRole.${name}`),
            value: name
        }
    });

    const initialValues = useMemo<ManagerUserFormType>(() => {
        if(!!managerUserToEdit) {
            return {
                email: managerUserToEdit._id,
                roles: managerUserToEdit.properties.managerRoles.map(r => ({ id: r, label:r, value: r}))
            }
        }
        return defaultValues;
    }, [managerUserToEdit]);

    const onSubmit = async (values: ManagerUserFormType, { resetForm }: FormikHelpers<ManagerUserFormType>): Promise<void> => {
        const userRoles = values.roles.map(r => r.id) as ManagerRole[];

        if (managerUserToEdit) {
            const managerUserToUpdate = { roles: userRoles, _id: managerUserToEdit._id }
            await onUpdate(managerUserToUpdate);

        } else {
            const managerUserToAdd = { managerUser: { email: values.email, roles:userRoles } }
            await onCreate(managerUserToAdd);

        }
        resetForm({ values: defaultValues });
        close();
    }

    const debouncedSearch = useMemo(() => debounce((value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, true);
    }, 500, { trailing:true, leading:false}), [])

    const debouncedEmailValidation = useCallback(debouncedSearch, [debouncedSearch]);

    // To avoid calling API at each key input, we debounced each validation call :
    // - we update field value instantly but without validation
    // - we use debounce method to trigger validation
    const onChangeEmail = (value:string, helpers: FieldHelperProps<string>) => {
        helpers.setValue(value, false);
        debouncedEmailValidation(value, helpers);
    }

    if(!show) return null

    const editMode = !!managerUserToEdit;
    return (
        <Modal open={show} title={!editMode ? t("ManagerManagement.AddManagerUser") : `${t("ManagerManagement.UpdateManagerUser", { name: managerUserToEdit?._id })}`} onClose={() => close()} closeOnEscape>
            <ModalContent>
                <Formik initialValues={initialValues} validationSchema={editMode ? editFormValidationSchema : creationFormValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                    <Container fluid>
                        <Form id="ManagerUserForm">
                            {!editMode &&
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("ManagerManagement.Email")}
                                        form="ManagerUserForm"
                                        id="email"
                                        name="email"
                                        placeholder={t("ManagerManagement.EmailPlaceholder")}
                                        customValidation={onChangeEmail}
                                        loading={validating}
                                    />
                                </FormGroup>
                            }
                            <FormGroup>
                                <FieldFormMultipleSelect
                                    id="roles"
                                    items={managerRolesItems}
                                    placeholder={t("ManagerManagement.RolesPlaceholder")}
                                    name="roles"
                                    form="ManagerUserForm"
                                    label={t("ManagerManagement.Roles")}
                                    noOptionsMessage=""
                                />
                            </FormGroup>
                            <FormAction
                                submittingLabel={t("Actions.Saving")}
                                submitLabel={t("Actions.Save")}
                                resetLabel={t("Actions.Cancel")}
                            />
                        </Form>
                    </Container>
                </Formik>
            </ModalContent>
        </Modal>
    )
}
