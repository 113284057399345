/**
 * Pub-sub like dispatcher
 */
export class Dispatcher<TAction> {
    // We only need a single subscriber, which is just a callback accepting an action
    private subscriber: ((value: TAction) => void) | undefined;

    public dispatch(action: TAction): void {
        if (this.subscriber) {
            // Upon dispatch, we simply call the subscriber...
            this.subscriber(action);
        }
    }

    public subscribe(subscriber: (value: TAction) => void): () => void {
        this.subscriber = subscriber;

        // We return a callback to enable unsubscribing
        return () => {
            this.subscriber = undefined;
        };
    }
}