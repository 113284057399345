import React, { useState, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { useTranslation } from "react-i18next";
import ImageUploading, { ImageListType, ErrorsType } from "react-images-uploading";
import Cropper, { Area, Point } from 'react-easy-crop';
import { Button, ButtonType, ButtonGroup, Icon, InputStatus } from "../../index";
import { getCroppedImg } from "./imageCrop";
import "./cropper.scss";
import { ImageType } from 'react-images-uploading/dist/typings';

export enum ImagePreviewRatio {
    square,
    oneThird,
    oneAndHalf,
    panoramic
}

interface IRatio {
    factor: number
    class:string
}

const ratioProps = (ratio: ImagePreviewRatio):IRatio => {
    switch (ratio) {
        case(ImagePreviewRatio.oneAndHalf):
            return { class:"one-and-half", factor:1.5 }
        case(ImagePreviewRatio.panoramic):
            return { class:"panoramic", factor:1.7777 }
        case(ImagePreviewRatio.square):
            return { class:"square", factor:1 }
        case(ImagePreviewRatio.oneThird):
        default:
            return { class:"one-third", factor:1.3333 }
    }
}

export interface ImageUploadProps {
    id:string
    name:string
    label?:string
    hint?:string
    fileFormat:string[]
    maxFileSize:number
    resolutionType?:'absolute' | 'less' | 'more' | 'ratio'
    resolutionWidth?:number
    resolutionHeight?:number
    disabled?:boolean
    resolutionErrorLabel:string,
    acceptTypeErrorLabel:string,
    maxFileSizeErrorLabel:string
    ratio?:ImagePreviewRatio,
    allowCropping?:boolean
}

export interface IImageSelector {
    currentImageUrl:string|null
    newImageFile:File|null
    noImage:boolean
}

interface ImagePreviewProps {
    image:ImageType
    onChangeImage:(index:number) => void
    onRemoveImage:(index:number) => void
    onEdit?:(imageType: ImageType) => void
    originalImageUrl:string|undefined
    onReset:() => void
    disabled?:boolean
    allowCropping?:boolean
    ratio:IRatio
    maxWidth?:number
}

const ImagePreview:React.FC<ImagePreviewProps> = ({image, onChangeImage, onRemoveImage, onReset, disabled, allowCropping, onEdit, ratio, originalImageUrl, maxWidth}) => {
    const { t } = useTranslation('amethyst');
    const [cropMode, setCropMode ] = useState<boolean>(false);

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedArea, setCroppedArea] = useState<Area|null>(null);
    const [imageUrl, setImageUrl] = useState<string|undefined>(image.dataURL);
    const [unCroppedImage, setUnCroppedImage] = useState<ImageType|null>(null);
    const [edited, setEdited] = useState<boolean>(false);

    // refresh imageUrl state to display the correct image
    useEffect(() => {
        setImageUrl(image.dataURL);
    }, [image]);

    // refresh "edited" boolean to know if the input image has been changed/edited
    useEffect(() => {
        setEdited(imageUrl !== originalImageUrl );
    }, [imageUrl, originalImageUrl])

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }, []);

    // Enable crop mode
    const onStartCrop = () => {
        setCropMode(true);
    }

    // Disable crop mode
    const onCancelCrop = () => {
        setCropMode(false);
    }

    // Reset cropping on the edited image
    const onResetCrop = () => {
        if(unCroppedImage) {
            setImageUrl(unCroppedImage.dataURL);
            onEdit && onEdit(unCroppedImage);
            setUnCroppedImage(null);
        }
    }

    // Ask parent component to restore original image
    const onRestoreImage = () => {
        setUnCroppedImage(null);
        onReset();
    }

    // Save a cropped image and send it back to parent component
    const onSaveCrop = async () => {
        if(croppedArea && image.dataURL) {
            setUnCroppedImage(image);
            const croppedImg = await getCroppedImg(image.dataURL, croppedArea);
            setCropMode(false);
            if(croppedImg) {
                setCropMode(false);
                setImageUrl(croppedImg.dataURL);
                onEdit && onEdit(croppedImg);
            }
        } else {
            setCropMode(false);
            setUnCroppedImage(null);
        }
    }

    return (
        <div className="file-uploader">
            <div className="file-uploader-dnd">
                {cropMode ?
                    <div className={`cropper-container ${ratio.class}`} style={{maxWidth:`${maxWidth||800}px`}}>
                        <div className="cropper-wrapper">
                            <Cropper
                                image={imageUrl}
                                onCropChange={setCrop}
                                crop={crop}
                                zoom={zoom}
                                onZoomChange={setZoom}
                                aspect={ratio.factor}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                    </div>:
                    <img className={`image-cropped-upload-preview ${ratio.class}`} style={{maxWidth:`${maxWidth||800}px`}} src={imageUrl} alt="logo" />
                }
                <ButtonGroup center>
                    {cropMode ?
                        <>
                            <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onSaveCrop() }} kind={ButtonType.secondary} label={t("ImageUpload.SaveCrop")} />
                            <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onCancelCrop() }} kind={ButtonType.secondary} label={t("ImageUpload.CancelCrop")} />
                        </>:
                        <>
                            {(allowCropping && !unCroppedImage) ? <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onStartCrop() }} kind={ButtonType.secondary} label={t("ImageUpload.Crop")} />: null }
                            { edited && unCroppedImage ? <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onResetCrop() }} kind={ButtonType.secondary} label={t("ImageUpload.ResetCrop")} />: null}
                            <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onChangeImage(0) }} kind={ButtonType.secondary} label={t("ImageUpload.Change")} />
                            <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onRemoveImage(0) }} kind={ButtonType.secondary} label={t("ImageUpload.Remove")} />
                            { originalImageUrl && edited ? <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onRestoreImage() }} kind={ButtonType.secondary} label={t("ImageUpload.Restore")} /> : <></>}
                        </>
                    }
                </ButtonGroup>
            </div>
        </div>
    )
}

const DnD:React.FC<any> = ({id, dragProps, isDragging, onImageUpload, currentImageUrl, onReset, disabled}) => {
    const { t } = useTranslation('amethyst');
    return (
        <div id={id} className="file-uploader" {...dragProps}>
            <div className="file-uploader-dnd" style={{backgroundColor:isDragging ? 'rgba(231,227,227,0.25)': 'inherit'}}>
                { !disabled ?
                    <>
                        <span className="file-uploader-dnd-icon">
                            <Icon icon="upload-2" block />
                        </span>
                        <span className="file-uploader-dnd-label">
                            {t("ImageUpload.DragAndDropImage")}
                        </span>
                        <ButtonGroup>
                            <Button disabled={disabled} onClick={(e) => {e.preventDefault(); onImageUpload() }} kind={ButtonType.secondary} label={t("ImageUpload.BrowseImage")} />
                            {currentImageUrl ? <Button onClick={(e) => {e.preventDefault(); onReset() }} kind={ButtonType.secondary} label={t("ImageUpload.Restore")} /> : <></>}
                        </ButtonGroup>
                    </>
                    : null }

            </div>
        </div>
    )
}

export const FieldImageUpload:React.FC<ImageUploadProps> = (
{
    id,
    name,
    label,
    hint,
    fileFormat,
    maxFileSize,
    resolutionType,
    resolutionWidth,
    resolutionHeight,
    disabled,
    resolutionErrorLabel,
    acceptTypeErrorLabel,
    maxFileSizeErrorLabel,
    allowCropping,
    ratio = ImagePreviewRatio.oneThird
}) => {
    const [field, , { setValue }] = useField<IImageSelector>(name)
    const [dataUrl, setDataUrl] = useState<string|undefined>(undefined);
    const [error, setError] = useState<string|undefined>(undefined);

    const noImage = field.value.noImage || !dataUrl || !field.value.currentImageUrl

    const buildImageType = () => {
        if(!field.value.noImage) {
            if(!!dataUrl) {
                return [{
                    dataURL:dataUrl,
                    file:(field.value.newImageFile !== null) ? field.value.newImageFile : undefined
                }]
            }
            if(!!field.value.currentImageUrl ) {
                return [{
                    dataURL:field.value.currentImageUrl,
                    file:undefined
                }]
            }
        }
        return []
    }

    const [originalImage] = useState<ImageType|undefined>(buildImageType()[0]);

    const onReset = () => {
        let value:IImageSelector;
        if(originalImage) {
            value = { currentImageUrl:originalImage.dataURL || null, newImageFile:null, noImage:originalImage.dataURL === null }
        } else {
            value = { currentImageUrl:null, newImageFile:null, noImage:true}
        }
        setValue(value, true);
        setDataUrl(undefined);
    }

    const onChangeImages = (newImages: ImageListType) => {
        setError(undefined);
        if(newImages.length > 0) {
            setValue({
                currentImageUrl:field.value.currentImageUrl, newImageFile:newImages[0].file || null, noImage:newImages[0].file === null
            });
            setDataUrl(newImages[0].dataURL);
        } else {
            setValue({
                currentImageUrl:field.value.currentImageUrl, newImageFile:null, noImage:true
            });
            setDataUrl(undefined);
        }
    }

    const onEdit = (editedImage: ImageType) => {
        if(editedImage.dataURL && editedImage.file) {
            setValue({ currentImageUrl:editedImage.dataURL, newImageFile:editedImage.file, noImage:false });
            setDataUrl(editedImage.dataURL);
        }
    }

    const onError = (errors: ErrorsType) => {
        const  r = errors && errors.resolution ? resolutionErrorLabel : ''
        const  f = errors && errors.acceptType ? acceptTypeErrorLabel : ''
        const  s = errors && errors.maxFileSize ? maxFileSizeErrorLabel : ''
        const errMsg = `Errors: ${r}${f}${s}`;
        setError(errMsg);
    }

    return (
        <div className="form-input">
            {label ? <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label}</label> : null}
            <ImageUploading
                allowNonImageType={false}
                value={buildImageType()}
                multiple={false}
                onError={onError}
                onChange={onChangeImages}
                acceptType={fileFormat}
                resolutionType={resolutionType}
                resolutionWidth={resolutionWidth}
                resolutionHeight={resolutionHeight}
                maxFileSize={maxFileSize}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    (imageList.length > 0) ?
                        <ImagePreview maxWidth={resolutionWidth} disabled={disabled} image={imageList[0]} onChangeImage={onImageUpdate} onRemoveImage={onImageRemove} originalImageUrl={originalImage?.dataURL} onReset={onReset} allowCropping={allowCropping && !!dataUrl} onEdit={onEdit} ratio={ratioProps(ratio)} /> :
                        <DnD id={id} disabled={disabled} dragProps={disabled ? {} : dragProps} isDragging={isDragging} onImageUpload={onImageUpload} currentImageUrl={field.value.currentImageUrl} onReset={onReset} />
                )}
            </ImageUploading>

            {((hint && noImage) || error) ?
                <span className={`form-feedback ${error ? InputStatus.Invalid : ''}`} id={`${id}-hint`}>
                    {error ?
                        <><span>{error}</span><br/></>: null
                    }
                    {hint ?  <span>{hint}</span> : null}
                </span> :
                null
            }
        </div>
    )
}
