import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { AgendaEntryType, useLatestAgendaEntries } from '../../model/agendaEntry';
import { useCurrentCustomer } from '../customer/view/customerContext';
import {
    Button,
    ButtonType,
    DataGridFooter, DataGridPagination,
    Divider, Grid, GridColumn, GridRow, Icon,
    Loader, LoaderSize, usePagination
} from '../amethyst';
import { ContentNotFound } from '../error/ContentNotFound';
import { printDate } from '../../i18n';
import "./agendaPage.scss";

export const AgendaPage:React.FC = () => {
    const {i18n, t } = useTranslation();
    const [pagination, setPagination] = usePagination();
    const { currentCustomerId } = useCurrentCustomer();
    const { pageOfAgendaEntries, loading, error } = useLatestAgendaEntries(pagination.page, pagination.pageSize, currentCustomerId);

    const onChangePage = (page: number) => {
        setPagination({page, pageSize:pagination.pageSize});
    }

    const onOpenUrl = (url: string|undefined) => {
        if (!!url) {
            window.open(url, '_blank', 'noreferrer');
        }
    }

    if(!!error) {
        return <ContentNotFound />
    }

    return (
        <div className="agenda-page">
            <h3 className="mb-0 mt-3"><Icon icon="calendar" /> {t("Agenda.Title")}</h3>
            <Divider dark narrow />

            {loading ? <Loader size={LoaderSize.medium} label={t("Agenda.Loading")} /> :
                <>
                    <Grid className="agenda-items-list">
                        <>
                            {pageOfAgendaEntries.items.map((agendaEntry, idx) =>
                                <div className="mb-5" key={idx}>
                                    <GridRow>
                                        <GridColumn defaultSize={2}>
                                            <div className="agenda-item-publication-date text-center">
                                                <p className="agenda-item-publication-day mb-0">
                                                    {printDate(parseISO(agendaEntry.date), i18n.language, "dd")}<br/>
                                                    {printDate(parseISO(agendaEntry.date), i18n.language, "MMM.")}
                                                </p>
                                                <p>{printDate(parseISO(agendaEntry.date), i18n.language, "yyyy")}</p>
                                            </div>
                                        </GridColumn>
                                        <GridColumn defaultSize={10}>
                                            <GridRow>
                                                <div className="mb-2">
                                                    <GridColumn defaultSize={12}>
                                                        <Link to={`/agenda/${agendaEntry._id}`} className="link agenda-item-link" >
                                                            <span className="agenda-item-title">{agendaEntry.title}</span>
                                                        </Link>
                                                    </GridColumn>
                                                </div>
                                            </GridRow>
                                            <GridRow>
                                                <GridColumn defaultSize={12}>
                                                    <GridRow>
                                                        <GridColumn>
                                                            <div className="agenda-item-description">
                                                                <p className="agenda-item-abstract font-normal mb-0" dangerouslySetInnerHTML={{ __html: agendaEntry.properties.teaser }} />
                                                                {(agendaEntry.type === AgendaEntryType.Event && !!agendaEntry.properties.cta.link && agendaEntry.properties.cta.link.url.length > 0) &&
                                                                    <Button kind={ButtonType.secondary} label={agendaEntry.properties.cta.link.label} onClick={() => onOpenUrl(agendaEntry.properties.cta.link?.url)} />
                                                                }
                                                                {agendaEntry.tags.length > 0 &&
                                                                    <p className="agenda-item-tags mb-0 mt-2">
                                                                        { agendaEntry.tags.join(', ') }
                                                                    </p>
                                                                }
                                                            </div>
                                                        </GridColumn>
                                                    </GridRow>
                                                </GridColumn>
                                            </GridRow>
                                        </GridColumn>
                                    </GridRow>
                                </div>
                            )}
                        </>
                    </Grid>
                    <DataGridFooter>
                        <DataGridPagination disabled={loading || pageOfAgendaEntries.totalPages < 2} currentPage={pagination.page} totalPages={pageOfAgendaEntries.totalPages} onPageClick={onChangePage} />
                    </DataGridFooter>
                </>
            }
        </div>
    )
}