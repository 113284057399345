import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { BuildEditorTools, initUndo } from './editorTools';
import './editor.scss';

export type IEditorImageUploadResult = {
    success:boolean;
    file?:{
        url:string
        blobId:string
    }
}
export type EditorImageUploader = (file: File) => Promise<IEditorImageUploadResult>

interface EditorProps {
    data?: OutputData;
    onChange(val: OutputData): void;
    holder: string;
    placeholder:string
    readonly?:boolean
    imageUploader:EditorImageUploader
    imageUploaderCaption:string
}

/**
 * Define the exposed API handlers, to be used through a forwarded ref
 */
export type EditorApiRef = {
    clear:() => void
    render:(data:OutputData|undefined) => void
};

/**
 * EditorJs React wrapper
 * with support of direct API access through forwarded ref and handles
 */
const Editor = forwardRef<EditorApiRef, EditorProps>((props, editorApiRef) => {
    //add a reference to editor
    const {data, holder, onChange, placeholder, readonly, imageUploader, imageUploaderCaption} = props;
    const ref = useRef<EditorJS>();

    /**
     * Initialize the editor, and register a callback for destroy (useEffect lifecycle)
     */
    useEffect(() => {
        //initialize editor if we don't have a reference
        if (!ref.current) {
            const editor = new EditorJS({
                onReady() {
                    initUndo(editor, data);
                },
                holder: holder,
                tools: BuildEditorTools(imageUploader, imageUploaderCaption),
                placeholder,
                readOnly:readonly,
                inlineToolbar: ['bold', 'italic', 'link'],
                data,
                async onChange(api, event) {
                    const data = await api.saver.save();
                    onChange(data);
                },
                hideToolbar: false,
            });
            ref.current = editor;
        }

        //add a return function handle cleanup
        return () => {
            if (ref.current && ref.current.destroy) {
                ref.current.destroy();
            }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Exposed API handlers
     */
    useImperativeHandle(editorApiRef, () => ({
        clear:() => {
            if(!!ref.current) {
                ref.current.clear();
            }
        },
        render:(data: OutputData|undefined) => {
            if(!!ref.current) {
                if(!!data) {
                    ref.current.render(data);
                } else {
                    ref.current.clear();
                }
            }
        }
    }));

    return <div className="content-editor" id={holder} />;
});

export default memo(Editor);
