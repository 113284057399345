import React from "react";
import {Navigate, Outlet, useOutletContext} from 'react-router-dom';
import { useMe, UserType, ManagerRole } from "../../model/user";

export interface ProtectedRouteProps {
    allowedType:UserType[]
    allowedManagerRoles?:ManagerRole[]
}

type ContextManagerRole = { managerRoles: ManagerRole[], locale:string };

export const useContextManagerRole = () => {
    return useOutletContext<ContextManagerRole>();
}

export const ProtectedRoute:React.FC<ProtectedRouteProps> = ({allowedManagerRoles, allowedType}) => {
    const { me, loading } = useMe();

    if(loading) return <></>
    if(!!me) {
        if(allowedType.includes(me.userType)) {
            if(me.isManager) {
                if(!allowedManagerRoles) {
                    return <Outlet context={{managerRoles: me.managerProperties?.managerRoles||[], locale:me.language}}/>
                }

                if(me.isManagerWithRoles(allowedManagerRoles)) {
                    return <Outlet context={{managerRoles: me.managerProperties?.managerRoles||[], locale:me.language}} />
                }
            } else {
                return <Outlet context={{managerRoles: [], locale:me.language}} />
            }
        }
    }
    return <Navigate to="/" replace />
}
