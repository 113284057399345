import { gql } from '@apollo/client';

export const QueryMyProperties = gql`
    query Me {
        me {
            _id
            properties {
                ... on BaseUserProperties {
                    userType,
                    language
                }
                ... on CustomerUserProperties {
                    userType,
                    language,
                    relationships {
                        customer {
                            _id
                            name
                            disabled
                        }
                    }
                }
                ... on ManagerUserProperties {
                    userType,
                    managerRoles
                    language
                }
            }
        }
    }
`

export const MutationUpdateMyLanguage = gql`
    mutation SetMyLanguage($language: UserLanguage!) {
        setMyLanguage(language: $language) {
            _id
            properties {
                ... on BaseUserProperties {
                    userType,
                    language
                }
                ... on CustomerUserProperties {
                    userType,
                    language,
                    relationships {
                        customer {
                            _id
                            name
                            disabled
                        }
                    }
                }
                ... on ManagerUserProperties {
                    userType,
                    managerRoles
                    language
                }
            }
        }
    }
`

export const QueryGetManagers = gql`
    query GetManagers($pagination: PaginationArgs, $filter: UserFilterInput) {
      managerUsers(pagination: $pagination, filter: $filter) {
        items {
            _id,
            properties {
                ... on ManagerUserProperties {
                    userType,
                    language,
                    managerRoles
                },
            }
        }
        currentPage
        totalPages
      }
    }    
`;

export const MutationUpdateCustomerUserRelationships = gql`
    mutation UpdateCustomerUserRelationships($_id: String!, $relationships: [ObjectId!]!) {
        updateCustomerUserRelationships(_id: $_id, relationships: $relationships) {
            _id
            properties {
                ... on CustomerUserProperties {
                    userType,
                    language,
                    relationships {
                        customer {
                            _id
                            name
                            disabled
                        }
                    }
                }
            }
        }
    }
`

export const MutationUpdateManagerRole = gql`
    mutation UpdateManagerRole($_id: String!, $roles: [ManagerRole!]!) {
        updateManagerUserRole(_id: $_id, roles: $roles) {
            _id
            properties {
                ... on ManagerUserProperties {
                    userType,
                    managerRoles
                    language
                }
            }
        }
    }
`

export const MutationCreateManagerUser = gql`
    mutation CreateManagerUser($managerUser: ManagerUserInput!) {
        createManagerUser(managerUser: $managerUser) {
            _id
            properties {
                ... on ManagerUserProperties {
                    userType,
                    managerRoles
                    language
                }
            }
        }
    }
`

export const MutationDeleteManagerUser = gql`
    mutation DeleteManagerUser($_id: String!) {
        deleteManagerUser(_id: $_id)
    }
`

export const QueryGetCustomerUserById = gql`
    query QueryGetCustomerUserById($_id: String!) {
        customerUser(_id: $_id) {
            _id
            properties {
                ... on CustomerUserProperties {
                    userType,
                    language,
                    relationships {
                        customer {
                            _id
                            name
                            disabled
                        },
                        userRole
                    }
                }
            }
        }
    }
`

export const QueryIsEmailAvailable = gql`
    query QueryIsEmailAvailable($_id: String!) {
        isEmailAvailable(_id: $_id)
    }
`

export const QueryGetCustomerUsers = gql`
    query GetCustomerUsers($filter: UserFilterInput!, $pagination: CustomerUsersPaginationArgs) {
        customerUsers(filter: $filter, pagination: $pagination) {
            items {
                _id
                properties {
                    ... on CustomerUserProperties {
                        userType,
                        language,
                        relationships {
                            customer {
                                _id
                                name
                                disabled
                            },
                            userRole
                        }
                    }
                }
            }
            currentPage
            totalPages
        }
    }
`

export const QueryGetCustomerAccountById = gql`
    query GetCustomerAccountById($_id: String!) {
        customerUserAccount(_id: $_id) {
            id
            accountEnabled
            displayName
            givenName
            surname
            email
            lastSignInDateTime
            passwordPolicies
            identities {
                issuerAssignedId
                issuer
            }
            mfaMethod
            mustResetPassword
        }
    }
`

export const MutationCreateCustomerUser = gql`
    mutation CreateCustomerUser($customerUser: CustomerUserInput!) {
        createCustomerUser(customerUser: $customerUser) {
            _id,
            properties {
                ... on CustomerUserProperties {
                    language
                    userType
                    relationships {
                        customer {
                            _id
                            name
                            disabled
                        }
                    }
                }
            }
        }
    }
`

export const MutationUpdateCustomerUserLock = gql`
    mutation UpdateCustomerUserLock($locked: Boolean!, $_id: String!) {
        updateCustomerUserLock(_id: $_id, locked: $locked)
    }
`

export const MutationUpdateCustomerUserForcePasswordReset = gql`
    mutation UpdateCustomerUserForcePasswordReset($_id: String!) {
        updateCustomerUserForcePasswordReset(_id: $_id)
    }
`

export const MutationUpdateCustomerUserMFAMethod = gql`
    mutation UpdateCustomerUserMFAMethod($method: B2CMFAMethod!, $_id: String!) {
        updateCustomerUserMFAMethod(_id: $_id, method: $method)
    }
`

export const MutationDeleteCustomerUser = gql`
    mutation DeleteCustomerUser($_id: String!) {
        deleteCustomerUser(_id: $_id)
    }
`
