export const amethystTranslation = {
    en:{
        amethyst:{
            Pagination: {
                Next: "Next",
                Previous: "Previous",
            },
            Modal: {
                Close: "Close",
            },
            ImageUpload:{
                Change: "Change picture",
                Remove: "Remove the picture",
                Restore: "Restore the original picture",
                BrowseImage: "Browse image",
                DragAndDropImage: "Drag and drop image here or",
                Crop: "Crop",
                SaveCrop:"Save",
                CancelCrop:"Cancel",
                ResetCrop:"Reset crop"
            },
        }
    },
    fr:{
        amethyst:{
            Pagination: {
                Next: "Suivant",
                Previous: "Précédent",
            },
            Modal: {
                Close: "Fermer",
            },
            ImageUpload: {
                Change: "Changer d'image",
                Remove: "Retirer l'image",
                Restore: "Restaurer l'image précédente",
                BrowseImage: "Sélectionner une image",
                DragAndDropImage: "Glisser et déposer l'image ici ou",
                Crop: "Recadrer",
                SaveCrop:"Sauvegarder",
                CancelCrop:"Annuler",
                ResetCrop:"Retirer le cadrage"
            }
        }
    }
}