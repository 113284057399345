import React from 'react';
import { NavBarFooter } from './NavBarFooter';
import { NavBarMenuItem, NavBarMenuSubItems } from './NavBarMenuItem';

export interface NavBarMenuProps {
    expanded?:boolean
    footer?:
        React.ReactElement<typeof NavBarFooter>
    children?:
        React.ReactElement<typeof NavBarMenuItem| typeof NavBarMenuSubItems>|
        React.ReactElement<typeof NavBarMenuItem| typeof NavBarMenuSubItems>[]
}

export const NavBarMenu:React.FC<NavBarMenuProps> = ({children, expanded, footer}) =>{
    return (
        <div className={`navbar-collapse ${expanded? 'open':''}`} aria-labelledby="NavToggleMenuButton">
            <nav aria-labelledby="menu">
                <ul className="navbar-nav">
                    {children}
                </ul>
            </nav>
            {footer}
        </div>
    )
}
