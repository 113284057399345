import React, {DOMAttributes } from "react";
import { Link } from "react-router-dom"
import { Icon } from "../icon";
import { DropDown, DropDownProps } from "../dropdown/DropDown";
import { Badge } from '../badge/Badge';

/********
 * Components hierarchy :
 *  - NavBarContent (navbar-top-bar-content)
 *      - NavBarContentBlock (navbar-top-bar-main or navbar-top-bar-aside)
 *          - NavBarContentBadge
 *          - NavBarContentLinkIcon (navbar-top-bar-col)
 *          - NavBarDropDown (navbar-top-bar-col)
 *          - NavBarLanguageLink (navbar-top-bar-nav-lang)
 *          - NavBarToggleBtn (navbar-top-bar-toggle-btn)
 *
 * *********/

/**
 * NavBarContentVisibility
 */
export enum NavBarContentVisibility {
    Large = "navbar-top-bar-col-hidden-xs",
    Small = "navbar-top-bar-col-hidden-lg"
}

/* ------- NavBarContentLinkIcon ------- */
export interface NavBarContentLinkIconProps extends DOMAttributes<HTMLElement> {
    visibility: NavBarContentVisibility
    icon: string
    label: string
    link?: string
}

export const NavBarContentLinkIcon:React.FC<NavBarContentLinkIconProps> = props => {
    const {icon, label, link, visibility, ...rest} = props;
    return (
        <div className={`navbar-top-bar-col ${visibility}`}>
            { link ?
                <Link to={link} className="navbar-top-bar-link-icon" {...rest}>
                    <Icon icon={icon} label={label} />
                </Link> :
                <div className="navbar-top-bar-link-icon" {...rest}>
                    <Icon icon={icon} label={label} />
                </div>
            }
        </div>
    )
}

export interface NavBarContentBadgeProps extends DOMAttributes<HTMLElement> {
    visibility: NavBarContentVisibility
    children:React.ReactElement<typeof Badge>
}

export const NavBarContentBadge:React.FC<NavBarContentBadgeProps> =  ({visibility, children }) => {
    return (
        <div className={`navbar-top-bar-col ${visibility}`}>
            {children}
        </div>
    )
}

/* ------- NavBarLanguageLink ------- */

export interface NavBarLanguageLinkProps extends DOMAttributes<HTMLElement> {
    visibility: NavBarContentVisibility
    label: string
    currentLanguage: string
    languages: string[]
    onChangeLanguage: (selectedLanguage: string) => void
}

export const NavBarLanguageLink:React.FC<NavBarLanguageLinkProps> = (props) => {
    const { label, languages, visibility, currentLanguage, onChangeLanguage, ...rest} = props;

    const onClickLanguage = (e:React.MouseEvent<HTMLElement>, language: string) => {
        e.preventDefault();
        onChangeLanguage(language);
    }

    return (
        <div className={`navbar-top-bar-col ${visibility}`}>
            <ul className="nav navbar-top-bar-nav-lang" aria-label={label} {...rest}>
                {languages.map((l, idx) =>
                    <li key={idx}>
                        {currentLanguage === l ?
                            <button type="button" className="active">{l}</button>
                            :
                            <button type="button" onClick={(e) => onClickLanguage(e, l)}>{l}</button>
                        }
                    </li>
                )}
            </ul>
        </div>
    )
}

/* ------- NavBarToggleBtn ------ */

export interface NavBarToggleBtnProps extends DOMAttributes<HTMLElement> {
    expanded?:boolean
    onClickToggleExpand?:(event: React.MouseEvent<HTMLButtonElement>) => void
    visibility: NavBarContentVisibility
}

export const NavBarToggleBtn:React.FC<NavBarToggleBtnProps> = ({visibility, expanded, onClickToggleExpand}) => {
    return (
        <div className={`navbar-top-bar-col ${visibility}`}>
            <button id="NavToggleMenuButton" className="navbar-top-bar-toggle-btn" type="button" aria-haspopup="true" onClick={(event) => !!onClickToggleExpand && onClickToggleExpand(event)} aria-expanded={expanded}>
                <span className="navbar-top-bar-toggle-btn-label">Menu</span>
                <span className="navbar-top-bar-toggle-btn-icon">
                  <span className="bar-icon"></span>
                  <span className="bar-icon"></span>
                  <span className="bar-icon"></span>
                </span>
            </button>
        </div>
    )
}


/* ------- NavBarDropDown ------- */

const NavBarDropDownIcon = (
    <span className="navbar-top-bar-user-dropdown-arrow">
        <i className="icon icon-d-block icon-chevron-small-down" aria-hidden="true"></i>
    </span>
)

export interface NavBarDropDownProp extends DropDownProps {
    visibility: NavBarContentVisibility
}

export const NavBarDropDown:React.FunctionComponent<NavBarDropDownProp> = ({ name, disabled= false, right= false, dropDrownMenuItems, visibility, children}) => {
    return (
        <div className={`navbar-top-bar-col ${visibility}`}>
            <DropDown
                name={name}
                disabled={disabled}
                right={right}
                icon={NavBarDropDownIcon}
                className="navbar-top-bar-user-dropdown"
                dropDrownMenuItems={dropDrownMenuItems}>
                {children}
            </DropDown>
        </div>
    )
}

/* ------- NavBarContentItem ------- */

/**
 * NavBarContentKind
 */
export enum NavBarContentKind {
    Main = "navbar-top-bar-main",
    Aside = "navbar-top-bar-aside"
}

/**
 * NavBarContentItemProps
 */
export interface NavBarContentBlockProps {
    kind?: NavBarContentKind
    children?:
        React.ReactElement<typeof NavBarContentLinkIcon|typeof NavBarDropDown|typeof NavBarLanguageLink>|
        React.ReactElement<typeof NavBarContentLinkIcon|typeof NavBarDropDown|typeof NavBarLanguageLink>[]
}

/**
 * NavBarContentItem
 * @param children
 * @param visibility
 * @param kind
 * @constructor
 */
export const NavBarContentBlock:React.FC<NavBarContentBlockProps> = ({children, kind= NavBarContentKind.Main}) =>
    <div className={kind}>
        {children}
    </div>


/* ------- NavBarContent ------- */

/**
 * NavBarContentProps
 */
export interface NavBarContentProps {
    children?:
        React.ReactElement<typeof NavBarContentBlock>|
        React.ReactElement<typeof NavBarContentBlock>[]
}

/**
 * NavBarContent
 * @param children
 * @constructor
 */
export const NavBarContent:React.FC<NavBarContentProps> = ({children}) =>
    <div className="navbar-top-bar-content">{children}</div>
