import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
};

export type AgendaDeadlineInput = {
  cta: CtaInput;
  date: Scalars['DateTime']['input'];
  description: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  target?: InputMaybe<AgendaEntryTargetInput>;
  title: Scalars['String']['input'];
};

export type AgendaEntry = {
  __typename?: 'AgendaEntry';
  _id: Scalars['ObjectId']['output'];
  auditTrail: AuditTrail;
  date: Scalars['DateTime']['output'];
  properties: AgendaEntryProperties;
  tags: Array<Scalars['String']['output']>;
  target?: Maybe<AgendaEntryTarget>;
  title: Scalars['String']['output'];
  type: AgendaEntryType;
};

export type AgendaEntryFilterInput = {
  customerId?: InputMaybe<Scalars['ObjectId']['input']>;
  dateRange?: InputMaybe<AgendaEntryRangeInput>;
  type?: InputMaybe<Array<AgendaEntryType>>;
};

export type AgendaEntryProperties = Deadline | Event;

export type AgendaEntryRangeInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<Sorting>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** MyGT Agenda target */
export type AgendaEntryTarget = {
  __typename?: 'AgendaEntryTarget';
  customers?: Maybe<Array<Customer>>;
  industries?: Maybe<Array<Scalars['String']['output']>>;
  services?: Maybe<Array<Scalars['String']['output']>>;
};

export type AgendaEntryTargetInput = {
  customerIds?: InputMaybe<Array<Scalars['ObjectId']['input']>>;
  industries?: InputMaybe<Array<Scalars['String']['input']>>;
  services?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Agenda entry  */
export enum AgendaEntryType {
  Deadline = 'Deadline',
  Event = 'Event'
}

export type AgendaEventInput = {
  cta: CtaInput;
  date: Scalars['DateTime']['input'];
  rawContent: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
  target?: InputMaybe<AgendaEntryTargetInput>;
  title: Scalars['String']['input'];
};

/** MyGT App */
export type App = {
  __typename?: 'App';
  _id: Scalars['ObjectId']['output'];
  auditTrail: AuditTrail;
  description: Scalars['String']['output'];
  fallbackUrl: Scalars['String']['output'];
  logo?: Maybe<BlobImage>;
  maintenanceWindow?: Maybe<MaintenanceWindow>;
  name: Scalars['String']['output'];
  status: AppStatus;
  subscriptions: Array<AppSubscription>;
  url: Scalars['String']['output'];
};

export type AppInput = {
  description: Scalars['String']['input'];
  fallbackUrl: Scalars['String']['input'];
  logo?: InputMaybe<BlobImageInput>;
  maintenanceWindow?: InputMaybe<MaintenanceWindowInput>;
  name: Scalars['String']['input'];
  status: AppStatus;
  url: Scalars['String']['input'];
};

/** App status */
export enum AppStatus {
  Maintenance = 'Maintenance',
  Offline = 'Offline',
  Online = 'Online'
}

/** MyGT App subscription for customers */
export type AppSubscription = {
  __typename?: 'AppSubscription';
  customer: Customer;
};

export type AppSubscriptions = {
  appId: Scalars['ObjectId']['input'];
  customerSubscriptions: Array<CustomerUsersSubscriptions>;
};

/** MyGT App subscription user list */
export type AppUserSubscriptions = {
  __typename?: 'AppUserSubscriptions';
  _id: Scalars['ObjectId']['output'];
  customerId: Scalars['ObjectId']['output'];
  customerUserIds: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** MyGT Article */
export type Article = {
  __typename?: 'Article';
  _id: Scalars['String']['output'];
  auditTrail: AuditTrail;
  properties: ArticleProperties;
  publicationDate: Scalars['DateTime']['output'];
  rawContent: Scalars['String']['output'];
  richContent: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  status: ArticleStatus;
  teaser: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ArticleCreateInput = {
  properties: ArticlePropertiesInput;
  publicationDate: Scalars['DateTime']['input'];
  rawContent: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ArticleFilterInput = {
  publicationRange?: InputMaybe<ArticlePublicationRangeInput>;
  status?: InputMaybe<Array<ArticleStatus>>;
};

/** MyGT Article properties */
export type ArticleProperties = {
  __typename?: 'ArticleProperties';
  contactPerson?: Maybe<Contact>;
  mainPicture?: Maybe<BlobImage>;
  tags: Array<Scalars['String']['output']>;
};

export type ArticlePropertiesInput = {
  contactPersonId?: InputMaybe<Scalars['ObjectId']['input']>;
  mainPicture?: InputMaybe<BlobImageInput>;
  tags: Array<Scalars['String']['input']>;
};

export type ArticlePublicationRangeInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleSelectorInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Article status */
export enum ArticleStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published',
  SubmittedForApproval = 'SubmittedForApproval'
}

export type ArticleUpdateInput = {
  properties: ArticlePropertiesInput;
  publicationDate: Scalars['DateTime']['input'];
  rawContent: Scalars['String']['input'];
  status: ArticleStatus;
  title: Scalars['String']['input'];
};

/** MyGt Audit trail history */
export type AuditHistory = {
  __typename?: 'AuditHistory';
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

/** MyGt Audit trail */
export type AuditTrail = {
  __typename?: 'AuditTrail';
  _id: Scalars['ObjectId']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  history: Array<AuditHistory>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Scalars['String']['output'];
};

/** B2CAccount */
export type B2CAccount = {
  __typename?: 'B2CAccount';
  accountEnabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identities?: Maybe<Array<B2CAccountIdentity>>;
  lastSignInDateTime?: Maybe<Scalars['String']['output']>;
  mfaMethod?: Maybe<B2CmfaMethod>;
  mustResetPassword?: Maybe<Scalars['Boolean']['output']>;
  passwordPolicies: Array<B2CPasswordPolicy>;
  surname: Scalars['String']['output'];
};

/** B2CAccountIdentity */
export type B2CAccountIdentity = {
  __typename?: 'B2CAccountIdentity';
  issuer: Scalars['String']['output'];
  issuerAssignedId: Scalars['String']['output'];
  signInType: Scalars['String']['output'];
};

/** B2C MFA Method */
export enum B2CmfaMethod {
  Email = 'email',
  Totp = 'totp'
}

/** B2C Password Policies  */
export enum B2CPasswordPolicy {
  DisablePasswordExpiration = 'DisablePasswordExpiration',
  DisableStrongPassword = 'DisableStrongPassword'
}

/** Base user properties */
export type BaseUserProperties = {
  __typename?: 'BaseUserProperties';
  language: UserLanguage;
  userType: UserType;
};

/** MyGT Image */
export type BlobImage = {
  __typename?: 'BlobImage';
  _id: Scalars['String']['output'];
  downloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
};

export type BlobImageInput = {
  _id: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
};

/** MyGT BulkCustomerUserCreationJob */
export type BulkCustomerUsersJob = IJob & {
  __typename?: 'BulkCustomerUsersJob';
  _id: Scalars['ObjectId']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerUsersCreationErrors: Array<CustomerUserOnError>;
  customerUsersToCreate: Array<CustomerUserJob>;
  jobTitle: Scalars['String']['output'];
  status: JobStatus;
  successfulCreations: Scalars['Float']['output'];
  type: JobType;
  updatedAt: Scalars['DateTime']['output'];
};

export type BulkCustomerUsersJobFilterInput = {
  range: BulkCustomerUsersJobRangeInput;
  status?: InputMaybe<JobStatus>;
};

export type BulkCustomerUsersJobInput = {
  customerUsersToCreate: Array<CustomerUserJobInput>;
  jobTitle: Scalars['String']['input'];
};

export type BulkCustomerUsersJobRangeInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

/** MyGT Call to action */
export type Cta = {
  __typename?: 'CTA';
  contactPerson?: Maybe<Contact>;
  link?: Maybe<CtaLink>;
};

export type CtaInput = {
  contactPersonId?: InputMaybe<Scalars['ObjectId']['input']>;
  link?: InputMaybe<CtaLinkInput>;
};

/** MyGT CTA Link */
export type CtaLink = {
  __typename?: 'CTALink';
  label: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type CtaLinkInput = {
  label: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

/** MyGT Contact */
export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['ObjectId']['output'];
  auditTrail: AuditTrail;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  picture: BlobImage;
  specialisation: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  picture: BlobImageInput;
  specialisation: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

/** MyGT Customer */
export type Customer = {
  __typename?: 'Customer';
  _id: Scalars['ObjectId']['output'];
  auditTrail: AuditTrail;
  disabled: Scalars['Boolean']['output'];
  gtCustomerId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  properties: CustomerProperties;
};

export type CustomerCreationInput = {
  gtCustomerId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  properties: CustomerPropertiesInput;
};

export type CustomerFilterInput = {
  assigned: Scalars['Boolean']['input'];
};

export type CustomerPaginationArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

/** MyGT Customer Properties */
export type CustomerProperties = {
  __typename?: 'CustomerProperties';
  comments: Scalars['String']['output'];
  contactInfo: Scalars['String']['output'];
  gtManagers: Array<Scalars['String']['output']>;
  industries: Array<Scalars['String']['output']>;
  services: Array<Scalars['String']['output']>;
};

export type CustomerPropertiesInput = {
  comments: Scalars['String']['input'];
  contactInfo: Scalars['String']['input'];
  gtManagers: Array<Scalars['String']['input']>;
  industries: Array<Scalars['String']['input']>;
  services: Array<Scalars['String']['input']>;
};

/** User relationship with customer */
export type CustomerRelationship = {
  __typename?: 'CustomerRelationship';
  customer: Customer;
  userRole: CustomerUserRole;
};

export type CustomerUpdateInput = {
  disabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  properties: CustomerPropertiesInput;
};

export type CustomerUserInput = {
  customerIds: Array<Scalars['ObjectId']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language?: InputMaybe<UserLanguage>;
  lastName: Scalars['String']['input'];
};

/** CustomerUser to create */
export type CustomerUserJob = {
  __typename?: 'CustomerUserJob';
  email: Scalars['String']['output'];
};

export type CustomerUserJobInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  relationships: Array<CustomerUserRelationshipsJobInput>;
  temporaryPassword?: InputMaybe<Scalars['String']['input']>;
};

/** CustomerUser failed to create */
export type CustomerUserOnError = {
  __typename?: 'CustomerUserOnError';
  email: Scalars['String']['output'];
  error: Scalars['String']['output'];
};

/** Customer user properties */
export type CustomerUserProperties = {
  __typename?: 'CustomerUserProperties';
  language: UserLanguage;
  relationships: Array<CustomerRelationship>;
  userType: UserType;
};

export type CustomerUserRelationshipsJobInput = {
  appIds: Array<Scalars['ObjectId']['input']>;
  customerId: Scalars['ObjectId']['input'];
};

/** Customer user role */
export enum CustomerUserRole {
  User = 'User'
}

export type CustomerUsersPaginationArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type CustomerUsersSubscriptions = {
  customerId: Scalars['ObjectId']['input'];
  customerUserIds: Array<Scalars['String']['input']>;
};

/** MyGT Agenda Deadline */
export type Deadline = {
  __typename?: 'Deadline';
  cta: Cta;
  description: Scalars['String']['output'];
  teaser: Scalars['String']['output'];
};

/** MyGT Agenda Event */
export type Event = {
  __typename?: 'Event';
  cta: Cta;
  rawContent: Scalars['String']['output'];
  richContent: Array<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
};

export type IJob = {
  _id: Scalars['ObjectId']['output'];
  createdAt: Scalars['DateTime']['output'];
  jobTitle: Scalars['String']['output'];
  status: JobStatus;
  type: JobType;
  updatedAt: Scalars['DateTime']['output'];
};

/** Job status */
export enum JobStatus {
  Failed = 'failed',
  Finished = 'finished',
  Idle = 'idle',
  InProgress = 'inProgress'
}

/** Job type */
export enum JobType {
  BulkCustomerUsers = 'BulkCustomerUsers',
  Email = 'Email'
}

/** MyGT App maintenance window */
export type MaintenanceWindow = {
  __typename?: 'MaintenanceWindow';
  from: Scalars['DateTime']['output'];
  to: Scalars['DateTime']['output'];
};

export type MaintenanceWindowInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

/** Manager employee user role */
export enum ManagerRole {
  Admin = 'Admin',
  Contributor = 'Contributor',
  CustomerManager = 'CustomerManager',
  Editor = 'Editor'
}

export type ManagerUserInput = {
  email: Scalars['String']['input'];
  roles: Array<ManagerRole>;
};

/** Manager user properties */
export type ManagerUserProperties = {
  __typename?: 'ManagerUserProperties';
  language: UserLanguage;
  managerRoles: Array<ManagerRole>;
  userType: UserType;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAgendaDeadline: AgendaEntry;
  createAgendaEvent: AgendaEntry;
  createApp: App;
  createArticle: Article;
  createBulkCustomerUserCreationJob: BulkCustomerUsersJob;
  createContact: Contact;
  createCustomer: Customer;
  createCustomerUser: User;
  createManagerUser: User;
  createNotification: Notification;
  deleteCustomerUser: Scalars['Boolean']['output'];
  deleteManagerUser: Scalars['Boolean']['output'];
  removeAgendaEntryById?: Maybe<Scalars['Boolean']['output']>;
  removeContactById?: Maybe<Scalars['Boolean']['output']>;
  removeNotification?: Maybe<Scalars['Boolean']['output']>;
  setMyLanguage: User;
  updateAgendaDeadline?: Maybe<AgendaEntry>;
  updateAgendaEvent?: Maybe<AgendaEntry>;
  updateApp?: Maybe<App>;
  updateAppSubscription?: Maybe<App>;
  updateAppSubscriptions?: Maybe<Array<App>>;
  updateArticle?: Maybe<Article>;
  updateContact?: Maybe<Contact>;
  updateCustomer?: Maybe<Customer>;
  updateCustomerUserForcePasswordReset: Scalars['Boolean']['output'];
  updateCustomerUserLock: Scalars['Boolean']['output'];
  updateCustomerUserMFAMethod: Scalars['Boolean']['output'];
  updateCustomerUserRelationships?: Maybe<User>;
  updateManagerUserRole?: Maybe<User>;
  updateNotification: Notification;
};


export type MutationCreateAgendaDeadlineArgs = {
  deadline: AgendaDeadlineInput;
};


export type MutationCreateAgendaEventArgs = {
  event: AgendaEventInput;
};


export type MutationCreateAppArgs = {
  app: AppInput;
};


export type MutationCreateArticleArgs = {
  article: ArticleCreateInput;
};


export type MutationCreateBulkCustomerUserCreationJobArgs = {
  job: BulkCustomerUsersJobInput;
};


export type MutationCreateContactArgs = {
  contact: ContactInput;
};


export type MutationCreateCustomerArgs = {
  customer: CustomerCreationInput;
};


export type MutationCreateCustomerUserArgs = {
  customerUser: CustomerUserInput;
};


export type MutationCreateManagerUserArgs = {
  managerUser: ManagerUserInput;
};


export type MutationCreateNotificationArgs = {
  notification: NotificationInput;
};


export type MutationDeleteCustomerUserArgs = {
  _id: Scalars['String']['input'];
};


export type MutationDeleteManagerUserArgs = {
  _id: Scalars['String']['input'];
};


export type MutationRemoveAgendaEntryByIdArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type MutationRemoveContactByIdArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type MutationRemoveNotificationArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type MutationSetMyLanguageArgs = {
  language: UserLanguage;
};


export type MutationUpdateAgendaDeadlineArgs = {
  _id: Scalars['ObjectId']['input'];
  deadline: AgendaDeadlineInput;
};


export type MutationUpdateAgendaEventArgs = {
  _id: Scalars['ObjectId']['input'];
  event: AgendaEventInput;
};


export type MutationUpdateAppArgs = {
  _id: Scalars['ObjectId']['input'];
  app: AppInput;
};


export type MutationUpdateAppSubscriptionArgs = {
  subscription: SubscriptionInput;
};


export type MutationUpdateAppSubscriptionsArgs = {
  subscriptions: SubscriptionsInput;
};


export type MutationUpdateArticleArgs = {
  _id: Scalars['String']['input'];
  article: ArticleUpdateInput;
};


export type MutationUpdateContactArgs = {
  _id: Scalars['ObjectId']['input'];
  contact: ContactInput;
};


export type MutationUpdateCustomerArgs = {
  _id: Scalars['ObjectId']['input'];
  customer: CustomerUpdateInput;
};


export type MutationUpdateCustomerUserForcePasswordResetArgs = {
  _id: Scalars['String']['input'];
};


export type MutationUpdateCustomerUserLockArgs = {
  _id: Scalars['String']['input'];
  locked: Scalars['Boolean']['input'];
};


export type MutationUpdateCustomerUserMfaMethodArgs = {
  _id: Scalars['String']['input'];
  method: B2CmfaMethod;
};


export type MutationUpdateCustomerUserRelationshipsArgs = {
  _id: Scalars['String']['input'];
  relationships: Array<Scalars['ObjectId']['input']>;
};


export type MutationUpdateManagerUserRoleArgs = {
  _id: Scalars['String']['input'];
  roles: Array<ManagerRole>;
};


export type MutationUpdateNotificationArgs = {
  _id: Scalars['ObjectId']['input'];
  notification: NotificationInput;
};

/** MyGT Notification */
export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ObjectId']['output'];
  auditTrail: AuditTrail;
  description: Scalars['String']['output'];
  expirationDate: Scalars['DateTime']['output'];
};

export type NotificationFilterInput = {
  notBefore: Scalars['DateTime']['input'];
};

export type NotificationInput = {
  description: Scalars['String']['input'];
  expirationDate: Scalars['DateTime']['input'];
};

export type PaginatedAgendaEntries = {
  __typename?: 'PaginatedAgendaEntries';
  currentPage: Scalars['Int']['output'];
  items: Array<AgendaEntry>;
  totalPages: Scalars['Int']['output'];
};

export type PaginatedArticles = {
  __typename?: 'PaginatedArticles';
  currentPage: Scalars['Int']['output'];
  items: Array<Article>;
  totalPages: Scalars['Int']['output'];
};

export type PaginatedCustomers = {
  __typename?: 'PaginatedCustomers';
  currentPage: Scalars['Int']['output'];
  items: Array<Customer>;
  totalPages: Scalars['Int']['output'];
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  currentPage: Scalars['Int']['output'];
  items: Array<User>;
  totalPages: Scalars['Int']['output'];
};

export type PaginationArgs = {
  page: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  agendaEntries: PaginatedAgendaEntries;
  agendaEntriesTags: Array<Scalars['String']['output']>;
  agendaEntry?: Maybe<AgendaEntry>;
  app?: Maybe<App>;
  apps: Array<App>;
  appsUsersSubscriptions: Array<AppUserSubscriptions>;
  article?: Maybe<Article>;
  articles: PaginatedArticles;
  articlesTags: Array<Scalars['String']['output']>;
  bulkCustomerUsersJob?: Maybe<BulkCustomerUsersJob>;
  bulkCustomerUsersJobs: Array<BulkCustomerUsersJob>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  contactsSpecialisations: Array<Scalars['String']['output']>;
  customer?: Maybe<Customer>;
  customerUser?: Maybe<User>;
  customerUserAccount?: Maybe<B2CAccount>;
  customerUsers: PaginatedUsers;
  customers: PaginatedCustomers;
  isCustomerIdAvailable: Scalars['Boolean']['output'];
  isEmailAvailable: Scalars['Boolean']['output'];
  managerUser?: Maybe<User>;
  managerUsers: PaginatedUsers;
  me: User;
  notifications: Array<Notification>;
  unknownUser?: Maybe<User>;
  unknownUsers: PaginatedUsers;
};


export type QueryAgendaEntriesArgs = {
  filter?: InputMaybe<AgendaEntryFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryAgendaEntryArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type QueryAppArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type QueryAppsUsersSubscriptionsArgs = {
  customerId: Scalars['ObjectId']['input'];
};


export type QueryArticleArgs = {
  selector: ArticleSelectorInput;
};


export type QueryArticlesArgs = {
  filter?: InputMaybe<ArticleFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryBulkCustomerUsersJobArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type QueryBulkCustomerUsersJobsArgs = {
  filter: BulkCustomerUsersJobFilterInput;
};


export type QueryContactArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type QueryCustomerArgs = {
  _id: Scalars['ObjectId']['input'];
};


export type QueryCustomerUserArgs = {
  _id: Scalars['String']['input'];
};


export type QueryCustomerUserAccountArgs = {
  _id: Scalars['String']['input'];
};


export type QueryCustomerUsersArgs = {
  filter: UserFilterInput;
  pagination?: InputMaybe<CustomerUsersPaginationArgs>;
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilterInput>;
  pagination?: InputMaybe<CustomerPaginationArgs>;
};


export type QueryIsCustomerIdAvailableArgs = {
  gtCustomerId: Scalars['String']['input'];
};


export type QueryIsEmailAvailableArgs = {
  _id: Scalars['String']['input'];
};


export type QueryManagerUserArgs = {
  _id: Scalars['String']['input'];
};


export type QueryManagerUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilterInput>;
};


export type QueryUnknownUserArgs = {
  _id: Scalars['String']['input'];
};


export type QueryUnknownUsersArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

/** Sorting order */
export enum Sorting {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type SubscriptionInput = {
  appId: Scalars['ObjectId']['input'];
  customerId: Scalars['ObjectId']['input'];
  customerUserIds: Array<Scalars['String']['input']>;
};

export type SubscriptionsInput = {
  appSubscriptions: Array<AppSubscriptions>;
};

/** MyGT User */
export type User = {
  __typename?: 'User';
  _id: Scalars['String']['output'];
  auditTrail: AuditTrail;
  properties: UserProperties;
};

export type UserFilterInput = {
  customerId?: InputMaybe<Scalars['ObjectId']['input']>;
  emailPattern?: InputMaybe<Scalars['String']['input']>;
  managerRoles?: InputMaybe<Array<ManagerRole>>;
};

/** user language */
export enum UserLanguage {
  En = 'en',
  Fr = 'fr'
}

export type UserProperties = BaseUserProperties | CustomerUserProperties | ManagerUserProperties;

/** User type, either Manager employee, customer or unknown */
export enum UserType {
  Customer = 'Customer',
  Manager = 'Manager',
  Unknown = 'Unknown'
}

export type GetCrudAgendaEntriesQueryVariables = Exact<{
  filter?: InputMaybe<AgendaEntryFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
  customersFilter?: InputMaybe<CustomerFilterInput>;
  customersPagination?: InputMaybe<CustomerPaginationArgs>;
}>;


export type GetCrudAgendaEntriesQuery = { __typename?: 'Query', agendaEntries: { __typename?: 'PaginatedAgendaEntries', currentPage: number, totalPages: number, items: Array<{ __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', teaser: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event', teaser: string, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null }> }, customers: { __typename?: 'PaginatedCustomers', items: Array<{ __typename?: 'Customer', _id: any, name: string }> } };

export type GetAgendaEntriesQueryVariables = Exact<{
  filter?: InputMaybe<AgendaEntryFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
}>;


export type GetAgendaEntriesQuery = { __typename?: 'Query', agendaEntries: { __typename?: 'PaginatedAgendaEntries', currentPage: number, totalPages: number, items: Array<{ __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', teaser: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event', teaser: string, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null }> } };

export type QueryGetAgendaEntriesTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryGetAgendaEntriesTagsQuery = { __typename?: 'Query', agendaEntriesTags: Array<string> };

export type QueryGetAgendaEntryToViewQueryVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type QueryGetAgendaEntryToViewQuery = { __typename?: 'Query', agendaEntry?: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', description: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event', richContent: Array<string>, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } | null };

export type QueryGetAgendaEntryToEditQueryVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type QueryGetAgendaEntryToEditQuery = { __typename?: 'Query', agendaEntry?: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', description: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event', rawContent: string, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } | null };

export type CreateAgendaEventMutationVariables = Exact<{
  event: AgendaEventInput;
}>;


export type CreateAgendaEventMutation = { __typename?: 'Mutation', createAgendaEvent: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline' } | { __typename?: 'Event', rawContent: string, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } };

export type CreateAgendaDeadlineMutationVariables = Exact<{
  deadline: AgendaDeadlineInput;
}>;


export type CreateAgendaDeadlineMutation = { __typename?: 'Mutation', createAgendaDeadline: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', description: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event' }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } };

export type UpdateAgendaEventMutationVariables = Exact<{
  event: AgendaEventInput;
  _id: Scalars['ObjectId']['input'];
}>;


export type UpdateAgendaEventMutation = { __typename?: 'Mutation', updateAgendaEvent?: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline' } | { __typename?: 'Event', rawContent: string, cta: { __typename?: 'CTA', link?: { __typename?: 'CTALink', label: string, title?: string | null, url: string } | null } }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } | null };

export type UpdateAgendaDeadlineMutationVariables = Exact<{
  deadline: AgendaDeadlineInput;
  _id: Scalars['ObjectId']['input'];
}>;


export type UpdateAgendaDeadlineMutation = { __typename?: 'Mutation', updateAgendaDeadline?: { __typename?: 'AgendaEntry', _id: any, title: string, date: any, tags: Array<string>, type: AgendaEntryType, properties: { __typename?: 'Deadline', description: string, cta: { __typename?: 'CTA', contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | { __typename?: 'Event' }, target?: { __typename?: 'AgendaEntryTarget', services?: Array<string> | null, industries?: Array<string> | null, customers?: Array<{ __typename?: 'Customer', _id: any, name: string }> | null } | null } | null };

export type RemoveAgendaEntryByIdMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type RemoveAgendaEntryByIdMutation = { __typename?: 'Mutation', removeAgendaEntryById?: boolean | null };

export type GetAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAppsQuery = { __typename?: 'Query', apps: Array<{ __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> }> };

export type GeyAppsByIdQueryVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type GeyAppsByIdQuery = { __typename?: 'Query', app?: { __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | null };

export type CreateAppMutationVariables = Exact<{
  app: AppInput;
}>;


export type CreateAppMutation = { __typename?: 'Mutation', createApp: { __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } };

export type UpdateAppMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
  app: AppInput;
}>;


export type UpdateAppMutation = { __typename?: 'Mutation', updateApp?: { __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | null };

export type UpdateAppSubscriptionMutationVariables = Exact<{
  subscription: SubscriptionInput;
}>;


export type UpdateAppSubscriptionMutation = { __typename?: 'Mutation', updateAppSubscription?: { __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | null };

export type UpdateAppSubscriptionsMutationVariables = Exact<{
  subscriptions: SubscriptionsInput;
}>;


export type UpdateAppSubscriptionsMutation = { __typename?: 'Mutation', updateAppSubscriptions?: Array<{ __typename?: 'App', _id: any, name: string, description: string, url: string, fallbackUrl: string, status: AppStatus, logo?: { __typename?: 'BlobImage', _id: string, filename: string, mimeType: string, downloadUrl: string } | null, maintenanceWindow?: { __typename?: 'MaintenanceWindow', from: any, to: any } | null, subscriptions: Array<{ __typename?: 'AppSubscription', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> }> | null };

export type QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables = Exact<{
  customerId: Scalars['ObjectId']['input'];
}>;


export type QueryGetAppsUsersSubscriptionByCustomerIdQuery = { __typename?: 'Query', appsUsersSubscriptions: Array<{ __typename?: 'AppUserSubscriptions', _id: any, name: string, customerId: any, customerUserIds: Array<string> }> };

export type GetArticlesQueryVariables = Exact<{
  filter?: InputMaybe<ArticleFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
}>;


export type GetArticlesQuery = { __typename?: 'Query', articles: { __typename?: 'PaginatedArticles', currentPage: number, totalPages: number, items: Array<{ __typename?: 'Article', _id: string, slug: string, title: string, teaser: string, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null } }> } };

export type GetArticlesWithAuditTrailQueryVariables = Exact<{
  filter?: InputMaybe<ArticleFilterInput>;
  pagination?: InputMaybe<PaginationArgs>;
}>;


export type GetArticlesWithAuditTrailQuery = { __typename?: 'Query', articles: { __typename?: 'PaginatedArticles', currentPage: number, totalPages: number, items: Array<{ __typename?: 'Article', _id: string, slug: string, title: string, teaser: string, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null }, auditTrail: { __typename?: 'AuditTrail', createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } }> } };

export type GetArticleToEditQueryVariables = Exact<{
  selector: ArticleSelectorInput;
}>;


export type GetArticleToEditQuery = { __typename?: 'Query', article?: { __typename?: 'Article', _id: string, slug: string, title: string, rawContent: string, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null, contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null }, auditTrail: { __typename?: 'AuditTrail', createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } } | null };

export type QueryGetArticleToViewQueryVariables = Exact<{
  selector: ArticleSelectorInput;
}>;


export type QueryGetArticleToViewQuery = { __typename?: 'Query', article?: { __typename?: 'Article', _id: string, slug: string, title: string, richContent: Array<string>, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null, contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, phone: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | null };

export type QueryGetArticlesTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryGetArticlesTagsQuery = { __typename?: 'Query', articlesTags: Array<string> };

export type CreateArticleMutationVariables = Exact<{
  article: ArticleCreateInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle: { __typename?: 'Article', _id: string, slug: string, title: string, rawContent: string, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null, contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } };

export type UpdateArticleMutationVariables = Exact<{
  _id: Scalars['String']['input'];
  article: ArticleUpdateInput;
}>;


export type UpdateArticleMutation = { __typename?: 'Mutation', updateArticle?: { __typename?: 'Article', _id: string, slug: string, title: string, rawContent: string, status: ArticleStatus, publicationDate: any, properties: { __typename?: 'ArticleProperties', tags: Array<string>, mainPicture?: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } | null, contactPerson?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', downloadUrl: string } } | null } } | null };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', contacts: Array<{ __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, phone: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } }> };

export type GetContactByIdQueryVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type GetContactByIdQuery = { __typename?: 'Query', contact?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, phone: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } } | null };

export type QueryGetContactsSpecialisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type QueryGetContactsSpecialisationsQuery = { __typename?: 'Query', contactsSpecialisations: Array<string> };

export type CreateContactMutationVariables = Exact<{
  contact: ContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, phone: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } } };

export type UpdateContactMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
  contact: ContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact?: { __typename?: 'Contact', _id: any, firstName: string, lastName: string, email: string, phone: string, title: string, specialisation: Array<string>, picture: { __typename?: 'BlobImage', _id: string, downloadUrl: string, filename: string, mimeType: string } } | null };

export type RemoveContactByIdMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type RemoveContactByIdMutation = { __typename?: 'Mutation', removeContactById?: boolean | null };

export type GetCustomersQueryVariables = Exact<{
  pagination?: InputMaybe<CustomerPaginationArgs>;
  filter?: InputMaybe<CustomerFilterInput>;
}>;


export type GetCustomersQuery = { __typename?: 'Query', customers: { __typename?: 'PaginatedCustomers', currentPage: number, totalPages: number, items: Array<{ __typename?: 'Customer', _id: any, name: string, gtCustomerId: string, disabled: boolean, properties: { __typename?: 'CustomerProperties', comments: string, contactInfo: string, industries: Array<string>, services: Array<string>, gtManagers: Array<string> } }> } };

export type GetCustomerByIdQueryVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type GetCustomerByIdQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', _id: any, name: string, gtCustomerId: string, disabled: boolean, properties: { __typename?: 'CustomerProperties', comments: string, contactInfo: string, industries: Array<string>, services: Array<string>, gtManagers: Array<string> } } | null };

export type IsGtCustomerIdAvailableQueryVariables = Exact<{
  gtCustomerId: Scalars['String']['input'];
}>;


export type IsGtCustomerIdAvailableQuery = { __typename?: 'Query', isCustomerIdAvailable: boolean };

export type CreateCustomerMutationVariables = Exact<{
  customer: CustomerCreationInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'Customer', _id: any, name: string, gtCustomerId: string, disabled: boolean, properties: { __typename?: 'CustomerProperties', comments: string, contactInfo: string, industries: Array<string>, services: Array<string>, gtManagers: Array<string> } } };

export type UpdateCustomerMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
  customer: CustomerUpdateInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'Customer', _id: any, name: string, gtCustomerId: string, disabled: boolean, properties: { __typename?: 'CustomerProperties', comments: string, contactInfo: string, industries: Array<string>, services: Array<string>, gtManagers: Array<string> } } | null };

export type GetNotificationsQueryVariables = Exact<{
  filter?: InputMaybe<NotificationFilterInput>;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', _id: any, description: string, expirationDate: any }> };

export type CreateNotificationMutationVariables = Exact<{
  notification: NotificationInput;
}>;


export type CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename?: 'Notification', _id: any, description: string, expirationDate: any } };

export type UpdateNotificationMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
  notification: NotificationInput;
}>;


export type UpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename?: 'Notification', _id: any, description: string, expirationDate: any } };

export type RemoveNotificationMutationVariables = Exact<{
  _id: Scalars['ObjectId']['input'];
}>;


export type RemoveNotificationMutation = { __typename?: 'Mutation', removeNotification?: boolean | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties', userType: UserType, language: UserLanguage } | { __typename?: 'CustomerUserProperties', userType: UserType, language: UserLanguage, relationships: Array<{ __typename?: 'CustomerRelationship', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties', userType: UserType, managerRoles: Array<ManagerRole>, language: UserLanguage } } };

export type SetMyLanguageMutationVariables = Exact<{
  language: UserLanguage;
}>;


export type SetMyLanguageMutation = { __typename?: 'Mutation', setMyLanguage: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties', userType: UserType, language: UserLanguage } | { __typename?: 'CustomerUserProperties', userType: UserType, language: UserLanguage, relationships: Array<{ __typename?: 'CustomerRelationship', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties', userType: UserType, managerRoles: Array<ManagerRole>, language: UserLanguage } } };

export type GetManagersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArgs>;
  filter?: InputMaybe<UserFilterInput>;
}>;


export type GetManagersQuery = { __typename?: 'Query', managerUsers: { __typename?: 'PaginatedUsers', currentPage: number, totalPages: number, items: Array<{ __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties' } | { __typename?: 'ManagerUserProperties', userType: UserType, language: UserLanguage, managerRoles: Array<ManagerRole> } }> } };

export type UpdateCustomerUserRelationshipsMutationVariables = Exact<{
  _id: Scalars['String']['input'];
  relationships: Array<Scalars['ObjectId']['input']> | Scalars['ObjectId']['input'];
}>;


export type UpdateCustomerUserRelationshipsMutation = { __typename?: 'Mutation', updateCustomerUserRelationships?: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties', userType: UserType, language: UserLanguage, relationships: Array<{ __typename?: 'CustomerRelationship', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties' } } | null };

export type UpdateManagerRoleMutationVariables = Exact<{
  _id: Scalars['String']['input'];
  roles: Array<ManagerRole> | ManagerRole;
}>;


export type UpdateManagerRoleMutation = { __typename?: 'Mutation', updateManagerUserRole?: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties' } | { __typename?: 'ManagerUserProperties', userType: UserType, managerRoles: Array<ManagerRole>, language: UserLanguage } } | null };

export type CreateManagerUserMutationVariables = Exact<{
  managerUser: ManagerUserInput;
}>;


export type CreateManagerUserMutation = { __typename?: 'Mutation', createManagerUser: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties' } | { __typename?: 'ManagerUserProperties', userType: UserType, managerRoles: Array<ManagerRole>, language: UserLanguage } } };

export type DeleteManagerUserMutationVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type DeleteManagerUserMutation = { __typename?: 'Mutation', deleteManagerUser: boolean };

export type QueryGetCustomerUserByIdQueryVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type QueryGetCustomerUserByIdQuery = { __typename?: 'Query', customerUser?: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties', userType: UserType, language: UserLanguage, relationships: Array<{ __typename?: 'CustomerRelationship', userRole: CustomerUserRole, customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties' } } | null };

export type QueryIsEmailAvailableQueryVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type QueryIsEmailAvailableQuery = { __typename?: 'Query', isEmailAvailable: boolean };

export type GetCustomerUsersQueryVariables = Exact<{
  filter: UserFilterInput;
  pagination?: InputMaybe<CustomerUsersPaginationArgs>;
}>;


export type GetCustomerUsersQuery = { __typename?: 'Query', customerUsers: { __typename?: 'PaginatedUsers', currentPage: number, totalPages: number, items: Array<{ __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties', userType: UserType, language: UserLanguage, relationships: Array<{ __typename?: 'CustomerRelationship', userRole: CustomerUserRole, customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties' } }> } };

export type GetCustomerAccountByIdQueryVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type GetCustomerAccountByIdQuery = { __typename?: 'Query', customerUserAccount?: { __typename?: 'B2CAccount', id: string, accountEnabled: boolean, displayName: string, givenName: string, surname: string, email: string, lastSignInDateTime?: string | null, passwordPolicies: Array<B2CPasswordPolicy>, mfaMethod?: B2CmfaMethod | null, mustResetPassword?: boolean | null, identities?: Array<{ __typename?: 'B2CAccountIdentity', issuerAssignedId: string, issuer: string }> | null } | null };

export type CreateCustomerUserMutationVariables = Exact<{
  customerUser: CustomerUserInput;
}>;


export type CreateCustomerUserMutation = { __typename?: 'Mutation', createCustomerUser: { __typename?: 'User', _id: string, properties: { __typename?: 'BaseUserProperties' } | { __typename?: 'CustomerUserProperties', language: UserLanguage, userType: UserType, relationships: Array<{ __typename?: 'CustomerRelationship', customer: { __typename?: 'Customer', _id: any, name: string, disabled: boolean } }> } | { __typename?: 'ManagerUserProperties' } } };

export type UpdateCustomerUserLockMutationVariables = Exact<{
  locked: Scalars['Boolean']['input'];
  _id: Scalars['String']['input'];
}>;


export type UpdateCustomerUserLockMutation = { __typename?: 'Mutation', updateCustomerUserLock: boolean };

export type UpdateCustomerUserForcePasswordResetMutationVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type UpdateCustomerUserForcePasswordResetMutation = { __typename?: 'Mutation', updateCustomerUserForcePasswordReset: boolean };

export type UpdateCustomerUserMfaMethodMutationVariables = Exact<{
  method: B2CmfaMethod;
  _id: Scalars['String']['input'];
}>;


export type UpdateCustomerUserMfaMethodMutation = { __typename?: 'Mutation', updateCustomerUserMFAMethod: boolean };

export type DeleteCustomerUserMutationVariables = Exact<{
  _id: Scalars['String']['input'];
}>;


export type DeleteCustomerUserMutation = { __typename?: 'Mutation', deleteCustomerUser: boolean };


export const GetCrudAgendaEntriesDocument = gql`
    query GetCrudAgendaEntries($filter: AgendaEntryFilterInput, $pagination: PaginationArgs, $customersFilter: CustomerFilterInput, $customersPagination: CustomerPaginationArgs) {
  agendaEntries(filter: $filter, pagination: $pagination) {
    items {
      _id
      title
      date
      tags
      type
      properties {
        ... on Event {
          cta {
            link {
              label
              title
              url
            }
          }
          teaser
        }
        ... on Deadline {
          cta {
            contactPerson {
              _id
              firstName
              lastName
              email
              title
              phone
              specialisation
              picture {
                downloadUrl
              }
            }
          }
          teaser
        }
      }
      target {
        customers {
          _id
          name
        }
        services
        industries
      }
    }
    currentPage
    totalPages
  }
  customers(filter: $customersFilter, pagination: $customersPagination) {
    items {
      _id
      name
    }
  }
}
    `;

/**
 * __useGetCrudAgendaEntriesQuery__
 *
 * To run a query within a React component, call `useGetCrudAgendaEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrudAgendaEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrudAgendaEntriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      customersFilter: // value for 'customersFilter'
 *      customersPagination: // value for 'customersPagination'
 *   },
 * });
 */
export function useGetCrudAgendaEntriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCrudAgendaEntriesQuery, GetCrudAgendaEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCrudAgendaEntriesQuery, GetCrudAgendaEntriesQueryVariables>(GetCrudAgendaEntriesDocument, options);
      }
export function useGetCrudAgendaEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCrudAgendaEntriesQuery, GetCrudAgendaEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCrudAgendaEntriesQuery, GetCrudAgendaEntriesQueryVariables>(GetCrudAgendaEntriesDocument, options);
        }
export type GetCrudAgendaEntriesQueryHookResult = ReturnType<typeof useGetCrudAgendaEntriesQuery>;
export type GetCrudAgendaEntriesLazyQueryHookResult = ReturnType<typeof useGetCrudAgendaEntriesLazyQuery>;
export type GetCrudAgendaEntriesQueryResult = Apollo.QueryResult<GetCrudAgendaEntriesQuery, GetCrudAgendaEntriesQueryVariables>;
export const GetAgendaEntriesDocument = gql`
    query GetAgendaEntries($filter: AgendaEntryFilterInput, $pagination: PaginationArgs) {
  agendaEntries(filter: $filter, pagination: $pagination) {
    items {
      _id
      title
      date
      tags
      type
      properties {
        ... on Event {
          cta {
            link {
              label
              title
              url
            }
          }
          teaser
        }
        ... on Deadline {
          cta {
            contactPerson {
              _id
              firstName
              lastName
              email
              title
              phone
              specialisation
              picture {
                downloadUrl
              }
            }
          }
          teaser
        }
      }
      target {
        customers {
          _id
          name
        }
        services
        industries
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetAgendaEntriesQuery__
 *
 * To run a query within a React component, call `useGetAgendaEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgendaEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgendaEntriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAgendaEntriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAgendaEntriesQuery, GetAgendaEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgendaEntriesQuery, GetAgendaEntriesQueryVariables>(GetAgendaEntriesDocument, options);
      }
export function useGetAgendaEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgendaEntriesQuery, GetAgendaEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgendaEntriesQuery, GetAgendaEntriesQueryVariables>(GetAgendaEntriesDocument, options);
        }
export type GetAgendaEntriesQueryHookResult = ReturnType<typeof useGetAgendaEntriesQuery>;
export type GetAgendaEntriesLazyQueryHookResult = ReturnType<typeof useGetAgendaEntriesLazyQuery>;
export type GetAgendaEntriesQueryResult = Apollo.QueryResult<GetAgendaEntriesQuery, GetAgendaEntriesQueryVariables>;
export const QueryGetAgendaEntriesTagsDocument = gql`
    query QueryGetAgendaEntriesTags {
  agendaEntriesTags
}
    `;

/**
 * __useQueryGetAgendaEntriesTagsQuery__
 *
 * To run a query within a React component, call `useQueryGetAgendaEntriesTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetAgendaEntriesTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetAgendaEntriesTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetAgendaEntriesTagsQuery(baseOptions?: Apollo.QueryHookOptions<QueryGetAgendaEntriesTagsQuery, QueryGetAgendaEntriesTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetAgendaEntriesTagsQuery, QueryGetAgendaEntriesTagsQueryVariables>(QueryGetAgendaEntriesTagsDocument, options);
      }
export function useQueryGetAgendaEntriesTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetAgendaEntriesTagsQuery, QueryGetAgendaEntriesTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetAgendaEntriesTagsQuery, QueryGetAgendaEntriesTagsQueryVariables>(QueryGetAgendaEntriesTagsDocument, options);
        }
export type QueryGetAgendaEntriesTagsQueryHookResult = ReturnType<typeof useQueryGetAgendaEntriesTagsQuery>;
export type QueryGetAgendaEntriesTagsLazyQueryHookResult = ReturnType<typeof useQueryGetAgendaEntriesTagsLazyQuery>;
export type QueryGetAgendaEntriesTagsQueryResult = Apollo.QueryResult<QueryGetAgendaEntriesTagsQuery, QueryGetAgendaEntriesTagsQueryVariables>;
export const QueryGetAgendaEntryToViewDocument = gql`
    query QueryGetAgendaEntryToView($_id: ObjectId!) {
  agendaEntry(_id: $_id) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Event {
        cta {
          link {
            label
            title
            url
          }
        }
        richContent
      }
      ... on Deadline {
        cta {
          contactPerson {
            _id
            firstName
            lastName
            email
            title
            phone
            specialisation
            picture {
              downloadUrl
            }
          }
        }
        description
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;

/**
 * __useQueryGetAgendaEntryToViewQuery__
 *
 * To run a query within a React component, call `useQueryGetAgendaEntryToViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetAgendaEntryToViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetAgendaEntryToViewQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useQueryGetAgendaEntryToViewQuery(baseOptions: Apollo.QueryHookOptions<QueryGetAgendaEntryToViewQuery, QueryGetAgendaEntryToViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetAgendaEntryToViewQuery, QueryGetAgendaEntryToViewQueryVariables>(QueryGetAgendaEntryToViewDocument, options);
      }
export function useQueryGetAgendaEntryToViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetAgendaEntryToViewQuery, QueryGetAgendaEntryToViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetAgendaEntryToViewQuery, QueryGetAgendaEntryToViewQueryVariables>(QueryGetAgendaEntryToViewDocument, options);
        }
export type QueryGetAgendaEntryToViewQueryHookResult = ReturnType<typeof useQueryGetAgendaEntryToViewQuery>;
export type QueryGetAgendaEntryToViewLazyQueryHookResult = ReturnType<typeof useQueryGetAgendaEntryToViewLazyQuery>;
export type QueryGetAgendaEntryToViewQueryResult = Apollo.QueryResult<QueryGetAgendaEntryToViewQuery, QueryGetAgendaEntryToViewQueryVariables>;
export const QueryGetAgendaEntryToEditDocument = gql`
    query QueryGetAgendaEntryToEdit($_id: ObjectId!) {
  agendaEntry(_id: $_id) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Event {
        cta {
          link {
            label
            title
            url
          }
        }
        rawContent
      }
      ... on Deadline {
        cta {
          contactPerson {
            _id
            firstName
            lastName
            email
            title
            phone
            specialisation
            picture {
              downloadUrl
            }
          }
        }
        description
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;

/**
 * __useQueryGetAgendaEntryToEditQuery__
 *
 * To run a query within a React component, call `useQueryGetAgendaEntryToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetAgendaEntryToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetAgendaEntryToEditQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useQueryGetAgendaEntryToEditQuery(baseOptions: Apollo.QueryHookOptions<QueryGetAgendaEntryToEditQuery, QueryGetAgendaEntryToEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetAgendaEntryToEditQuery, QueryGetAgendaEntryToEditQueryVariables>(QueryGetAgendaEntryToEditDocument, options);
      }
export function useQueryGetAgendaEntryToEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetAgendaEntryToEditQuery, QueryGetAgendaEntryToEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetAgendaEntryToEditQuery, QueryGetAgendaEntryToEditQueryVariables>(QueryGetAgendaEntryToEditDocument, options);
        }
export type QueryGetAgendaEntryToEditQueryHookResult = ReturnType<typeof useQueryGetAgendaEntryToEditQuery>;
export type QueryGetAgendaEntryToEditLazyQueryHookResult = ReturnType<typeof useQueryGetAgendaEntryToEditLazyQuery>;
export type QueryGetAgendaEntryToEditQueryResult = Apollo.QueryResult<QueryGetAgendaEntryToEditQuery, QueryGetAgendaEntryToEditQueryVariables>;
export const CreateAgendaEventDocument = gql`
    mutation CreateAgendaEvent($event: AgendaEventInput!) {
  createAgendaEvent(event: $event) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Event {
        cta {
          link {
            label
            title
            url
          }
        }
        rawContent
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;
export type CreateAgendaEventMutationFn = Apollo.MutationFunction<CreateAgendaEventMutation, CreateAgendaEventMutationVariables>;

/**
 * __useCreateAgendaEventMutation__
 *
 * To run a mutation, you first call `useCreateAgendaEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgendaEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgendaEventMutation, { data, loading, error }] = useCreateAgendaEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateAgendaEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgendaEventMutation, CreateAgendaEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgendaEventMutation, CreateAgendaEventMutationVariables>(CreateAgendaEventDocument, options);
      }
export type CreateAgendaEventMutationHookResult = ReturnType<typeof useCreateAgendaEventMutation>;
export type CreateAgendaEventMutationResult = Apollo.MutationResult<CreateAgendaEventMutation>;
export type CreateAgendaEventMutationOptions = Apollo.BaseMutationOptions<CreateAgendaEventMutation, CreateAgendaEventMutationVariables>;
export const CreateAgendaDeadlineDocument = gql`
    mutation CreateAgendaDeadline($deadline: AgendaDeadlineInput!) {
  createAgendaDeadline(deadline: $deadline) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Deadline {
        cta {
          contactPerson {
            _id
            firstName
            lastName
            email
            title
            phone
            specialisation
            picture {
              downloadUrl
            }
          }
        }
        description
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;
export type CreateAgendaDeadlineMutationFn = Apollo.MutationFunction<CreateAgendaDeadlineMutation, CreateAgendaDeadlineMutationVariables>;

/**
 * __useCreateAgendaDeadlineMutation__
 *
 * To run a mutation, you first call `useCreateAgendaDeadlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgendaDeadlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgendaDeadlineMutation, { data, loading, error }] = useCreateAgendaDeadlineMutation({
 *   variables: {
 *      deadline: // value for 'deadline'
 *   },
 * });
 */
export function useCreateAgendaDeadlineMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgendaDeadlineMutation, CreateAgendaDeadlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgendaDeadlineMutation, CreateAgendaDeadlineMutationVariables>(CreateAgendaDeadlineDocument, options);
      }
export type CreateAgendaDeadlineMutationHookResult = ReturnType<typeof useCreateAgendaDeadlineMutation>;
export type CreateAgendaDeadlineMutationResult = Apollo.MutationResult<CreateAgendaDeadlineMutation>;
export type CreateAgendaDeadlineMutationOptions = Apollo.BaseMutationOptions<CreateAgendaDeadlineMutation, CreateAgendaDeadlineMutationVariables>;
export const UpdateAgendaEventDocument = gql`
    mutation UpdateAgendaEvent($event: AgendaEventInput!, $_id: ObjectId!) {
  updateAgendaEvent(event: $event, _id: $_id) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Event {
        cta {
          link {
            label
            title
            url
          }
        }
        rawContent
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;
export type UpdateAgendaEventMutationFn = Apollo.MutationFunction<UpdateAgendaEventMutation, UpdateAgendaEventMutationVariables>;

/**
 * __useUpdateAgendaEventMutation__
 *
 * To run a mutation, you first call `useUpdateAgendaEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgendaEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgendaEventMutation, { data, loading, error }] = useUpdateAgendaEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateAgendaEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgendaEventMutation, UpdateAgendaEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgendaEventMutation, UpdateAgendaEventMutationVariables>(UpdateAgendaEventDocument, options);
      }
export type UpdateAgendaEventMutationHookResult = ReturnType<typeof useUpdateAgendaEventMutation>;
export type UpdateAgendaEventMutationResult = Apollo.MutationResult<UpdateAgendaEventMutation>;
export type UpdateAgendaEventMutationOptions = Apollo.BaseMutationOptions<UpdateAgendaEventMutation, UpdateAgendaEventMutationVariables>;
export const UpdateAgendaDeadlineDocument = gql`
    mutation UpdateAgendaDeadline($deadline: AgendaDeadlineInput!, $_id: ObjectId!) {
  updateAgendaDeadline(deadline: $deadline, _id: $_id) {
    _id
    title
    date
    tags
    type
    properties {
      ... on Deadline {
        cta {
          contactPerson {
            _id
            firstName
            lastName
            email
            title
            phone
            specialisation
            picture {
              downloadUrl
            }
          }
        }
        description
      }
    }
    target {
      customers {
        _id
        name
      }
      services
      industries
    }
  }
}
    `;
export type UpdateAgendaDeadlineMutationFn = Apollo.MutationFunction<UpdateAgendaDeadlineMutation, UpdateAgendaDeadlineMutationVariables>;

/**
 * __useUpdateAgendaDeadlineMutation__
 *
 * To run a mutation, you first call `useUpdateAgendaDeadlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgendaDeadlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgendaDeadlineMutation, { data, loading, error }] = useUpdateAgendaDeadlineMutation({
 *   variables: {
 *      deadline: // value for 'deadline'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateAgendaDeadlineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgendaDeadlineMutation, UpdateAgendaDeadlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgendaDeadlineMutation, UpdateAgendaDeadlineMutationVariables>(UpdateAgendaDeadlineDocument, options);
      }
export type UpdateAgendaDeadlineMutationHookResult = ReturnType<typeof useUpdateAgendaDeadlineMutation>;
export type UpdateAgendaDeadlineMutationResult = Apollo.MutationResult<UpdateAgendaDeadlineMutation>;
export type UpdateAgendaDeadlineMutationOptions = Apollo.BaseMutationOptions<UpdateAgendaDeadlineMutation, UpdateAgendaDeadlineMutationVariables>;
export const RemoveAgendaEntryByIdDocument = gql`
    mutation RemoveAgendaEntryById($_id: ObjectId!) {
  removeAgendaEntryById(_id: $_id)
}
    `;
export type RemoveAgendaEntryByIdMutationFn = Apollo.MutationFunction<RemoveAgendaEntryByIdMutation, RemoveAgendaEntryByIdMutationVariables>;

/**
 * __useRemoveAgendaEntryByIdMutation__
 *
 * To run a mutation, you first call `useRemoveAgendaEntryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAgendaEntryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAgendaEntryByIdMutation, { data, loading, error }] = useRemoveAgendaEntryByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveAgendaEntryByIdMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAgendaEntryByIdMutation, RemoveAgendaEntryByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAgendaEntryByIdMutation, RemoveAgendaEntryByIdMutationVariables>(RemoveAgendaEntryByIdDocument, options);
      }
export type RemoveAgendaEntryByIdMutationHookResult = ReturnType<typeof useRemoveAgendaEntryByIdMutation>;
export type RemoveAgendaEntryByIdMutationResult = Apollo.MutationResult<RemoveAgendaEntryByIdMutation>;
export type RemoveAgendaEntryByIdMutationOptions = Apollo.BaseMutationOptions<RemoveAgendaEntryByIdMutation, RemoveAgendaEntryByIdMutationVariables>;
export const GetAppsDocument = gql`
    query GetApps {
  apps {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;

/**
 * __useGetAppsQuery__
 *
 * To run a query within a React component, call `useGetAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetAppsQuery, GetAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppsQuery, GetAppsQueryVariables>(GetAppsDocument, options);
      }
export function useGetAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppsQuery, GetAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppsQuery, GetAppsQueryVariables>(GetAppsDocument, options);
        }
export type GetAppsQueryHookResult = ReturnType<typeof useGetAppsQuery>;
export type GetAppsLazyQueryHookResult = ReturnType<typeof useGetAppsLazyQuery>;
export type GetAppsQueryResult = Apollo.QueryResult<GetAppsQuery, GetAppsQueryVariables>;
export const GeyAppsByIdDocument = gql`
    query GeyAppsById($_id: ObjectId!) {
  app(_id: $_id) {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;

/**
 * __useGeyAppsByIdQuery__
 *
 * To run a query within a React component, call `useGeyAppsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeyAppsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeyAppsByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGeyAppsByIdQuery(baseOptions: Apollo.QueryHookOptions<GeyAppsByIdQuery, GeyAppsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeyAppsByIdQuery, GeyAppsByIdQueryVariables>(GeyAppsByIdDocument, options);
      }
export function useGeyAppsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeyAppsByIdQuery, GeyAppsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeyAppsByIdQuery, GeyAppsByIdQueryVariables>(GeyAppsByIdDocument, options);
        }
export type GeyAppsByIdQueryHookResult = ReturnType<typeof useGeyAppsByIdQuery>;
export type GeyAppsByIdLazyQueryHookResult = ReturnType<typeof useGeyAppsByIdLazyQuery>;
export type GeyAppsByIdQueryResult = Apollo.QueryResult<GeyAppsByIdQuery, GeyAppsByIdQueryVariables>;
export const CreateAppDocument = gql`
    mutation CreateApp($app: AppInput!) {
  createApp(app: $app) {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;
export type CreateAppMutationFn = Apollo.MutationFunction<CreateAppMutation, CreateAppMutationVariables>;

/**
 * __useCreateAppMutation__
 *
 * To run a mutation, you first call `useCreateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMutation, { data, loading, error }] = useCreateAppMutation({
 *   variables: {
 *      app: // value for 'app'
 *   },
 * });
 */
export function useCreateAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppMutation, CreateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppMutation, CreateAppMutationVariables>(CreateAppDocument, options);
      }
export type CreateAppMutationHookResult = ReturnType<typeof useCreateAppMutation>;
export type CreateAppMutationResult = Apollo.MutationResult<CreateAppMutation>;
export type CreateAppMutationOptions = Apollo.BaseMutationOptions<CreateAppMutation, CreateAppMutationVariables>;
export const UpdateAppDocument = gql`
    mutation UpdateApp($_id: ObjectId!, $app: AppInput!) {
  updateApp(_id: $_id, app: $app) {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;
export type UpdateAppMutationFn = Apollo.MutationFunction<UpdateAppMutation, UpdateAppMutationVariables>;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      app: // value for 'app'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppMutation, UpdateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument, options);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;
export type UpdateAppMutationResult = Apollo.MutationResult<UpdateAppMutation>;
export type UpdateAppMutationOptions = Apollo.BaseMutationOptions<UpdateAppMutation, UpdateAppMutationVariables>;
export const UpdateAppSubscriptionDocument = gql`
    mutation UpdateAppSubscription($subscription: SubscriptionInput!) {
  updateAppSubscription(subscription: $subscription) {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;
export type UpdateAppSubscriptionMutationFn = Apollo.MutationFunction<UpdateAppSubscriptionMutation, UpdateAppSubscriptionMutationVariables>;

/**
 * __useUpdateAppSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateAppSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSubscriptionMutation, { data, loading, error }] = useUpdateAppSubscriptionMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useUpdateAppSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppSubscriptionMutation, UpdateAppSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppSubscriptionMutation, UpdateAppSubscriptionMutationVariables>(UpdateAppSubscriptionDocument, options);
      }
export type UpdateAppSubscriptionMutationHookResult = ReturnType<typeof useUpdateAppSubscriptionMutation>;
export type UpdateAppSubscriptionMutationResult = Apollo.MutationResult<UpdateAppSubscriptionMutation>;
export type UpdateAppSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateAppSubscriptionMutation, UpdateAppSubscriptionMutationVariables>;
export const UpdateAppSubscriptionsDocument = gql`
    mutation UpdateAppSubscriptions($subscriptions: SubscriptionsInput!) {
  updateAppSubscriptions(subscriptions: $subscriptions) {
    _id
    name
    description
    url
    fallbackUrl
    logo {
      _id
      filename
      mimeType
      downloadUrl
    }
    status
    maintenanceWindow {
      from
      to
    }
    subscriptions {
      customer {
        _id
        name
        disabled
      }
    }
  }
}
    `;
export type UpdateAppSubscriptionsMutationFn = Apollo.MutationFunction<UpdateAppSubscriptionsMutation, UpdateAppSubscriptionsMutationVariables>;

/**
 * __useUpdateAppSubscriptionsMutation__
 *
 * To run a mutation, you first call `useUpdateAppSubscriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSubscriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSubscriptionsMutation, { data, loading, error }] = useUpdateAppSubscriptionsMutation({
 *   variables: {
 *      subscriptions: // value for 'subscriptions'
 *   },
 * });
 */
export function useUpdateAppSubscriptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppSubscriptionsMutation, UpdateAppSubscriptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppSubscriptionsMutation, UpdateAppSubscriptionsMutationVariables>(UpdateAppSubscriptionsDocument, options);
      }
export type UpdateAppSubscriptionsMutationHookResult = ReturnType<typeof useUpdateAppSubscriptionsMutation>;
export type UpdateAppSubscriptionsMutationResult = Apollo.MutationResult<UpdateAppSubscriptionsMutation>;
export type UpdateAppSubscriptionsMutationOptions = Apollo.BaseMutationOptions<UpdateAppSubscriptionsMutation, UpdateAppSubscriptionsMutationVariables>;
export const QueryGetAppsUsersSubscriptionByCustomerIdDocument = gql`
    query QueryGetAppsUsersSubscriptionByCustomerId($customerId: ObjectId!) {
  appsUsersSubscriptions(customerId: $customerId) {
    _id
    name
    customerId
    customerUserIds
  }
}
    `;

/**
 * __useQueryGetAppsUsersSubscriptionByCustomerIdQuery__
 *
 * To run a query within a React component, call `useQueryGetAppsUsersSubscriptionByCustomerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetAppsUsersSubscriptionByCustomerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetAppsUsersSubscriptionByCustomerIdQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useQueryGetAppsUsersSubscriptionByCustomerIdQuery(baseOptions: Apollo.QueryHookOptions<QueryGetAppsUsersSubscriptionByCustomerIdQuery, QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetAppsUsersSubscriptionByCustomerIdQuery, QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables>(QueryGetAppsUsersSubscriptionByCustomerIdDocument, options);
      }
export function useQueryGetAppsUsersSubscriptionByCustomerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetAppsUsersSubscriptionByCustomerIdQuery, QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetAppsUsersSubscriptionByCustomerIdQuery, QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables>(QueryGetAppsUsersSubscriptionByCustomerIdDocument, options);
        }
export type QueryGetAppsUsersSubscriptionByCustomerIdQueryHookResult = ReturnType<typeof useQueryGetAppsUsersSubscriptionByCustomerIdQuery>;
export type QueryGetAppsUsersSubscriptionByCustomerIdLazyQueryHookResult = ReturnType<typeof useQueryGetAppsUsersSubscriptionByCustomerIdLazyQuery>;
export type QueryGetAppsUsersSubscriptionByCustomerIdQueryResult = Apollo.QueryResult<QueryGetAppsUsersSubscriptionByCustomerIdQuery, QueryGetAppsUsersSubscriptionByCustomerIdQueryVariables>;
export const GetArticlesDocument = gql`
    query GetArticles($filter: ArticleFilterInput, $pagination: PaginationArgs) {
  articles(filter: $filter, pagination: $pagination) {
    items {
      _id
      slug
      title
      teaser
      status
      publicationDate
      properties {
        tags
        mainPicture {
          _id
          downloadUrl
          filename
          mimeType
        }
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetArticlesQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
      }
export function useGetArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesQuery, GetArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(GetArticlesDocument, options);
        }
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<typeof useGetArticlesLazyQuery>;
export type GetArticlesQueryResult = Apollo.QueryResult<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetArticlesWithAuditTrailDocument = gql`
    query GetArticlesWithAuditTrail($filter: ArticleFilterInput, $pagination: PaginationArgs) {
  articles(filter: $filter, pagination: $pagination) {
    items {
      _id
      slug
      title
      teaser
      status
      publicationDate
      properties {
        tags
        mainPicture {
          _id
          downloadUrl
          filename
          mimeType
        }
      }
      auditTrail {
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetArticlesWithAuditTrailQuery__
 *
 * To run a query within a React component, call `useGetArticlesWithAuditTrailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesWithAuditTrailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesWithAuditTrailQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetArticlesWithAuditTrailQuery(baseOptions?: Apollo.QueryHookOptions<GetArticlesWithAuditTrailQuery, GetArticlesWithAuditTrailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticlesWithAuditTrailQuery, GetArticlesWithAuditTrailQueryVariables>(GetArticlesWithAuditTrailDocument, options);
      }
export function useGetArticlesWithAuditTrailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticlesWithAuditTrailQuery, GetArticlesWithAuditTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticlesWithAuditTrailQuery, GetArticlesWithAuditTrailQueryVariables>(GetArticlesWithAuditTrailDocument, options);
        }
export type GetArticlesWithAuditTrailQueryHookResult = ReturnType<typeof useGetArticlesWithAuditTrailQuery>;
export type GetArticlesWithAuditTrailLazyQueryHookResult = ReturnType<typeof useGetArticlesWithAuditTrailLazyQuery>;
export type GetArticlesWithAuditTrailQueryResult = Apollo.QueryResult<GetArticlesWithAuditTrailQuery, GetArticlesWithAuditTrailQueryVariables>;
export const GetArticleToEditDocument = gql`
    query GetArticleToEdit($selector: ArticleSelectorInput!) {
  article(selector: $selector) {
    _id
    slug
    title
    rawContent
    status
    publicationDate
    properties {
      tags
      mainPicture {
        _id
        downloadUrl
        filename
        mimeType
      }
      contactPerson {
        _id
        firstName
        lastName
        email
        title
        phone
        specialisation
        picture {
          downloadUrl
        }
      }
    }
    auditTrail {
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
}
    `;

/**
 * __useGetArticleToEditQuery__
 *
 * To run a query within a React component, call `useGetArticleToEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticleToEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticleToEditQuery({
 *   variables: {
 *      selector: // value for 'selector'
 *   },
 * });
 */
export function useGetArticleToEditQuery(baseOptions: Apollo.QueryHookOptions<GetArticleToEditQuery, GetArticleToEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArticleToEditQuery, GetArticleToEditQueryVariables>(GetArticleToEditDocument, options);
      }
export function useGetArticleToEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArticleToEditQuery, GetArticleToEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArticleToEditQuery, GetArticleToEditQueryVariables>(GetArticleToEditDocument, options);
        }
export type GetArticleToEditQueryHookResult = ReturnType<typeof useGetArticleToEditQuery>;
export type GetArticleToEditLazyQueryHookResult = ReturnType<typeof useGetArticleToEditLazyQuery>;
export type GetArticleToEditQueryResult = Apollo.QueryResult<GetArticleToEditQuery, GetArticleToEditQueryVariables>;
export const QueryGetArticleToViewDocument = gql`
    query QueryGetArticleToView($selector: ArticleSelectorInput!) {
  article(selector: $selector) {
    _id
    slug
    title
    richContent
    status
    publicationDate
    properties {
      tags
      mainPicture {
        _id
        downloadUrl
        filename
        mimeType
      }
      contactPerson {
        _id
        firstName
        lastName
        email
        title
        phone
        specialisation
        picture {
          downloadUrl
        }
      }
    }
  }
}
    `;

/**
 * __useQueryGetArticleToViewQuery__
 *
 * To run a query within a React component, call `useQueryGetArticleToViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetArticleToViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetArticleToViewQuery({
 *   variables: {
 *      selector: // value for 'selector'
 *   },
 * });
 */
export function useQueryGetArticleToViewQuery(baseOptions: Apollo.QueryHookOptions<QueryGetArticleToViewQuery, QueryGetArticleToViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetArticleToViewQuery, QueryGetArticleToViewQueryVariables>(QueryGetArticleToViewDocument, options);
      }
export function useQueryGetArticleToViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetArticleToViewQuery, QueryGetArticleToViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetArticleToViewQuery, QueryGetArticleToViewQueryVariables>(QueryGetArticleToViewDocument, options);
        }
export type QueryGetArticleToViewQueryHookResult = ReturnType<typeof useQueryGetArticleToViewQuery>;
export type QueryGetArticleToViewLazyQueryHookResult = ReturnType<typeof useQueryGetArticleToViewLazyQuery>;
export type QueryGetArticleToViewQueryResult = Apollo.QueryResult<QueryGetArticleToViewQuery, QueryGetArticleToViewQueryVariables>;
export const QueryGetArticlesTagsDocument = gql`
    query QueryGetArticlesTags {
  articlesTags
}
    `;

/**
 * __useQueryGetArticlesTagsQuery__
 *
 * To run a query within a React component, call `useQueryGetArticlesTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetArticlesTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetArticlesTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetArticlesTagsQuery(baseOptions?: Apollo.QueryHookOptions<QueryGetArticlesTagsQuery, QueryGetArticlesTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetArticlesTagsQuery, QueryGetArticlesTagsQueryVariables>(QueryGetArticlesTagsDocument, options);
      }
export function useQueryGetArticlesTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetArticlesTagsQuery, QueryGetArticlesTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetArticlesTagsQuery, QueryGetArticlesTagsQueryVariables>(QueryGetArticlesTagsDocument, options);
        }
export type QueryGetArticlesTagsQueryHookResult = ReturnType<typeof useQueryGetArticlesTagsQuery>;
export type QueryGetArticlesTagsLazyQueryHookResult = ReturnType<typeof useQueryGetArticlesTagsLazyQuery>;
export type QueryGetArticlesTagsQueryResult = Apollo.QueryResult<QueryGetArticlesTagsQuery, QueryGetArticlesTagsQueryVariables>;
export const CreateArticleDocument = gql`
    mutation CreateArticle($article: ArticleCreateInput!) {
  createArticle(article: $article) {
    _id
    slug
    title
    rawContent
    status
    publicationDate
    properties {
      tags
      mainPicture {
        _id
        downloadUrl
        filename
        mimeType
      }
      contactPerson {
        _id
        firstName
        lastName
        email
        title
        specialisation
        picture {
          downloadUrl
        }
      }
    }
  }
}
    `;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      article: // value for 'article'
 *   },
 * });
 */
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<CreateArticleMutation, CreateArticleMutationVariables>;
export const UpdateArticleDocument = gql`
    mutation UpdateArticle($_id: String!, $article: ArticleUpdateInput!) {
  updateArticle(_id: $_id, article: $article) {
    _id
    slug
    title
    rawContent
    status
    publicationDate
    properties {
      tags
      mainPicture {
        _id
        downloadUrl
        filename
        mimeType
      }
      contactPerson {
        _id
        firstName
        lastName
        email
        title
        specialisation
        picture {
          downloadUrl
        }
      }
    }
  }
}
    `;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      article: // value for 'article'
 *   },
 * });
 */
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<UpdateArticleMutation, UpdateArticleMutationVariables>;
export const GetContactsDocument = gql`
    query GetContacts {
  contacts {
    _id
    firstName
    lastName
    email
    phone
    title
    specialisation
    picture {
      _id
      downloadUrl
      filename
      mimeType
    }
  }
}
    `;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetContactByIdDocument = gql`
    query GetContactById($_id: ObjectId!) {
  contact(_id: $_id) {
    _id
    firstName
    lastName
    email
    phone
    title
    specialisation
    picture {
      _id
      downloadUrl
      filename
      mimeType
    }
  }
}
    `;

/**
 * __useGetContactByIdQuery__
 *
 * To run a query within a React component, call `useGetContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetContactByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(GetContactByIdDocument, options);
      }
export function useGetContactByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactByIdQuery, GetContactByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactByIdQuery, GetContactByIdQueryVariables>(GetContactByIdDocument, options);
        }
export type GetContactByIdQueryHookResult = ReturnType<typeof useGetContactByIdQuery>;
export type GetContactByIdLazyQueryHookResult = ReturnType<typeof useGetContactByIdLazyQuery>;
export type GetContactByIdQueryResult = Apollo.QueryResult<GetContactByIdQuery, GetContactByIdQueryVariables>;
export const QueryGetContactsSpecialisationsDocument = gql`
    query QueryGetContactsSpecialisations {
  contactsSpecialisations
}
    `;

/**
 * __useQueryGetContactsSpecialisationsQuery__
 *
 * To run a query within a React component, call `useQueryGetContactsSpecialisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetContactsSpecialisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetContactsSpecialisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQueryGetContactsSpecialisationsQuery(baseOptions?: Apollo.QueryHookOptions<QueryGetContactsSpecialisationsQuery, QueryGetContactsSpecialisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetContactsSpecialisationsQuery, QueryGetContactsSpecialisationsQueryVariables>(QueryGetContactsSpecialisationsDocument, options);
      }
export function useQueryGetContactsSpecialisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetContactsSpecialisationsQuery, QueryGetContactsSpecialisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetContactsSpecialisationsQuery, QueryGetContactsSpecialisationsQueryVariables>(QueryGetContactsSpecialisationsDocument, options);
        }
export type QueryGetContactsSpecialisationsQueryHookResult = ReturnType<typeof useQueryGetContactsSpecialisationsQuery>;
export type QueryGetContactsSpecialisationsLazyQueryHookResult = ReturnType<typeof useQueryGetContactsSpecialisationsLazyQuery>;
export type QueryGetContactsSpecialisationsQueryResult = Apollo.QueryResult<QueryGetContactsSpecialisationsQuery, QueryGetContactsSpecialisationsQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($contact: ContactInput!) {
  createContact(contact: $contact) {
    _id
    firstName
    lastName
    email
    phone
    title
    specialisation
    picture {
      _id
      downloadUrl
      filename
      mimeType
    }
  }
}
    `;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($_id: ObjectId!, $contact: ContactInput!) {
  updateContact(_id: $_id, contact: $contact) {
    _id
    firstName
    lastName
    email
    phone
    title
    specialisation
    picture {
      _id
      downloadUrl
      filename
      mimeType
    }
  }
}
    `;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const RemoveContactByIdDocument = gql`
    mutation RemoveContactById($_id: ObjectId!) {
  removeContactById(_id: $_id)
}
    `;
export type RemoveContactByIdMutationFn = Apollo.MutationFunction<RemoveContactByIdMutation, RemoveContactByIdMutationVariables>;

/**
 * __useRemoveContactByIdMutation__
 *
 * To run a mutation, you first call `useRemoveContactByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactByIdMutation, { data, loading, error }] = useRemoveContactByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveContactByIdMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactByIdMutation, RemoveContactByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContactByIdMutation, RemoveContactByIdMutationVariables>(RemoveContactByIdDocument, options);
      }
export type RemoveContactByIdMutationHookResult = ReturnType<typeof useRemoveContactByIdMutation>;
export type RemoveContactByIdMutationResult = Apollo.MutationResult<RemoveContactByIdMutation>;
export type RemoveContactByIdMutationOptions = Apollo.BaseMutationOptions<RemoveContactByIdMutation, RemoveContactByIdMutationVariables>;
export const GetCustomersDocument = gql`
    query GetCustomers($pagination: CustomerPaginationArgs, $filter: CustomerFilterInput) {
  customers(pagination: $pagination, filter: $filter) {
    items {
      _id
      name
      gtCustomerId
      disabled
      properties {
        comments
        contactInfo
        industries
        services
        gtManagers
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetCustomerByIdDocument = gql`
    query GetCustomerById($_id: ObjectId!) {
  customer(_id: $_id) {
    _id
    name
    gtCustomerId
    disabled
    properties {
      comments
      contactInfo
      industries
      services
      gtManagers
    }
  }
}
    `;

/**
 * __useGetCustomerByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
      }
export function useGetCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>(GetCustomerByIdDocument, options);
        }
export type GetCustomerByIdQueryHookResult = ReturnType<typeof useGetCustomerByIdQuery>;
export type GetCustomerByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerByIdLazyQuery>;
export type GetCustomerByIdQueryResult = Apollo.QueryResult<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>;
export const IsGtCustomerIdAvailableDocument = gql`
    query IsGtCustomerIdAvailable($gtCustomerId: String!) {
  isCustomerIdAvailable(gtCustomerId: $gtCustomerId)
}
    `;

/**
 * __useIsGtCustomerIdAvailableQuery__
 *
 * To run a query within a React component, call `useIsGtCustomerIdAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGtCustomerIdAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGtCustomerIdAvailableQuery({
 *   variables: {
 *      gtCustomerId: // value for 'gtCustomerId'
 *   },
 * });
 */
export function useIsGtCustomerIdAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsGtCustomerIdAvailableQuery, IsGtCustomerIdAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsGtCustomerIdAvailableQuery, IsGtCustomerIdAvailableQueryVariables>(IsGtCustomerIdAvailableDocument, options);
      }
export function useIsGtCustomerIdAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsGtCustomerIdAvailableQuery, IsGtCustomerIdAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsGtCustomerIdAvailableQuery, IsGtCustomerIdAvailableQueryVariables>(IsGtCustomerIdAvailableDocument, options);
        }
export type IsGtCustomerIdAvailableQueryHookResult = ReturnType<typeof useIsGtCustomerIdAvailableQuery>;
export type IsGtCustomerIdAvailableLazyQueryHookResult = ReturnType<typeof useIsGtCustomerIdAvailableLazyQuery>;
export type IsGtCustomerIdAvailableQueryResult = Apollo.QueryResult<IsGtCustomerIdAvailableQuery, IsGtCustomerIdAvailableQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($customer: CustomerCreationInput!) {
  createCustomer(customer: $customer) {
    _id
    name
    gtCustomerId
    disabled
    properties {
      comments
      contactInfo
      industries
      services
      gtManagers
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($_id: ObjectId!, $customer: CustomerUpdateInput!) {
  updateCustomer(_id: $_id, customer: $customer) {
    _id
    name
    gtCustomerId
    disabled
    properties {
      comments
      contactInfo
      industries
      services
      gtManagers
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($filter: NotificationFilterInput) {
  notifications(filter: $filter) {
    _id
    description
    expirationDate
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const CreateNotificationDocument = gql`
    mutation CreateNotification($notification: NotificationInput!) {
  createNotification(notification: $notification) {
    _id
    description
    expirationDate
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($_id: ObjectId!, $notification: NotificationInput!) {
  updateNotification(_id: $_id, notification: $notification) {
    _id
    description
    expirationDate
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const RemoveNotificationDocument = gql`
    mutation RemoveNotification($_id: ObjectId!) {
  removeNotification(_id: $_id)
}
    `;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<RemoveNotificationMutation, RemoveNotificationMutationVariables>;

/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationMutation, { data, loading, error }] = useRemoveNotificationMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useRemoveNotificationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNotificationMutation, RemoveNotificationMutationVariables>(RemoveNotificationDocument, options);
      }
export type RemoveNotificationMutationHookResult = ReturnType<typeof useRemoveNotificationMutation>;
export type RemoveNotificationMutationResult = Apollo.MutationResult<RemoveNotificationMutation>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    _id
    properties {
      ... on BaseUserProperties {
        userType
        language
      }
      ... on CustomerUserProperties {
        userType
        language
        relationships {
          customer {
            _id
            name
            disabled
          }
        }
      }
      ... on ManagerUserProperties {
        userType
        managerRoles
        language
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SetMyLanguageDocument = gql`
    mutation SetMyLanguage($language: UserLanguage!) {
  setMyLanguage(language: $language) {
    _id
    properties {
      ... on BaseUserProperties {
        userType
        language
      }
      ... on CustomerUserProperties {
        userType
        language
        relationships {
          customer {
            _id
            name
            disabled
          }
        }
      }
      ... on ManagerUserProperties {
        userType
        managerRoles
        language
      }
    }
  }
}
    `;
export type SetMyLanguageMutationFn = Apollo.MutationFunction<SetMyLanguageMutation, SetMyLanguageMutationVariables>;

/**
 * __useSetMyLanguageMutation__
 *
 * To run a mutation, you first call `useSetMyLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyLanguageMutation, { data, loading, error }] = useSetMyLanguageMutation({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSetMyLanguageMutation(baseOptions?: Apollo.MutationHookOptions<SetMyLanguageMutation, SetMyLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMyLanguageMutation, SetMyLanguageMutationVariables>(SetMyLanguageDocument, options);
      }
export type SetMyLanguageMutationHookResult = ReturnType<typeof useSetMyLanguageMutation>;
export type SetMyLanguageMutationResult = Apollo.MutationResult<SetMyLanguageMutation>;
export type SetMyLanguageMutationOptions = Apollo.BaseMutationOptions<SetMyLanguageMutation, SetMyLanguageMutationVariables>;
export const GetManagersDocument = gql`
    query GetManagers($pagination: PaginationArgs, $filter: UserFilterInput) {
  managerUsers(pagination: $pagination, filter: $filter) {
    items {
      _id
      properties {
        ... on ManagerUserProperties {
          userType
          language
          managerRoles
        }
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetManagersQuery(baseOptions?: Apollo.QueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, options);
      }
export function useGetManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, options);
        }
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<typeof useGetManagersLazyQuery>;
export type GetManagersQueryResult = Apollo.QueryResult<GetManagersQuery, GetManagersQueryVariables>;
export const UpdateCustomerUserRelationshipsDocument = gql`
    mutation UpdateCustomerUserRelationships($_id: String!, $relationships: [ObjectId!]!) {
  updateCustomerUserRelationships(_id: $_id, relationships: $relationships) {
    _id
    properties {
      ... on CustomerUserProperties {
        userType
        language
        relationships {
          customer {
            _id
            name
            disabled
          }
        }
      }
    }
  }
}
    `;
export type UpdateCustomerUserRelationshipsMutationFn = Apollo.MutationFunction<UpdateCustomerUserRelationshipsMutation, UpdateCustomerUserRelationshipsMutationVariables>;

/**
 * __useUpdateCustomerUserRelationshipsMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerUserRelationshipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerUserRelationshipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerUserRelationshipsMutation, { data, loading, error }] = useUpdateCustomerUserRelationshipsMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      relationships: // value for 'relationships'
 *   },
 * });
 */
export function useUpdateCustomerUserRelationshipsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerUserRelationshipsMutation, UpdateCustomerUserRelationshipsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerUserRelationshipsMutation, UpdateCustomerUserRelationshipsMutationVariables>(UpdateCustomerUserRelationshipsDocument, options);
      }
export type UpdateCustomerUserRelationshipsMutationHookResult = ReturnType<typeof useUpdateCustomerUserRelationshipsMutation>;
export type UpdateCustomerUserRelationshipsMutationResult = Apollo.MutationResult<UpdateCustomerUserRelationshipsMutation>;
export type UpdateCustomerUserRelationshipsMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerUserRelationshipsMutation, UpdateCustomerUserRelationshipsMutationVariables>;
export const UpdateManagerRoleDocument = gql`
    mutation UpdateManagerRole($_id: String!, $roles: [ManagerRole!]!) {
  updateManagerUserRole(_id: $_id, roles: $roles) {
    _id
    properties {
      ... on ManagerUserProperties {
        userType
        managerRoles
        language
      }
    }
  }
}
    `;
export type UpdateManagerRoleMutationFn = Apollo.MutationFunction<UpdateManagerRoleMutation, UpdateManagerRoleMutationVariables>;

/**
 * __useUpdateManagerRoleMutation__
 *
 * To run a mutation, you first call `useUpdateManagerRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerRoleMutation, { data, loading, error }] = useUpdateManagerRoleMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUpdateManagerRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManagerRoleMutation, UpdateManagerRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManagerRoleMutation, UpdateManagerRoleMutationVariables>(UpdateManagerRoleDocument, options);
      }
export type UpdateManagerRoleMutationHookResult = ReturnType<typeof useUpdateManagerRoleMutation>;
export type UpdateManagerRoleMutationResult = Apollo.MutationResult<UpdateManagerRoleMutation>;
export type UpdateManagerRoleMutationOptions = Apollo.BaseMutationOptions<UpdateManagerRoleMutation, UpdateManagerRoleMutationVariables>;
export const CreateManagerUserDocument = gql`
    mutation CreateManagerUser($managerUser: ManagerUserInput!) {
  createManagerUser(managerUser: $managerUser) {
    _id
    properties {
      ... on ManagerUserProperties {
        userType
        managerRoles
        language
      }
    }
  }
}
    `;
export type CreateManagerUserMutationFn = Apollo.MutationFunction<CreateManagerUserMutation, CreateManagerUserMutationVariables>;

/**
 * __useCreateManagerUserMutation__
 *
 * To run a mutation, you first call `useCreateManagerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagerUserMutation, { data, loading, error }] = useCreateManagerUserMutation({
 *   variables: {
 *      managerUser: // value for 'managerUser'
 *   },
 * });
 */
export function useCreateManagerUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagerUserMutation, CreateManagerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagerUserMutation, CreateManagerUserMutationVariables>(CreateManagerUserDocument, options);
      }
export type CreateManagerUserMutationHookResult = ReturnType<typeof useCreateManagerUserMutation>;
export type CreateManagerUserMutationResult = Apollo.MutationResult<CreateManagerUserMutation>;
export type CreateManagerUserMutationOptions = Apollo.BaseMutationOptions<CreateManagerUserMutation, CreateManagerUserMutationVariables>;
export const DeleteManagerUserDocument = gql`
    mutation DeleteManagerUser($_id: String!) {
  deleteManagerUser(_id: $_id)
}
    `;
export type DeleteManagerUserMutationFn = Apollo.MutationFunction<DeleteManagerUserMutation, DeleteManagerUserMutationVariables>;

/**
 * __useDeleteManagerUserMutation__
 *
 * To run a mutation, you first call `useDeleteManagerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManagerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManagerUserMutation, { data, loading, error }] = useDeleteManagerUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteManagerUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManagerUserMutation, DeleteManagerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManagerUserMutation, DeleteManagerUserMutationVariables>(DeleteManagerUserDocument, options);
      }
export type DeleteManagerUserMutationHookResult = ReturnType<typeof useDeleteManagerUserMutation>;
export type DeleteManagerUserMutationResult = Apollo.MutationResult<DeleteManagerUserMutation>;
export type DeleteManagerUserMutationOptions = Apollo.BaseMutationOptions<DeleteManagerUserMutation, DeleteManagerUserMutationVariables>;
export const QueryGetCustomerUserByIdDocument = gql`
    query QueryGetCustomerUserById($_id: String!) {
  customerUser(_id: $_id) {
    _id
    properties {
      ... on CustomerUserProperties {
        userType
        language
        relationships {
          customer {
            _id
            name
            disabled
          }
          userRole
        }
      }
    }
  }
}
    `;

/**
 * __useQueryGetCustomerUserByIdQuery__
 *
 * To run a query within a React component, call `useQueryGetCustomerUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryGetCustomerUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryGetCustomerUserByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useQueryGetCustomerUserByIdQuery(baseOptions: Apollo.QueryHookOptions<QueryGetCustomerUserByIdQuery, QueryGetCustomerUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryGetCustomerUserByIdQuery, QueryGetCustomerUserByIdQueryVariables>(QueryGetCustomerUserByIdDocument, options);
      }
export function useQueryGetCustomerUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryGetCustomerUserByIdQuery, QueryGetCustomerUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryGetCustomerUserByIdQuery, QueryGetCustomerUserByIdQueryVariables>(QueryGetCustomerUserByIdDocument, options);
        }
export type QueryGetCustomerUserByIdQueryHookResult = ReturnType<typeof useQueryGetCustomerUserByIdQuery>;
export type QueryGetCustomerUserByIdLazyQueryHookResult = ReturnType<typeof useQueryGetCustomerUserByIdLazyQuery>;
export type QueryGetCustomerUserByIdQueryResult = Apollo.QueryResult<QueryGetCustomerUserByIdQuery, QueryGetCustomerUserByIdQueryVariables>;
export const QueryIsEmailAvailableDocument = gql`
    query QueryIsEmailAvailable($_id: String!) {
  isEmailAvailable(_id: $_id)
}
    `;

/**
 * __useQueryIsEmailAvailableQuery__
 *
 * To run a query within a React component, call `useQueryIsEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryIsEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryIsEmailAvailableQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useQueryIsEmailAvailableQuery(baseOptions: Apollo.QueryHookOptions<QueryIsEmailAvailableQuery, QueryIsEmailAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryIsEmailAvailableQuery, QueryIsEmailAvailableQueryVariables>(QueryIsEmailAvailableDocument, options);
      }
export function useQueryIsEmailAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryIsEmailAvailableQuery, QueryIsEmailAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryIsEmailAvailableQuery, QueryIsEmailAvailableQueryVariables>(QueryIsEmailAvailableDocument, options);
        }
export type QueryIsEmailAvailableQueryHookResult = ReturnType<typeof useQueryIsEmailAvailableQuery>;
export type QueryIsEmailAvailableLazyQueryHookResult = ReturnType<typeof useQueryIsEmailAvailableLazyQuery>;
export type QueryIsEmailAvailableQueryResult = Apollo.QueryResult<QueryIsEmailAvailableQuery, QueryIsEmailAvailableQueryVariables>;
export const GetCustomerUsersDocument = gql`
    query GetCustomerUsers($filter: UserFilterInput!, $pagination: CustomerUsersPaginationArgs) {
  customerUsers(filter: $filter, pagination: $pagination) {
    items {
      _id
      properties {
        ... on CustomerUserProperties {
          userType
          language
          relationships {
            customer {
              _id
              name
              disabled
            }
            userRole
          }
        }
      }
    }
    currentPage
    totalPages
  }
}
    `;

/**
 * __useGetCustomerUsersQuery__
 *
 * To run a query within a React component, call `useGetCustomerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCustomerUsersQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, options);
      }
export function useGetCustomerUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>(GetCustomerUsersDocument, options);
        }
export type GetCustomerUsersQueryHookResult = ReturnType<typeof useGetCustomerUsersQuery>;
export type GetCustomerUsersLazyQueryHookResult = ReturnType<typeof useGetCustomerUsersLazyQuery>;
export type GetCustomerUsersQueryResult = Apollo.QueryResult<GetCustomerUsersQuery, GetCustomerUsersQueryVariables>;
export const GetCustomerAccountByIdDocument = gql`
    query GetCustomerAccountById($_id: String!) {
  customerUserAccount(_id: $_id) {
    id
    accountEnabled
    displayName
    givenName
    surname
    email
    lastSignInDateTime
    passwordPolicies
    identities {
      issuerAssignedId
      issuer
    }
    mfaMethod
    mustResetPassword
  }
}
    `;

/**
 * __useGetCustomerAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetCustomerAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAccountByIdQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetCustomerAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerAccountByIdQuery, GetCustomerAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAccountByIdQuery, GetCustomerAccountByIdQueryVariables>(GetCustomerAccountByIdDocument, options);
      }
export function useGetCustomerAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAccountByIdQuery, GetCustomerAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAccountByIdQuery, GetCustomerAccountByIdQueryVariables>(GetCustomerAccountByIdDocument, options);
        }
export type GetCustomerAccountByIdQueryHookResult = ReturnType<typeof useGetCustomerAccountByIdQuery>;
export type GetCustomerAccountByIdLazyQueryHookResult = ReturnType<typeof useGetCustomerAccountByIdLazyQuery>;
export type GetCustomerAccountByIdQueryResult = Apollo.QueryResult<GetCustomerAccountByIdQuery, GetCustomerAccountByIdQueryVariables>;
export const CreateCustomerUserDocument = gql`
    mutation CreateCustomerUser($customerUser: CustomerUserInput!) {
  createCustomerUser(customerUser: $customerUser) {
    _id
    properties {
      ... on CustomerUserProperties {
        language
        userType
        relationships {
          customer {
            _id
            name
            disabled
          }
        }
      }
    }
  }
}
    `;
export type CreateCustomerUserMutationFn = Apollo.MutationFunction<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>;

/**
 * __useCreateCustomerUserMutation__
 *
 * To run a mutation, you first call `useCreateCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerUserMutation, { data, loading, error }] = useCreateCustomerUserMutation({
 *   variables: {
 *      customerUser: // value for 'customerUser'
 *   },
 * });
 */
export function useCreateCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>(CreateCustomerUserDocument, options);
      }
export type CreateCustomerUserMutationHookResult = ReturnType<typeof useCreateCustomerUserMutation>;
export type CreateCustomerUserMutationResult = Apollo.MutationResult<CreateCustomerUserMutation>;
export type CreateCustomerUserMutationOptions = Apollo.BaseMutationOptions<CreateCustomerUserMutation, CreateCustomerUserMutationVariables>;
export const UpdateCustomerUserLockDocument = gql`
    mutation UpdateCustomerUserLock($locked: Boolean!, $_id: String!) {
  updateCustomerUserLock(_id: $_id, locked: $locked)
}
    `;
export type UpdateCustomerUserLockMutationFn = Apollo.MutationFunction<UpdateCustomerUserLockMutation, UpdateCustomerUserLockMutationVariables>;

/**
 * __useUpdateCustomerUserLockMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerUserLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerUserLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerUserLockMutation, { data, loading, error }] = useUpdateCustomerUserLockMutation({
 *   variables: {
 *      locked: // value for 'locked'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateCustomerUserLockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerUserLockMutation, UpdateCustomerUserLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerUserLockMutation, UpdateCustomerUserLockMutationVariables>(UpdateCustomerUserLockDocument, options);
      }
export type UpdateCustomerUserLockMutationHookResult = ReturnType<typeof useUpdateCustomerUserLockMutation>;
export type UpdateCustomerUserLockMutationResult = Apollo.MutationResult<UpdateCustomerUserLockMutation>;
export type UpdateCustomerUserLockMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerUserLockMutation, UpdateCustomerUserLockMutationVariables>;
export const UpdateCustomerUserForcePasswordResetDocument = gql`
    mutation UpdateCustomerUserForcePasswordReset($_id: String!) {
  updateCustomerUserForcePasswordReset(_id: $_id)
}
    `;
export type UpdateCustomerUserForcePasswordResetMutationFn = Apollo.MutationFunction<UpdateCustomerUserForcePasswordResetMutation, UpdateCustomerUserForcePasswordResetMutationVariables>;

/**
 * __useUpdateCustomerUserForcePasswordResetMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerUserForcePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerUserForcePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerUserForcePasswordResetMutation, { data, loading, error }] = useUpdateCustomerUserForcePasswordResetMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateCustomerUserForcePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerUserForcePasswordResetMutation, UpdateCustomerUserForcePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerUserForcePasswordResetMutation, UpdateCustomerUserForcePasswordResetMutationVariables>(UpdateCustomerUserForcePasswordResetDocument, options);
      }
export type UpdateCustomerUserForcePasswordResetMutationHookResult = ReturnType<typeof useUpdateCustomerUserForcePasswordResetMutation>;
export type UpdateCustomerUserForcePasswordResetMutationResult = Apollo.MutationResult<UpdateCustomerUserForcePasswordResetMutation>;
export type UpdateCustomerUserForcePasswordResetMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerUserForcePasswordResetMutation, UpdateCustomerUserForcePasswordResetMutationVariables>;
export const UpdateCustomerUserMfaMethodDocument = gql`
    mutation UpdateCustomerUserMFAMethod($method: B2CMFAMethod!, $_id: String!) {
  updateCustomerUserMFAMethod(_id: $_id, method: $method)
}
    `;
export type UpdateCustomerUserMfaMethodMutationFn = Apollo.MutationFunction<UpdateCustomerUserMfaMethodMutation, UpdateCustomerUserMfaMethodMutationVariables>;

/**
 * __useUpdateCustomerUserMfaMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerUserMfaMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerUserMfaMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerUserMfaMethodMutation, { data, loading, error }] = useUpdateCustomerUserMfaMethodMutation({
 *   variables: {
 *      method: // value for 'method'
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUpdateCustomerUserMfaMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerUserMfaMethodMutation, UpdateCustomerUserMfaMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerUserMfaMethodMutation, UpdateCustomerUserMfaMethodMutationVariables>(UpdateCustomerUserMfaMethodDocument, options);
      }
export type UpdateCustomerUserMfaMethodMutationHookResult = ReturnType<typeof useUpdateCustomerUserMfaMethodMutation>;
export type UpdateCustomerUserMfaMethodMutationResult = Apollo.MutationResult<UpdateCustomerUserMfaMethodMutation>;
export type UpdateCustomerUserMfaMethodMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerUserMfaMethodMutation, UpdateCustomerUserMfaMethodMutationVariables>;
export const DeleteCustomerUserDocument = gql`
    mutation DeleteCustomerUser($_id: String!) {
  deleteCustomerUser(_id: $_id)
}
    `;
export type DeleteCustomerUserMutationFn = Apollo.MutationFunction<DeleteCustomerUserMutation, DeleteCustomerUserMutationVariables>;

/**
 * __useDeleteCustomerUserMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerUserMutation, { data, loading, error }] = useDeleteCustomerUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerUserMutation, DeleteCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerUserMutation, DeleteCustomerUserMutationVariables>(DeleteCustomerUserDocument, options);
      }
export type DeleteCustomerUserMutationHookResult = ReturnType<typeof useDeleteCustomerUserMutation>;
export type DeleteCustomerUserMutationResult = Apollo.MutationResult<DeleteCustomerUserMutation>;
export type DeleteCustomerUserMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerUserMutation, DeleteCustomerUserMutationVariables>;