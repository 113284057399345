import { ApolloError} from "@apollo/client";
import {GraphQLError} from "graphql";
import {
    User,
    UserLanguage,
    useMeQuery,
    useSetMyLanguageMutation,

    useGetCustomerUsersLazyQuery,
    useCreateCustomerUserMutation,
    useUpdateCustomerUserMfaMethodMutation,
    useUpdateCustomerUserLockMutation,
    useDeleteCustomerUserMutation,
    MutationCreateCustomerUserArgs,
    MutationUpdateCustomerUserMfaMethodArgs,
    MutationUpdateCustomerUserLockArgs,
    MutationDeleteCustomerUserArgs,

    useGetManagersQuery,
    useCreateManagerUserMutation,
    useUpdateManagerRoleMutation,
    useDeleteManagerUserMutation,
    useQueryIsEmailAvailableLazyQuery,
    MutationCreateManagerUserArgs,
    MutationUpdateManagerUserRoleArgs,
    MutationDeleteManagerUserArgs,
    useGetCustomerUsersQuery,
    useGetCustomerAccountByIdQuery,
    MutationUpdateCustomerUserRelationshipsArgs, useUpdateCustomerUserRelationshipsMutation, UserFilterInput,

} from '../../service/api/generated-types';
import { QueryGetManagers, QueryGetCustomerUsers } from "../../service/api/api-queries/user";
import { Me, ManagerUser, CustomerUser, CustomerUserAccount } from "./model";
import { PaginationFilter } from '../../components/amethyst';

/**** ME *****/
type FetchMe = { loading: boolean, me:Me|null, error:ApolloError|undefined };

export const useMe = ():FetchMe => {
    const {loading, data, error } = useMeQuery();
    if(loading) return { loading, me:null, error}
    if(!loading && data) return { loading, me:Me.fromApi(data.me as Me), error }
    return { loading, me:null, error}
}

type SetMyLanguageRes = {
    me:Me|null,
    errors:readonly GraphQLError[]|undefined
}
type SetMyLanguage = [fn:(language: UserLanguage) => Promise<SetMyLanguageRes>, status: {loading: boolean}]
export const useSetMyLanguage = ():SetMyLanguage => {
    const [setMyLanguage, { loading }] = useSetMyLanguageMutation();
    const fn = (language: UserLanguage) => {
        return setMyLanguage({variables: { language }}).then(({data, errors}) => {
            if(data) return { me:Me.fromApi(data.setMyLanguage as User), errors}
            return { me:null, errors }
        });
    }
    return [fn, { loading }]
}


/***** Customer User ******/

type PaginatedCustomerUsers = {
    items:CustomerUser[]
    currentPage:number,
    totalPages:number
}

type FetchCustomerUsersRes = {
    loading: boolean,
    pageOfCustomerUsers:PaginatedCustomerUsers
    error:ApolloError|undefined
}

export interface CustomerUsersFilter {
    pagination?:PaginationFilter,
    customerId?:string,
    emailPattern:string
}

const customerUserFilterToVariableArgs = (filter:CustomerUsersFilter) => ({
    filter:{
        customerId:filter.customerId,
        emailPattern:filter.emailPattern
    },
    pagination:filter.pagination
})

export const useGetCustomerUsers = (filter: CustomerUsersFilter = { emailPattern: ''}):FetchCustomerUsersRes => {
    const { loading, data, error } = useGetCustomerUsersQuery({
        variables: customerUserFilterToVariableArgs(filter),
        fetchPolicy: "cache-and-network"
    });
    if(data) return {
        loading,
        pageOfCustomerUsers : {
            items:data.customerUsers.items.map(m => CustomerUser.fromApi(m as User)),
            currentPage:data.customerUsers.currentPage,
            totalPages:data.customerUsers.totalPages
        },
        error
    }
    return { loading, pageOfCustomerUsers:{ items:[], currentPage:0, totalPages:0}, error}
}

type FetCustomerUsersLazyRes = {
    pageOfCustomerUsers:PaginatedCustomerUsers;
}
type FetCustomerUsersLazy = [fn:(filter: CustomerUsersFilter) => Promise<FetCustomerUsersLazyRes>, status:{loading: boolean, error: ApolloError|undefined}]

export const useGetCustomerUsersLazy = ():FetCustomerUsersLazy => {
    const [ getCustomerUsers, { loading, error}] = useGetCustomerUsersLazyQuery();
    const fn = (filter: CustomerUsersFilter = { emailPattern: '@'}):Promise<FetCustomerUsersLazyRes> => {
        return getCustomerUsers({
            variables:customerUserFilterToVariableArgs(filter),
            fetchPolicy: "cache-and-network"
        }).then(({data}) => {
            if(!!data) {
                return {
                    pageOfCustomerUsers:{
                        items:data.customerUsers.items.map(m => CustomerUser.fromApi(m as User)),
                        currentPage:data.customerUsers.currentPage,
                        totalPages:data.customerUsers.totalPages
                    }
                }
            }
            return {
                pageOfCustomerUsers: {
                    items:[],
                    currentPage: 0,
                    totalPages: 0
                }
            }
        })
    }
    return [fn, { loading, error}]
}

type FetchCustomerUserAccountRes = {
    account:CustomerUserAccount|null,
    error:ApolloError|undefined
    loading:boolean
}

export const useGetCustomerUserAccountById = (userId: string):FetchCustomerUserAccountRes => {
    const { data, error, loading} = useGetCustomerAccountByIdQuery({
        variables:{_id: userId},
        fetchPolicy: "network-only"
    })
    if(!!data && !!data.customerUserAccount) {
        return { account: data.customerUserAccount as CustomerUserAccount, error, loading}
    }
    return { account:null, error, loading}
}

type CreateCustomerUserRes = {
    customerUser: CustomerUser|null|undefined
    errors:readonly GraphQLError[]|undefined
}
type CreateCustomerUser = [fn:(args: MutationCreateCustomerUserArgs, filter:CustomerUsersFilter) => Promise<CreateCustomerUserRes>, status: { loading: boolean}]
export const useCreateCustomerUser = ():CreateCustomerUser => {
    const [createCustomerUser, { loading }] = useCreateCustomerUserMutation();
    const fn = (args: MutationCreateCustomerUserArgs, filter:CustomerUsersFilter) => {
        return createCustomerUser({
            variables: args,
            refetchQueries:[{
                query:QueryGetCustomerUsers,
                variables:customerUserFilterToVariableArgs(filter),
                fetchPolicy:'network-only'
            }]
        }).then(({data, errors}) => {
            if(data) return { customerUser:CustomerUser.fromApi(data.createCustomerUser as User), errors }
            return { customerUser:null, errors }
        });
    }
    return [fn, { loading }]
}

type UpdateCustomerUserRelationshipsRes = {
    customerUser: CustomerUser|null|undefined
    errors:readonly GraphQLError[]|undefined
}

type UpdateCustomerUserRelationships = [fn:(args: MutationUpdateCustomerUserRelationshipsArgs) => Promise<UpdateCustomerUserRelationshipsRes>, status: { loading: boolean}]

export const useUpdateCustomerUserRelationships = ():UpdateCustomerUserRelationships => {
    const [updateCustomerUserRelationships, { loading }] = useUpdateCustomerUserRelationshipsMutation();
    const fn = (args: MutationUpdateCustomerUserRelationshipsArgs) => {
        return updateCustomerUserRelationships({
            variables: args
        }).then(({ data, errors }) => {
            if(data && data.updateCustomerUserRelationships ) return { customerUser:CustomerUser.fromApi(data.updateCustomerUserRelationships as User), errors }
            return { customerUser:null, errors }
        })
    }
    return [fn, { loading }]
}

type UpdateCustomerUserMfaMethodRes = boolean
type UpdateCustomerUserMfaMethod = [fn:(args: MutationUpdateCustomerUserMfaMethodArgs) => Promise<UpdateCustomerUserMfaMethodRes>, status: { loading: boolean, error:ApolloError|undefined}]
export const useUpdateCustomerUserMfaMethod = ():UpdateCustomerUserMfaMethod => {
    const [updateMfaMethod, { loading, error }] = useUpdateCustomerUserMfaMethodMutation();
    const fn = (args: MutationUpdateCustomerUserMfaMethodArgs) => {
        return updateMfaMethod({variables: args}).then(({data, errors}) => {
            if(data) return data.updateCustomerUserMFAMethod;
            return false
        });
    }
    return [fn, { loading, error }]
}

type UpdateCustomerUserLockRes = boolean
type UpdateCustomerUserLock = [fn:(args: MutationUpdateCustomerUserLockArgs) => Promise<UpdateCustomerUserLockRes>, status: { loading: boolean, error:ApolloError|undefined}]
export const useUpdateCustomerUserLock = ():UpdateCustomerUserLock => {
    const [updateLock, { loading, error }] = useUpdateCustomerUserLockMutation();
    const fn = (args: MutationUpdateCustomerUserLockArgs) => {
        return updateLock({variables: args}).then(({data, errors}) => {
            if(data) return data.updateCustomerUserLock;
            return false
        });
    }
    return [fn, { loading, error }]
}

type DeleteCustomerUserRes = boolean
type DeleteCustomerUser = [fn:(args: MutationDeleteCustomerUserArgs) => Promise<DeleteCustomerUserRes>, status: { loading: boolean, error:ApolloError|undefined}]
export const useDeleteCustomerUser = (filter:CustomerUsersFilter):DeleteCustomerUser => {
    const [deleteCustomerUser, { loading, error }] = useDeleteCustomerUserMutation({
        refetchQueries: [
            {
                query:QueryGetCustomerUsers,
                variables:customerUserFilterToVariableArgs(filter),
                fetchPolicy:'network-only'
            }
        ]
    });
    const fn = (args: MutationDeleteCustomerUserArgs) => {
        return deleteCustomerUser({variables: args}).then(({data, errors}) => {
            if(data) return data.deleteCustomerUser;
            return false
        });
    }
    return [fn, { loading, error }]
}

type PaginatedManagerUsers = {
    items:ManagerUser[]
    currentPage:number,
    totalPages:number
}

/***** Manager User ******/
type FetchManagerUsers = {
    loading: boolean,
    pageOfManagerUsers:PaginatedManagerUsers
    error:ApolloError|undefined
}
export const useManagerUsers = (pagination: PaginationFilter, filter?: UserFilterInput):FetchManagerUsers => {
    const { loading, data, error } = useGetManagersQuery({
        variables: {
            filter:filter,
            pagination: {
                page:pagination.page,
                pageSize:pagination.pageSize
            }
        }
    });
    if(data) return {
        loading,
        pageOfManagerUsers: {
            items:data.managerUsers.items.map(m => ManagerUser.fromApi(m as User)),
            currentPage:data.managerUsers.currentPage,
            totalPages:data.managerUsers.totalPages
        },
        error
    }
    return { loading, pageOfManagerUsers:{ items:[], currentPage:0, totalPages:0}, error}
}

type CreateManagerUserRes = {
    managerUser:ManagerUser|null|undefined,
    errors:readonly GraphQLError[]|undefined
}
type CreateManagerUser = [fn:(args: MutationCreateManagerUserArgs, pagination: PaginationFilter) => Promise<CreateManagerUserRes>, status:{ loading: boolean}]
export const useCreateManagerUser = ():CreateManagerUser => {
    const [createManagerUser, { loading}] = useCreateManagerUserMutation({ refetchQueries:[{query: QueryGetManagers}]});

    const onCreateManagerUser = (args: MutationCreateManagerUserArgs, pagination: PaginationFilter) => {
        return createManagerUser({
            variables: args,
            refetchQueries:[
                {
                    query: QueryGetManagers,
                    variables: {
                        pagination: {
                            page:pagination.page,
                            pageSize:pagination.pageSize
                        }
                    }
                }
            ]
        }).then(({data, errors}) => {
            if(data) return { managerUser: ManagerUser.fromApi(data.createManagerUser as User), errors }
            return { managerUser:null, errors}
        });
    }

    return [onCreateManagerUser, { loading }]
}

type UpdateManagerUserRoleResult = {
    managerUser:ManagerUser|null|undefined,
    errors:readonly GraphQLError[]|undefined
}
type UpdateManagerUserRole = [fn:(args:MutationUpdateManagerUserRoleArgs) => Promise<UpdateManagerUserRoleResult>, status: { loading: boolean}]
export const useUpdateManagerUserRole = ():UpdateManagerUserRole => {
    const [updateManagerUserRole, { loading }] = useUpdateManagerRoleMutation();

    const onUpdateManagerUserRole = (args:MutationUpdateManagerUserRoleArgs) => {
        return updateManagerUserRole({variables:args}).then(({data, errors}) => {
            if(data) return { managerUser: ManagerUser.fromApi(data.updateManagerUserRole as User), errors }
            return { managerUser: null, errors}
        })
    }
    return [onUpdateManagerUserRole, { loading }]
}

type DeleteManagerUserRes = {
    result:boolean,
    errors:readonly GraphQLError[]|undefined
}
type DeleteManagerUser = [fn:(args: MutationDeleteManagerUserArgs, pagination: PaginationFilter) => Promise<DeleteManagerUserRes>, status: { loading: boolean }];
export const useDeleteManager = ():DeleteManagerUser => {
    const [deleteManagerUser, { loading }] = useDeleteManagerUserMutation();

    const onDeleteManager = (args: MutationDeleteManagerUserArgs, pagination: PaginationFilter) => {
        return deleteManagerUser({
            variables: args,
            refetchQueries:[
                {
                    query: QueryGetManagers,
                    variables: {
                        pagination: {
                            page:pagination.page,
                            pageSize:pagination.pageSize
                        }
                    }
                }
            ]
        })
        .then(({data, errors}) => {
            if(data) {
                return { result:data.deleteManagerUser, errors}
            }
            return { result: false, errors }
        });
    }
    return [onDeleteManager, { loading }]
}

/*** Utils ***/
type IsEmailAvailableRes = boolean
type IsEmailAvailable = [fn:(email: string) => Promise<IsEmailAvailableRes>, status:{loading: boolean, error: ApolloError|undefined}]
export const useIsEmailAvailable = ():IsEmailAvailable => {
    const [isEmailAvailable, { loading, error }] = useQueryIsEmailAvailableLazyQuery({ fetchPolicy: "network-only"});
    const onIsEmailAvailable = (email: string) => {
        return isEmailAvailable({variables: { _id: email}}).then(({data, error}) => {
            if(data) return data.isEmailAvailable;
            return false;
        });
    }
    return [onIsEmailAvailable, { loading, error }]
}
