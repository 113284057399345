import React from "react"
import { useFormikContext } from 'formik';
import {Button, ButtonType} from "../../button";
import {FormGroup} from "../Form";

export interface FormActionProps {
    submitLabel:string
    submittingLabel:string,
    resetLabel?:string
    onCustomCancel?:() => void
    right?:boolean
    noBorder?:boolean
    disabled?:boolean
}

export const FormAction:React.FC<FormActionProps> = ({submitLabel, submittingLabel, resetLabel, right, noBorder, disabled, onCustomCancel}) => {
    const formik = useFormikContext();
    return (
        <FormGroup disabled={disabled}>
            <div className={`form-input-action ${right ? 'right' : ''} ${noBorder ? 'no-border':''}`}>
                <Button
                    disabled={formik.isSubmitting || !formik.dirty || (Object.keys(formik.errors).length > 0)}
                    type="submit"
                    onClick={(e) => { e.preventDefault(); formik.handleSubmit()}}
                    label={formik.isSubmitting ? submittingLabel : submitLabel}
                    kind={ButtonType.primary}
                />
                { resetLabel ? <Button type="reset" onClick={(e) => { e.preventDefault(); onCustomCancel ? onCustomCancel() : formik.handleReset()}} label={resetLabel} kind={ButtonType.secondary} /> : null}
            </div>
        </FormGroup>
    )
}