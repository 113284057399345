import React from "react"
import { useField } from "formik";
import {
    SimpleSelect,
    SimpleSelectProps,
    MultipleSelect,
    MultipleSelectProps,
    ISelectItem,
    CreatableMultiSelect,
} from '../../select';
import { InputStatus } from "../../common";

export interface FormSimpleSelectProps extends SimpleSelectProps {
    form?:string
    label?:string
}

export const FormSimpleSelect:React.FC<FormSimpleSelectProps> = (props) => {
    return (
        <div className="form-select">
            <label htmlFor={props.id} className={props.label ? "form-label" : "sr-only"}>{props.label ? props.label : props.name}</label>
            <SimpleSelect {...props} />
        </div>
    )
}

export interface FormMultipleSelectProps extends MultipleSelectProps {
    form?:string
    label?:string
}

export const FormMultipleSelect:React.FC<FormMultipleSelectProps> = (props) => {
    return (
        <div className="form-select">
            <label htmlFor={props.id} className={props.label ? "form-label" : "sr-only"}>{props.label ? props.label : props.name}</label>
            <MultipleSelect {...props} />
        </div>
    )
}

export const FieldFormSimpleSelect:React.FC<FormSimpleSelectProps> = (props) => {
    const [field, meta, helpers] = useField<ISelectItem|null>(props.name);
    const { placeholder, id, name, form, items, noOptionsMessage, isClearable, searchable, disabled } = props;

    return (
        <div className="form-select">
            <label htmlFor={props.id} className={props.label ? "form-label" : "sr-only"}>{props.label ? props.label : props.name}</label>
            <SimpleSelect
                id={id}
                items={items}
                placeholder={placeholder}
                form={form}
                name={name}
                noOptionsMessage={noOptionsMessage}
                selectedItemId={field.value?.id}
                onChange={(item) => helpers.setValue(item, true)}
                onBlur={() => helpers.setTouched(true, true)}
                status={meta.touched && meta.error ? InputStatus.Invalid : undefined}
                hint={meta.error ? meta.error : undefined }
                isClearable={isClearable}
                searchable={searchable}
                disabled={disabled}
            />
        </div>
    )
}

export const FieldFormMultipleSelect:React.FC<FormMultipleSelectProps> = (props) => {
    const [field, meta, helpers] = useField<ISelectItem[]>(props.name);
    const { placeholder, id, name, form, items, noOptionsMessage, isClearable, disabled, displayLabelAsValue } = props;

    return (
        <div className="form-select">
            <label htmlFor={props.id} className={props.label ? "form-label" : "sr-only"}>{props.label ? props.label : props.name}</label>
            <MultipleSelect
                id={id}
                name={name}
                placeholder={placeholder}
                form={form}
                items={items}
                selectedItemIds={field.value.map(i => i.id)}
                onChange={(items) => helpers.setValue([...items], true)}
                onBlur={() => helpers.setTouched(true, true)}
                status={meta.touched && meta.error ? InputStatus.Invalid : undefined}
                hint={meta.error ? meta.error : undefined }
                noOptionsMessage={noOptionsMessage}
                isClearable={isClearable}
                disabled={disabled}
                displayLabelAsValue={displayLabelAsValue}
            />
        </div>
    )
}


export interface FormCreatableMultipleSelectProps extends Omit<FormMultipleSelectProps, 'selectedItems'>  {
    form?:string
    label?:string
}

export const FieldFormCreatableMultiSelect:React.FC<FormCreatableMultipleSelectProps> = (props) => {
    const [field, meta, helpers] = useField<ISelectItem[]>(props.name);
    const { placeholder, id, name, form, items, noOptionsMessage, isClearable, disabled } = props;

    return (
        <div className="form-select">
            <label htmlFor={props.id} className={props.label ? "form-label" : "sr-only"}>{props.label ? props.label : props.name}</label>
            <CreatableMultiSelect
                id={id}
                name={name}
                placeholder={placeholder}
                form={form}
                items={items}
                selectedItems={field.value}
                onChange={(items) => helpers.setValue([...items], true)}
                onBlur={() => helpers.setTouched(true, true)}
                status={meta.touched && meta.error ? InputStatus.Invalid : undefined}
                hint={meta.error ? meta.error : undefined }
                noOptionsMessage={noOptionsMessage}
                isClearable={isClearable}
                disabled={disabled}
            />
        </div>
    )
}
