import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { SubscriptionCrud } from "./SubscriptionCrud";
export const SubscriptionManagement:React.FC<any> = () => {
    return (
        <CrudLayout>
            <SubscriptionCrud />
        </CrudLayout>
    )
}
