import React from "react"
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import {loginRequest} from "./security/authConfig";
import { Container } from './components/amethyst';
import { useTranslation } from 'react-i18next';
import { SignInButton } from './components/security/SigninButton';
import { config } from './config';

export const AnonymousLanding:React.FC<any> = () => {
    // to trigger redirect to authentication page
    useMsalAuthentication(InteractionType.Redirect, loginRequest);

    // to display message and sign-in button while waiting for redirect
    const { t } = useTranslation();
    return (
        <Container fluid app className="text-center">
            <h4>{t("MyGT.AnonymousWelcome", {website_name:config.website_name})}</h4>
            <p>{t("MyGT.AnonymousWelcomeFallback")}</p>
            <SignInButton />
        </Container>
    )
}