import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { config } from '../../config';
import { b2cPolicies } from '../../security/authConfig';
import { IdTokenClaims } from '../../security/types';
import { CustomerUser, useMe, UserLanguage, useSetMyLanguage } from '../../model/user';
import { UserNavBarDropDown, UserNavBarMenu } from './UserMenu';
import { PersonalInfo } from './PersonalInfo';
import { CustomerAccountSwitch } from '../customer/view/CustomerAccountSwitch';
import {
    Badge,
    BadgeType,
    Icon,
    NavBar,
    NavBarBrandWithLabel,
    NavBarContent,
    NavBarContentBadge,
    NavBarContentBlock,
    NavBarContentVisibility,
    NavBarFooter,
    NavBarFooterItem,
    NavBarFooterItemIcons,
    NavBarFooterItemLink,
    NavBarLanguageLink,
    NavBarMenu,
    NavBarMenuItem,
    NavBarMenuSubItemsLanguages,
    NavBarToggleBtn,
    NavBarTopBar,
} from '../amethyst';
import { useValidNotification } from '../../model/notification';

const languages = ["en","fr"];
const languagesLabels = {
    "en":"UserMenu.Languages.en",
    "fr":"UserMenu.Languages.fr"
}

export interface MyGTNavBarProps {
    pathname:string
}

interface NotificationsBadgeProps {
    visibility:NavBarContentVisibility
}

const NotificationsBadge:React.FC<NotificationsBadgeProps> = ({visibility}) => {

    const { loading, data:notifications} = useValidNotification();

    if(!loading && notifications.length > 0) {
        return (
            <NavBarContentBadge visibility={visibility} >
                <Badge type={BadgeType.Notification} enabled={true} notificationMessage={notifications[0].description} />
            </NavBarContentBadge>
        );
    }
    return null;
}

export const MyGTNavBar:React.FC<MyGTNavBarProps> = ({pathname}) => {
    const [showPersonalInfo, setShowPersonalInfo] = useState<boolean>(false);
    const [showCustomerAccounts, setShowCustomerAccounts] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandedSubMenu, setExpandedSubMenu] = useState<string|null>(null);
    const { t, i18n } = useTranslation();
    const { inProgress, instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accountInfo = useAccount(accounts[0] || {});
    const { me, loading } = useMe();
    const [setMyLanguage] = useSetMyLanguage();

    const menuLanguagesLabels = Object.entries(languagesLabels).reduce<{[l:string]:string}>((acc, [k, v]) => {
        acc[k] = t(v);
        return acc;
    }, {})
    
    const footer = (
        <NavBarFooter>
            <NavBarFooterItem label={t("UserMenu.AboutGT")} />
            <NavBarFooterItemIcons icons={[
                {icon:'www-internet', href:'https://www.grantthornton.lu/', external:true},
                {icon:'linkedin', href:'https://www.linkedin.com/company/grant-thornton-luxembourg/', external:true},
                {icon:'youtube', href:'https://www.youtube.com/@grantthorntonluxembourg9567', external:true}
            ]} />
            <NavBarFooterItemLink label={t("UserMenu.PrivacyPolicy")} href={t("UserMenu.PrivacyPolicyURL")} external />
        </NavBarFooter>
    )

    const closeAllMenu = () => {
        setExpanded(false);
        setExpandedSubMenu(null);
    }

    useEffect(() => {
        closeAllMenu();
    }, [pathname]);

    const handleLogout = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        instance.logoutRedirect();
    }

    const showPersonalData = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowPersonalInfo(true);
    }

    const changePassword = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isAuthenticated && inProgress === InteractionStatus.None) {
            instance.acquireTokenRedirect({...b2cPolicies.authorities.changePassword, scopes:[]});
        }
    }

    const onClosePersonalInfo = () => {
        setShowPersonalInfo(false);
    }

    const changeCustomerAccount = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowCustomerAccounts(true);
    }

    const onCloseCustomerAccount = () => {
        setShowCustomerAccounts(false);
    }

    const onChangeUserLanguage = async (language:string) => {
        //await i18n.changeLanguage(language);
        await setMyLanguage(language as UserLanguage);
        closeAllMenu();
    };

    const toggleSubMenu = (menuName:string) => {
        if(menuName === expandedSubMenu) {
            setExpandedSubMenu(null);
        } else {
            setExpandedSubMenu(menuName);
        }
    }

    const onCloseMenu = () => {
        closeAllMenu();
    }

    const onToggleMenu = () => {
        if(expanded) setExpandedSubMenu(null);
        setExpanded(!expanded);
    }

    const currentLanguage = loading ? UserLanguage.En : !me?.language ? UserLanguage.En : me.language;

    return (
        <>
            <NavBar closeMenu={onCloseMenu}>
            <NavBarTopBar>
                <NavBarBrandWithLabel icon="energy" title={config.website_name} />
                <NavBarContent>
                    <NotificationsBadge visibility={NavBarContentVisibility.Small} />
                    <NavBarContentBlock>
                        {
                            !loading ?
                                <>
                                    <NotificationsBadge visibility={NavBarContentVisibility.Large} />
                                    <NavBarLanguageLink visibility={NavBarContentVisibility.Large} languages={languages} currentLanguage={currentLanguage} label="language" onChangeLanguage={onChangeUserLanguage} />
                                    {(me && isAuthenticated && accountInfo) ?
                                        <UserNavBarDropDown claims={accountInfo.idTokenClaims as IdTokenClaims} me={me} onShowPersonalInfo={showPersonalData} onChangeCustomerAccount={changeCustomerAccount} onChangePassword={changePassword} onLogout={handleLogout} />
                                        : null
                                    }
                                    <NavBarToggleBtn expanded={expanded} onClickToggleExpand={onToggleMenu} visibility={NavBarContentVisibility.Small} />
                                </> :
                                <></>
                        }
                    </NavBarContentBlock>
                </NavBarContent>
            </NavBarTopBar>
            <NavBarMenu expanded={expanded} footer={footer}  >
                <NavBarMenuItem>
                    <Link title={t("UserMenu.Home")} to="/" className={pathname === "/" ? 'active':undefined}>
                        <span className="navbar-nav-icon">
                            <Icon block icon="building-house" label={t("UserMenu.Home")} title={t("UserMenu.Home")} />
                        </span>
                        <span>{t("UserMenu.Home")}</span>
                    </Link>
                </NavBarMenuItem>
                <NavBarMenuItem>
                    <Link title={t("UserMenu.Apps")} to="/apps" className={pathname === "/apps" ? 'active':undefined}>
                        <span className="navbar-nav-icon">
                            <Icon block icon="folder" label={t("UserMenu.Apps")} title={t("UserMenu.Apps")} />
                        </span>
                        <span>{t("UserMenu.Apps")}</span>
                    </Link>
                </NavBarMenuItem>
                <NavBarMenuItem>
                    <Link title={t("UserMenu.News")} to="/news" className={pathname.startsWith("/news") ? 'active':undefined}>
                        <span className="navbar-nav-icon">
                            <Icon block icon="newspaper" label={t("UserMenu.News")} title={t("UserMenu.News")} />
                        </span>
                        <span>{t("UserMenu.News")}</span>
                    </Link>
                </NavBarMenuItem>
                <NavBarMenuItem>
                    <Link title={t("UserMenu.Agenda")} to="/agenda" className={pathname.startsWith("/agenda") ? 'active':undefined}>
                        <span className="navbar-nav-icon">
                            <Icon block icon="calendar" label={t("UserMenu.Agenda")} title={t("UserMenu.Agenda")} />
                        </span>
                        <span>{t("UserMenu.Agenda")}</span>
                    </Link>
                </NavBarMenuItem>
                { (me && me.managerProperties && me.managerProperties.managerRoles.length > 0) ?
                    <NavBarMenuItem>
                        <Link title={t("UserMenu.Admin")} to="/management" className={pathname.startsWith("/management") ? 'active':undefined}>
                            <span className="navbar-nav-icon">
                                <Icon block icon="gears" label={t("UserMenu.Admin")} title={t("UserMenu.Admin")} />
                            </span>
                            <span>{t("UserMenu.Admin")}</span>
                        </Link>
                    </NavBarMenuItem>
                    : <></>
                }
                {(me && isAuthenticated && accountInfo) ?
                    <UserNavBarMenu name="user" onToggle={toggleSubMenu} isOpen={expandedSubMenu === "user"} claims={accountInfo.idTokenClaims as IdTokenClaims} me={me} onShowPersonalInfo={showPersonalData} onChangePassword={changePassword} onChangeCustomerAccount={changeCustomerAccount} onLogout={handleLogout} />
                    : <></>
                }
                <NavBarMenuSubItemsLanguages
                    languages={languages}
                    labels={menuLanguagesLabels}
                    currentLanguage={i18n.language}
                    onChangeUserLanguage={onChangeUserLanguage}
                    name="language"
                    label={t("UserMenu.Language")}
                    icon="globe-map"
                    hideOnXl
                    onToggle={toggleSubMenu}
                    isOpen={expandedSubMenu === "language"}
                />
            </NavBarMenu>
        </NavBar>
            {(isAuthenticated && accountInfo) ?
                <PersonalInfo showPersonalInfo={showPersonalInfo} onClosePersonalInfo={onClosePersonalInfo} email={me?._id || ""} claims={accountInfo.idTokenClaims as IdTokenClaims} />
                : null
            }
            {(isAuthenticated && me && me.isCustomer) ?
                <CustomerAccountSwitch customerUser={me as CustomerUser} show={showCustomerAccounts} close={onCloseCustomerAccount} />
                : null
            }
        </>
    )
}

