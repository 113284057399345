import React, { PropsWithChildren } from 'react';
import { Icon } from '../icon';

export interface NavBarMenuItemProps extends PropsWithChildren {
    hideOnXl?:boolean
}
export const NavBarMenuItem:React.FC<NavBarMenuItemProps> = ({hideOnXl, children}) => {
    return (
        <li className={hideOnXl? 'hide-on-xl':undefined}>
            {children}
        </li>
    )
}

export interface NavBarMenuSubItemsProps extends NavBarMenuItemProps {
    name:string
    label:string
    icon:string
    isOpen?:boolean
    onToggle:(menuName: string) => void
}

export const NavBarMenuSubItems:React.FC<NavBarMenuSubItemsProps> = ({name, hideOnXl, label, icon, children, isOpen, onToggle}) => {
    return (
        <li className={`${hideOnXl? 'hide-on-xl':undefined} has-submenu`}>
            <a href="/#" onClick={(e) => { e.preventDefault(); onToggle(name)}} aria-expanded={isOpen}>
                <span className="navbar-nav-icon">
                    <Icon block icon={icon} label={label} title={label}/>
                </span>
                <span>{label}</span>
            </a>
            <ul className="nav navbar-subnav collapse" aria-hidden={!isOpen} style={{display: isOpen ? 'block' : 'none'}}>
                {children}
            </ul>
        </li>
    )
}

export interface NavBarMenuSubItemsLanguagesProps extends NavBarMenuSubItemsProps {
    languages:string[]
    labels: { [l:string]:string }
    currentLanguage:string
    onChangeUserLanguage:(l:string) => void
}

export const NavBarMenuSubItemsLanguages:React.FC<NavBarMenuSubItemsLanguagesProps> = (props) => {
    const { languages, labels, currentLanguage, onChangeUserLanguage } = props;
    return (
        <NavBarMenuSubItems {...props}>
            {languages.map((l,idx) =>
                <li key={l} className="no-dotted">
                    {l === currentLanguage ?
                        <a href='/#' className="disabled">
                            <span className="navbar-nav-icon"><Icon block icon="chevron-small-right" /></span>
                            &nbsp;{labels[l]}
                        </a>
                        :
                        <a href='/#' onClick={(e) => { e.preventDefault(); onChangeUserLanguage(l)}}>&nbsp;{labels[l]}</a>
                    }
                </li>
            )}
        </NavBarMenuSubItems>
    )
}