import { PaginatedCustomers } from '../../service/api/generated-types';
import { ApolloError } from "@apollo/client";
import {GraphQLError} from "graphql";
import {
    Customer, useGetCustomersQuery, useIsGtCustomerIdAvailableLazyQuery,
    useCreateCustomerMutation, MutationCreateCustomerArgs,
    useUpdateCustomerMutation, MutationUpdateCustomerArgs } from "../../service/api/generated-types";
import {QueryGetCustomers} from "../../service/api/api-queries/customer";

type FetchCustomersRes = { pageOfCustomers: PaginatedCustomers, error: ApolloError|undefined, loading:boolean }

type CustomersFilter = {
    page: number,
    pageSize: number
    assigned?:boolean
}

export const useCustomers = (filter: CustomersFilter):FetchCustomersRes => {
    const { page, pageSize } = filter;
    const { data, loading, error } = useGetCustomersQuery({
        variables: {
            filter: { assigned: !!filter.assigned },
            pagination: { page, pageSize}
        }
    });
    if(data && data.customers && !loading) {
        return { pageOfCustomers: data.customers as PaginatedCustomers, error, loading}
    } else {
        return { pageOfCustomers: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

type CreateCustomerRes = {
    customer:Customer|null,
    errors: readonly GraphQLError[]|undefined
}

const refetchOnCreateDelete = (filter:CustomersFilter) => [
    {
        query:QueryGetCustomers,
        variables: {
            filter: { assigned: !!filter.assigned },
            pagination: { page:filter.page, pageSize:filter.pageSize}
        }
    }
]

type CreateCustomer = [fn: (args: MutationCreateCustomerArgs) => Promise<CreateCustomerRes>, status:{loading:boolean}]
export const useCreateCustomer = (filter: CustomersFilter):CreateCustomer => {
    const [createCustomer, { loading }] = useCreateCustomerMutation({refetchQueries:[{query:QueryGetCustomers}]});
    const fn = (args: MutationCreateCustomerArgs) => {
        return createCustomer({variables: args, refetchQueries:refetchOnCreateDelete(filter)}).then(({data, errors}) => {
            if(data) return { customer:data.createCustomer as Customer, errors}
            return { customer: null, errors }
        })
    }
    return [fn, { loading}]
}

type UpdateCustomerRes = {
    customer:Customer|null,
    errors: readonly GraphQLError[]|undefined
}
type UpdateCustomer = [fn: (args: MutationUpdateCustomerArgs) => Promise<UpdateCustomerRes>, status:{loading:boolean}]
export const useUpdateCustomer = ():UpdateCustomer => {
    const [updateCustomer, { loading }] = useUpdateCustomerMutation({refetchQueries:[{query:QueryGetCustomers}]});
    const fn = (args: MutationUpdateCustomerArgs) => {
        return updateCustomer({variables: args}).then(({data, errors}) => {
            if(data) return { customer:data.updateCustomer as Customer, errors}
            return { customer: null, errors }
        })
    }
    return [fn, { loading}]
}

type IsGtCustomerIdAvailableRes = boolean
type IsGtCustomerIdAvailable = [fn:(gtCustomerId: string) => Promise<IsGtCustomerIdAvailableRes>, status:{loading: boolean, error: ApolloError|undefined}]
export const useIsGtCustomerIdAvailable = ():IsGtCustomerIdAvailable => {
    const [isGtCustomerIdAvailable, { loading, error }] = useIsGtCustomerIdAvailableLazyQuery({ fetchPolicy: "network-only"});
    const onIsGtCustomerIdAvailable = (gtCustomerId: string) => {
        return isGtCustomerIdAvailable({variables: { gtCustomerId }}).then(({data, error}) => {
            if(data) return data.isCustomerIdAvailable;
            return false;
        });
    }
    return [onIsGtCustomerIdAvailable, { loading, error }]
}
