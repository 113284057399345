import { enGB, fr } from 'date-fns/locale'
import { format, Locale, parseISO } from 'date-fns';

interface ILocales { [l:string ]:Locale}

export const locales:ILocales = {
    'en': enGB,
    'fr': fr
}

export const printDate = (date:Date, language:string, formatStr:string = "dd/MM/yyyy"):string =>
    format(date, formatStr, { locale: locales[language]});

export const printDateFromIso = (dateIso:string, language:string, formatStr:string = "dd/MM/yyyy"):string =>
    format(parseISO(dateIso), formatStr, { locale: locales[language]});