import React from 'react';
import { Me } from '../../../model/user';
import { App, AppStatus, useApps } from '../../../model/app';
import { useCurrentCustomer } from "../../customer/view/customerContext";
import { AppTiles } from './AppTiles';

const sortAppsByName = ( a: App, b: App ) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
}
export interface AppDirectoryProps {
    me:Me
    maxApps?:number
}

type filteredAppsType = {
    apps:App[],
    disabledApps:App[]
}

/**
 * To build two list of apps to display, depending on user profile :
 * - apps : subscribed apps
 * - disabled apps : non-subscribed apps, only displayed for information
 * @param apps
 * @param me
 * @param currentCustomerId
 * @param maxApps
 */
const filteredApps = (apps:App[], me:Me, currentCustomerId:string|null, maxApps?:number):filteredAppsType => {
    const nonOfflineApps = apps.filter(a => a.status !== AppStatus.Offline).sort(sortAppsByName);

    // Admin users : apps are all available
    if(me.isManager) {
        return {
            apps:maxApps ? nonOfflineApps.slice(0, maxApps) : nonOfflineApps,
            disabledApps:[]
        }
    }

    // Customer users :
    // If "maxApps" is defined, then at max "maxApps" apps must be displayed
    // First we must display subscribed apps, then complete the list with non subscribed apps
    if(me.isCustomer) {

        let customerApps = nonOfflineApps.filter(app => (app.subscriptions.findIndex(s => s.customer._id === currentCustomerId) > - 1)) ;
        let nonCustomerApps = nonOfflineApps.filter(app => customerApps.every(customerApp => customerApp._id !== app._id));

        let availableApps = maxApps ? customerApps.slice(0, 4) : customerApps;
        let maxDisabledApp = maxApps && maxApps >= maxApps - availableApps.length ? maxApps - availableApps.length : undefined;

        let disabledApps = maxDisabledApp!==undefined ? nonCustomerApps.slice(0, maxDisabledApp) : nonCustomerApps;

        return {
            apps:availableApps,
            disabledApps:disabledApps
        }
    }

    // Unknown users : only non subscribed apps
    return {
        apps:[],
        disabledApps:maxApps ? nonOfflineApps.slice(0, maxApps) : nonOfflineApps
    }
}

export const AppDirectory: React.FC<AppDirectoryProps> = ({me, maxApps}) => {
    const { currentCustomerId } = useCurrentCustomer();
    const {apps, loading} = useApps();

    if(!loading) {
        const fApps = filteredApps(apps, me, currentCustomerId, maxApps);
        return <AppTiles apps={fApps.apps} disabledApps={fApps.disabledApps} maxApps={maxApps} />
    }
    return null;
}