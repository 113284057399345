import React from "react";
import { InputProps, TextAreaProps, TextInput, TextAreaInput } from '../../input/Input';
import { InputStatus } from "../../common";
import {FieldHelperProps, useField} from "formik";

export interface FieldTextInputProps extends InputProps {
    customValidation?:(value:string, helpers:FieldHelperProps<string>) => void
}

export const FieldTextInput:React.FC<FieldTextInputProps> = (props) => {
    const [field, meta, helpers] = useField<string>(props.name)
    const {id, name, label, placeholder, form, customValidation, ...rest} = props;

    return (
        <TextInput
            id={id}
            name={name}
            label={label}
            placeholder={placeholder}
            form={form}
            status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
            hint={meta.error}
            onBlur={(e:React.FocusEvent<HTMLInputElement>) => customValidation ? helpers.setTouched(true, false) : field.onBlur(e)}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => customValidation ? customValidation(e.target.value, helpers) : field.onChange(e)}
            value={field.value}
            {...rest}
        />
    )
}

export const FieldTextAreaInput:React.FC<TextAreaProps> = (props) => {
    const [field, meta] = useField<string>(props.name)
    const {id, name, label, placeholder, form, ...rest} = props;
    return (
        <TextAreaInput
            id={id}
            name={name}
            label={label}
            placeholder={placeholder}
            form={form}
            status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
            hint={meta.error}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
            {...rest}
        />
    )
}
