export class JsonLocalStorage<T>{
    setItem(key: string, item: T): void {
        localStorage.setItem(key, JSON.stringify(item));
    }

    getItem(key: string):T|null {
        let s = localStorage.getItem(key);
        if(s) {
            try {
                let o = JSON.parse(s);
                return o as T;
            } catch (e) {
                this.removeItem(key);
            }
        }
        return null;
    }

    removeItem(key:string):void {
        localStorage.removeItem(key);
    }
}
