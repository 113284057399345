import React, {PropsWithChildren} from "react";

export interface DataGridFooterProps extends PropsWithChildren {}

export const DataGridFooter:React.FC<DataGridFooterProps> = ({children}) => {
    return (
        <div className="datagrid-footer">
            {children}
        </div>
    )
}