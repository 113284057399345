import React from "react";
import { Grid, GridRow, GridColumn } from "../amethyst";
import { NewsFeed } from "./NewsFeed";
import { AgendaFeed } from "./AgendaFeed";

export const ContentFlow:React.FC<any> = () => {
    return (
        <Grid>
            <GridRow>
                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 6, 'col-lg': 6 }}>
                    <NewsFeed />
                </GridColumn>
                <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 6, 'col-lg': 6 }}>
                    <AgendaFeed />
                </GridColumn>
            </GridRow>
        </Grid>
    )
}