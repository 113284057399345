import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
    Article,
    ArticleStatus, PaginatedArticles,
    MutationCreateArticleArgs,
    MutationUpdateArticleArgs,
    useGetArticleToEditQuery,
    useQueryGetArticleToViewQuery,
    useCreateArticleMutation, useUpdateArticleMutation,
    useQueryGetArticlesTagsQuery, useGetArticlesQuery,
    useGetArticlesWithAuditTrailQuery
} from '../../service/api/generated-types';

import { QueryGetArticles } from "../../service/api/api-queries/article";

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 10

const refetchLatestArticles = {
    query:QueryGetArticles,
    variables:{ pagination: { page:DEFAULT_PAGE, pageSize:DEFAULT_PAGE_SIZE } }
}

type FetchArticlesRes = { pageOfArticles:PaginatedArticles, error: ApolloError|undefined, loading: boolean }

export const useLatestArticles = (page: number = DEFAULT_PAGE, pageSize: number = DEFAULT_PAGE_SIZE):FetchArticlesRes => {
    const {data, error, loading } = useGetArticlesQuery({
        variables: {
            pagination: { page, pageSize }
        }
    });
    if(data && data.articles && !loading) {
        return { pageOfArticles: data.articles as PaginatedArticles, error, loading}
    } else {
        return { pageOfArticles: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

export interface ArticlesFilter {
    page: number,
    pageSize: number,
    status: ArticleStatus[],
    from: Date,
    to: Date
}

export const useArticles = (filter: ArticlesFilter):FetchArticlesRes => {
    const {data, error, loading } = useGetArticlesWithAuditTrailQuery({
        variables: {
            pagination: { page:filter.page, pageSize:filter.pageSize},
            filter: { status:filter.status.length > 0 ? filter.status : undefined, publicationRange: { from:filter.from, to:filter.to } }
        },
        fetchPolicy:'cache-and-network' // Important to keep in sync the article list in CRUD page
    });
    if(data && data.articles && !loading) {
        return { pageOfArticles: data.articles as PaginatedArticles, error, loading}
    } else {
        return { pageOfArticles: { items:[], currentPage:0, totalPages:0 }, error, loading}
    }
}

type FetchArticle = { article:Article|undefined, error: ApolloError|undefined, loading: boolean }

export const useArticleToEdit = (_id:string|undefined = undefined, slug:string|undefined = undefined):FetchArticle => {
    const {data, error, loading } = useGetArticleToEditQuery({variables: {selector: {slug, _id}}});
    if(data && data.article && !loading) {
        return { article: data.article as Article, error, loading}
    } else {
        return { article: undefined, error, loading}
    }
}

export const useArticleToView = (_id:string|undefined = undefined, slug:string|undefined = undefined):FetchArticle => {
    const {data, error, loading } = useQueryGetArticleToViewQuery({variables: {selector: {slug, _id}}});
    if(data && data.article && !loading) {
        return { article: data.article as Article, error, loading}
    } else {
        return { article: undefined, error, loading}
    }
}

type FetchArticlesTags = { loading:boolean, tags: string[], error: ApolloError|undefined }
export const useArticlesTags = ():FetchArticlesTags => {
    const { data, loading, error } = useQueryGetArticlesTagsQuery({fetchPolicy: "cache-and-network"});
    if(loading) return { loading, tags:[], error }
    return { loading, tags: (data ? data.articlesTags : []), error}
}

type CreateArticleRes = {
    article:Article|null,
    errors:readonly GraphQLError[]|undefined
}

type CreateArticle = [fn:(args: MutationCreateArticleArgs) => Promise<CreateArticleRes>, status: { loading: boolean}]

export const useCreateArticle = ():CreateArticle => {
    const [createArticle, { loading }] = useCreateArticleMutation();
    const fn = (args: MutationCreateArticleArgs):Promise<CreateArticleRes> => {
        return createArticle({
            variables: args,
            refetchQueries:[refetchLatestArticles]
        })
            .then(({data, errors}) => {
                if(data) return { article:data.createArticle as Article, errors}
                return { article:null, errors }
            });
    }
    return [fn, { loading }]
}

type UpdateNewRes = {
    article:Article|null
    errors:readonly GraphQLError[]|undefined
}

type UpdateArticle = [fn:(args: MutationUpdateArticleArgs) => Promise<UpdateNewRes>, status: { loading: boolean}]

export const useUpdateArticle = ():UpdateArticle => {
    const [updateArticle, { loading }] = useUpdateArticleMutation();
    const fn = (args: MutationUpdateArticleArgs):Promise<UpdateNewRes> => {
        return updateArticle(
            {
                variables:args,
                refetchQueries:[refetchLatestArticles]
            })
            .then(({data, errors}) => {
                if(data) return { article:data.updateArticle as Article, errors}
                return { article:null, errors }
            })
    }
    return [fn, { loading }]
}
