import React from 'react';
import { useMe } from '../../model/user';
import { AppDirectory } from '../app/view/AppDirectory';

export const AppsPage:React.FC = () => {
    const { me } = useMe();
    if(me) {
        return <AppDirectory me={me} />
    }
    return null;
}