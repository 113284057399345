import React, { useMemo, useRef } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { AxiosProgressEvent } from 'axios';
import * as Yup from 'yup';
import {
    Modal,
    ModalContent,
    Container,
    Form,
    FormGroup,
    FieldTextInput,
    FormAction,
    FieldFormCreatableMultiSelect,
    ISelectItem,
    FieldDatePicker,
    FieldFormEditor,
    LoaderSize,
    LoaderWithOverlay,
    FieldFormMultipleSelect,
} from '../../amethyst';
import { useTranslation } from 'react-i18next';
import { AgendaEntry, MutationCreateAgendaEventArgs, MutationUpdateAgendaEventArgs, Event } from '../../../model/agendaEntry';
import { EditorApiRef, IEditorImageUploadResult } from '../../amethyst/editor/Editor';
import { parseISO } from 'date-fns';
import { useContextManagerRole } from '../../security/ProtectedRoute';
import { richContentLenght, uploadContentImage } from '../../utils';
import { Customer, ServicesItems, IndustriesItems } from '../../../model/customer';
import { AgendaEntryTargetInput } from '../../../service/api/generated-types';

// Max filesize for pictures attached to event
const IMAGE_MAX_SIZE = 500*1024;

interface AgendaEventFormType {
    title:string,
    date:Date,
    tags:ISelectItem[],
    content: string,
    ctaLabel: string,
    //ctaTitle: string,
    ctaUrl: string,
    targetIndustries:ISelectItem[],
    targetServices:ISelectItem[],
    targetCustomers:ISelectItem[]
}

export interface AgendaEventEditFormProps {
    agendaEntryToEdit?:AgendaEntry
    customers:Customer[]
    onCreate: (params: MutationCreateAgendaEventArgs) => Promise<any>
    onUpdate: (params: MutationUpdateAgendaEventArgs) => Promise<any>
    mustBeDuplicated: boolean
    show: boolean
    tags:string[]
    close: () => void
    loading:boolean
}

const newAgendaEventDefaultValues:AgendaEventFormType = {
    title: '',
    date: new Date(),
    tags: [],
    content:'',
    ctaLabel: '',
    //ctaTitle: '',
    ctaUrl: '',
    targetIndustries:[],
    targetServices:[],
    targetCustomers:[]
}

const targetingValue = (industries: ISelectItem[], services: ISelectItem[], customerIds: ISelectItem[]):AgendaEntryTargetInput|undefined => {
    if(industries.length === 0 && services.length === 0 && customerIds.length === 0) return undefined;
    const target:AgendaEntryTargetInput = {
        industries:industries.map(i => i.id),
        services:services.map(i => i.id),
        customerIds:customerIds.map(i => i.id)
    }
    return target;
}

export const AgendaEventEditForm: React.FC<AgendaEventEditFormProps> = ({ agendaEntryToEdit, customers, onCreate, onUpdate, mustBeDuplicated, show, close, tags, loading }) => {
    const { t } = useTranslation();
    const { locale } = useContextManagerRole();
    const editorApiRef = useRef<EditorApiRef>(null); // Ref to EditorJs exposed API

    const progressCallback = (progressEvent: AxiosProgressEvent) => { console.log(progressEvent); }

    /**
     * Manage image upload for inline image in EditorJs
     * @param file
    */
    const imageUploader = (file:File):Promise<IEditorImageUploadResult> => {
        // EditorJs image plugin does not support max file size, so we inspect it just before upload
        if(file.size > IMAGE_MAX_SIZE) {
            return Promise.reject(t("FormValidation.FileTooLarge"));
        }

        return uploadContentImage(file, progressCallback).then(r => {
            if(!!r) {
                return {
                    success: true,
                    file: {
                        url:r.url,
                        blobId:r.blobId
                    }
                }
            }
            return { file:undefined, success:false }
        })
    }

    const formValidationSchema = Yup.object().shape({
        title: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length:60 }))
            .required(t("FormValidation.Required")),
        content:Yup.string().test('content-check', t("FormValidation.Required"), (arg:string|undefined) => {
            return richContentLenght(arg) > 0;
        }),
        date: Yup.date()
            .typeError(t("FormValidation.InvalidDate"))
            .label("Date")
            .required(t("FormValidation.Required")),
        ctaLabel: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length: 60 }))
            .required(t("FormValidation.Required")),
        /*ctaTitle: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length: 60 }))
            .required(t("FormValidation.Required")),*/
        ctaUrl: Yup.string()
            .url(t("FormValidation.InvalidUrl"))
            .required(t("FormValidation.Required"))
    });

    const tagsItems = useMemo<ISelectItem[]>(() => tags.map(t => ({id:t, label:t, value:t})), [tags]);
    const customersItems = useMemo<ISelectItem[]>(() => customers.map(c => ({id:c._id, value: c.name, label: c.name})), [customers]);
    
    const initialValues = useMemo<AgendaEventFormType>(() => {
        if (!!agendaEntryToEdit) {
            const agendaEventProperties = agendaEntryToEdit.properties as Event;
            return {
                title: agendaEntryToEdit.title,
                date: parseISO(agendaEntryToEdit.date),
                tags: agendaEntryToEdit.tags.map(t => ({ id: t, value: t, label: t })),
                content: agendaEventProperties.rawContent,
                ctaLabel: agendaEventProperties.cta.link ? agendaEventProperties.cta.link.label : '',
                //ctaTitle: agendaEventProperties.cta.link ? agendaEventProperties.cta.link.title : '',
                ctaUrl: agendaEventProperties.cta.link ? agendaEventProperties.cta.link.url : '',
                targetIndustries:agendaEntryToEdit.target?.industries ? agendaEntryToEdit.target.industries.reduce<ISelectItem[]>((indItems, id) => {
                    let item = IndustriesItems.find(item => item.id === id);
                    if(item) indItems.push(item);
                    return indItems;
                }, []) : [],
                targetServices:agendaEntryToEdit.target?.services ? agendaEntryToEdit.target.services.reduce<ISelectItem[]>((svcItems, id) => {
                    let item = ServicesItems.find(item => item.id === id);
                    if(item) svcItems.push(item);
                    return svcItems;
                }, []) : [],
                targetCustomers:agendaEntryToEdit.target?.customers ? agendaEntryToEdit.target.customers.reduce<ISelectItem[]>((cItems, c) => {
                    let item = customers.find(item => item._id === c._id);
                    if(item) cItems.push({ id: item._id, label: item.name, value: item.name});
                    return cItems;
                }, []): []
            }
        }
        return newAgendaEventDefaultValues;
    }, [agendaEntryToEdit, customers]);

    /**
     * Article submit (creation or update)
     * @param values
     */

    const onSubmit = async (values:AgendaEventFormType, {resetForm}:FormikHelpers<AgendaEventFormType>):Promise<void> => {
        const event = {
            title:values.title,
            rawContent:values.content,
            date:values.date,
            tags: values.tags.map(t => t.value),
            cta: {
                link: {
                    label: values.ctaLabel,
                    //title: values.ctaTitle,
                    url: values.ctaUrl
                }
            },
            target:targetingValue(values.targetIndustries, values.targetServices, values.targetCustomers)
        }
        if (!!agendaEntryToEdit && !mustBeDuplicated) {
            await onUpdate({ _id:agendaEntryToEdit._id, event });
        } else {
            await onCreate({ event });
        }
        resetForm({ values: initialValues });
        close();
    }

    if (!show) return null;
    const editMode = !!agendaEntryToEdit;

    return (
        <>
            {loading ?
                <LoaderWithOverlay size={LoaderSize.medium} label={t("AgendaManagement.LoadingEvent")} /> :
                <Modal
                    open={show}
                    title={
                        !editMode ? t("AgendaManagement.AddEvent")
                            : mustBeDuplicated ? `${t("AgendaManagement.CreateEventFrom")} ${agendaEntryToEdit.title}`
                            : `${t("Actions.Update")} ${agendaEntryToEdit.title}`
                    }
                    onClose={() => close()}
                    closeOnEscape
                >
                    <ModalContent>
                        <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                            <Container fluid>
                                <Form id="agendaEventForm" inline>
                                    <FormGroup>
                                        <FieldTextInput
                                            label={t("AgendaManagement.TitleAgendaEntry")}
                                            form="agendaEventForm"
                                            id="title"
                                            name="title"
                                            placeholder={t("AgendaManagement.TitleAgendaEntryPlaceholder")}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldDatePicker
                                            id="date"
                                            form="agendaEventForm"
                                            name="date"
                                            label={t("AgendaManagement.Date")}
                                            locale={locale}
                                        />
                                        <FieldFormCreatableMultiSelect
                                            id="tags"
                                            name="tags"
                                            form="agendaEventForm"
                                            items={tagsItems}
                                            label={t("AgendaManagement.Tags")}
                                            placeholder={t("AgendaManagement.TagsPlaceholder")}
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormEditor
                                            id="content"
                                            name="content"
                                            placeholder={t("AgendaManagement.ContentPlaceholder")}
                                            label={t("AgendaManagement.Content")}
                                            imageUploader={imageUploader}
                                            imageUploaderCaption={t("AgendaManagement.PictureUploaderCaption")}
                                            editorApiRef={editorApiRef}
                                        />
                                    </FormGroup>
                                    <FormGroup legend={t("AgendaManagement.CTA")}>
                                        <FieldTextInput
                                            label={t("AgendaManagement.CtaLabel")}
                                            form="agendaEventForm"
                                            id="ctaLabel"
                                            name="ctaLabel"
                                        />
                                        <FieldTextInput
                                            label={t("AgendaManagement.CtaUrl")}
                                            form="agendaEventForm"
                                            id="ctaUrl"
                                            name="ctaUrl"
                                        />
                                    </FormGroup>
                                    <FormGroup legend={t("AgendaManagement.Targeting")}>
                                        <FieldFormMultipleSelect
                                            id="targetIndustries"
                                            items={IndustriesItems}
                                            placeholder={t("AgendaManagement.TargetServices")}
                                            name="targetIndustries"
                                            label={t("AgendaManagement.TargetIndustries")}
                                            noOptionsMessage=""
                                            displayLabelAsValue
                                        />
                                        <FieldFormMultipleSelect
                                            id="targetServices"
                                            items={ServicesItems}
                                            placeholder={t("AgendaManagement.TargetServices")}
                                            name="targetServices"
                                            label={t("AgendaManagement.TargetServices")}
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FieldFormMultipleSelect
                                            id="targetCustomers"
                                            items={customersItems}
                                            label={t("AgendaManagement.TargetCustomers")}
                                            placeholder={t("AgendaManagement.TargetCustomersPlaceHolder")}
                                            name="targetCustomers"
                                            noOptionsMessage=""
                                        />
                                    </FormGroup>
                                    <FormAction
                                        submittingLabel={t("Actions.Saving")}
                                        submitLabel={t("Actions.Save")}
                                        resetLabel={t("Actions.Cancel")}
                                    />
                                </Form>
                            </Container>
                        </Formik>
                    </ModalContent>
                </Modal>
            }
        </>
    )
}