import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { CustomerCrud } from './CustomerCrud';

export const CustomerManagement:React.FC = () => {
    return (
        <CrudLayout>
            <CustomerCrud />
        </CrudLayout>
    )
}
