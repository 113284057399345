import React from "react";
import { GridColumn } from "./Grid.Column";

export interface GridRowProps {
    children:React.ReactElement<typeof GridColumn>|React.ReactElement<typeof GridColumn>[];
    center?:boolean
}

export const GridRow:React.FunctionComponent<GridRowProps> = ({children, center = false}) => {
    return (
        <div className={`row ${center ? 'text-center' : ''}`}>
            {children}
        </div>
    )
}
