import { GraphQLError } from 'graphql';

export enum MyGTApiErrors {
    UnAuthenticated = 'UNAUTHENTICATED',
    Forbidden = 'FORBIDDEN',
    GraphQLValidationFailed = 'GRAPHQL_VALIDATION_FAILED',
    Unknown ='UNKNOWN',
    InternalServerError = 'INTERNAL_SERVER_ERROR',
}

const ErrorCodeArray = Object.values(MyGTApiErrors);

export const parseErrors = (errors: ReadonlyArray<GraphQLError>): MyGTApiErrors[] => {
    if (errors && errors.length > 0) {
        return errors.map((e:GraphQLError) => {
            let code = e.extensions?.code as MyGTApiErrors || MyGTApiErrors.Unknown;
            if (!ErrorCodeArray.includes(code as MyGTApiErrors)) {
                code = MyGTApiErrors.Unknown;
            }
            return code;
        });
    }
    return [];
}

