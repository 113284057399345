import React from "react"
import { Icon } from "../icon";

export enum ButtonType {
    primary = "btn-primary",
    secondary = "btn-secondary",
    tertiary = "btn-tertiary"
}

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, React.AriaAttributes {
    kind:ButtonType
    icon?:string
    label?:string|null
    title?:string
    type?:"submit"|"reset"|"button"|undefined
}

const defaultProps: Partial<ButtonProps> = {
    kind: ButtonType.primary,
    disabled: false,
    type:"button"
}

export const Button:React.FC<ButtonProps> = props => {
    const { type, kind, disabled, icon, label, title, className, ...rest } = {...defaultProps, ...props};

    return (
        <button disabled={disabled} type={type} aria-disabled={disabled ? true : undefined} tabIndex={disabled ? -1 : undefined} className={`btn ${kind} ${icon ? 'btn-icon': ''} ${disabled ? 'disabled' : ''}`} {...rest}  >
            {icon ?
                <>
                    <Icon icon={icon} title={title} label={label || ""} />{label? <span>&nbsp;{label}</span> :null}
                </>
                : <>{label}</>
            }
        </button>
    );
}