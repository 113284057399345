import React, { useState, ChangeEvent } from "react";
import { Checkbox, CheckboxProps } from "./Checkbox"

export interface AutoCheckboxProps extends CheckboxProps {
    confirmMessage?:{
        add:string,
        remove:string
    }
}

export const AutoCheckbox:React.FC<AutoCheckboxProps> = (props) => {
    const [checked, setChecked] = useState<boolean>(false);

    const onInternalChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(!props.confirmMessage || (props.confirmMessage && window.confirm(checked ? props.confirmMessage.remove : props.confirmMessage.add))) {
            setChecked(!checked);
            !!props.onChange && props.onChange(e);
        }
    }

    return (
        <Checkbox {...props} checked={checked} onChange={(e) => onInternalChange(e)} />
    )
}