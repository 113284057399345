import React from "react";
import { Container } from "../container/Container";
import { GridRow } from "./Grid.Row";

export interface GridProps {
    fluid?:boolean
    app?:boolean
    className?: string
    children:React.ReactElement<typeof GridRow>|React.ReactElement<typeof GridRow>[];
}

export const Grid:React.FunctionComponent<GridProps> = ({app, fluid, children, className}) => {
    return (
        <Container fluid={fluid} app={app} className={className}>
            {children}
        </Container>
    )
}