import axios, { AxiosProgressEvent } from 'axios';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMyGTUserToken } from '../../security/accessToken';
import { MyGTApiErrors, parseErrors } from './graphqlErrors';
import { globalErrorDispatcher, GlobalErrorType, parseNetworkError } from '../globalError';
import { config } from '../../config';


const myGTApiHttpLink = createHttpLink({
    uri: config.mygt_api_graphql
});

export const authLink = setContext((request, { headers }) => getMyGTUserToken().then(token => {
    return {
        headers: {
            ...headers,
            Authorization: `bearer ${token}`,
        },
    };
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if(networkError) {
        console.error("NETWORK ERROR");
        console.error(networkError);
        const networkErrorType = parseNetworkError(networkError);
        globalErrorDispatcher.dispatch({ errorType:GlobalErrorType.network, message: networkError.message, networkError:networkErrorType });
    }

    if (graphQLErrors) {
        console.error("GRAPHQL ERROR");
        const errors = parseErrors(graphQLErrors);
        console.error(errors);
        globalErrorDispatcher.dispatch({errorType:GlobalErrorType.api, message:'Api ERROR', apiError:errors[0]})
    }
});

export const myGtApiClient = new ApolloClient({
    link: from([errorLink, authLink, myGTApiHttpLink]),
    cache: new InMemoryCache({
        typePolicies:{
            Customer:{
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                },
                fields: {
                    properties:{
                        merge(existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        },
                    }
                }
            },
            App:{
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                }
            },
            User:{
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                },
                fields: {
                    properties:{
                        merge(existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        },
                    }
                }
            },
            Contact:{
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                }
            },
            Article:{
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                },
                fields: {
                    properties:{
                        merge(existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        },
                    }
                }
            },
            AgendaEntry: {
                keyFields: ["_id"],
                merge:(existing, incoming, options) => {
                    return incoming;
                },
                fields: {
                    properties:{
                        merge(existing, incoming, { mergeObjects }) {
                            return mergeObjects(existing, incoming);
                        },
                    }
                }
            }
        }
    })
});

export interface FileUploadResult {
    id:string
    filename:string
    encoding:string
    mimeType:string
    name:string
    url:string
}

export const secureFileUpload = async (files:File[], progressCallback:(progressEvent: AxiosProgressEvent) => void):Promise<FileUploadResult[]> => {
    const token = await getMyGTUserToken();
    let formData = new FormData();

    files.forEach(file => {
        formData.append(file.name, file);
    });

    return axios.post(config.mygt_api_blob, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `bearer ${token}`,
        },
        onUploadProgress:progressCallback
    }).then(response => {
        return response.data.files;
    }).catch((e) => {
        console.error(e);
        globalErrorDispatcher.dispatch({errorType:GlobalErrorType.api, message:'', apiError:MyGTApiErrors.InternalServerError})
    });
}
