import React from "react";
import { DataGridCell } from "./DataGridCell"

export interface DataGridRowProps {
    left?:boolean
    right?:boolean
    children:React.ReactElement<typeof DataGridCell>|React.ReactElement<typeof DataGridCell>[];
}

export const DataGridRow:React.FC<DataGridRowProps> = ({children, left, right}) => {
    const classes = `${left ? 'left' : right ? 'right' : ''}`
    return (
        <tr className={classes}>
            {children}
        </tr>
    )
}

