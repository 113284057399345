import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import {
    Button, ButtonType,
    Container,
    DataGridCell, DataGridHeader, DataGridContent,
    DataGridFooter, DataGridPagination, DataGridRow,
    ISelectItem, MultipleSelect,
    Loader, LoaderSize,
} from '../../amethyst';
import { useAgendaEntriesQuery } from './useAgendaQuery';
import { printDateFromIso } from '../../../i18n';
import { useCrudAgendaEntries, AgendaEntryType, useCreateAgendaEvent, useUpdateAgendaEvent,
    useRemoveAgendaEntry, AgendaEntry, useCreateAgendaDeadline,
    useUpdateAgendaDeadline, Sorting } from '../../../model/agendaEntry';
import { ConfirmModal } from '../../common/ConfirmModal';
import { AgendaEventEdit } from './AgendaEventEdit';
import { AgendaDeadlineEdit } from './AgendaDeadlineEdit';
import { Customer } from '../../../model/customer';

//TODO: should we enhance this component to allow date selection ?
const from = parse('01.01.1970', 'd.MM.yyyy', new Date());
const to = parse('01.01.2100', 'd.MM.yyyy', new Date());
const order = Sorting.Desc;

interface EditState {
    agendaEntry:AgendaEntry|undefined,
    availableCustomers:Customer[]
    type:AgendaEntryType|undefined
    mustBeDuplicated:boolean
    showEditForm:boolean

}

export const AgendaCrud:React.FC = () => {
    // use query params to store pagination and status filtering
    const [setAgendaEntryQuery, { type }, pagination ] = useAgendaEntriesQuery()

    const { t } = useTranslation();
    const i18nEnums = useTranslation('enums');
    const { language } = i18nEnums.i18n;
    const [ createAgendaEvent ] = useCreateAgendaEvent({page:pagination.page, pageSize:pagination.pageSize, type, from, to, order});
    const [ updateAgendaEvent ] = useUpdateAgendaEvent();
    const [ createAgendaDeadline ] = useCreateAgendaDeadline({page:pagination.page, pageSize:pagination.pageSize, type, from, to, order});
    const [ updateAgendaDeadline ] = useUpdateAgendaDeadline();
    const [ deleteAgendaEntry]  = useRemoveAgendaEntry({page:pagination.page, pageSize:pagination.pageSize, type, from, to, order});

    const [editState, setEditState] = useState<EditState>({
        type:undefined,
        availableCustomers:[],
        agendaEntry:undefined,
        mustBeDuplicated:false,
        showEditForm:false
    });

    const [ agendaEntryToDelete, setAgendaEntryToDelete ] = useState<AgendaEntry | undefined>(undefined);
    const [ showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState<boolean>(false);

    // News query and results
    const { pageOfAgendaEntries, pageOfCustomers, loading } = useCrudAgendaEntries({page:pagination.page, pageSize:pagination.pageSize, type, from, to, order});

    const typeItems = useMemo<ISelectItem[]>(() => {
        return Object.keys(AgendaEntryType).map(s => ({id:s, value:s, label:i18nEnums.t(`AgendaEntryType.${s}`)}));
    }, [i18nEnums]);

    const onUpdateTypeFilter = (items: readonly ISelectItem[]) => {
        setAgendaEntryQuery({type:items.map(s => s.id as AgendaEntryType)})
    }

    const onChangePage = (page: number) => {
        setAgendaEntryQuery({type}, {page:page, pageSize:pagination.pageSize});
    }

    const onOpenEditForm = (type: AgendaEntryType, agendaEntry: AgendaEntry | undefined = undefined, mustBeDuplicated: boolean = false) => {
        setEditState({
            agendaEntry: !!agendaEntry ? { ...agendaEntry } : undefined,
            availableCustomers:pageOfCustomers.items,
            mustBeDuplicated,
            showEditForm:true,
            type
        });
    }

    const onOpenDeleteConfirmModal = (agendaEntry: AgendaEntry) => {
        setAgendaEntryToDelete(agendaEntry);
        setShowDeleteConfirmModal(true);
    }

    const onDelete = () => {
        if(agendaEntryToDelete) {
            return deleteAgendaEntry({_id: agendaEntryToDelete._id})
        }
        return Promise.resolve();
    }

    return (
        <>
            {editState.type === AgendaEntryType.Event &&
                <AgendaEventEdit
                    agendaEntryIdToEdit={editState.agendaEntry?._id}
                    customers={editState.availableCustomers}
                    show={editState.showEditForm}
                    onCreate={createAgendaEvent}
                    onUpdate={updateAgendaEvent}
                    mustBeDuplicated={editState.mustBeDuplicated}
                    close={() => setEditState({...editState, showEditForm:false})}
                />
            }
            {editState.type === AgendaEntryType.Deadline &&
                <AgendaDeadlineEdit
                    agendaEntryIdToEdit={editState.agendaEntry?._id}
                    customers={editState.availableCustomers}
                    show={editState.showEditForm}
                    onCreate={createAgendaDeadline}
                    onUpdate={updateAgendaDeadline}
                    mustBeDuplicated={editState.mustBeDuplicated}
                    close={() => setEditState({...editState, showEditForm:false})}
                />
            }
            <ConfirmModal
                title={t("AgendaManagement.ConfirmDeletion")}
                message={agendaEntryToDelete ? agendaEntryToDelete.title : ""}
                close={() => setShowDeleteConfirmModal(false)}
                show={showDeleteConfirmModal}
                onConfirmAction={onDelete}
            />
            <Container fluid>
                <DataGridHeader title={t("AgendaManagement.Title")}>
                    <div style={{display:"flex", gap:"10px"}}>
                        <MultipleSelect
                            id="type"
                            selectedItemIds={type}
                            items={typeItems}
                            onChange={onUpdateTypeFilter}
                            placeholder={t("AgendaManagement.FilterTypePlaceholder")}
                            name="type"
                            noOptionsMessage=""
                            disabled={loading}
                        />
                        <Button
                            kind={ButtonType.primary}
                            label={t("AgendaManagement.AddEvent")}
                            onClick={() => onOpenEditForm(AgendaEntryType.Event)}
                        />
                        <Button
                            kind={ButtonType.primary}
                            label={t("AgendaManagement.AddDeadline")}
                            onClick={() => onOpenEditForm(AgendaEntryType.Deadline)}
                        />
                    </div>
                </DataGridHeader>
                {loading || !pageOfAgendaEntries ? <Loader label={t("AgendaManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("AgendaManagement.Date"),
                            t("AgendaManagement.TitleAgendaEntry"),
                            t("AgendaManagement.Type")
                        ]}>
                        {pageOfAgendaEntries.items.map((agendaEntry, idx) =>
                            <DataGridRow left key={idx}>
                                <DataGridCell>{printDateFromIso(agendaEntry.date, language)}</DataGridCell>
                                <DataGridCell>{agendaEntry.title}</DataGridCell>
                                <DataGridCell>{i18nEnums.t(`AgendaEntryType.${agendaEntry.type}`)}</DataGridCell>
                                <DataGridCell details>
                                    <Button
                                        kind={ButtonType.tertiary}
                                        title={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.DeleteEvent")
                                                : t("AgendaManagement.DeleteDeadline")
                                        }
                                        aria-label={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.DeleteEvent")
                                                : t("AgendaManagement.DeleteDeadline")
                                        }
                                        icon="trash"
                                        onClick={() => onOpenDeleteConfirmModal(agendaEntry)}
                                    />
                                    <Button
                                        kind={ButtonType.tertiary}
                                        title={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.DuplicateEvent")
                                                : t("AgendaManagement.DuplicateDeadline")
                                        }
                                        aria-label={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.DuplicateEvent")
                                                : t("AgendaManagement.DuplicateDeadline")
                                        }
                                        icon="recycle"
                                        onClick={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                () => onOpenEditForm(AgendaEntryType.Event, agendaEntry, true)
                                                : () => onOpenEditForm(AgendaEntryType.Deadline, agendaEntry, true)
                                        }
                                    />
                                    <Button
                                        kind={ButtonType.tertiary}
                                        title={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.EditEvent")
                                                : t("AgendaManagement.EditDeadline")
                                        }
                                        aria-label={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                t("AgendaManagement.EditEvent")
                                                : t("AgendaManagement.EditDeadline")
                                        }
                                        icon="chevron-small-right"
                                        onClick={
                                            agendaEntry.type === AgendaEntryType.Event ?
                                                () => onOpenEditForm(AgendaEntryType.Event, agendaEntry)
                                                : () => onOpenEditForm(AgendaEntryType.Deadline, agendaEntry)
                                        }
                                    />
                                </DataGridCell>
                            </DataGridRow>
                        )}
                        </DataGridContent>
                        <DataGridFooter>
                            <DataGridPagination disabled={loading || pageOfAgendaEntries.totalPages < 2} currentPage={pagination.page} totalPages={pageOfAgendaEntries.totalPages} onPageClick={onChangePage} />
                        </DataGridFooter>
                    </>
                }
            </Container>
        </>
    )
}
