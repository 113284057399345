import { parseISO } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { printDate } from "../../i18n";
import { AgendaEntryType, useLatestAgendaEntries } from "../../model/agendaEntry";
import { Container, Icon, Divider, GridRow, Grid, GridColumn, Button, ButtonType } from "../amethyst";
import "./agendaFeed.scss";
import { useCurrentCustomer } from '../customer/view/customerContext';

const AgendaList: React.FC<any> = () => {
    const { currentCustomerId } = useCurrentCustomer();
    const { pageOfAgendaEntries } = useLatestAgendaEntries(undefined, undefined, currentCustomerId);
    const { i18n } = useTranslation();

    const onOpenUrl = (url: string|undefined) => {
        if (!!url) {
            window.open(url, '_blank', 'noreferrer');
        }
    }

    return (
        <Grid className="agenda-items-list">
            <>
                {pageOfAgendaEntries.items.slice(0, 3).map((agendaEntry, idx) =>
                    <div className="mb-5" key={idx}>
                        <GridRow>
                            <GridColumn defaultSize={2}>
                                <div className="agenda-item-publication-date text-center">
                                    <p className="agenda-item-publication-day mb-0">
                                        {printDate(parseISO(agendaEntry.date), i18n.language, "dd")}<br/>
                                        {printDate(parseISO(agendaEntry.date), i18n.language, "MMM.")}
                                    </p>
                                    <p>{printDate(parseISO(agendaEntry.date), i18n.language, "yyyy")}</p>
                                </div>
                            </GridColumn>
                            <GridColumn defaultSize={10}>
                                <GridRow>
                                    <div className="mb-2">
                                        <GridColumn defaultSize={12}>
                                            <Link to={`/agenda/${agendaEntry._id}`} className="link agenda-item-link" >
                                                <span className="agenda-item-title">{agendaEntry.title}</span>
                                            </Link>
                                        </GridColumn>
                                    </div>
                                </GridRow>
                                <GridRow>
                                    <GridColumn defaultSize={12}>
                                        <GridRow>
                                            <GridColumn defaultSize={12}>
                                                <div className="agenda-item-description">
                                                    <div className="agenda-item-info">
                                                        <p className="agenda-item-abstract font-normal mb-0" dangerouslySetInnerHTML={{ __html: agendaEntry.properties.teaser }} />
                                                    </div>
                                                    {(agendaEntry.type === AgendaEntryType.Event && !!agendaEntry.properties.cta.link && agendaEntry.properties.cta.link.url.length > 0) &&
                                                        <Button kind={ButtonType.secondary} label={agendaEntry.properties.cta.link.label} onClick={() => onOpenUrl(agendaEntry.properties.cta.link?.url)} />
                                                    }
                                                </div>
                                                {agendaEntry.tags.length > 0 &&
                                                    <p className="agenda-item-tags mb-0 mt-2">
                                                        { agendaEntry.tags.join(', ') }
                                                    </p>
                                                }
                                            </GridColumn>
                                        </GridRow>
                                    </GridColumn>
                                </GridRow>
                            </GridColumn>
                        </GridRow>
                    </div>
                )}
            </>
        </Grid>
    )
}

export const AgendaFeed: React.FC<any> = () => {
    const { t } = useTranslation();
    return (
        <Container fluid className="agenda-items-feed">
            <h3 className="mb-0 mt-3"><Icon icon="calendar" /> {t("Agenda.Title")}</h3>
            <Divider narrow dark />
            <AgendaList />
            <div className="all-agenda-items text-sm-center mb-3">
                <Link className="link" to="/agenda" >{t("Agenda.ViewAllAgengaEntries")}&nbsp;<Icon icon="chevron-right" /></Link>
            </div>
            
        </Container>
    )
}