import { JsonLocalStorage } from '../../../service/storage/localStorage';

export type PersistedCustomerAccountType = {
    customerId:string
}

/**
 * Persistence of selected customer account, using its id
 * The browser local storage is used for persistence
 */
class PersistedCustomerAccount {
    private readonly storageKey:string = "selectedCustomerAccount";
    private readonly storage:JsonLocalStorage<PersistedCustomerAccountType>;

    constructor() {
         this.storage = new JsonLocalStorage<PersistedCustomerAccountType>();
    }

    save(selected: PersistedCustomerAccountType) {
        this.storage.setItem(this.storageKey, selected);
    }

    remove() {
        this.storage.removeItem(this.storageKey);
    }

    get customerAccount():PersistedCustomerAccountType|null {
        return this.storage.getItem(this.storageKey);
    }
}

export const persistedCustomerAccount = new PersistedCustomerAccount();
