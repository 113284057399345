import React, { PropsWithChildren, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, GridColumn, GridRow, HomeContainer, Loader, LoaderSize, Message, MessageSize } from '../amethyst';
import { MyGTNavBar } from '../navbar/MyGTNavBar';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import { CurrentCustomerProvider } from '../customer/view/customerContext';
import { Me, useMe } from '../../model/user';

export interface LayoutProps extends PropsWithChildren {}

const LoadingMe = () => {
    const { t } = useTranslation();

    return <Grid app fluid >
        <GridRow center>
            <GridColumn>
                <Loader size={LoaderSize.xlarge} label={t("PageLayout.MeLoading")} />
            </GridColumn>
        </GridRow>
    </Grid>
}

const MeError = () => {
    const { t } = useTranslation();
    return <Grid app fluid >
        <GridRow center>
            <GridColumn>
                <Message size={MessageSize.large} icon="drowning" label={t("MyGT.ErrorLoadingProfile")} />
            </GridColumn>
        </GridRow>
    </Grid>
}

interface CatchCustomerUsersWithNoAccessProps extends PropsWithChildren{
    me:Me|null
}

const CatchCustomerUsersWithNoAccess:React.FC<CatchCustomerUsersWithNoAccessProps> = ({me, children}) => {
    const { t } = useTranslation();

    if(me && me.isCustomer && me.numberOfCustomerRelationships === 0) {
        return (
            <Grid app fluid >
                <GridRow center>
                    <GridColumn>
                        <Message size={MessageSize.large} icon="lock-locked" label={t("MyGT.NoAccess")} />
                    </GridColumn>
                </GridRow>
            </Grid>
        )
    }

    return (
        <>
            {children}
        </>
    )
}

export const PageLayout:React.FunctionComponent<LayoutProps> = ({children}) => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
    const { me, loading, error } = useMe();
    const { pathname } = useLocation();
    const isAuthenticated = useIsAuthenticated();

    useLayoutEffect(() => {
        if(!!me) {
            if(currentLanguage !== me.language) {
                setCurrentLanguage(me.language);
                i18n.changeLanguage(me.language);
            }
        }
    }, [me, currentLanguage, i18n]);

    return (
        <CurrentCustomerProvider me={me}>
            <MyGTNavBar pathname={pathname} />
            <HomeContainer>
                <CatchCustomerUsersWithNoAccess me={me}>
                    {!!error ? <MeError /> :
                        <>
                            {(loading && isAuthenticated) ? <LoadingMe /> : children }
                        </>
                    }
                </CatchCustomerUsersWithNoAccess>
            </HomeContainer>
        </CurrentCustomerProvider>
    );
};
