import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { useLatestArticles } from '../../model/article';
import {
    DataGridFooter, DataGridPagination,
    Divider, Grid, GridColumn, GridRow, Icon,
    Loader, LoaderSize, usePagination
} from '../amethyst';

import { printDate } from '../../i18n';
import "./newsPage.scss";
import { ContentNotFound } from '../error/ContentNotFound';

export const NewsPage:React.FC = () => {
    const {i18n, t } = useTranslation();
    const [pagination, setPagination] = usePagination();
    const { pageOfArticles, loading, error } = useLatestArticles(pagination.page, pagination.pageSize);

    const onChangePage = (page: number) => {
        setPagination({page, pageSize:pagination.pageSize});
    }

    if(!!error) {
        return <ContentNotFound />
    }

    return (
        <div className="news-page">
            <h3 className="mb-0 mt-3"><Icon icon="newspaper" /> {t("News.Title")}</h3>
            <Divider dark narrow />

            {loading ? <Loader size={LoaderSize.medium} label={t("News.Loading")} /> :
                <>
                    <Grid className="news-list">
                        <>
                            {pageOfArticles.items.map((article, idx) =>
                                <div className="mb-5" key={idx}>
                                    <GridRow>
                                        <div className="mb-2">
                                            <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                                <Link to={`/news/${article.slug}`} className="link news-link" >
                                                    <span className="news-title">{article.title}</span>
                                                </Link>
                                            </GridColumn>
                                        </div>
                                    </GridRow>
                                    <GridRow>
                                        {article.properties.mainPicture ?
                                            <>
                                                <GridColumn defaultSize={4} columnSize={{ 'col-sm': 4, 'col-md': 4, 'col-lg': 4 }}>
                                                    <Link to={`/news/${article.slug}`}>
                                                        <img alt="article" style={{width:"100%"}} src={article.properties.mainPicture.downloadUrl} />
                                                    </Link>
                                                </GridColumn>
                                                <GridColumn defaultSize={8} columnSize={{ 'col-sm': 8, 'col-md': 8, 'col-lg': 8 }}>
                                                    <p className="font-normal news-abstract mb-0" dangerouslySetInnerHTML={{__html: article.teaser}} />
                                                </GridColumn>
                                            </>
                                            :
                                            <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                                <p className="font-normal news-abstract mb-0" dangerouslySetInnerHTML={{__html: article.teaser}} />
                                            </GridColumn>
                                        }
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 12 }}>
                                            <p className="news-publication-date mb-0 mt-2">
                                                {article.properties.tags.length > 0 ?
                                                    <>
                                                        { article.properties.tags.join(', ') }
                                                        &nbsp; • &nbsp;
                                                    </>
                                                    : null}
                                                {printDate(parseISO(article.publicationDate), i18n.language, "dd MMM. yyyy")}
                                            </p>
                                        </GridColumn>
                                    </GridRow>
                                </div>
                            )}
                        </>
                    </Grid>
                    <DataGridFooter>
                        <DataGridPagination disabled={loading || pageOfArticles.totalPages < 2} currentPage={pagination.page} totalPages={pageOfArticles.totalPages} onPageClick={onChangePage} />
                    </DataGridFooter>
                </>
            }
        </div>
    )
}