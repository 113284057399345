import React, {ChangeEvent, FocusEvent} from "react";
import { InputStatus } from "../common";

export interface CheckboxProps {
    id:string,
    name:string,
    title?:string,
    label?:string,
    form?:string,
    status?:InputStatus,
    disabled?:boolean,
    checked?:boolean,
    onChange?:(e: ChangeEvent<HTMLInputElement>) => void,
    onBlur?:(e: FocusEvent<HTMLInputElement>) => void
    hoverTitle?:string
}

export const Checkbox:React.FC<CheckboxProps> = ({id, name, title, label, form, disabled = false, status, checked, onChange, onBlur, hoverTitle}) => {
    return (
        <div className="form-checkbox">
            {title ? <label className="form-label">{title}</label> : null}
            <label className="form-checkbox-label">
                <input
                    id={id}
                    form={form}
                    checked={!!checked}
                    placeholder={label}
                    className={`form-checkbox-input ${status ? status : ''}`}
                    name={name}
                    type="checkbox"
                    onChange={(e) => onChange && onChange(e) }
                    onBlur={(e) => onBlur && onBlur(e)}
                    disabled={disabled}
                />
                <label title={hoverTitle} className={`form-checkbox-text ${status ? status : ''}`} htmlFor={id}>{label ? label : ''}</label>
            </label>
        </div>
    )
}
