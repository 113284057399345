import React from 'react'
import { PlainColor } from '../common';

export interface DotProps {
    color: PlainColor
}

export const Dot:React.FC<DotProps> = ({color}) => {
    return <span style={{
        height: "10px",
        width: "10px",
        marginLeft: "5px",
        marginRight: "5px",
        backgroundColor: color,
        borderRadius: "50%",
        display: "inline-block"
    }} />
}