import React from "react";

export interface ModalActionProps {
    children:React.ReactNode[]|React.ReactNode
}

const renderChildren = (children: React.ReactNode[]|React.ReactNode) => {
    if(Array.isArray(children)) {
        return children.map((node, idx) =>
                <li key={idx}>
                    {node}
                </li>
            )
    } else return <li>{children}</li>;
}

export const ModalAction:React.FC<ModalActionProps> = ({children}) => {
    return (
        <div className="modal-footer">
            <ul className="nav modal-footer-nav">
                {renderChildren(children)}
            </ul>
        </div>
    )
}
