import { useQueryParams, NumberParam, ArrayParam, withDefault } from 'use-query-params';
import { ArticleStatus } from '../../../model/article';
import { PaginationFilter, DEFAULT_PAGE_SIZE } from '../../amethyst'

const DEFAULT_STATUS = [ArticleStatus.Published, ArticleStatus.Draft, ArticleStatus.SubmittedForApproval];

type ArticleQuery = {
  status: ArticleStatus[]
};

const ArticleStatusKeys = Object.keys(ArticleStatus);

type UseArticlesQuery = [updateArticleQuery:(query:ArticleQuery, pagination?:PaginationFilter) => void, query:ArticleQuery, pagination:PaginationFilter ]

export const useArticlesQuery = (articleQuery: ArticleQuery = {status:DEFAULT_STATUS}, pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}):UseArticlesQuery => {
    const [query, setQuery] = useQueryParams({
        p: withDefault(NumberParam, pagination.page),
        s: withDefault(NumberParam, pagination.pageSize),
        as: withDefault(ArrayParam, articleQuery.status as ArticleStatus[]),
    });

    const status = (query.as as ArticleStatus[]).reduce<ArticleStatus[]>((selected, currentValue) => {
        if(ArticleStatusKeys.includes(currentValue)) selected.push(currentValue);
        return selected;
    }, []);

    return [
        (articleQuery: ArticleQuery, pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}) => {
            setQuery({
                p:pagination.page,
                s:pagination.pageSize,
                as:articleQuery.status
            })
        },
        {status},
        { page:query.p, pageSize:query.s}
    ]
}

