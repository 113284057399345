import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCustomers } from '../../../model/customer';
import {
    AsyncMultiSelect,
    AutoCheckbox,
    Button, ButtonGroup, ButtonType,
    Checkbox,
    Container,
    DataGridCell, DataGridContent, DataGridHeader, DataGridRow,
    Divider,
    Grid, GridColumn, GridRow,
    Loader, LoaderSize,
    Message, MessageSize,
    Modal, ModalAction, ModalContent,
    SimpleSelect,
    ISelectItem,
} from '../../amethyst';
import { App, useApps, useGetAppUsersSubscriptions, useUpdateAppSubscriptions, AppSubscriptions } from '../../../model/app';
import { useGetCustomerUsersLazy } from '../../../model/user';
import { AppUserSubscriptions } from '../../../service/api/generated-types';
import './subscriptionEdit.scss';
import { useTranslation } from 'react-i18next';

const sortUsers = ( a: string, b: string ) => {
    if ( a < b ) { return -1; }
    if ( a > b ) { return 1; }
    return 0;
}

const sortAppsByName = ( a: App, b: App ) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
}

interface SubscriptionEditProps {
    apps:App[]
    customerUsers:string[]
    appUserSubscriptions:AppUserSubscriptions[]
    onSaveSubscriptions:(appSubscriptions: AppSubscriptions[]) => Promise<void>
    customerId:string
    fetching:boolean
}

/**
 * to store the user <-> app subscription matrix
 */
interface UserApps { [email:string]: string[] }

/**
 * to build an "app Id" => "app Name" dictionary
 */
interface AppTuple { [appId:string]: string}

/**
 * Transpose an AppUserSubscriptions array into UserApps dictionary
 * @param appUserSubscriptions
 */
const appUserSubscriptionsToUserApps = (appUserSubscriptions: AppUserSubscriptions[]):UserApps => appUserSubscriptions.reduce<UserApps>((acc, subscriptions) => {
    let { _id, customerUserIds } = subscriptions;
    customerUserIds.forEach(user => {
        if(acc[user]) {
            acc[user].push(_id);
        } else {
            acc[user] = [_id];
        }
    });
    return acc;
}, {});

/**
 * Transpose a userApps dictionary into AppSubscriptions array (to be used as input for API to save updated subscriptions)
 * @param userApps
 * @param customerId
 * @param allAppId
 */
const userAppsToAppUsers = (userApps: UserApps, customerId: string, allAppId: string[]):AppSubscriptions[] => {

    // 1) we build an array of AppUsers based on userApps input
    const appUsers = Object.entries(userApps).reduce<AppSubscriptions[]>((acc, [userId, appIds]) => {
        appIds.forEach(appId => {
            let appIdx = acc.findIndex(i => i.appId === appId);
            if(appIdx > -1) {
                // customerUsersSubscriptions[0] as we don't manage multiple customers at the same time on this interface
                acc[appIdx].customerSubscriptions[0].customerUserIds.push(userId);
            } else {
                acc.push({
                    appId,
                    customerSubscriptions:[{customerId, customerUserIds:[userId]}]
                })
            }
        });
        return acc;
    }, []);

    // 2) then, we find/add missing appIds to force them to have an empty customerUsersIds array
    // This is to make sure we remove any existing app subscriptions
    // (as we know what to add, but not anymore what to remove)
    allAppId.forEach(appId => {
        if(appUsers.findIndex(au => au.appId === appId) === -1) {
            appUsers.push({appId,customerSubscriptions:[{customerId, customerUserIds:[]}]});
        }
    });
    return appUsers;
}

/**
 * Update and return a new list of apps for a specified user, appId and required action (add or remove),
 * based on a current UserApp dictionary
 * @param appId
 * @param userId
 * @param currentUserApps
 * @param addApp
 */
const updateAppList = (appId:string, userId: string, currentUserApps:UserApps, addApp: boolean):string[] => {
    let updatedUserApps:string[];
    if(currentUserApps[userId]) {   // user has already at least one app subscription
        let apps = [...currentUserApps[userId]];
        let appIdx = apps.indexOf(appId);

        if(appIdx > -1) {   // user has already a subscription for this app
            if(!addApp) {
                apps.splice(appIdx, 1); // remove it if action is to removed it
            }
            updatedUserApps = apps;  // else do nothing
        } else {            // user does not have a subscription for this app
            if(addApp) {
                updatedUserApps = [...apps, appId] // add it if action is to add it
            } else {
                updatedUserApps = apps;  // else do nothing
            }
        }
    } else { // new user with new app subscription
        if(addApp) {
            updatedUserApps = [appId] // add app is action is to add
        } else {
            updatedUserApps = [] // else add nothing
        }
    }
    return updatedUserApps
}

const SubscriptionEdit:React.FC<SubscriptionEditProps> = ({fetching, apps, customerUsers, appUserSubscriptions, customerId, onSaveSubscriptions}) => {
    const { t } = useTranslation();
    // transpose appUserSubscriptions array into UserApps dictionary
    const [userApps, setUserApps] = useState<UserApps>(() => appUserSubscriptionsToUserApps(appUserSubscriptions));
    const [dirty, setDirty] = useState<boolean>(false);
    const [fetchingMessage, setFetchingMessage] = useState<string>(t("SubscriptionManagement.SearchingSubscriptions"));

    // transpose app array into a dict of tuple (id, name)
    const appTuples = useMemo<AppTuple>(() => {
        return apps.reduce<AppTuple>((tuples, app) => {
            tuples[app._id] = app.name
            return tuples;
        }, {});
    }, [apps]);

    useEffect(() => setUserApps(appUserSubscriptionsToUserApps(appUserSubscriptions)), [appUserSubscriptions]);

    const cancelEdit = () => {
        setUserApps(appUserSubscriptionsToUserApps(appUserSubscriptions));
        setDirty(false);
    }

    const saveEdit = () => {
        const appUserSubscriptions = userAppsToAppUsers(userApps, customerId, Object.keys(appTuples));
        setFetchingMessage(t("SubscriptionManagement.SavingSubscriptions"));
        onSaveSubscriptions(appUserSubscriptions).then( _ => {
            setFetchingMessage(t("SubscriptionManagement.SearchingSubscriptions"));
            setDirty(false)
        });
    }

    const hasSubscription = (userId: string, appId:string) => !!userApps[userId] && userApps[userId].includes(appId)

    /**
     * Manage the add/remove app subscription at cell (app/user) level
     * @param userId
     * @param appId
     * @param toggleTo
     */
    const onToggleSubscription = (userId: string, appId: string, toggleTo: boolean) => {
        let updatedUserApps;
        setDirty(true);
        updatedUserApps = updateAppList(appId, userId, userApps, toggleTo);
        setUserApps({...userApps, [userId]: updatedUserApps});
    }

    /**
     * Manage the add/remove app subscription at column (app) level
     * @param idx
     * @param addApp
     */
    const columnSelector = (idx:number, addApp:boolean) => {
        setDirty(true);
        const appId = apps[idx-1]._id;
        const updatedAllUserApps = customerUsers.reduce<UserApps>((updatedUserApps, userId) => {
            updatedUserApps[userId] = updateAppList(appId, userId, userApps, addApp)
            return updatedUserApps;
        }, {});
        setUserApps(updatedAllUserApps)
    }

    /**
     * Manage the add/remove app subscription at row (user) level
     * @param userId
     * @param addApp
     */
    const rowSelector = (userId: string, addApp:boolean) => {
        setDirty(true);
        let updatedUserApps:string[] = [];
        if(addApp) {
            updatedUserApps = [...Object.keys(appTuples)];
        }
        setUserApps({...userApps, [userId]: updatedUserApps});
    }

    return (
        <div className="subscriptions-edit-matrix">
            <Divider  />
            {fetching ? <Loader label={fetchingMessage} size={LoaderSize.medium} />: <></>}
            {(!fetching && customerUsers.length === 0) ? <Message label={t("SubscriptionManagement.NoSubcriptionsFound")} icon="information-mark" size={MessageSize.medium} /> : <></>}
            {customerUsers.length > 0 ?
                <>
                    <div className="subscriptions-edit-actions">
                        <ButtonGroup>
                            <Button disabled={!dirty || fetching} kind={ButtonType.primary} label={t("Actions.Save")} onClick={saveEdit} />
                            <Button disabled={!dirty || fetching} kind={ButtonType.primary} label={t("Actions.Cancel")} onClick={cancelEdit} />
                        </ButtonGroup>
                    </div>
                    <div>
                        <DataGridContent checkColumn={columnSelector} fixed rotateHeaders hoverable columnNames={[t("SubscriptionManagement.Email"), ...Object.values(appTuples) ]} checkColumnConfirmMessage={{add:t('SubscriptionManagement.AddColumnMessage'), remove:t('SubscriptionManagement.RemoveColumnMessage')}} >
                            {customerUsers.map((userId, idx) =>
                                    <DataGridRow key={idx}>
                                        <DataGridCell>
                                            <AutoCheckbox
                                                confirmMessage={
                                                    {
                                                        add:t("SubscriptionManagement.AddRowMessage", { userId }),
                                                        remove:t("SubscriptionManagement.RemoveRowMessage", { userId })
                                                    }
                                                }
                                                label={userId}
                                                id={userId}
                                                name={userId}
                                                hoverTitle={userId} onChange={(e) => rowSelector(userId, e.target.checked)}
                                            />
                                        </DataGridCell>
                                        <>
                                            {Object.keys(appTuples).map((appId, appIdx) =>
                                                <DataGridCell key={appIdx}>
                                                    <Checkbox id={`${userId}-${appIdx}`} name={`${userId}-${appIdx}`} checked={hasSubscription(userId, appId)} onChange={(e) => onToggleSubscription(userId, appId, e.target.checked)} />
                                                </DataGridCell>)
                                            }
                                        </>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                    </div>
                </>
                : null
            }
        </div>
    )
}

/**
 * flatten customerIds array into one array, and remove duplicates
 * return a list of unique user ids
 * @param appUserSubscriptions
 */
const buildUserListFromSubscriptions = (appUserSubscriptions: AppUserSubscriptions[]):string[] => {
    return appUserSubscriptions
        .map(a => a.customerUserIds)
        .flat()
        .filter((elem, pos, array) => {
            return array.indexOf(elem) === pos;
        })
        .sort(sortUsers);
}

/**
 * Merge two list of userIds, remove duplicates and sort them
 * @param lOne
 * @param lTwo
 */
const mergeUserIds = (lOne: string[], lTwo: string[]): string[] => {
    const merge = [...lOne, ...lTwo];
    return merge
        .filter((elem, pos, array) => {
            return array.indexOf(elem) === pos;
        })
        .sort(sortUsers);
}

export const SubscriptionCrud:React.FC<any> = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { apps, loading:appLoading } = useApps();
    const { pageOfCustomers, loading:customerLoading } = useCustomers({page:1, pageSize:100});

    const sortedApps = useMemo(() => [...apps].sort(sortAppsByName), [apps]);

    const [ updateAppSubscriptions, updateAppSubscriptionsStatus] = useUpdateAppSubscriptions();
    const [ getCustomerUsers, { loading:customerUserLoading }] = useGetCustomerUsersLazy();

    const [ getAppUsersSubscriptions, getAppUserSubscriptionStatus] = useGetAppUsersSubscriptions();

    // Selected customer, that could be initialized from router "location" parameter (in-app navigation)
    const [ selectedCustomerItem, setSelectedCustomerItem ] = useState<ISelectItem|null>(() => {
        // to allow navigation from "customers" to "customer users" by selecting one customer
        if(location.state && location.state.customerId) {
            const customerFromRouter = pageOfCustomers.items.find(c => c._id === location.state.customerId);
            if(customerFromRouter) {
                return {id:customerFromRouter._id, label:customerFromRouter.name, value:customerFromRouter._id}
            }
        }
        return null;
    });

    // selected customer users from the search result
    const [selectedUsers, setSelectedUsers] = useState<ISelectItem[]>([]);
    // Merge of users present in subscription and added users from search
    const [foundCustomerUsers, setFoundCustomerUsers] = useState<string[]>([]);
    // Subscriptions available for the current customer
    const [foundSubscriptions, setFoundSubscriptions] = useState<AppUserSubscriptions[]>([]);
    const [updatingSubscriptions, setUpdatingSubscriptions] = useState<boolean>(false);

    // Add users states
    const [showAllUsersModal, setShowAllUsersModal] = useState<boolean>(false);
    const [allUserIds, setAllUserIds] = useState<string[]>([])

    // helper for loading state
    const loading = appLoading || customerLoading || customerUserLoading || updatingSubscriptions || getAppUserSubscriptionStatus.loading || updateAppSubscriptionsStatus.loading;

    const customerItems = useMemo<ISelectItem[]>(() => {
        return pageOfCustomers.items.map(c => ({id:c._id, label:c.name, value:c._id}));
    }, [ pageOfCustomers.items ]);

    const onChangeCustomer = (item: ISelectItem|null) => {
        if(item) {
            setSelectedCustomerItem(item);
            setSelectedUsers([]);
        }
    }

    const refreshCrudInputs = useCallback((appUserSubscriptions: AppUserSubscriptions[]) => {
        setFoundSubscriptions([...appUserSubscriptions])
        const allUserIds = buildUserListFromSubscriptions(appUserSubscriptions);
        setFoundCustomerUsers(allUserIds);
    }, [setFoundCustomerUsers, setFoundSubscriptions]);

    useEffect(() => {
        if(!!selectedCustomerItem) {
            getAppUsersSubscriptions(selectedCustomerItem.id).then(({appUserSubscriptions, error}) => {
                refreshCrudInputs(appUserSubscriptions);
            });
        }
        // eslint-disable-next-line
    }, [selectedCustomerItem, refreshCrudInputs]);

    const onSaveSubscriptions = async (appSubscriptions: AppSubscriptions[]) => {
        setUpdatingSubscriptions(true);
        updateAppSubscriptions({subscriptions: { appSubscriptions }}).then(({apps, errors})=> {
            if(!!selectedCustomerItem) {
                getAppUsersSubscriptions(selectedCustomerItem.id).then(({appUserSubscriptions, error}) => {
                    refreshCrudInputs([...appUserSubscriptions]);
                    setUpdatingSubscriptions(false);
                });
            }
        });
    }

    const searchForUsers = async (input:string):Promise<ISelectItem[]> => {
        if(!!selectedCustomerItem) {
            return getCustomerUsers({pagination:{page:1, pageSize:500}, customerId:selectedCustomerItem.id, emailPattern:input}).then(({pageOfCustomerUsers}) => {
                return pageOfCustomerUsers.items
                    .map(u => ({id:u._id, label:u._id, value:u._id}))
                    .slice(0, 50) // to be fine tune
            });
        }
        return Promise.resolve([]);
    }

    const addSelectedUsers = () => {
        setFoundCustomerUsers(mergeUserIds(foundCustomerUsers, selectedUsers.map(i => i.id)));
        setSelectedUsers([]);
    }

    const startAddAllUsers = () => {
        setShowAllUsersModal(true);
        searchForUsers('.*').then(r => {
            setAllUserIds(r.map(u => u.id))
        });
    }

    const confirmAddAllUser = () => {
        setFoundCustomerUsers(mergeUserIds(foundCustomerUsers, allUserIds));
        setShowAllUsersModal(false);
    }

    const closeAllUsers = () => {
        setShowAllUsersModal(false);
    }

    return (
        <Container fluid className="subscriptions-edit">
            <Modal title={t("SubscriptionManagement.AddAllUsers")} open={showAllUsersModal} onClose={closeAllUsers} >
                <ModalContent>
                    {customerUserLoading ?
                        <Loader label={t("SubscriptionManagement.LoadingUsers")} size={LoaderSize.medium} /> :
                        <>
                            <p>{t("SubscriptionManagement.FoundUsersLength", {length : allUserIds.length})}
                            <br/>
                            {t("SubscriptionManagement.AddAllUsersConfirm")}
                            <br />
                            {t("SubscriptionManagement.AddAllUsersMessage")}
                            </p>
                        </>

                    }
                </ModalContent>
                <ModalAction>
                    <ButtonGroup>
                        <Button kind={ButtonType.primary} label={t("SubscriptionManagement.AddAllUsers")} onClick={confirmAddAllUser} />
                        <Button kind={ButtonType.secondary} label={t("Actions.Cancel")} onClick={closeAllUsers} />
                    </ButtonGroup>
                </ModalAction>
            </Modal>
            <DataGridHeader title={t("SubscriptionManagement.Title")} >
                    <Grid fluid>
                        <GridRow>
                            <GridColumn marginTop={1} defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 6, 'col-lg': 4 }}>
                                <SimpleSelect id="customer" items={customerItems} disabled={loading} searchable selectedItemId={selectedCustomerItem?.id} placeholder={t("SubscriptionManagement.SelectCustomer")} name="customer" noOptionsMessage="" onChange={onChangeCustomer} />
                            </GridColumn>
                            <GridColumn marginTop={1} defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 6, 'col-lg': 4 }}>
                                <AsyncMultiSelect
                                    id="customer-users"
                                    name="customer-users"
                                    placeholder={t("SubscriptionManagement.SearchUsers")}
                                    noOptionsMessage={t("SubscriptionManagement.NoUsersFound")}
                                    disabled={!selectedCustomerItem}
                                    selectedItems={selectedUsers}
                                    onChange={(items) => setSelectedUsers([...items])}
                                    promisedItems={(input:string) => searchForUsers(input)}
                                />
                            </GridColumn>
                            <GridColumn marginTop={1} defaultSize={12} columnSize={{ 'col-sm': 12, 'col-md': 12, 'col-lg': 4 }}>
                                <ButtonGroup>
                                    <Button kind={ButtonType.primary} label={t("SubscriptionManagement.AddSelectedUsers")} disabled={!selectedCustomerItem || selectedUsers.length === 0 || loading} onClick={addSelectedUsers}/>
                                    <Button kind={ButtonType.primary} label={t("SubscriptionManagement.AddAllUsers")} disabled={!selectedCustomerItem || loading } onClick={startAddAllUsers}/>
                                </ButtonGroup>
                            </GridColumn>
                        </GridRow>
                    </Grid>
            </DataGridHeader>
            {!!selectedCustomerItem ?
                <SubscriptionEdit apps={sortedApps} customerUsers={foundCustomerUsers} appUserSubscriptions={foundSubscriptions} customerId={selectedCustomerItem.id} fetching={getAppUserSubscriptionStatus.loading || updateAppSubscriptionsStatus.loading} onSaveSubscriptions={onSaveSubscriptions}  /> :
                <Message label={t("SubscriptionManagement.PleaseSelectCustomer")} icon="information-mark" size={MessageSize.medium}  />
            }
        </Container>
    )
}