import React from "react";
import { App } from "../../../model/app"
import { isAfter, parseISO, isWithinInterval, isBefore } from "date-fns"
import { Icon, Tooltip, TooltipPosition } from "../../amethyst";
import "./appTile.scss";
import defaultLogo from "../../../assets/img/app-logo.png";
import { printDateFromIso } from "../../../i18n";
import { useTranslation } from "react-i18next";

export interface AppTileProps {
    app:App
    active?:boolean
    maintenance?:boolean
    showThisAppDetails: () => void
}

interface ActiveAppTileProps {
    app:App
}

const ActiveAppTile:React.FC<ActiveAppTileProps> = ({app}) =>
    <div className="app-tile-container">
        <a href={app.url} target="_blank" rel="noreferrer" >
            <img className="app-background" src={app.logo?.downloadUrl || defaultLogo} alt="" />
            <div className='app-title active'>
                {app.name}
            </div>
        </a>
    </div>

const ActiveAppWithFutureMaintenanceTile: React.FC<ActiveAppTileProps> = ({ app }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className="app-tile-container">
            <a href={app.url} target="_blank" rel="noreferrer" >
                <img className="app-background" src={app.logo?.downloadUrl || defaultLogo} alt="" />
                <div className='app-title active'>
                    {app.name}
                </div>
            </a>
            {app.maintenanceWindow && 
                <Tooltip
                    classNames="info-icon"
                    position={TooltipPosition.bottom}
                    label={t("Apps.MaintenanceRangeDatesInfo", {
                        startDate: printDateFromIso(app.maintenanceWindow.from, i18n.language),
                        endDate: printDateFromIso(app.maintenanceWindow.to, i18n.language)
                    })}
                    id={`maintenanceInfo-${app._id}`}>
                    <Icon block icon="information-mark" />
                </Tooltip>
            }
        </div>
    )
}

interface InactiveAppOrWithMaintenanceTileProps extends ActiveAppTileProps {
    showThisAppDetails: () => void
}

const ActiveAppWithMaintenanceTile:React.FC<InactiveAppOrWithMaintenanceTileProps> = ({app, showThisAppDetails}) =>
    <div className="app-tile-container">
        <a href="/#" onClick={(e) => { e.preventDefault(); showThisAppDetails() }} >
            <img className="app-background" src={app.logo?.downloadUrl || defaultLogo} alt="" />
            <div className='app-title maintenance'>
                {app.name}
                <div className="info-icon">
                    <Icon block icon="danger" />
                </div>
            </div>
        </a>
    </div>

const InactiveAppTile:React.FC<InactiveAppOrWithMaintenanceTileProps> = ({app, showThisAppDetails}) =>
    <div className="app-tile-container">
        <a className='disabled' href='/#' onClick={(e) => { e.preventDefault(); showThisAppDetails() }}  >
            <img className="app-background" src={app.logo?.downloadUrl || defaultLogo} alt="" />
            <span className='app-title '>{app.name}</span>
        </a>
    </div>

export const AppTile:React.FC<AppTileProps> = ({app, active, maintenance, showThisAppDetails}) => {

    // Active apps
    if(active && !maintenance) {
        return <ActiveAppTile app={app} />
    }

    // App with maintenance : we show maintenance layout only if not after the end of maintenance period
    if(active && maintenance && !!app.maintenanceWindow) {
        const endOfMaintenance = parseISO(app.maintenanceWindow.to);
        const isWithinMaintenancePeriod = (from:string, to:string):boolean =>
            isWithinInterval(new Date(), { start:parseISO(from), end: parseISO(to) });
        
        if(isAfter(new Date(), endOfMaintenance)) {
            return <ActiveAppTile app={app} />
        }
        if (isBefore(new Date(), endOfMaintenance) && !isWithinMaintenancePeriod(app.maintenanceWindow.from, app.maintenanceWindow.to)) {
            return <ActiveAppWithFutureMaintenanceTile app={app} />
        }
        return <ActiveAppWithMaintenanceTile showThisAppDetails={showThisAppDetails} app={app} />
    }

    // App without subscription
    return <InactiveAppTile app={app} showThisAppDetails={showThisAppDetails} />
}


