import { ISelectItem } from '../../components/amethyst';

export const IndustriesItems:ISelectItem[] = [
    {"id":"0101","label":"0101 : Real estate and construction > Construction > Construction & engineering","value":"0101"},
    {"id":"0102","label":"0102 : Real estate and construction > Construction > Construction/homebuilding","value":"0102"},
    {"id":"0103","label":"0103 : Real estate and construction > Real estate > Diversified real estate activities","value":"0103"},
    {"id":"0104","label":"0104 : Real estate and construction > Construction > Energy efficiency (cleantech)","value":"0104"},
    {"id":"0105","label":"0105 : Real estate and construction > Real estate > Real estate development","value":"0105"},
    {"id":"0106","label":"0106 : Real estate and construction > Real estate > Real estate operating companies","value":"0106"},
    {"id":"0107","label":"0107 : Real estate and construction > Real estate > REITS","value":"0107"},
    {"id":"0201","label":"0201 : Consumer products > Food & beverage > Agricultural products","value":"0201"},
    {"id":"0202","label":"0202 : Consumer products > Retail & logistics > Apparel, accessories & luxury goods manufacturers","value":"0202"},
    {"id":"0203","label":"0203 : Consumer products > Retail & logistics > Apparel retail","value":"0203"},
    {"id":"0204","label":"0204 : Consumer products > Automotive > Automobile manufacturers","value":"0204"},
    {"id":"0205","label":"0205 : Consumer products > Automotive > Automobile parts & equipment manufacturers","value":"0205"},
    {"id":"0206","label":"0206 : Consumer products > Automotive > Automotive retail","value":"0206"},
    {"id":"0207","label":"0207 : Consumer products > Food & beverage > Beverage distributors","value":"0207"},
    {"id":"0208","label":"0208 : Consumer products > Food & beverage > Beverages producers","value":"0208"},
    {"id":"0209","label":"0209 : Consumer products > Retail & logistics > Catalogue retail","value":"0209"},
    {"id":"0210","label":"0210 : Consumer products > Retail & logistics > Computer & electronics retail","value":"0210"},
    {"id":"0211","label":"0211 : Consumer products > Retail & logistics > Consumer electronics manufacturers","value":"0211"},
    {"id":"0212","label":"0212 : Consumer products > Retail & logistics > Convenience stores","value":"0212"},
    {"id":"0213","label":"0213 : Consumer products > Retail & logistics > Department stores","value":"0213"},
    {"id":"0214","label":"0214 : Consumer products > Retail & logistics > Distributors","value":"0214"},
    {"id":"0215","label":"0215 : Consumer products > Food & beverage > Food distributors","value":"0215"},
    {"id":"0216","label":"0216 : Consumer products > Retail & logistics > Food & staples retailing","value":"0216"},
    {"id":"0217","label":"0217 : Consumer products > Retail & logistics > Footwear manufacturers","value":"0217"},
    {"id":"0218","label":"0218 : Consumer products > Retail & logistics > General merchandise stores","value":"0218"},
    {"id":"0219","label":"0219 : Consumer products > Retail & logistics > Home furnishing retail","value":"0219"},
    {"id":"0220","label":"0220 : Consumer products > Retail & logistics > Home furnishings manufacturers","value":"0220"},
    {"id":"0221","label":"0221 : Consumer products > Retail & logistics > Home improvement retail","value":"0221"},
    {"id":"0222","label":"0222 : Consumer products > Retail & logistics > Household appliances manufacturers","value":"0222"},
    {"id":"0223","label":"0223 : Consumer products > Retail & logistics > Household products","value":"0223"},
    {"id":"0224","label":"0224 : Consumer products > Retail & logistics > Housewares and specialties manufacturers","value":"0224"},
    {"id":"0225","label":"0225 : Consumer products > Retail & logistics > Internet retail","value":"0225"},
    {"id":"0226","label":"0226 : Consumer products > Retail & logistics > Leisure products manufacturers","value":"0226"},
    {"id":"0227","label":"0227 : Consumer products > Automotive > Motorcycle manufacturers","value":"0227"},
    {"id":"0228","label":"0228 : Consumer products > Food & beverage > Packaged foods & meats","value":"0228"},
    {"id":"0229","label":"0229 : Consumer products > Retail & logistics > Personal products","value":"0229"},
    {"id":"0230","label":"0230 : Consumer products > Retail & logistics > Photographic products manufacturers","value":"0230"},
    {"id":"0231","label":"0231 : Consumer products > Retail & logistics > Speciality stores","value":"0231"},
    {"id":"0232","label":"0232 : Consumer products > Retail & logistics > Textiles manufacturers","value":"0232"},
    {"id":"0233","label":"0233 : Consumer products > > Tyres & rubber manufacturers","value":"0233"},
    {"id":"0234","label":"0234 : Consumer products > Other > Tobacco","value":"0234"},
    {"id":"0235","label":"0235 : Consumer products > Retail & logistics > Air freight & logistics","value":"0235"},
    {"id":"0236","label":"0236 : Consumer products > Retail & logistics > Trucking","value":"0236"},
    {"id":"0301","label":"0301 : Energy & natural resources > Utilities & cleantech > Cleantech producers","value":"0301"},
    {"id":"0302","label":"0302 : Energy & natural resources > Oil & gas > Coal & consumable fuels","value":"0302"},
    {"id":"0303","label":"0303 : Energy & natural resources > Utilities & cleantech > Electric utilities","value":"0303"},
    {"id":"0304","label":"0304 : Energy & natural resources > Utilities & cleantech > Energy infrastructure","value":"0304"},
    {"id":"0305","label":"0305 : Energy & natural resources > Utilities & cleantech > Environmental & facilities services","value":"0305"},
    {"id":"0306","label":"0306 : Energy & natural resources > Oil & gas > Gas utilities","value":"0306"},
    {"id":"0307","label":"0307 : Energy & natural resources > Oil & gas > Independent power producers and energy traders","value":"0307"},
    {"id":"0308","label":"0308 : Energy & natural resources > Utilities & cleantech > Multi-utilities","value":"0308"},
    {"id":"0309","label":"0309 : Energy & natural resources > Oil & gas > Oil & gas","value":"0309"},
    {"id":"0310","label":"0310 : Energy & natural resources > Utilities & cleantech > Waste water treatment or purification","value":"0310"},
    {"id":"0311","label":"0311 : Energy & natural resources > Utilities & cleantech > Water utilities","value":"0311"},
    {"id":"0312","label":"0312 : Energy & natural resources > Other > Extraction equipment and services","value":"0312"},
    {"id":"0313","label":"0313 : Energy & natural resources > Oil & gas > Oil & gas drilling","value":"0313"},
    {"id":"0314","label":"0314 : Energy & natural resources > Oil & gas > Oil & gas services","value":"0314"},
    {"id":"0315","label":"0315 : Energy & natural resources > Mining > Mining services","value":"0315"},
    {"id":"0316","label":"0316 : Energy & natural resources > Mining > Mining producers","value":"0316"},
    {"id":"0317","label":"0317 : Energy & natural resources > Mining > Mining explorers","value":"0317"},
    {"id":"0401","label":"0401 : Financial services > Asset management > Asset managers","value":"0401"},
    {"id":"0401-1","label":"0401-1 : Financial services > Asset management > Asset managers > Investment firm / PFS","value":"0401-1"},
    {"id":"0401-2","label":"0401-2 : Financial services > Asset management > Asset managers > Investment Fund Managers","value":"0401-2"},
    {"id":"0401-3","label":"0401-3 : Financial services > Asset management > Asset managers > Other","value":"0401-3"},
    {"id":"0402","label":"0402 : Financial services > Banking > Brokerage","value":"0402"},
    {"id":"0403","label":"0403 : Financial services > Banking > Commercial banks","value":"0403"},
    {"id":"0404","label":"0404 : Financial services > Banking > Credit unions","value":"0404"},
    {"id":"0405","label":"0405 : Financial services > Asset management > Fund administrators","value":"0405"},
    {"id":"0405-1","label":"0405-1 : Financial services > Asset management > Fund administrators > Specialised PFS","value":"0405-1"},
    {"id":"0405-2","label":"0405-2 : Financial services > Asset management > Fund administrators > Other","value":"0405-2"},
    {"id":"0406","label":"0406 : Financial services > Private equity > Global private equity","value":"0406"},
    {"id":"0407","label":"0407 : Financial services > Asset management > Hedge funds & special purpose vehicles","value":"0407"},
    {"id":"0407-1","label":"0407-1 : Financial services > Asset management > Hedge funds & special purpose vehicles > SIF","value":"0407-1"},
    {"id":"0407-2","label":"0407-2 : Financial services > Asset management > Hedge funds & special purpose vehicles > SICAR","value":"0407-2"},
    {"id":"0407-3","label":"0407-3 : Financial services > Asset management > Hedge funds & special purpose vehicles > UCITS part 1 /2","value":"0407-3"},
    {"id":"0407-4","label":"0407-4 : Financial services > Asset management > Hedge funds & special purpose vehicles > RAIF","value":"0407-4"},
    {"id":"0407-5","label":"0407-5 : Financial services > Asset management > Hedge funds & special purpose vehicles > Securitisation (regulated)","value":"0407-5"},
    {"id":"0407-6","label":"0407-6 : Financial services > Asset management > Hedge funds & special purpose vehicles > Securitisation (no regulated)","value":"0407-6"},
    {"id":"0407-7","label":"0407-7 : Financial services > Asset management > Hedge funds & special purpose vehicles > Other (no regulated)","value":"0407-7"},
    {"id":"0408","label":"0408 : Financial services > Banking > Investment Banking","value":"0408"},
    {"id":"0409","label":"0409 : Financial services > Insurance > Insurance brokers and intermediaries","value":"0409"},
    {"id":"0410","label":"0410 : Financial services > Private equity > Mid-market private equity","value":"0410"},
    {"id":"0411","label":"0411 : Financial services > Banking > Specialised finance","value":"0411"},
    {"id":"0412","label":"0412 : Financial services > Banking > Thrifts & mortgage finance","value":"0412"},
    {"id":"0413","label":"0413 : Financial services > Private equity > Venture capital & venture capital trusts","value":"0413"},
    {"id":"0414","label":"0414 : Financial services > Banking > Diversified financial services","value":"0414"},
    {"id":"0414-1","label":"0414-1 : Financial services > Banking > Diversified financial services > Support PFS","value":"0414-1"},
    {"id":"0414-2","label":"0414-2 : Financial services > Banking > Diversified financial services > EU financial institutions","value":"0414-2"},
    {"id":"0414-3","label":"0414-3 : Financial services > Banking > Diversified financial services > Credit institutions","value":"0414-3"},
    {"id":"0414-4","label":"0414-4 : Financial services > Banking > Diversified financial services > Payment Institutions / Electronic Money Institutions / AISP","value":"0414-4"},
    {"id":"0414-5","label":"0414-5 : Financial services > Banking > Diversified financial services > Virtual Asset Service Providers (VASP)","value":"0414-5"},
    {"id":"0414-6","label":"0414-6 : Financial services > Banking > Diversified financial services > Other","value":"0414-6"},
    {"id":"0415","label":"0415 : Financial services > Banking > Consumer finance","value":"0415"},
    {"id":"0416","label":"0416 : Financial services > Banking > Capital markets","value":"0416"},
    {"id":"0417","label":"0417 : Financial services > Insurance > Life and health insurance","value":"0417"},
    {"id":"0417-1","label":"0417-1: Financial services > Insurance > Life and health insurance > Reinsurance","value":"0417-1"},
    {"id":"0417-2","label":"0417-2: Financial services > Insurance > Life and health insurance > Reinsurance Captive","value":"0417-2"},
    {"id":"0418","label":"0418 : Financial services > Insurance > Non-life insurance","value":"0418"},
    {"id":"0418-1","label":"0418-1 : Financial services > Insurance > Non-life insurance > Reinsurance","value":"0418-1"},
    {"id":"0418-2","label":"0418-2 : Financial services > Insurance > Non-life insurance > Reinsurance Captive","value":"0418-2"},
    {"id":"0501","label":"0501 : Healthcare > Healthcare providers & services > Healthcare services","value":"0501"},
    {"id":"0502","label":"0502 : Healthcare > Healthcare providers & services > Healthcare technology","value":"0502"},
    {"id":"0503","label":"0503 : Healthcare > Healthcare providers & services > Hospitals, health systems, clinics and special","value":"0503"},
    {"id":"0505","label":"0505 : Healthcare > Healthcare providers & services > Long term care","value":"0505"},
    {"id":"0506","label":"0506 : Healthcare > Healthcare providers & services > Managed healthcare","value":"0506"},
    {"id":"0507","label":"0507 : Healthcare > Healthcare providers & services > Rehabilitation centers","value":"0507"},
    {"id":"0508","label":"0508 : Healthcare > Life sciences > Biotechnology","value":"0508"},
    {"id":"0509","label":"0509 : Healthcare > Life sciences > Life sciences tools & services","value":"0509"},
    {"id":"0510","label":"0510 : Healthcare > Life sciences > Pharmaceuticals","value":"0510"},
    {"id":"0511","label":"0511 : Healthcare > Life sciences > Healthcare equipment & supplies","value":"0511"},
    {"id":"0512","label":"0512 : Healthcare > > Healthcare providers & services","value":"0512"},
    {"id":"0601","label":"0601 : Industrial products > Aerospace & defense > Aerospace & defense","value":"0601"},
    {"id":"0602","label":"0602 : Industrial products > Building products > Building products","value":"0602"},
    {"id":"0603","label":"0603 : Industrial products > Chemicals > Chemicals","value":"0603"},
    {"id":"0604","label":"0604 : Industrial products > Commercial printing > Commercial printing","value":"0604"},
    {"id":"0605","label":"0605 : Industrial products > Construction & farm machinery & heavy trucks > Construction & farm mach","value":"0605"},
    {"id":"0606","label":"0606 : Industrial products > Construction materials > Construction materials","value":"0606"},
    {"id":"0607","label":"0607 : Industrial products > Containers & packaging > Containers & packaging","value":"0607"},
    {"id":"0608","label":"0608 : Industrial products > Electrical equipment > Electrical equipment","value":"0608"},
    {"id":"0609","label":"0609 : Industrial products > Industrial conglomerates > Industrial conglomerates","value":"0609"},
    {"id":"0610","label":"0610 : Industrial products > Industrial machinery > Industrial machinery","value":"0610"},
    {"id":"0611","label":"0611 : Industrial products > Marine > Marine","value":"0611"},
    {"id":"0612","label":"0612 : Industrial products > Office electronics > Office electronics","value":"0612"},
    {"id":"0613","label":"0613 : Industrial products > Office services & supplies > Office services & supplies","value":"0613"},
    {"id":"0614","label":"0614 : Industrial products > Paper & forest products > Paper & forest products","value":"0614"},
    {"id":"0615","label":"0615 : Industrial products > Security & alarm services > Security & alarm services","value":"0615"},
    {"id":"0616","label":"0616 : Industrial products > Trading companies & distributors > Trading companies & distributors","value":"0616"},
    {"id":"0617","label":"0617 : Industrial products > Highways & railtracks > Highways & railtracks","value":"0617"},
    {"id":"0618","label":"0618 : Industrial products > Marine ports & services > Marine ports & services","value":"0618"},
    {"id":"0701","label":"0701 : Not for profit > Charities > Cultural organisations","value":"0701"},
    {"id":"0702","label":"0702 : Not for profit > Charities > Foundations","value":"0702"},
    {"id":"0703","label":"0703 : Not for profit > Social housing > Housing organisations","value":"0703"},
    {"id":"0704","label":"0704 : Not for profit > Charities > International NGOs","value":"0704"},
    {"id":"0705","label":"0705 : Not for profit > Charities > Membership organisations","value":"0705"},
    {"id":"0706","label":"0706 : Not for profit > Charities > Museums","value":"0706"},
    {"id":"0707","label":"0707 : Not for profit > Other not for profit organisations > Other not for profit organisations","value":"0707"},
    {"id":"0708","label":"0708 : Not for profit > Charities > Professional and trade associations","value":"0708"},
    {"id":"0709","label":"0709 : Not for profit > Charities > Religious organisations","value":"0709"},
    {"id":"0710","label":"0710 : Not for profit > Charities > Social services organisations","value":"0710"},
    {"id":"0711","label":"0711 : Not for profit > Education > For profit colleges and universities","value":"0711"},
    {"id":"0712","label":"0712 : Not for profit > Education > Further education (continuing education)","value":"0712"},
    {"id":"0713","label":"0713 : Not for profit > Education > Private colleges and universities (not-for-profit)","value":"0713"},
    {"id":"0714","label":"0714 : Not for profit > Education > Public and private primary and secondary schools including relig","value":"0714"},
    {"id":"0715","label":"0715 : Not for profit > Education > Public colleges and universities (government)","value":"0715"},
    {"id":"0801","label":"0801 : Public sector > County, regional and municipal > County, regional and municipal","value":"0801"},
    {"id":"0802","label":"0802 : Public sector > Supranational governmental organisations > Supranational governmental organis","value":"0802"},
    {"id":"0803","label":"0803 : Public sector > National / Federal > National / Federal","value":"0803"},
    {"id":"0804","label":"0804 : Public sector > State and provincial > State and provincial","value":"0804"},
    {"id":"0805","label":"0805 : Public sector > Donor or international development organisations > Donor or international dev","value":"0805"},
    {"id":"0806","label":"0806 : Public sector > Quasi-government > Quasi-government","value":"0806"},
    {"id":"0901","label":"0901 : Services > Other > Advertising & marketing agencies","value":"0901"},
    {"id":"0902","label":"0902 : Services > Other > Diversified support services","value":"0902"},
    {"id":"0903","label":"0903 : Services > Professional services > Professional","value":"0903"},
    {"id":"0904","label":"0904 : Services > Other > Specialised consumer services","value":"0904"},
    {"id":"0905","label":"0905 : Services > Professional services > Research services","value":"0905"},
    {"id":"0906","label":"0906 : Services > Professional services > Architectural, engineering and technical services","value":"0906"},
    {"id":"0907","label":"0907 : Services > Professional services > Legal and accounting services","value":"0907"},
    {"id":"0908","label":"0908 : Services > Professional services > Advertising services","value":"0908"},
    {"id":"0909","label":"0909 : Services > Professional services > Management and related consulting services","value":"0909"},
    {"id":"0909-1","label":"0909-1 : Services > Professional services > Management and related consulting services > AIFM","value":"0909-1"},
    {"id":"0909-2","label":"0909-2 : Services > Professional services > Management and related consulting services > Management Companies chapter 15or16","value":"0909-2"},
    {"id":"0909-3","label":"0909-3 : Services > Professional services > Management and related consulting services > Payment Institutions / Electronic Money Institutions / AISP","value":"0909-3"},
    {"id":"0909-4","label":"0909-4 : Services > Professional services > Management and related consulting services > Other","value":"0909-4"},
    {"id":"1001","label":"1001 : Technology, media & telecommunications > Media > B2B publishing, events and conferences","value":"1001"},
    {"id":"1002","label":"1002 : Technology, media & telecommunications > Media > Consumer publishing","value":"1002"},
    {"id":"1003","label":"1003 : Technology, media & telecommunications > Telecomms > Diversified telecommunication services","value":"1003"},
    {"id":"1004","label":"1004 : Technology, media & telecommunications > Media > Entertainment, other","value":"1004"},
    {"id":"1005","label":"1005 : Technology, media & telecommunications > Software & infrastucture > Internet software & servi","value":"1005"},
    {"id":"1006","label":"1006 : Technology, media & telecommunications > Media > Music","value":"1006"},
    {"id":"1007","label":"1007 : Technology, media & telecommunications > Software & infrastucture > Software","value":"1007"},
    {"id":"1008","label":"1008 : Technology, media & telecommunications > Software & infrastucture > Technology hardware","value":"1008"},
    {"id":"1009","label":"1009 : Technology, media & telecommunications > Software & infrastucture > Technology services","value":"1009"},
    {"id":"1010","label":"1010 : Technology, media & telecommunications > Media > TV/film production & distribution","value":"1010"},
    {"id":"1011","label":"1011 : Technology, media & telecommunications > Telecomms > Wireless telecommunication services","value":"1011"},
    {"id":"1012","label":"1012 : Technology, media & telecommunications > Media > Media","value":"1012"},
    {"id":"1013","label":"1013 : Technology, media & telecommunications > IT services > IT consulting & other services","value":"1013"},
    {"id":"1014","label":"1014 : Technology, media & telecommunications > IT services > Data processing & outsourced services","value":"1014"},
    {"id":"1015","label":"1015 : Technology, media & telecommunications > Software & infrastucture > Electronic manufacturing","value":"1015"},
    {"id":"1016","label":"1016 : Technology, media & telecommunications > Software & infrastucture > Technology distributors","value":"1016"},
    {"id":"1017","label":"1017 : Technology, media & telecommunications > Software & infrastucture > Semiconductor equipment","value":"1017"},
    {"id":"1018","label":"1018 : Technology, media & telecommunications > Software & infrastucture > Semiconductors","value":"1018"},
    {"id":"1101","label":"1101 : Travel, tourism & leisure > Tourism & leisure > Amusement parks, theme parks & race tracks","value":"1101"},
    {"id":"1102","label":"1102 : Travel, tourism & leisure > Tourism & leisure > Casinos and gaming","value":"1102"},
    {"id":"1103","label":"1103 : Travel, tourism & leisure > Tourism & leisure > Cruise lines","value":"1103"},
    {"id":"1104","label":"1104 : Travel, tourism & leisure > Tourism & leisure > Fitness facilities","value":"1104"},
    {"id":"1105","label":"1105 : Travel, tourism & leisure > Tourism & leisure > Golf courses","value":"1105"},
    {"id":"1106","label":"1106 : Travel, tourism & leisure > Hospitality > Hotels and motels","value":"1106"},
    {"id":"1107","label":"1107 : Travel, tourism & leisure > Hospitality > Restaurants","value":"1107"},
    {"id":"1108","label":"1108 : Travel, tourism & leisure > Tourism & leisure > Sports arenas","value":"1108"},
    {"id":"1109","label":"1109 : Travel, tourism & leisure > Tourism & leisure > Tour operators","value":"1109"},
    {"id":"1110","label":"1110 : Travel, tourism & leisure > Tourism & leisure > Travel agencies","value":"1110"},
    {"id":"1111","label":"1111 : Travel, tourism & leisure > Transport > Vehicle rental and taxi companies","value":"1111"},
    {"id":"1112","label":"1112 : Travel, tourism & leisure > Transport > Airlines and miscellaneous non-rail transportation","value":"1112"},
    {"id":"1113","label":"1113 : Travel, tourism & leisure > Transport > Railroads","value":"1113"},
    {"id":"1114","label":"1114 : Travel, tourism & leisure > Transport > Airport services","value":"1114"},
    {"id":"1201","label":"1201 : Individual private clients > Individual or family > Individual or family","value":"1201"},
    {"id":"1202","label":"1202 : Individual private clients > Trust > Trust","value":"1202"},
    {"id":"9999","label":"9999 : N.A.","value":"9999"},
    {"id":"9998","label":"9998 NACE not in GTI","value":"9998"},
]