import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// MSAL imports
import { EventType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { EventMessage } from '@azure/msal-browser/dist/event/EventMessage';
import { CustomNavigationClient } from './security/navigationClient';
import { AppProps } from './security/types';

// App imports
import { PageLayout } from './components/layout/PageLayout';
import { PageLoader } from './components/content/PageLoader';
import { AppRoutes } from './AppRoutes';
import { ApolloProvider } from '@apollo/client';
import { myGtApiClient } from './service/api/api';
import { AnonymousLanding } from './AnonymousLanding';
import { GlobalErrorType, NetworkErrorsType, useGlobalError } from './service/globalError';
import { MyGTApiErrors } from './service/api/graphqlErrors';
import { config } from './config';

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions
 *  when MSAL redirects between pages in your app
 */
const ClientSideNavigation: React.FunctionComponent<any> = ({ pca, children }) => {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to
    // get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
}

export function Pages() {
    const { instance } = useMsal();

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: EventMessage) => {
            /*
            const payload = event.payload as AuthenticationResult;
            if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && payload.account) {
                console.log("Login success");
            }

            if (event.eventType === EventType.SSO_SILENT_SUCCESS && payload.account) {
                console.log("silent login success");
            }
             */

            if (event.eventType === EventType.LOGIN_FAILURE) {
                console.log("login failure");
                console.log(event.error);
            }

        });

        // on umount component : we remove the handler
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
    }, [instance]);

    return <AppRoutes />
}

const App:React.FunctionComponent<AppProps> = ({ pca }) => {
    const { t } = useTranslation();
    useGlobalError((error) => {
        if(error.errorType === GlobalErrorType.network) {

            switch (error.networkError) {
                case NetworkErrorsType.InteractionRequiredAuthError:
                    toast.warning(t('MyGT.GlobalError.AuthenticationError'), { toastId: 'global-error'});
                    break;
                case NetworkErrorsType.Unknown:
                    toast.error(t('MyGT.GlobalError.NetworkError', {website_name:config.website_name}), { toastId: 'global-error'});
                    break;
            }


        } else if(error.errorType === GlobalErrorType.api && error.apiError) {
            switch (error.apiError) {
                case MyGTApiErrors.Forbidden:
                    toast.error(t('MyGT.GlobalError.ApiForbiddenError'), { toastId: 'global-error'});
                    break;
                case MyGTApiErrors.GraphQLValidationFailed:
                case MyGTApiErrors.InternalServerError:
                case MyGTApiErrors.Unknown:
                    toast.error(t('MyGT.GlobalError.ApiValidationError'), { toastId: 'global-error'});
                    break;
            }
        } else {
            toast.error(t('MyGT.GlobalError.UnexpectedError'), { toastId: 'global-error'});
        }
    });

    return (
        <ClientSideNavigation pca={pca}>
            <MsalProvider instance={pca}>
                <ApolloProvider client={myGtApiClient}>
                    <AuthenticatedTemplate>
                        <PageLayout>
                            <PageLoader>
                                <Pages />
                            </PageLoader>
                        </PageLayout>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <AnonymousLanding />
                    </UnauthenticatedTemplate>
                </ApolloProvider>
            </MsalProvider>
        </ClientSideNavigation>
    );
}

export default App;
