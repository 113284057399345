import { useQueryParams, NumberParam, StringParam, withDefault } from 'use-query-params';
import { PaginationFilter, DEFAULT_PAGE_SIZE } from '../../amethyst'

type CustomerUserQuery = {
    emailPattern:string,
    customerId?:string
};

type UseCustomerUserQuery = [updateCustomerUserQuery:(query:CustomerUserQuery, pagination?: PaginationFilter) => void, query:CustomerUserQuery, pagination: PaginationFilter]

export const useCustomerUserQuery = (customerUserquery: CustomerUserQuery = {emailPattern:''}, pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}):UseCustomerUserQuery => {
    const [query, setQuery] = useQueryParams({
        p: withDefault(NumberParam, pagination.page),
        s: withDefault(NumberParam, pagination.pageSize),
        e: withDefault(StringParam, customerUserquery.emailPattern),
        c: withDefault(StringParam, customerUserquery.customerId),
    });

    return [
        (query: CustomerUserQuery = {emailPattern:''}, pagination: PaginationFilter = {page:1, pageSize: DEFAULT_PAGE_SIZE}) => {
            setQuery({
                p:pagination.page,
                s:pagination.pageSize,
                e:query.emailPattern,
                c:query.customerId
            })
        },
        { emailPattern:query.e, customerId:query.c },
        { page:query.p, pageSize:query.s }
    ]
}

