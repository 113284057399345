import React, {DOMAttributes, PropsWithChildren } from "react"
import {Icon} from "../icon";

export enum LinkArrow {
    Left,
    Right
}

export enum LinkColor {
    Red = 'link-danger',
    White = 'link-white'
}

export interface LinkProps extends PropsWithChildren, DOMAttributes<HTMLAnchorElement> {
    href: string
    small?: boolean
    arrow?: LinkArrow
    color?: LinkColor
    disabled?: boolean
    target?:string
    rel?:string
    external?:boolean
}

export const Link:React.FC<LinkProps> = props => {
    const {href, small, arrow, color, disabled, children, target, external, rel, ...rest} = props
    return (
        <a href={href} target={target} rel={rel} className={`link ${small ? 'text-smallest': ''} ${arrow ? 'link-icon' : ''} ${color ? color : ''} ${disabled ? 'disabled' : ''} ${external ? 'external':''}`} aria-disabled={disabled ? true : undefined} tabIndex={disabled ? -1 : undefined} {...rest}>
            {arrow === LinkArrow.Left ? <Icon icon='chevron-small-left' /> : null}
            {children}
            {arrow === LinkArrow.Right ? <Icon icon='chevron-small-right' /> : null}
        </a>
    )
}