import React, { SyntheticEvent, FocusEvent } from 'react';
import { useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import { locales } from '../../../../i18n/format';
import "react-datepicker/dist/react-datepicker.css";
import "./formDatePicker.scss";
import { InputStatus } from '../../common';

registerLocale('en', locales['en']);
registerLocale('fr', locales['fr']);

export interface FormDatePickerProps {
    id:string
    name:string
    form?:string
    placeholder?:string
    locale?:string
    label:string,
    status?:InputStatus
    hint?:string
    selectedDate?: Date|null
    onChange?:(date: Date|null, event:SyntheticEvent|undefined) => void
    onBlur?:(e:FocusEvent<HTMLInputElement>) => void
    disabled?:boolean
    minDate?:Date,
    maxDate?:Date
}

export const FormDatePicker:React.FC<FormDatePickerProps> = (
    {
        id, form, locale, status, name, label,
        placeholder, hint, selectedDate, onChange, onBlur,
        disabled, minDate, maxDate}
) => {
    return (
        <div className="form-datepicker">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
            <DatePicker
                id={id}
                className={`form-field ${status === InputStatus.Invalid ? 'is-invalid':''}`}
                wrapperClassName="input"
                clearButtonClassName="datepicker-clear"
                calendarClassName="datepicker-calendar"
                placeholderText={placeholder}
                locale={locale}
                dateFormat="dd/MM/yyyy"
                isClearable={!disabled}
                selected={selectedDate}
                onChange={(date, event) => onChange && onChange(date, event) }
                onBlur={(e) => onBlur && onBlur(e) }
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
            />
            {(!!hint || !!status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <></>
            }
        </div>
    )
}

export interface FormDatePickerRangeProps extends Omit<FormDatePickerProps, 'onChange'| 'selectedDate'> {
    startDate?: Date|null
    endDate?: Date|null
    onChange?:(dates: [Date|null, Date|null]|null, event:SyntheticEvent|undefined) => void
}

export const FormDateRangePicker:React.FC<FormDatePickerRangeProps> = (
    {
        id, form, locale, status, name, label,
        placeholder, hint, startDate, onChange, onBlur,
        disabled, endDate, maxDate, minDate}
) => {
    return (
        <div className="form-datepicker">
            <label htmlFor={id} className={label ? "form-label" : "sr-only"}>{label ? label : name}</label>
            <DatePicker
                id={id}
                className={`form-field ${status === InputStatus.Invalid ? 'is-invalid':''}`}
                wrapperClassName="input"
                clearButtonClassName="datepicker-clear"
                calendarClassName="datepicker-calendar"
                placeholderText={placeholder}
                locale={locale}
                dateFormat="dd/MM/yyyy"
                isClearable={true}
                startDate={startDate}
                endDate={endDate}
                selectsRange={true}
                onChange={(dates, event) => onChange && onChange(dates, event) }
                onBlur={(e) => onBlur && onBlur(e) }
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
            />
            {(!!hint || !!status) ?
                <span className={`form-feedback ${status ? status : ''}`} id={`${id}-hint`}>{hint}</span> :
                <></>
            }
        </div>
    )
}

export const FieldDatePicker:React.FC<FormDatePickerProps> = (props) => {
    const [field, meta, helper ] = useField<Date|null|undefined>(props.name);

    return (
        <FormDatePicker
            {...props}
            selectedDate={field.value}
            status={ meta.touched && meta.error ? InputStatus.Invalid : undefined }
            hint={meta.error}
            onBlur={field.onBlur}
            onChange={(date) => helper.setValue(date, true)}
        />
    )
}

export interface FieldDatePickerRangeProps extends FormDatePickerRangeProps {
    startDateField:string
    endDateField:string
}

export const FieldDateRangePicker:React.FC<FieldDatePickerRangeProps> = (props) => {
    const [startDateField, metaStart, helperStart ] = useField<Date|null|undefined>(props.startDateField);
    const [endDateField, metaEnd, helperEnd ] = useField<Date|null|undefined>(props.endDateField);

    return (
        <FormDateRangePicker
            {...props}
            startDate={startDateField.value}
            endDate={endDateField.value}
            status={ metaStart.touched && (metaStart.error || metaEnd.error) ? InputStatus.Invalid : undefined }
            hint={metaStart.error || metaEnd.error}
            // onBlur={startDateField.onBlur}
            onBlur={() => helperStart.setTouched(true, true)}
            onChange={(dates) => {
                if(dates) {
                    helperStart.setValue(dates[0]);
                    helperEnd.setValue(dates[1]);
                } else {
                    helperStart.setValue(null);
                    helperEnd.setValue(null);
                }
            } }
        />
    )
}