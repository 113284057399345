import React, { useRef } from 'react';

/**
 * Hook to build a ref attached to a function component, to detect a click outside of it
 * @param callback
 */
export const useOutsideClick = <T extends HTMLElement>(callback: () => void) => {
    const ref = useRef<T|null>(null);

    React.useEffect(() => {
        const handleClick = (event:any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, callback]);

    return ref;
};
