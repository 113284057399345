import React from "react";
import { CrudLayout } from "../../layout/CrudLayout";
import { ManagerCrud } from './ManagerCrud';

export const ManagerManagement:React.FC<any> = () => {
    return (
        <CrudLayout>
            <ManagerCrud />
        </CrudLayout>
    )
}
