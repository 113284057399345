import React from "react"
import { Button } from "./Button";
import "./Button.scss";

export interface ButtonGroupProps {
    children:React.ReactElement<typeof Button>[]|React.ReactElement<typeof Button>;
    center?:boolean
}

export const ButtonGroup:React.FC<ButtonGroupProps> = ({children,center}) => {
    return (
        <div className={`button-group ${center ? "center":""}`}>
            {children}
        </div>
    )
}