import { gql } from '@apollo/client';

export const QueryGetNotifications = gql`
    query GetNotifications($filter: NotificationFilterInput) {
        notifications(filter: $filter) {
            _id
            description
            expirationDate
        }
    }
`

export const MutationCreateNotification = gql`
    mutation CreateNotification($notification: NotificationInput!) {
        createNotification(notification: $notification) {
            _id
            description
            expirationDate
        }
    }
`

export const MutationUpdateNotification = gql`
    mutation UpdateNotification($_id: ObjectId!, $notification: NotificationInput!) {
        updateNotification(_id: $_id, notification: $notification) {
            _id
            description
            expirationDate
        }
    }
`

export const MutationRemoveNotification = gql`
    mutation RemoveNotification($_id: ObjectId!) {
        removeNotification(_id: $_id)
    }
`