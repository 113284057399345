import { gql } from '@apollo/client';
export const QueryGetContacts = gql`
    query GetContacts {
        contacts {
            _id
            firstName
            lastName
            email
            phone
            title
            specialisation
            picture {
                _id
                downloadUrl
                filename,
                mimeType
            }
        }
    }
`;


export const QueryGetContactById = gql`
    query GetContactById($_id: ObjectId!) {
        contact(_id: $_id) {
            _id
            firstName
            lastName
            email
            phone
            title
            specialisation
            picture {
                _id
                downloadUrl
                filename,
                mimeType
            }
        }
    }
`;

export const QueryGetContactsSpecialisations = gql`
    query QueryGetContactsSpecialisations {
        contactsSpecialisations
    }
`

export const MutationCreateContact = gql`
    mutation CreateContact($contact: ContactInput!) {
        createContact(contact: $contact) {
            _id
            firstName
            lastName
            email
            phone
            title
            specialisation
            picture {
                _id
                downloadUrl
                filename,
                mimeType
            }    
        }
    }
`;

export const MutationUpdateContact = gql`
    mutation UpdateContact($_id: ObjectId!, $contact: ContactInput!) {
        updateContact(_id: $_id, contact: $contact) {
            _id
            firstName
            lastName
            email
            phone
            title
            specialisation
            picture {
                _id
                downloadUrl
                filename,
                mimeType
            }   
        }
    }
`;

export const MutationRemoveContactById = gql`
    mutation RemoveContactById($_id: ObjectId!) {
        removeContactById(_id: $_id)
    }
`