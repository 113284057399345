import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { AxiosProgressEvent } from 'axios';
import {
    Contact,
    MutationCreateContactArgs,
    MutationUpdateContactArgs,
    useSpecialisationTags,
} from '../../../model/contact';
import {
    Container,
    FieldFormCreatableMultiSelect,
    FieldImageUpload,
    FieldTextInput,
    Form,
    FormAction,
    FormGroup,
    IImageSelector,
    ISelectItem,
    Modal,
    ModalContent,
} from '../../amethyst';
import { buildBlobValue, validateEmail } from '../../utils';
import { useTranslation } from 'react-i18next';
import { ImagePreviewRatio } from '../../amethyst/form';

interface ContactFormType {
    email:string,
    firstName:string,
    lastName:string,
    phone:string,
    title:string,
    specialisation:ISelectItem[]
    picture:IImageSelector
}

export interface ContactCreateEditProps {
    contactToEdit:Contact|undefined
    onCreate: (params: MutationCreateContactArgs) => Promise<any>
    onUpdate: (params: MutationUpdateContactArgs) => Promise<any>
    show:boolean,
    close:() => void
}

const newContactDefaultValues:ContactFormType = {
    firstName: '',
    lastName: '',
    email: '',
    phone:'',
    title:'',
    picture:{ noImage:true, newImageFile:null, currentImageUrl:null},
    specialisation:[]
}

const ContactCreateEditComponent:React.FC<ContactCreateEditProps> = ({contactToEdit, onCreate, onUpdate, show, close}) => {
    const { tags } = useSpecialisationTags();
    const { t } = useTranslation();

    const formValidationSchema = Yup.object().shape({
        email: Yup.string()
            .label('Email')
            .test('invalid-email',t("FormValidation.InvalidEmail"), (arg:string|undefined) => !!arg && !!validateEmail(arg))
            .required(t("FormValidation.Required")),
        firstName: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length:60 }))
            .required(t("FormValidation.Required")),
        lastName: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length:60 }))
            .required(t("FormValidation.Required")),
        phone: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length:60 }))
            .required(t("FormValidation.Required")),
        title: Yup.string()
            .min(3, t("FormValidation.TooShort", { length:3 }))
            .max(60, t("FormValidation.TooLong", { length:60 }))
            .required(t("FormValidation.Required")),
        picture: Yup.object().shape({
            noImage:Yup.boolean().oneOf([false])
        }).required(t("FormValidation.Required")).label('Picture'),
        specialisation: Yup.array().required(t("FormValidation.Required")).min(1, t("FormValidation.TooFewItems", { length:1 })).max(4, t("FormValidation.TooManyItems", { length:4 }))
    });

    const tagsItems = useMemo<ISelectItem[]>(() => tags.map(t => ({id:t, label:t, value:t})), [tags]);

    const initialValues = useMemo<ContactFormType>(() => {
        if(!!contactToEdit) {
            return {
                email: contactToEdit.email,
                firstName: contactToEdit.firstName,
                lastName: contactToEdit.lastName,
                phone: contactToEdit.phone,
                title: contactToEdit.title,
                specialisation: contactToEdit.specialisation.map(s => ({id: s, label: s, value: s})),
                picture: { noImage: false, newImageFile:null, currentImageUrl: contactToEdit.picture.downloadUrl }
            }
        }
        return {...newContactDefaultValues}

    }, [contactToEdit])

    const progressCallback = (progressEvent: AxiosProgressEvent) => { console.log(progressEvent); }

    const onSubmit = async (values:ContactFormType, { resetForm }:FormikHelpers<ContactFormType>):Promise<void> => {
        const blobInput = await buildBlobValue(values.picture, contactToEdit?.picture, progressCallback);
        if(blobInput) {

            const contact = {
                email:values.email,
                firstName:values.firstName,
                lastName:values.lastName,
                phone:values.phone,
                title:values.title,
                specialisation:values.specialisation.map(s => s.value),
                picture:blobInput
            }

            if(!!contactToEdit) {
                await onUpdate({ _id:contactToEdit._id, contact });
            } else {
                await onCreate({ contact });
            }
        }

        resetForm({ values: newContactDefaultValues });
        close();
    }


    if(!show) return null;
    const editMode = !!contactToEdit;

    return (
        <Modal open={show} title={!editMode ? t("ContactManagement.AddContact") : `${t("Actions.Update")} ${contactToEdit?.email}`} onClose={() => close()} closeOnEscape>
            <ModalContent>
                <Formik initialValues={initialValues} validationSchema={formValidationSchema} onSubmit={onSubmit} onReset={() => close()} enableReinitialize>
                    {({values}) =>
                        <Container fluid>
                            <Form id="contactForm" inline>
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("ContactManagement.FirstName")}
                                        form="contactForm"
                                        id="firstName"
                                        name="firstName"
                                        placeholder={t("ContactManagement.FirstNamePlaceholder")}
                                    />
                                    <FieldTextInput
                                        label={t("ContactManagement.LastName")}
                                        form="contactForm"
                                        id="lastName"
                                        name="lastName"
                                        placeholder={t("ContactManagement.LastNamePlaceholder")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("ContactManagement.Email")}
                                        form="contactForm"
                                        id="email"
                                        name="email"
                                        placeholder={t("ContactManagement.EmailPlaceholder")}
                                    />
                                    <FieldTextInput
                                        label={t("ContactManagement.ContactTitle")}
                                        form="contactForm"
                                        id="title"
                                        name="title"
                                        placeholder={t("ContactManagement.ContactTitlePlaceholder")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldTextInput
                                        label={t("ContactManagement.Phone")}
                                        form="contactForm"
                                        id="phone"
                                        name="phone"
                                        placeholder={t("ContactManagement.PhonePlaceholder")}
                                    />
                                    <FieldFormCreatableMultiSelect
                                        id="specialisation"
                                        name="specialisation"
                                        label={t("ContactManagement.Specialisations")}
                                        placeholder={t("ContactManagement.SpecialisationsPlaceholder")}
                                        noOptionsMessage=""
                                        isClearable
                                        items={tagsItems}
                                        form="contactForm"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FieldImageUpload
                                        id="picture"
                                        name="picture"
                                        label={t("ContactManagement.PictureInput")}
                                        hint={t("ContactManagement.PictureHint")}
                                        resolutionType="ratio"
                                        resolutionHeight={240}
                                        resolutionWidth={240}
                                        maxFileSize={102400} // 100kb
                                        fileFormat={['jpg', 'jpeg', 'png']}
                                        resolutionErrorLabel={t("FormValidation.ImageResolutionDoesntMatch")}
                                        acceptTypeErrorLabel={t("FormValidation.UnsupportedFileFormat")}
                                        maxFileSizeErrorLabel={t("FormValidation.FileTooLarge")}
                                        ratio={ImagePreviewRatio.square}
                                        allowCropping={true}
                                    />
                                </FormGroup>
                                <FormAction
                                    submittingLabel={t("Actions.Saving")}
                                    submitLabel={t("Actions.Save")}
                                    resetLabel={t("Actions.Cancel")}
                                />
                            </Form>
                        </Container>
                    }
                </Formik>
            </ModalContent>
        </Modal>
    )
}

export const ContactCreateEdit:React.FC<ContactCreateEditProps> = (props) => {
    const { show } = props;
    if(show) {
        return <ContactCreateEditComponent {...props} />
    } else {
        return null;
    }
}