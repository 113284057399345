import React, { useMemo, useState } from 'react';
import {
    Contact,
    useContacts,
    useCreateContact,
    useUpdateContact,
    useRemoveContact,
    useSpecialisationTags,
} from '../../../model/contact';
import {
    Button, ButtonType,
    Container,
    DataGridCell, DataGridContent, DataGridFooter, DataGridHeader, DataGridRow,
    Loader, LoaderSize
} from '../../amethyst';
import { ConfirmModal } from "../../common/ConfirmModal";
import { ContactCreateEdit } from "./ContactCreateEdit";
import { useContextManagerRole } from "../../security/ProtectedRoute";
import { ManagerRole } from '../../../model/user';
import { useTranslation } from 'react-i18next';

export const ContactCrud: React.FC = () => {
    const { t } = useTranslation();
    const { contacts, loading } = useContacts();
    const { loading: tagLoading } = useSpecialisationTags()
    const [ createContact ] = useCreateContact();
    const [ updateContact ] = useUpdateContact();
    const [ deleteContact ] = useRemoveContact();
    const [ showEditForm, setShowEditForm ] = useState<boolean>(false);
    const [ contactToEdit, setContactToEdit ] = useState<Contact|undefined>(undefined);
    const [ contactToDelete, setContactToDelete] = useState<Contact | undefined>(undefined);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal ] = useState<boolean>(false);
    const { managerRoles } = useContextManagerRole();

    const canDelete = useMemo<boolean>(() => managerRoles.includes(ManagerRole.Admin), [managerRoles]);

    const onOpenEditForm = (contact: Contact|undefined = undefined) => {
        setContactToEdit(!!contact ? {...contact} : undefined);
        setShowEditForm(true);
    }

    const onOpenDeleteConfirmModal = (contact: Contact) => {
        setContactToDelete(contact);
        setShowDeleteConfirmModal(true);
    }

    const onDelete = () => {
        if(contactToDelete) {
            return deleteContact({_id: contactToDelete._id})
        }
        return Promise.resolve();
    }

    return (
        <>
            <ContactCreateEdit
                onCreate={createContact}
                onUpdate={updateContact}
                contactToEdit={contactToEdit}
                show={showEditForm}
                close={() => setShowEditForm(false)}
            />
            <ConfirmModal
                title={t("ContactManagement.ConfirmDeletion")}
                message={contactToDelete ? contactToDelete.email : ""}
                close={() => setShowDeleteConfirmModal(false)}
                show={showDeleteConfirmModal}
                onConfirmAction={onDelete}
            />
            <Container fluid>
                <DataGridHeader title={t("ContactManagement.Title")}>
                    <Button kind={ButtonType.primary} label={t("ContactManagement.AddContact")} onClick={() => onOpenEditForm()} />
                </DataGridHeader>
                { loading || tagLoading ? <Loader label={t("ContactManagement.Loading")} size={LoaderSize.medium} /> :
                    <>
                        <DataGridContent left hoverable columnNames={[
                            t("ContactManagement.Picture"),
                            t("ContactManagement.FirstName"),
                            t("ContactManagement.LastName"),
                            t("ContactManagement.Email")
                        ]}>
                            {
                                contacts.map((contact, index) =>
                                    <DataGridRow left key={index} >
                                        <DataGridCell>
                                            <div className="user-card-small-img">
                                                <img className="img-circle img-fluid" alt={`${contact.firstName} ${contact.lastName}`} src={contact.picture.downloadUrl} />
                                            </div>
                                        </DataGridCell>
                                        <DataGridCell>{contact.firstName}</DataGridCell>
                                        <DataGridCell>{contact.lastName}</DataGridCell>
                                        <DataGridCell>{contact.email}</DataGridCell>
                                        <DataGridCell details>
                                            <Button kind={ButtonType.tertiary} disabled={!canDelete} title={t("ContactManagement.DeleteContact")} aria-label={t("ContactManagement.DeleteContact")} icon="trash" onClick={() => onOpenDeleteConfirmModal(contact)} />
                                            <Button kind={ButtonType.tertiary} title={t("ContactManagement.EditContact")} aria-label={t("ContactManagement.EditContact")} icon="chevron-small-right" onClick={() => onOpenEditForm(contact)} />
                                        </DataGridCell>
                                    </DataGridRow>
                                )
                            }
                        </DataGridContent>
                        <DataGridFooter />
                    </>
                }
            </Container>
        </>
    )
}
