import React from "react";
import { ISelectItem } from "../../amethyst";
import { CustomerUser, CustomerUsersFilter, useCreateCustomerUser, UserLanguage } from '../../../model/user';
import { NewCustomerUserForm, NewCustomerUserFormType} from './NewCustomerUserForm';
import { EditCustomerUserForm } from './EditCustomerUserForm';

export interface CustomerUserCreateEditProps {
    user?:CustomerUser
    customers:ISelectItem[]
    show:boolean
    onClose:() => void
    filter:CustomerUsersFilter
}

export const CustomerUserCreateEdit:React.FC<CustomerUserCreateEditProps> = ({user, show, onClose, customers, filter}) => {
    const [ createCustomerUser ] = useCreateCustomerUser();

    const onCreateUser = async (values: NewCustomerUserFormType) => {
        const newCustomerUser = {
            firstName:values.firstName,
            lastName:values.lastName,
            email:values.email,
            customerIds: values.customers.map(i => i.id),
            language: values.language ? (values.language.value) as UserLanguage : undefined
        }
        return createCustomerUser({ customerUser:newCustomerUser }, filter);
    }

    return (
        <>
            { user ? <EditCustomerUserForm user={user} customers={customers} show={show} close={onClose} filter={filter} /> :
                <NewCustomerUserForm onCreate={onCreateUser} customers={customers} show={show} close={onClose} />
            }
        </>
    )
}
