import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    ButtonGroup,
    ButtonType,
    Form,
    FormGroup,
    FormSimpleSelect,
    ISelectItem,
    Modal,
    ModalAction,
    ModalContent,
} from '../../amethyst';
import { useCurrentCustomer } from './customerContext';
import { CustomerUser } from '../../../model/user';
import { useTranslation } from 'react-i18next';

export interface CustomerAccountSwitchProps {
    show:boolean
    close:() => void
    customerUser:CustomerUser
}

/**
 * Modal component to allow customer user, link to multiple account to switch between them
 * @param show
 * @param close
 * @param customerUser
 * @constructor
 */
export const CustomerAccountSwitch:React.FC<CustomerAccountSwitchProps> = ({show, close, customerUser}) => {
    const { t } = useTranslation();
    const { currentCustomerId, setCustomerId } = useCurrentCustomer();
    const [selectedCustomerItem, setSelectedCustomerItem] = useState<ISelectItem|undefined>(undefined);

    const customerItems = useMemo<ISelectItem[]>(() => {
        return customerUser.properties.relationships.map(r => ({id:r.customer._id, label:r.customer.name, value:r.customer.name}));
    }, [customerUser]);

    useEffect(() => {
        setSelectedCustomerItem(customerItems.find(c => c.id === currentCustomerId));
    }, [currentCustomerId, customerItems]);

    if(!show) {
        return null;
    }

    const onChangeSelection = (item:ISelectItem|null) => {
        if(item) {
            setSelectedCustomerItem(item);
        }
    }

    const onSaveSelection = () => {
        if(selectedCustomerItem) {
            setCustomerId(selectedCustomerItem.id);
        }
        close();
    }

    return (
        <Modal open={show} onClose={close} title={t('UserMenu.CustomerAccountModal.Title')} >
            <ModalContent>
                <Form id="customerAccounts">
                    <FormGroup>
                        <FormSimpleSelect
                            id="customers"
                            items={customerItems}
                            placeholder=""
                            name="customers"
                            label={t('UserMenu.CustomerAccountModal.ListLabel')}
                            noOptionsMessage=""
                            isClearable={false}
                            searchable={false}
                            selectedItemId={selectedCustomerItem?.id}
                            onChange={onChangeSelection}
                        />
                    </FormGroup>
                </Form>
            </ModalContent>
            <ModalAction>
                <ButtonGroup>
                    <Button kind={ButtonType.primary} label={t('Actions.Save')} onClick={onSaveSelection} />
                    <Button kind={ButtonType.secondary} label={t('Actions.Cancel')} onClick={close} />
                </ButtonGroup>
            </ModalAction>
        </Modal>
    )
}